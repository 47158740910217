import React from 'react';
import { useParams } from 'react-router-dom';

const ConstructionsMachine = () => {
  const { stepId } = useParams();

  return (
    <div>ConstructionsMachine - {stepId}</div>
  );
};

export default ConstructionsMachine;
