import React, { forwardRef } from 'react';
import { default as MuiNativeSelect } from '@mui/material/NativeSelect';
import { NativeSelectProps } from './types';

const NativeSelect = forwardRef<any, NativeSelectProps>((props, ref) => {
  const { label, isLabelWhite = false, ...other } = props;

  return (
    <div style={{ position: 'relative' }}>
      {label && (
        <span
          style={{
            position: 'absolute',
            color: '#7a7a7a',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '1.4375em',
            padding: '0 6px',
            background: isLabelWhite ? '#ffffff' : '#f6f6f6',
            display: 'block',
            top: '-8px',
            left: '8px',
            zIndex: 1,
          }}
        >
          {label}
        </span>
      )}
      <MuiNativeSelect
        ref={ref}
        disableUnderline
        sx={{
          '& > select': {
            padding: props.size !== 'small' ? '16px !important' : undefined,
            fontSize: props.size === 'small' ? '14px' : 'inherit',
            borderColor: '#bdbdbd !important',
            '&:focus': {
              backgroundColor: 'initial !important',
            },
          },
        }}
        {...other}
      />
    </div>
  );
});

NativeSelect.displayName = 'NativeSelect';

export default NativeSelect;
