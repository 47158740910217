import { gql } from '@apollo/client';

export const GET_NEW_REFUELING = gql`
  query GetNewRefueling {
    newRefueling @client {
      fuelTypeId
      state
    }
  }
`;
