import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import appCacheControl from '@state/mutations/app';
import useAppHeaderWidget from '@hooks/useAppHeaderWidget';

interface IProps {
  headline: string
  widget?: ReactNode
  menuButtonVisible?: boolean
  backButtonVisible?: boolean
}

const useHeader: FC<IProps> = ({ headline, widget, menuButtonVisible = true, backButtonVisible = true }) => {
  const { t } = useTranslation();
  const { setToolbarHeadline, setBackButtonVisibility, setMenuButtonVisibility } = appCacheControl;

  setToolbarHeadline(t(headline));
  useAppHeaderWidget(widget);
  setBackButtonVisibility(backButtonVisible);
  setMenuButtonVisibility(menuButtonVisible);

  return null;
};

export default useHeader;
