import { ReactiveVar, makeVar } from '@apollo/client';
import { INavigationModal } from './types';

export const init: INavigationModal = {
  isBlocking: false,
  pendingNavigation: null,
};

const navigationModal: ReactiveVar<INavigationModal> = makeVar<INavigationModal>(init);

export default navigationModal;
