import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mechis/elements';

const MechisCalibration: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 256 256" {...props}>
      <path d="M160.2,162.1H156c0,25,20.3,45,45,45s45-20.3,45-45h-3.6h-0.6L211.6,61.9h0.9V49h-78.3l0.2-31.2H134h-0.5h-14.1h-0.5h-0.3
	l0.2,31.2H40.3v12.9h0.9L11.1,162.1h-0.6H6.8c0,24.9,20.3,45,45,45s45-20.1,45-45h-4.2h-1L61.5,61.9l57,0.4v156.3l-0.1-0.3v0.6H51.6
	V246h149.5v-27.2h-66.8v-0.6l-0.1,0.3V62.3l57-0.4L161,162.1H160.2z M81.7,162.1H21.9L51.3,63l0.3-0.1L81.7,162.1z M201.1,62.8
	l0.3,0.1l29.4,99.1H171L201.1,62.8z"/>
    </SvgIcon>
  );
};

export default MechisCalibration;
