import React, { FC } from 'react';
import { IProps } from './types';
import { Block, Headline } from './styles';
import { Paper, Box, Button } from '@mechis/elements';

const FormBlock: FC<IProps> = ({ children, headline, btnTitle, onSubmit }) => {
  return (
    <Block>
      <Paper variant="outlined">
        <Box p={2} pt={4} pb={4}>
          <Headline variant="h5">{headline}</Headline>
          {children}
        </Box>
      </Paper>
      {onSubmit && btnTitle && (
        <Box textAlign="right">
          <Button
            onClick={onSubmit}
            variant="contained"
            size="small"
            color="secondary"
            sx={{
              position: 'relative',
              top: (theme) => theme.spacing(-2),
            }}
          >
            {btnTitle}
          </Button>
        </Box>
      )}
    </Block>
  );
};

export default FormBlock;
