// eslint-disable-next-line
export enum OutputDateFormats {
  Classic = 'dd.MM.yyyy',
  ManufacturedDate = 'MM/yyyy',
}

export type MimeTypes =
  'text/plain' |
  'text/html' |
  'audio/mpeg' |
  'video/mp4' |
  'application/vnd.ms-excel' |
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' |
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' |
  'application/msword' |
  'application/pdf' |
  'application/vnd.ms-powerpoint' |
  'application/vnd.openxmlformats-officedocument.presentationml.presentation' |
  string
