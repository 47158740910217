import React from 'react';
import {
  Button,
  Paper,
  Box,
  Typography,
  Stack,
} from '@mechis/elements';
import useHeader from '@hooks/useHeader';
import Stepper from '@mechis/blocks/Stepper';
import { useNavigate } from 'react-router-dom';
import AddDeviceLayout from '@mechis/layouts/AddDeviceLayout';
import SimpleTable from '@mechis/blocks/SimpleTable';
import { ITagOption } from '@mechis/sections/Selections/TagFilters/types';
import useMaintenance from '@hooks/useMaintenance';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { THOUSAND_SEPARATOR } from '@configurations/constants/app';
import { useDeviceHeaderDetailQuery } from '@state/mechis-backend/generated/schema';
import {
  // MaintenanceInterval,
  useMaintenanceIntervalsByDeviceQuery,
} from '@state/mechis-backend/generated/schema';
import FormBlock from '@mechis/blocks/FormBlock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Date from '@mechis/sections/Selections/Date';
import Odometer from '@mechis/sections/Selections/Odometer';
import Attachments from '@mechis/sections/Selections/Attachments';
import { useTranslation } from 'react-i18next';
import useTech from '@hooks/useTech';

const Step3 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { techId } = useTech();

  useHeader({
    headline: t('newMaintenance.step3.headline'),
    widget: (
      <Stepper
        steps={4}
        defaultStep={3}
        onBack={() => navigate(`/app/${techId}/new-maintenance/2`)}
      />
    ),
  });

  const { data: headerDetail } = useDeviceHeaderDetailQuery({
    variables: {
      deviceId: techId,
    },
    fetchPolicy: 'cache-first',
  });

  const { 
    onCreateMaintenanceEvent,
    description,
    expenses,
    prices,
    clearedOperations,
  } = useMaintenance({ keepOperations: true });
  
  const { data: maintenanceIntervals } = useMaintenanceIntervalsByDeviceQuery({
    variables: {
      deviceId: techId,
    },
  });

  const getCompletedOperations = () => {
    if (maintenanceIntervals && clearedOperations) {
      const operations = clearedOperations.map(({ operation }) => operation.id);    
      const data = maintenanceIntervals.maintenanceIntervalsByDevice
        .map((interval) => {
          const operationData: Array<any> = interval.operation
            ? interval.operation
              .filter((operation) => operations.includes(+operation.id))
              .map((operation) => ({
                label: operation.name,
                value: <CheckCircleIcon color="secondary" />,
              }))
            : [];
          if (operationData.length > 0) {
            return {
              name: interval.name,
              data: operationData,
            };
          }
          return null;
        })
        .filter(Boolean); 
      return data;
    }
  };

  const getExpensesData = () => {
    return expenses.map((expense: ITagOption) => {
      return {
        label: expense.tagTranslation,
        value: (
          <NumberFormat
            value={_.filter(prices, { tagId: expense.tagId })[0].price}
            suffix=" kč"
            displayType="text"
            thousandSeparator={THOUSAND_SEPARATOR}
          />
        ),
      };
    });
  };

  const getPriceTotal = () => {
    let total = 0;
    expenses.forEach((expense: ITagOption) => {
      const price = _.filter(prices, { tagId: expense.tagId })[0].price;
      if (price) total += price;
    });
    return total;
  };

  return (
    <AddDeviceLayout
      defaultNickname={headerDetail?.device?.name}
      disableEditMode
    >
      <Stack spacing={3} mt={-2}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Date />
          <Odometer />
          <Attachments />
        </Stack>
        <FormBlock headline="Provedené úkony">
          <Stack spacing={2}>
            {getCompletedOperations()?.map((interval, index) => 
              <SimpleTable
                title={interval?.name as string}
                key={index}
                // @ts-expect-error
                data={interval.data}
              />
            )}
          </Stack>
        </FormBlock>
        <SimpleTable
          title="Výdaje"
          data={getExpensesData()}
        />
        <Typography align="right" variant="sectionHeadline" sx={{ 
          lineHeight: 1, 
          marginTop: '12px !important', 
          marginBottom: '-24px !important',
          pr: 1.5,
        }}>
          {t('newMaintenance.step3.priceTotal')}: <Typography variant="h4">
            {getPriceTotal()} {t('newMaintenance.step3.currency')}</Typography>
        </Typography>
        <Box>
          <Typography variant="sectionHeadline">{t('newMaintenance.step3.note')}</Typography>
          <Paper>
            <Box p={2}>
              <Typography>
                <small>
                  {description ? description : <em>{t('newMaintenance.step3.withoutNote')}</em>}
                </small>
              </Typography>
            </Box>
          </Paper>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={onCreateMaintenanceEvent}
          sx={{
            alignSelf: 'center',
          }}
        >
          {t('newMaintenance.step3.save')}
        </Button>
      </Stack>
    </AddDeviceLayout>
  );
};

export default Step3;
