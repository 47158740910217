import React from 'react';
import useHeader from '@hooks/useHeader';
import AddFlowStepper from '@mechis/sections/AddFlowStepper';
import FuelList from '../components/FuelList';

const Step3 = () => {
  useHeader({
    headline: 'addDevice.motorcycle.step3.title',
    widget: (
      <AddFlowStepper />
    ),
  });

  return (
    <FuelList />
  );
};

export default Step3;
