import React, { FC } from 'react';
import { IProps } from './types';
import useNavigate from '@hooks/useNavigate';
import { isBrowser, isMobile } from 'react-device-detect';
import useAuth from '@hooks/useAuth';
import AppMobileNavigation from '@mechis/sections/AppNavigation/AppMobileNavigation/AppMobileNavigation';
import AppBrowserNavigation from '@mechis/sections/AppNavigation/AppBrowserNavigation/AppBrowserNavigation';

const AppNavigation: FC<IProps> = () => {
  const { LogoutConfirmModal } = useAuth();
  const { navigate } = useNavigate();

  const handleCreateLink = (link: string) => {
    navigate(link);
  };
  
  return (
    <>
      <LogoutConfirmModal />
      {isBrowser && (
        <AppBrowserNavigation
          onHandleCreateLink={handleCreateLink}
        />
      )}
      {isMobile && (
        <AppMobileNavigation
          onHandleCreateLink={handleCreateLink}
        />
      )}
    </>
  );
};

export default AppNavigation;
