import React, { FC, useRef, useState } from 'react';
import useAttachments from './useAttachments';
import {
  ContentBlock,
  ContentBlockFunctions,
  ContentBlockFilters,
} from './styles';
import {
  IconButton,
  Box,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  Stack,
  Grid,
  CircularProgress,
  Slider,
} from '@mechis/elements';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import WindowIcon from '@mui/icons-material/Window';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import AvatarEditor from 'react-avatar-editor';

interface AttachmentsContentProps {
  variant?: 'attachments' | 'avatar'
}

const AttachmentsContent: FC<AttachmentsContentProps> = ({ variant }) => {
  const {
    view,
    setView,
    selectedFiles,
    uploadedFiles,
    handleRemoveFile,
    filter,
    setFilter,
    isSelectedUploaded,
    isUploading,
  } = useAttachments();

  const editor = useRef(null);
  const [ scale, setScale ] = useState(1.2);

  const handleScaleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setScale(newValue);
    }
  };
  
  return (
    <ContentBlock>
      {variant === 'attachments' && (
        <Stack direction="row">
          <ContentBlockFilters spacing={2} alignItems="center" direction="row">
            <Chip
              label="Vše"
              variant={filter === 'all' ? 'filled' : 'outlined'}
              color={filter === 'all' ? 'primary' : 'secondary'}
              size="small"
              onClick={() => setFilter('all')}
            />
            <Chip
              label="Fotky"
              variant={filter === 'image' ? 'filled' : 'outlined'}
              color={filter === 'image' ? 'primary' : 'secondary'}
              size="small"
              onClick={() => setFilter('image')}
            />
            <Chip
              label="PDF"
              variant={filter === 'pdf' ? 'filled' : 'outlined'}
              color={filter === 'pdf' ? 'primary' : 'secondary'}
              size="small"
              onClick={() => setFilter('pdf')}
            />
          </ContentBlockFilters>
          <ContentBlockFunctions alignItems="center" direction="row">
            <IconButton size="large" onClick={() => setView('list')} sx={{ p: 0.5 }}>
              <Box
                sx={{
                  background: view === 'list' ? (theme) => theme.palette.secondary.main : undefined,
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ViewStreamIcon 
                  htmlColor={view === 'list' ? 'white' : undefined}
                  color={view === 'grid' ? 'primary' : undefined}
                />
              </Box>
            </IconButton>
            <IconButton onClick={() => setView('grid')} sx={{ p: 0.5 }}>
              <Box
                sx={{
                  background: view === 'grid' ? (theme) => theme.palette.secondary.main : undefined,
                  borderRadius: '50%',
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <WindowIcon 
                  htmlColor={view === 'grid' ? 'white' : undefined}
                  color={view === 'list' ? 'primary' : undefined}
                />
              </Box>
            </IconButton>
          </ContentBlockFunctions>
        </Stack>
      )}

      {variant === 'avatar' ? (
        <div>
          {selectedFiles.map((file, index) => (
            <Box key={index} textAlign="center">
              <AvatarEditor 
                ref={editor}
                // @ts-ignore
                image={file.preview}
                width={250}
                height={250}
                border={40}
                color={[ 0, 0, 0, 0.4 ]}
                scale={scale}
                rotate={0}
                //borderRadius={40}
                style={{
                  marginTop: '8px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                }}
              />
              <Typography
                variant="sectionHeadline" 
                mt={2}
                sx={{
                  display: 'inline',
                  
                }}
              >
                Zoom: {scale}
              </Typography>
              <Slider
                value={scale}
                min={1}
                max={5}
                step={0.1}
                onChange={handleScaleChange}
                aria-labelledby="zoom-slider"
                style={{ width: '300px', margin: '0px auto' }}
              />
            </Box>
          ))}
        </div>
      ) : (
        <>
          {view === 'list' && (
            <List>
              {selectedFiles.map((file, index) => {
                const isUploaded = isSelectedUploaded(file.name, file.size, file.type);

                return (
                  <ListItem 
                    key={index}
                    sx={{ 
                      border: isUploaded ? '1px dashed green' : '1px dashed lightgrey',
                      my: 2, 
                      borderRadius: '16px',
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        src={file.preview}
                        alt={file.name}
                        sx={{
                          border: '1px solid lightgrey',
                          backgroundColor: '#bdbdbd1f',
                        }} 
                      >
                        {file.type === 'application/pdf' && <PictureAsPdfIcon color="error" />}
                        {file.type === 'text/plain' && <TextSnippetIcon color="info" />}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.name}
                      secondary={`${(file.size / 1024 / 1024).toFixed(2)} MB`}
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                        display: 'block',
                      }}
                    />
                    <ListItemSecondaryAction>
                      {isUploading ? (
                        <CircularProgress />
                      ) : (
                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(file)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          )}
          {view === 'grid' && (
            <Grid container>
              {selectedFiles.map((file, index) => (
                <Grid key={index} item xs={4}>
                  <div style={{
                    width: '100%',
                    paddingTop: '100%',
                    position: 'relative',
                  }}>
                    <Avatar
                      variant="rounded"
                      src={file.preview}
                      alt={file.name}
                      sx={{
                        border: '1px solid lightgrey',
                        backgroundColor: '#bdbdbd1f',
                        position: 'absolute',
                        top: '10%',
                        left: '10%',
                        width: '80%',
                        height: '80%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }} 
                    >
                      {file.type === 'application/pdf' && <PictureAsPdfIcon color="error" />}
                      {file.type === 'text/plain' && <TextSnippetIcon color="info" />}
                    </Avatar>
                    <Typography 
                      variant="body2"
                      sx={{ 
                        position: 'absolute',
                        bottom: 0,
                        textAlign: 'center',
                        lineHeight: 1.25,
                        background: 'white',
                        padding: '4px',
                        borderRadius: '16px',
                      }}
                    >
                      <small><small>{file.name}</small></small>
                    </Typography>
                  </div>

                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </ContentBlock>
  );
};

export default AttachmentsContent;
