import React, { FC } from 'react';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import ControlMenu from '../ControlMenu';
import AttachmentListInfinitySroll from '@mechis/sections/AttachmentListInfinityScroll';
import { AttachmentsContent } from '@mechis/sections/Attachments';

const Attachments: FC = () => {
  useHeader({
    headline: 'appBar.headline.attachments',
    widget: (
      <Selections
        tagFilters={{ isSelection: true, order: 1 }}
        generalFilters={{
          isSelection: true,
          order: 2,
          isDate: true,
          isOdometer: true,
          isPrice: true,
          isRecordType: true,
        }}
      >
        <ControlMenu />
      </Selections>
    ),
  });

  return (
    <>
      <AttachmentsContent />
      <AttachmentListInfinitySroll />
    </>
  );
};

export default Attachments;
