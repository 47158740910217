import React from 'react';
import { Box, Button, Dialog, DialogContent } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { IModalUpdateFuelProps } from './types';
import FuelList from './components/FuelList';
import editFuel from '@state/models/editFuel';
import snackbarCacheControl from '@state/mutations/snackbar';
import { FuelCreateInput, FuelUpdateInput } from '@state/mechis-backend/generated/schema';
import UpdateInfoModalHeader from '@screens/TechDetail/Info/modals/UpdateInfoModalHeader';
import useTech from '@hooks/useTech';

const UpdateFuel: React.FC<IModalUpdateFuelProps> = ({
  isOpen,
  onClose,
  updateUsedFuels,
  usedFuelsData,
  errorUpdateUsedFuels,
}) => {
  const { t } = useTranslation();
  const { techId } = useTech();
  const { setSnackbar } = snackbarCacheControl;

  const handleSubmit = async () => {
    const formData = editFuel().fuels;
    const dataToUpdate: FuelUpdateInput[] = formData
      .filter((fuel) => fuel.isSelected && !!usedFuelsData?.device?.fuel.find((f) => parseInt(f.id) === fuel.id))
      .map((fuel) => ({
        id: fuel.id,
        actualConsumption: fuel.actualConsumption,
        consumptionUnit: fuel.consumptionUnit,
        fuelType: {
          id: fuel.fuelType?.id,
        },
        initialConsumption: fuel.initialConsumption,
        tankVolume: fuel.tankVolume,
        volumeUnit: fuel.volumeUnit,
      })) as any;

    const dataToCreate: FuelCreateInput[] = formData
      .filter((fuel) => fuel.id === 0 && fuel.isSelected)
      .map((fuel) => ({
        actualConsumption: fuel.actualConsumption,
        consumptionUnit: fuel.consumptionUnit,
        fuelType: {
          id: fuel.fuelType?.id,
        },
        initialConsumption: fuel.initialConsumption,
        tankVolume: fuel.tankVolume,
        volumeUnit: fuel.volumeUnit,
        device: {
          id: techId,
        },
      })) as any;

    const idsToDelete = formData
      .filter((fuel) => fuel.id !== 0 && !fuel.isSelected)
      .map((fuel) => fuel.id);

    await updateUsedFuels({
      variables: {
        device: {
          id: techId,
          fuel: dataToUpdate,
        },
        fuels: dataToCreate,
        ids: idsToDelete,
      },
    });

    errorUpdateUsedFuels ? setSnackbar('error', 'DEVICE_UPDATE_FAILED') : setSnackbar('success', 'DEVICE_UPDATE_SUCCESS');
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen={true}
      maxWidth="xs"
      onClose={() => {
        onClose();
      }}
    >
      <DialogContent>
        <UpdateInfoModalHeader
          onClose={onClose}
          tParam="techDetail.headlines.fuelInfo"
        />
        <FuelList />
        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={false}
          >
            {t('myProfile.button.saveChanges')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateFuel;
