import colors from '@configurations/themes/default/colors';
import palette from '@configurations/themes/default/palette';

const variants = [
  {
    props: { variant: 'elevation' },
    style: {
      background: 'rgb(255, 255, 255)',
      boxShadow: 'rgba(255, 255, 255, 0.1) 0px 0px 0px 1px, rgba(14, 30, 37, 0.12) 0px 2px 4px 0px',
    },
  },
  {
    props: { variant: 'outlined' },
    style: {
      background: 'transparent',
      border: `2px dashed ${palette.common.lightGrey}`,
    },
  },
  {
    props: { variant: 'bg-primary-100' },
    style: {
      backgroundColor: colors.primary[100],
    },
  },
  {
    props: { variant: 'bg-primary-150' },
    style: {
      backgroundColor: colors.primary[150],
    },
  },
  {
    props: { variant: 'bg-primary-200' },
    style: {
      backgroundColor: colors.primary[200],
    },
  },
  {
    props: { variant: 'bg-primary-250' },
    style: {
      backgroundColor: colors.primary[250],
    },
  },
  {
    props: { variant: 'bg-primary-300' },
    style: {
      backgroundColor: colors.primary[300],
    },
  },
  {
    props: { variant: 'bg-primary-350' },
    style: {
      backgroundColor: colors.primary[350],
    },
  },
  {
    props: { variant: 'bg-primary-400' },
    style: {
      backgroundColor: colors.primary[400],
    },
  },
  {
    props: { variant: 'bg-primary-450' },
    style: {
      backgroundColor: colors.primary[450],
    },
  },
  {
    props: { variant: 'bg-primary-500' },
    style: {
      backgroundColor: colors.primary[500],
    },
  },
  {
    props: { variant: 'bg-primary-550' },
    style: {
      backgroundColor: colors.primary[550],
    },
  },
  {
    props: { variant: 'bg-primary-600' },
    style: {
      backgroundColor: colors.primary[600],
    },
  },
  {
    props: { variant: 'bg-primary-650' },
    style: {
      backgroundColor: colors.primary[650],
    },
  },
  {
    props: { variant: 'bg-primary-700' },
    style: {
      backgroundColor: colors.primary[700],
    },
  },
  {
    props: { variant: 'bg-primary-750' },
    style: {
      backgroundColor: colors.primary[750],
    },
  },
  {
    props: { variant: 'bg-primary-800' },
    style: {
      backgroundColor: colors.primary[800],
    },
  },
  {
    props: { variant: 'bg-primary-850' },
    style: {
      backgroundColor: colors.primary[850],
    },
  },
  {
    props: { variant: 'bg-primary-900' },
    style: {
      backgroundColor: colors.primary[900],
    },
  },
  {
    props: { variant: 'bg-secondary-100' },
    style: {
      backgroundColor: colors.secondary[100],
    },
  },
  {
    props: { variant: 'bg-secondary-150' },
    style: {
      backgroundColor: colors.secondary[150],
    },
  },
  {
    props: { variant: 'bg-secondary-200' },
    style: {
      backgroundColor: colors.secondary[200],
    },
  },
  {
    props: { variant: 'bg-secondary-250' },
    style: {
      backgroundColor: colors.secondary[250],
    },
  },
  {
    props: { variant: 'bg-secondary-300' },
    style: {
      backgroundColor: colors.secondary[300],
    },
  },
  {
    props: { variant: 'bg-secondary-350' },
    style: {
      backgroundColor: colors.secondary[350],
    },
  },
  {
    props: { variant: 'bg-secondary-400' },
    style: {
      backgroundColor: colors.secondary[400],
    },
  },
  {
    props: { variant: 'bg-secondary-450' },
    style: {
      backgroundColor: colors.secondary[450],
    },
  },
  {
    props: { variant: 'bg-secondary-500' },
    style: {
      backgroundColor: colors.secondary[500],
    },
  },
  {
    props: { variant: 'bg-secondary-550' },
    style: {
      backgroundColor: colors.secondary[550],
    },
  },
  {
    props: { variant: 'bg-secondary-600' },
    style: {
      backgroundColor: colors.secondary[600],
    },
  },
  {
    props: { variant: 'bg-secondary-650' },
    style: {
      backgroundColor: colors.secondary[650],
    },
  },
  {
    props: { variant: 'bg-secondary-700' },
    style: {
      backgroundColor: colors.secondary[700],
    },
  },
  {
    props: { variant: 'bg-secondary-750' },
    style: {
      backgroundColor: colors.secondary[750],
    },
  },
  {
    props: { variant: 'bg-secondary-800' },
    style: {
      backgroundColor: colors.secondary[800],
    },
  },
  {
    props: { variant: 'bg-secondary-850' },
    style: {
      backgroundColor: colors.secondary[850],
    },
  },
  {
    props: { variant: 'bg-secondary-900' },
    style: {
      backgroundColor: colors.secondary[900],
    },
  },
];

export default variants;
