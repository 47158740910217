import { radiuses } from '@configurations/themes/default/radiuses';

const styleOverrides = {
  root: {
    width: '100%',
    borderRadius: `${radiuses.medium}px`,
  },
};

export default styleOverrides;

