import { useDevicesQuery } from '@state/mechis-backend/generated/schema';

const useDevices = () => {
  const { data, loading, error } = useDevicesQuery();

  return {
    data,
    loading,
    error,
    userHasNoDevices: data?.devices && data.devices.length < 1,
  };
};

export default useDevices;
