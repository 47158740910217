import { NativeSelect } from '@mechis/elements';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n, t } = useTranslation();
  const [ language, setLanguage ] = useState(i18n.language);

  const handleLanguageChange = (e: any) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <>
      <NativeSelect
        id="language-select"
        value={language}
        onChange={handleLanguageChange}
        label={t('languageSwitcher.label')}
      >
        {/* <option value="en">{t('languageSwitcher.optionEnglish')}</option> */}
        <option value="cs">{t('languageSwitcher.optionCzech')}</option>
      </NativeSelect>
    </>
  );
};

export default LanguageSwitcher;
