import React, { useState, FC } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ButtonFixed } from './styles';

const ScrollButton: FC = () => {
  const [ visible, setVisible ] = useState(false);
  const SCROLL_BREAKPOINT = 100;
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > SCROLL_BREAKPOINT) {
      setVisible(true);
    } else if (scrolled <= SCROLL_BREAKPOINT) {
      setVisible(false);
    }
  };
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth',
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    <ButtonFixed>
      <ArrowUpwardIcon onClick={scrollToTop} fontSize="large"
        style={{display: visible ? 'inline' : 'none'}} />
    </ButtonFixed>
  );
};

export default ScrollButton;
