const variants = [
  {
    props: { size: 'large' },
    style: {
      fontSize: '2rem',
      padding: '6px',
    },
  },
  {
    props: { color: 'default' },
    style: ({ theme }: any) => ({
      color: theme.palette.common.lightGrey,
    }),
  },
  {
    props: { color: 'primary' },
    style: ({ theme }: any) => ({
      color: theme.palette.primary.main,
    }),
  },
  {
    props: { color: 'secondary' },
    style: ({ theme }: any) => ({
      color: theme.palette.secondary.main,
    }),
  },
  {
    props: { color: 'error' },
    style: ({ theme }: any) => ({
      color: theme.palette.error.main,
    }),
  },
];

export default variants;
