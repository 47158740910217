import { ReactiveVar } from '@apollo/client';
import navigateModal from '@state/models/navigateModal';
import { INavigationModal, PendingNavigation } from '../models/navigateModal/types';

const setIsBlocking = (navigateModalModel: ReactiveVar<INavigationModal>) => {
  return (isBlocking: boolean) => {
    const model = {
      ...navigateModalModel(),
      isBlocking,
    };
    navigateModalModel(model);
  };
};

const setPendingNavigation = (navigateModalModel: ReactiveVar<INavigationModal>) => {
  return (pendingNavigation: PendingNavigation | null) => {
    const model = {
      ...navigateModalModel(),
      pendingNavigation,
    };
    navigateModalModel(model);
  };
};

const navigateModalCacheControl = {
  setIsBlocking: setIsBlocking(navigateModal),
  setPendingNavigation: setPendingNavigation(navigateModal),
};

export default navigateModalCacheControl;
