import { styled } from '@mui/material/styles';

const LAYOUT_SPACING_DESKTOP = 1;
const LAYOUT_SPACING_MOBILE = 0;
const LAYOUT_BREAK_POINT = 'sm';
const CONTAINER_NAVIGATION_WIDTH = 340;

export const Layout = styled('div', {
  name: 'AppLayout',
  slot: 'Root',
})(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(LAYOUT_SPACING_DESKTOP),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.down(LAYOUT_BREAK_POINT)]: {
    flexDirection: 'column',
    padding: theme.spacing(LAYOUT_SPACING_MOBILE),
  },
}));

export const ContainerNavigation = styled('div', {
  name: 'AppLayout',
  slot: 'ContainerNavigation',
})(({ theme }) => ({
  order: 1,
  width: `${CONTAINER_NAVIGATION_WIDTH}px`,
  height: `calc(100vh - ${theme.spacing(LAYOUT_SPACING_DESKTOP * 2)})`,
  position: 'fixed',
  [theme.breakpoints.down(LAYOUT_BREAK_POINT)]: {
    order: 2,
    width: '100%',
    height: '60px',
    marginRight: 0,
    padding: theme.spacing(2),
    position: 'relative',
  },
}));

export const ContainerContent = styled('div', {
  name: 'AppLayout',
  slot: 'ContainerContent',
})(({ theme }) => ({
  width: '100%',
  order: 2,
  marginLeft: `calc(${CONTAINER_NAVIGATION_WIDTH}px + ${theme.spacing(LAYOUT_SPACING_DESKTOP)})`,
  [theme.breakpoints.down(LAYOUT_BREAK_POINT)]: {
    order: 1,
    marginLeft: 0,
  },
}));

