import React, {useState} from 'react';
import {
  ICloudinaryDestroyAttachmentRequestModel,
  IProps,
} from '@mechis/sections/Selections/UploadedImageAttachmentList/types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mechis/elements';
import ClaudinaryImageBuilder from '@mechis/blocks/ClaudinaryImageBuilder';
import Delete from '@mui/icons-material/Delete';
import {
  EventAttachmentsByEventIdDocument,
  useDeleteAttachmentMutation,
  useDeleteEventAttachmentMutation,
  useDestroySignatureLazyQuery,
} from '@state/mechis-backend/generated/schema';
import { CLAUDINARY_CLOUD_NAME } from '@configurations/constants/app';
import { useTranslation } from 'react-i18next';

const handleCloudinaryDestroyRequest = async(requestBody: ICloudinaryDestroyAttachmentRequestModel) => {
  try {
    const cloudinaryResponse = await fetch(`https://api.cloudinary.com/v1_1/${CLAUDINARY_CLOUD_NAME}/image/destroy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!cloudinaryResponse.ok) {
      throw new Error('Failed to make POST request');
    }

    return cloudinaryResponse.ok;
  } catch (e) {
    console.error('Error making Cloudinary POST request:', e);
  }
};

interface IAttachmentDetailState {
  readonly publicId: string
  readonly internalId: string
}

const detailEmptyState: IAttachmentDetailState = {
  publicId: '',
  internalId: '',
};

const UploadedImageAttachmentsList: React.FC<IProps> = ({ eventId, imageAttachments, setImageAttachments}) => {
  const { t } = useTranslation();
  const [ runDestroyQuery, { data, error, loading} ] = useDestroySignatureLazyQuery();
  const [ runDeleteEventAttachmentQuery ] = useDeleteEventAttachmentMutation({
    refetchQueries: [
      {
        query: EventAttachmentsByEventIdDocument,
        variables: {
          eventId: Number(eventId),
        },
      },
    ],
  });
  const [ runDeleteAttachmentQuery ] = useDeleteAttachmentMutation();
  const [ isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen ] = useState<boolean>(false);
  const [ attachmentDetails, setAttachmentDetails ] 
    = useState<IAttachmentDetailState>(detailEmptyState);

  const onDeleteAttachmentConfirm = async (attachment: IAttachmentDetailState) => {
    try {
      setIsDeleteConfirmModalOpen(false);
      const cloudinaryResponse = await handleCloudinaryDestroyRequest({
        api_key: process.env.REACT_APP_CLOUDINARY_API_KEY ?? '',
        public_id: attachment.publicId,
        signature: data?.destroySignature?.signature ?? '',
        timestamp: data?.destroySignature?.timestamp ?? '',
      });

      if (cloudinaryResponse && eventId) {
        await runDeleteEventAttachmentQuery({
          variables: {
            attachmentId: Number(attachment.internalId),
            eventId: Number(eventId),
          },
        });
        await runDeleteAttachmentQuery({ variables: { deleteAttachmentId: Number(attachment.internalId) }});
      }
    } catch (e) {
      console.error('onDeleteAttachmentConfirm: Failed to proceed', e);
    } finally {
      setImageAttachments(attachmentDetails.internalId);
    }
  };

  const handleDeleteImageAttachment = async (attachment: IAttachmentDetailState) => {
    const { publicId} = attachment;
    try {
      setIsDeleteConfirmModalOpen(true);
      setAttachmentDetails(attachment);
      await runDestroyQuery({ variables: { publicId }});
    } catch (e) {
      console.error(e, error);
    }
  };
  
  return (
    <>
      <Box mb={1}>
        <Typography variant="body1">{t('components.attachmentDialog.photos')}:</Typography>
      </Box>
      {imageAttachments.map((image) => (
        <Box key={image.id} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box mr={1}>
            <ClaudinaryImageBuilder
              imageId={image.storageName ?? ''}
              width={64}
              height={64}
            />
          </Box>
          <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" flex={1}>{image.originalName}</Box>
          <div style={{ minWidth: '15%', textAlign: 'right' }}>
            <IconButton
              size="small"
              onClick={() => handleDeleteImageAttachment({
                internalId: image.id,
                publicId: image.storageName ?? '',
              })}
              color="error"
            >
              <Delete fontSize="small" />
            </IconButton>
          </div>
        </Box>
      ))}
      <Dialog
        onClose={() => {
          setIsDeleteConfirmModalOpen(false);
        }}
        open={isDeleteConfirmModalOpen}
        maxWidth="xs"
      >
        <DialogContent>
          <DialogTitle
            sx={{
              fontSize: '1.3rem !important',
              color: (theme) => theme.palette.primary.dark,
              textAlign: 'center',
            }}
          >
            {t('components.attachmentDialog.deleteEnsure')}
          </DialogTitle>

          <Box mt={4} justifyContent="space-evenly" display="flex" flexDirection="row">
            <Button
              variant="outlined"
              disabled={(loading && !data)}
              onClick={() => onDeleteAttachmentConfirm(attachmentDetails)}
            >
              {t('components.attachmentDialog.delete')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsDeleteConfirmModalOpen(false);
              }}
            >
              {t('components.attachmentDialog.cancel')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadedImageAttachmentsList;
