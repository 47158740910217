import React, { FC } from 'react';

import { Skeleton, TableCell, TableRow } from '@mechis/elements';

const AnalysisWidgetSkeletonRow: FC = () => {
  return (
    <TableRow>
      <TableCell colSpan={2}>
        <Skeleton variant="rounded" sx={{ height: '16px' }} />
      </TableCell>
    </TableRow>
  );
};

export default AnalysisWidgetSkeletonRow;
