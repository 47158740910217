import { alpha } from '@mui/material/styles';

const variants = [
  {
    props: { color: 'white' },
    style: ({ theme }: any) => ({
      '& label': {
        color: alpha(theme.palette.common.white, 0.7),
      },
      '& .MuiOutlinedInput-input': {
        color: alpha(theme.palette.common.white, 0.7),
      },
      '& .MuiOutlinedInput-notchedOutline': {
        color: 'red',
        borderColor: alpha(theme.palette.common.white, 0.7),
      },
    }),
  },
];

export default variants;
