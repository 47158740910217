export interface IFormInputs {
  vin: string | null
}

export interface ICreateTechModalProps {
  isOpen: boolean
  onClose: () => void
}

export const PASSENGER_CAR_DEVICE_CATEGORY_ID = 1;
export const MOTORCYCLE_DEVICE_CATEGORY_ID = 6;

export const PASSENGER_CAR_DEFAULT_VEHICLE_TYPE_SEDAN_ID = 16;
export const MOTORCYCLE_DEFAULT_VEHICLE_TYPE_SUPERSPORT_ID = 30;
