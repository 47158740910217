import React, { FC } from 'react';
import { IProps, IOwnerState } from './types';
import { Block } from './styles';
import Typography from '@mechis/elements/Typography';
import { Tooltip } from '@mechis/elements';
import { useTranslation } from 'react-i18next';

const CreateBlock: FC<IProps> = ({ 
  title,
  icon,
  onClick,
  disabled,
  type = 'default',
}) => {
  const { t } = useTranslation();

  const ownerState: IOwnerState = {
    type,
  };

  return (
    disabled ? (
      <Tooltip title={t('deviceListing.noDeviceNavigationTooltip')} enterTouchDelay={0} placement="top">
        <Block ownerState={ownerState} sx={{opacity: 0.38}}>
          {icon}
          <Typography variant="h6">{title}</Typography>
        </Block>
      </Tooltip>
    ) : (
      <Block ownerState={ownerState} onClick={onClick}>
        {icon}
        <Typography variant="h6">{title}</Typography>
      </Block>
    ));
};

export default CreateBlock;
