import React, { useState, FC } from 'react';
import {
  Tabs,
  Tab,
  Box,
  Stack,
  Paper,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mechis/elements';
import useMaintenance from '@hooks/useMaintenance';
import Summary from '@mechis/sections/MaintenanceChecklist/components/Summary';
import { getLowestDueDate } from '@utilities/app';
import Operation from '@mechis/sections/MaintenanceChecklist/components/Operation';
import { useTranslation } from 'react-i18next';
import { IMaintenanceEditProps } from '../types';

const Edit: FC<IMaintenanceEditProps> = ({ eventId, onBack }) => {
  const { t } = useTranslation();
  const [ value, setValue ] = useState<number>(0);

  const { 
    maintenanceIntervals,
    operations,
    setOperations,
    onEditMaintenanceEvent,
  } = useMaintenance({ eventId: eventId });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // todo: fix typescript
  const isAllChecked = (operationsSet: any) => {
    // todo: fix typescript
    return operationsSet.every((operation: any) => operations.includes(+operation.id));
  };

  const onChangeCheckbox = (operationId: number) => {    
    const operationsCopy = [ ...operations ];
    const index = operationsCopy.indexOf(operationId);

    if (index !== -1) {
      operationsCopy.splice(index, 1);
    } else {
      operationsCopy.push(operationId);
    }

    setOperations(operationsCopy);
  };

  // todo: fix typescript
  const onChangeAllCheckbox = (operationsSet: any) => {
    const isAll = isAllChecked(operationsSet);
    if (!isAll) {
      const operationsCopy = [ ...operations ];
      // todo: fix typescript
      operationsSet.forEach((operation: any) => {
        if (!operationsCopy.includes(+operation.id)) {
          operationsCopy.push(+operation.id);
        }
      });
      setOperations(operationsCopy);
    } else {
      const operationsCopy = 
        operations.filter((operation: any) => !operationsSet.some((setOperation: any) => +setOperation.id === operation));
      setOperations(operationsCopy);
    }
  };

  return (
    <>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          // todo: prepare variant
          sx={{
            border: '1px dashed lightgrey',
            borderRadius: '8px',
          }}
        >
          {maintenanceIntervals && 
            maintenanceIntervals?.length > 0 && 
            maintenanceIntervals?.map((interval, index) =>
              <Tab
                key={interval.id}
                label={interval.name}
                value={index}
                wrapped
              />
            )}
        </Tabs>
        {maintenanceIntervals && 
            maintenanceIntervals?.length > 0 && 
            maintenanceIntervals?.map((interval, index) =>
              <Stack 
                key={interval.id}
                mt={2}
                spacing={2}
                sx={{ 
                  display: index === value ? 'block' : 'none',
                }}
              >
                <Box>
                  <Paper>
                    <Box p={2}>
                      <Summary
                        periodDay={interval.periodDay}
                        periodDistance={interval.periodDistance}
                        name={interval.name}
                        lowestDueDate={getLowestDueDate(interval)}
                        dueDistance={interval.dueDistance}
                      />
                    </Box>
                    <Box textAlign="right" mr={1} sx={{ position: 'relative', bottom: '8px' }}>
                      <FormControlLabel
                        label={interval.operation?.length === 1 && !interval.operation[0].isVisible ? 'Vybrat' : 'Vybrat vše'}
                        control={
                          <Checkbox
                            size="large"
                            checked={isAllChecked(interval.operation)}
                            onClick={() => onChangeAllCheckbox(interval.operation)}
                            color="primary"
                          />
                        }
                        labelPlacement="start"
                        sx={{
                          paddingRight: '8px',
                          '& > span': {
                            fontSize: '12px',
                            fontStyle: 'italic',
                            opacity: 0.8,
                          },
                        }}
                      />
                    </Box>
                  </Paper>
                </Box>
                <Box>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography 
                      variant="sectionHeadline" 
                      sx={{ 
                        pl: 1,
                        display: interval.operation?.length === 1 && !interval.operation[0].isVisible ? 'none' : 'initial',
                      }}
                    >
                      {t('newMaintenance.tasks')}
                    </Typography>
                  </Stack>
                  <Paper
                    sx={{
                      visibility: interval.operation?.length === 1 && !interval.operation[0].isVisible ? 'hidden' : 'initial',
                    }}
                  >
                    <Box p={2}>
                      {interval.operation?.map((operation, i) => 
                        <div key={operation.id}>
                          {operation.isVisible && (
                            <Operation
                              dueDistance={operation.dueDistance}
                              periodEndDate={operation.periodEndDate}
                              name={operation.name}
                              isLast={i === (interval?.operation?.length ?? 0) - 1}
                              type={operation.operationType}
                            >
                              <Checkbox
                                size="medium"
                                checked={operations.includes(+operation.id)}
                                onClick={() => onChangeCheckbox(+operation.id)}
                                color={operations.includes(+operation.id) ? 'secondary' : 'default'}
                              />
                            </Operation>
                          )}
                        </div>
                      )}
                    </Box>
                  </Paper>
                </Box>
              </Stack>
            )}
      </Box>
      <Stack justifyContent="center" mt={2} direction={'row'} spacing={2}>
        <Button variant="text" color="secondary" onClick={onBack}>
          {t('techDetail.updateEvent.maintenance.back')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            onEditMaintenanceEvent();
            onBack();
          }}
        >
          {t('techDetail.updateEvent.maintenance.update')}
        </Button>
      </Stack>
    </>
  );
};

export default Edit;
