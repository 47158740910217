/**
 * Normalizes a value to a percentage scale between 0 and 100 based on a maximum value.
 *
 * @param {number} value - The value to normalize.
 * @param {number} max - The maximum value to use for normalization.
 * @returns {number} The normalized value between 0 and 100.
 *
 * @example
 * // Normalizing a value of 25 with a maximum of 50
 * const normalizedValue = normalizeToPercentage(25, 50);
 * // Result: 50(%) (because 25/50 * 100 = 50)
 */
const normalizeToPercentage = (value: number, max: number): number => (value / max) * 100;

export default normalizeToPercentage;
