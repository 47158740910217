import React, { FC, useState, useEffect } from 'react';
import { IProps } from './types';
import { Backdrop, Box, Typography } from '@mui/material';

const PortraitOrientationWrapper: FC<IProps> = ({ children }) => {
  const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  const [ isPortrait, setIsPortrait ] = useState(isMobile ? window.matchMedia('(orientation: portrait)').matches : true);

  useEffect(() => {
    if (!isMobile) return;

    const handleResizeChange = () => {
      setIsPortrait(window.matchMedia('(orientation: portrait)').matches);
    };

    window.addEventListener('resize', handleResizeChange);
    return () => {
      window.removeEventListener('resize', handleResizeChange);
    };
  }, []);

  return (
    <>
      {children}
      <Backdrop
        open={!isPortrait}
        sx={{
          zIndex: 1300,
          backdropFilter: 'blur(20px)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        <Box textAlign="center" p={2}>
          <Typography variant="h4" color={'#fff'}>
            Režim na šířku není podporován
          </Typography>
          <Typography variant="body1" color={'#fff'}>
            Otočte prosím své zařízení do režimu na výšku.
          </Typography>
        </Box>
      </Backdrop>
    </>
  );
};

export default PortraitOrientationWrapper;
