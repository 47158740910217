import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mechis/elements';

export const Block = styled('div', {
  name: 'TechCard',
  slot: 'Root',
})(() => ({
  display: 'flex',
}));

export const Content = styled(Box, {
  name: 'TechCard',
  slot: 'Content',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const Title = styled(Typography, {
  name: 'TechCard',
  slot: 'Title',
})(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '0.85rem',
}));
