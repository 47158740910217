import React, { FC } from 'react';
import { IProps } from './types';
import {
  TextField,
  InputAdornment,
} from '@mechis/elements';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

const YearOfManufacture: FC<IProps> = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { setYearOfManufacture } = addDeviceCacheControl;

  const onChange = (value: Date | null) => {
    setYearOfManufacture(value);
  };

  return (
    <MobileDatePicker
      views={[ 'year', 'month' ]}
      label={t('addDevice.components.yearOfManufacture.name')}
      inputFormat="MM/yyyy"
      value={data?.addDevice.manufacturedYearMonth}
      onChange={onChange}
      renderInput={(params) =>
        <TextField
          id="yearOfManufacture"
          {...params}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PrecisionManufacturingIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      }
    />
  );
};

export default YearOfManufacture;
