import React, { FC, useState, useEffect } from 'react';
import { IProps } from './types';
import { Block } from './styles';
import { 
  Button,
  MobileStepper,
} from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const Stepper: FC<IProps> = ({ 
  steps,
  defaultStep,
  onChangeStep,
  onBack,
  onNext,
}) => {
  const { t } = useTranslation();
  const [ step, setStep ] = useState<number>(0);

  const handleBack = () => {
    const newStep = step-1;
    setStep(newStep);
    if (onChangeStep) onChangeStep(newStep);
    if (onBack) onBack();
  };
  
  const handleNext = () => {
    const newStep = step+1;
    setStep(newStep);
    if (onChangeStep) onChangeStep(newStep);
    if (onNext) onNext();
  };

  useEffect(() => {
    if (defaultStep) {
      setStep(defaultStep);
    }
  }, [ defaultStep ]);

  return (
    <Block>
      <MobileStepper
        variant="progress"
        steps={steps}
        position="static"
        activeStep={step}
        LinearProgressProps={{
          color: 'secondary',
        }}
        backButton={
          <Button
            size="medium"
            color="white"
            onClick={handleBack}
            disabled={step === 0}
          >
            <KeyboardArrowLeft id="previous-icon" />
            {t('components.stepper.back')}
          </Button>
        }
        nextButton={
          <Button
            size="medium"
            color="white"
            onClick={() => {
              if (step === (steps-1)) {
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: 'smooth',
                });
              } else {
                handleNext();
              }
            }}
          >
            {(step === (steps-1)) && 
              <>
                {t('components.stepper.finish')}
              </>
            }
            {!(step === (steps-1)) && 
              <>
                {t('components.stepper.next')}
              </>
            }
            <KeyboardArrowRight id="next-icon" />
          </Button>
        }
      />
    </Block>
  );
};

export default Stepper;
