import React, { useRef, useState } from 'react';
import {
  Box,
  Stack,
  IconButton,
  Button,
  Tabs,
  Tab,
  Paper,
  Typography,
} from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import useHeader from '@hooks/useHeader';
import useMaintenance from '@hooks/useMaintenance';
import Selections from '@mechis/sections/Selections';
import {
  Control,
  ControlLabel,
} from '@mechis/sections/Selections/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { v4 as uuid } from 'uuid';
import NewPlan from './modals/NewPlan';
import UpdatePlan from './modals/UpdatePlan';
import NewOperation from './modals/NewOperation';
import UpdateOperation from './modals/UpdateOperation';
import Operation from '@mechis/sections/MaintenanceChecklist/components/Operation';
import Summary from '@mechis/sections/MaintenanceChecklist/components/Summary';
import EditIcon from '@mui/icons-material/Edit';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {
  MaintenanceInterval,
  Operation as OperationSchema,
} from '@state/mechis-backend/generated/schema';
import ControlMenu from '../ControlMenu';
import QuoteBlock from '@mechis/blocks/QuoteBlock';
import useAuth from '@hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const MaintenancePlan = () => {
  const { t } = useTranslation();
  const { isComgateUser } = useAuth();
  const navigate = useNavigate();
  const [ openModalNewMaintenance, setOpenModalNewMaintenance ] = useState(false);

  if (isComgateUser()) {
    navigate('/');
  }

  useHeader({
    headline: 'appBar.headline.maintenancePlan',
    widget: (
      <Selections>
        <Control>
          <IconButton
            size="small"
            color="secondary"
            isSquared
            onClick={() => setOpenModalNewMaintenance(true)}
          >
            <AddCircleOutlineIcon fontSize="large" />
          </IconButton>
          <ControlLabel ownerState={{ state: 'filled' }}>
            {t('newMaintenance.button.newMaintenance')}
          </ControlLabel>
        </Control>
        <ControlMenu />
      </Selections>
    ),
  });

  const [ value, setValue ] = useState<number>(0);
  const [ openModalNewOperation, setOpenModalNewOperation ] = useState(false);
  const [ openModalUpdateInterval, setOpenModalUpdateInterval ] = useState(false);
  const [ openUpdateOperation, setOpenUpdateOperation ] = useState(false);
  const currentInterval = useRef<MaintenanceInterval>();
  const currentOperation = useRef<OperationSchema>();

  const { 
    maintenanceIntervals,
    createMaintenanceInterval,
    errorCreatingInterval,
    createOperation,
    errorCreateOperation,
    updateOperation,
    errorUpdateOperation,
    deleteOperation,
    updateMainMaintenanceInterval,
    errorUpdateMaintenanceInterval,
    deleteMaintenanceInterval,
  } = useMaintenance({});

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleOpenNewOperationModal = (interval: any) => {
    currentInterval.current = interval;
    setOpenModalNewOperation(true);
  };

  const handleOpenEditMaintenanceModal = (interval: any) => {
    currentInterval.current = interval;
    setOpenModalUpdateInterval(true);
  };

  // todo: fix typescript here?
  // interval: MaintenanceInterval
  const handleOpenUpdateOperationModal = (operation: OperationSchema, interval: any) => {
    currentInterval.current = interval;
    currentOperation.current = operation;
    setOpenUpdateOperation(true);
  };

  const onCreated = () => {
    // eslint-disable-next-line
    const value = maintenanceIntervals?.length ? maintenanceIntervals.length : 0;
    setValue(value);
  };

  const onDeleted = () => {
    setValue(0);
  };

  if (maintenanceIntervals?.length === 0) {
    return (
      <>
        <NewPlan
          open={openModalNewMaintenance}
          setOpen={() => setOpenModalNewMaintenance(false)}
          createMaintenanceInterval={createMaintenanceInterval}
          errorCreatingInterval={errorCreatingInterval}
          onCreated={onCreated}
        />
        <QuoteBlock>
          {t('newMaintenance.noPlan')}
        </QuoteBlock>
        <Button
          onClick={() => setOpenModalNewMaintenance(true)}
          variant="contained"
          color="secondary"
          sx={{
            margin: '16px auto',
            display: 'block',
          }}
        >
          {t('newMaintenance.button.create')}
        </Button>
      </>
    );
  }

  return (
    <div>
      <NewPlan
        open={openModalNewMaintenance}
        setOpen={() => setOpenModalNewMaintenance(false)}
        createMaintenanceInterval={createMaintenanceInterval}
        errorCreatingInterval={errorCreatingInterval}
        onCreated={onCreated}
      />
      <UpdatePlan
        open={openModalUpdateInterval}
        setOpen={() => setOpenModalUpdateInterval(false)}
        maintenanceData={currentInterval.current}
        updateMaintenanceInterval={updateMainMaintenanceInterval}
        deleteMaintenanceInterval={deleteMaintenanceInterval}
        errorUpdateMaintenance={errorUpdateMaintenanceInterval}
        onDeleted={onDeleted}
      />
      <NewOperation
        open={openModalNewOperation}
        setOpen={() => setOpenModalNewOperation(false)}
        maintenanceInterval={currentInterval.current}
        createOperation={createOperation}
        error={errorCreateOperation}
      />
      <UpdateOperation
        open={openUpdateOperation}
        setOpen={() => setOpenUpdateOperation(false)}
        updateOperation={updateOperation}
        deleteOperation={deleteOperation}
        error={errorUpdateOperation}
        operationData={currentOperation.current}
        intervalId={currentInterval.current?.id}
      />
      <Tabs 
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        // todo: prepare variant
        sx={{
          border: '1px dashed lightgrey',
          borderRadius: '8px',
        }}
      >
        {maintenanceIntervals && 
          maintenanceIntervals?.length > 0 && 
          maintenanceIntervals?.map((interval, index) =>
            <Tab
              key={uuid()}
              label={interval.name}
              value={index}
              wrapped
            />
          )}
      </Tabs>
      {maintenanceIntervals && 
          maintenanceIntervals?.length > 0 && 
          maintenanceIntervals?.map((interval, index) =>
            <Stack 
              key={uuid()}
              mt={2}
              spacing={2}
              sx={{ 
                display: index === value ? 'block' : 'none',
              }}
            >
              <Box>
                <Paper>
                  <Box p={2}>
                    <Summary
                      periodDay={interval.periodDay}
                      periodDistance={interval.periodDistance}
                      name={interval.name}
                      lowestDueDate={null}
                      dueDistance={interval.dueDistance}
                      onEdit={() => handleOpenEditMaintenanceModal(interval)}
                    />
                  </Box>
                </Paper>
              </Box>
              <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="sectionHeadline" sx={{ pl: 1 }}>Úkony</Typography>
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    sx={{ mb: 1 }}
                    onClick={() => handleOpenNewOperationModal(interval)}
                    startIcon={<PlaylistAddIcon />}
                  >
                    Přidat úkon
                  </Button>
                </Stack>
                <Paper
                  sx={{ 
                    visibility: interval.operation?.length === 1 && !interval.operation[0].isVisible ? 'hidden' : 'initial',
                  }}
                >
                  <Box p={2}>
                    {interval.operation?.map((operation, i) => 
                      <div key={uuid()}>
                        {operation.isVisible && (
                          <Operation
                            periodDay={operation.periodDay}
                            periodDistance={operation.periodDistance}
                            name={operation.name}
                            isLast={i === (interval?.operation?.length ?? 0) - 1}
                            description={operation.description ?? undefined}
                            type={operation.operationType}
                          >
                            <IconButton
                              className="edit-function"
                              variant="default"
                              color="primary"
                              isSquared
                              size="small"
                              onClick={() => handleOpenUpdateOperationModal(operation, interval)}
                            >
                              <EditIcon
                                fontSize="small"
                                color="secondary"
                              />
                            </IconButton>
                          </Operation>
                        )}
                     
                      </div>
                    )}
                  </Box>
                </Paper>
              </Box>
            </Stack>
          )}
    </div>
  );
};

export default MaintenancePlan;
