import React from 'react';
import { useParams } from 'react-router-dom';

const Racing = () => {
  const { stepId } = useParams();

  return (
    <div>Racing - {stepId}</div>
  );
};

export default Racing;
