import { InMemoryCache } from '@apollo/client';
import app from './models/app';
import addDevice from './models/addDevice';
// todo: delete this after done
import addMaintenanceEvent from './models/addMaintenanceEvent';
import selections from './models/selections';
import snackbar from './models/snackbar';
import newExpense from './models/newExpense';
import newRefueling from './models/newRefueling';
import newMaintenance from './models/newMaintenance';
import navigateModal from './models/navigateModal';

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        app: {
          read () {
            return app();
          },
        },
        addDevice: {
          read () {
            return addDevice();
          },
        },
        addMaintenanceEvent: {
          read () {
            return addMaintenanceEvent();
          },
        },
        selections: {
          read () {
            return selections();
          },
        },
        snackbar: {
          read () {
            return snackbar();
          },
        },
        newExpense: {
          read () {
            return newExpense();
          },
        },
        newRefueling: {
          read () {
            return newRefueling();
          },
        },
        newMaintenance: {
          read () {
            return newMaintenance();
          },
        },
        navigateModal: {
          read () {
            return navigateModal();
          },
        },
      },
    },
  },
});

export default cache;
