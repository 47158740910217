import palette from '@configurations/themes/default/palette';

const variants = [
  {
    props: { variant: 'white' },
    style: {
      '& > span': {
        color: palette.common.white,
        opacity: 0.9,
        // fontWeight: 600,
        fontFamily: '"Poppins", sans-serif',
      },
    },
  },
];

export default variants;
