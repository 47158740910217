import {useEffect} from 'react';
import addDeviceCacheControl from '@state/mutations/addDevice';

export const useAddDeviceSetValidationError = (name: string, errorMessage: string | undefined) => {
  const { setValidationErrors } = addDeviceCacheControl;
  
  useEffect(() => {
    setValidationErrors({name: name, hasError: !!errorMessage });
  },[ errorMessage ]);
};
