import { styled } from '@mui/material/styles';
import { Typography, Box, Stack } from '@mechis/elements';

export const Section = styled('section', {
  name: 'MaintenanceChecklist',
  slot: 'Root',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',

  '& .MuiPaper-root': {
    position: 'relative',
  },

  '& .MuiCheckbox-root.main-checkbox': {
    position: 'relative',
    zIndex: 2,
    background: 'white',
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(1.5),
    top: '26px',
    boxShadow: 'rgba(255, 255, 255, 0.1) 0px 0px 0px 1px, rgba(14, 30, 37, 0.12) 0px 2px 4px 0px',
  },

  '& .MuiAccordionSummary-content': {
    flexDirection: 'column',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },

  '& .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered': {
    paddingTop: 0,
    marginTop: theme.spacing(-3),
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    alignSelf: 'baseline',
    position: 'relative',
    top: theme.spacing(1.5),
  },

  '& .edit-function': {
    position: 'relative',
    zIndex: 2,
    top: '26px',
  },
}));

export const MaintenanceCard = styled(Box, {
  name: 'MaintenanceChecklist',
  slot: 'MaintenanceCard',
})(() => ({
  width: '100%',
}));

export const BlockLine = styled('div', {
  name: 'MaintenanceChecklist',
  slot: 'BlockLine',
})(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  width: '4px',
  height: '80%',
  background: theme.palette.secondary.main,
  left: '-1px',
  top: '11%',
  opacity: '0.4',
  borderTopRightRadius: '4px',
  borderBottomRightRadius: '4px',
}));

export const HeadlineDays = styled(Typography, {
  name: 'MaintenanceChecklist',
  slot: 'HeadlineDays',
})(() => ({
  fontSize: '0.7em',
}));

export const HeadlineKilometers = styled(Typography, {
  name: 'MaintenanceChecklist',
  slot: 'HeadlineKilometers',
})(() => ({
  fontSize: '0.7em',
}));

export const Intervals = styled('div', {
  name: 'MaintenanceChecklist',
  slot: 'Intervals',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0.25),
}));

export const Labels = styled('div', {
  name: 'MaintenanceChecklist',
  slot: 'Labels',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '0.2em',
}));

export const LineItem = styled('div', {
  name: 'MaintenanceChecklist',
  slot: 'LineItem',
})(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px dashed ${theme.palette.common.lightGrey}`,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
}));

export const OperationContainer = styled(Stack, {
  name: 'MaintenanceChecklist',
  slot: 'OperationContainer',
})(({ theme }) => ({
  borderBottom: '1px dashed lightgrey',
  marginBottom: theme.spacing(1.5),
  marginTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  '&.disable-line': {
    borderBottom: 0,
    marginBottom: 0,
  },
}));

