import { ReactiveVar, makeVar } from '@apollo/client';
import { INewExpenseModel } from './types';

export const init: INewExpenseModel = {
  expenses: [],
  prices: [],
  priceTotal: 0,
  locationFrom: '',
};

const newExpense: ReactiveVar<INewExpenseModel> = makeVar<INewExpenseModel>(init);

export default newExpense;
