import React, { useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
} from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateUserMutation, useUserQuery } from '@state/mechis-backend/generated/schema';
import Loader from '@mechis/blocks/Loader';
import { useLocalStorage } from 'react-use';
import { USER_ID } from '@configurations/constants/app';
import useAuth from '@hooks/useAuth';
import { UserData } from './types';
import snackbarCacheControl from '@state/mutations/snackbar';
import useHeader from '@hooks/useHeader';

interface IForm {
  firstName: string
  lastName: string
  phoneNumber: string
  phoneCountryCode: string
}

const MyProfile = () => {
  const { setSnackbar } = snackbarCacheControl;
  const { t } = useTranslation();
  useHeader({ headline: 'appBar.headline.myProfile' });

  const {
    onPasswordReset,
    passwordReset: {
      error: passwordResetError,
    },
  } = useAuth();

  const onSendPasswordResetLink = async () => {
    await onPasswordReset({ email: userInfo?.login ?? '' });
    passwordResetError ? setSnackbar('error', 'RESET_TOKEN_NOT_SENT') : setSnackbar('success', 'RESET_TOKEN_SENT_SUCCESSFUL');
  };

  const [ userId ] = useLocalStorage(USER_ID, '');

  const { data: userData, loading: userLoading, refetch: refetchUserQuery } = useUserQuery({
    variables: {
      userId: Number(userId),
    },
  });

  const [ updateUser, {
    error: updateUserError,
  } ] = useUpdateUserMutation({
    onCompleted: () => refetchUserQuery({
      userId: Number(userId),
    }),
  });

  const userInfo = userData?.user;

  const { control, handleSubmit, formState: { isDirty, isSubmitting }, reset } = useForm<IForm>({
    defaultValues: {
      firstName: userInfo?.firstName || '',
      lastName: userInfo?.surname || '',
      phoneNumber: userInfo?.phone || '',
      phoneCountryCode: userInfo?.countryCode || '',
    },
  });

  useEffect(() => {
    reset({
      firstName: userData?.user?.firstName || '',
      lastName: userData?.user?.surname || '',
      phoneCountryCode: userData?.user?.countryCode || '',
      phoneNumber: userData?.user?.phone || '',
    });
  }, [ userData?.user ]);

  const onChangePersonalDetail = async (data: UserData) => {
    await updateUser({
      variables: {
        user: {
          id: Number(userId),
          firstName: data.firstName,
          surname: data.lastName,
          phone: data.phoneNumber,
          countryCode: data.phoneCountryCode,
        },
      },
    });
    updateUserError ? setSnackbar('error', 'USER_UPDATE_ERROR') : setSnackbar('success', 'USER_UPDATE_SUCCESSFUL');
  };

  if (userLoading) {
    return (
      <Loader />
    );
  }

  return (
    <>
      <Box display={'grid'} gap={'30px'} >
        <Controller
          render={({ field, formState }) => (
            <TextField
              {...field}
              id={'firstName'}
              label={t('myProfile.form.firstName.name')}
              variant="outlined"
              type="text"
              error={!!formState.errors.firstName?.message}
              helperText={<>{formState.errors.firstName?.message}</>}
            />
          )}
          name="firstName"
          control={control}
        />
        <Controller
          render={({ field, formState }) => (
            <TextField
              {...field}
              id={'lastName'}
              label={t('myProfile.form.lastName.name')}
              variant="outlined"
              type="text"
              error={!!formState.errors.lastName?.message}
              helperText={<>{formState.errors.lastName?.message}</>}
            />
          )}
          name="lastName"
          control={control}
        />
        <Box>
          <TextField
            value={userData?.user?.login}
            label={t('myProfile.form.email.name')}
            variant="outlined"
            type="text"
            disabled
          />
        </Box>
        <Box display={'flex'} gap={'10px'} >
          <Box display={'flex'} width={'90px'} position={'relative'}>
            <Box position={'absolute'} top={'50%'} left={'5px'} style={{ transform: 'translateY(-50%)' }}>+</Box>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  type="text"
                  error={!!formState.errors.phoneCountryCode?.message}
                  helperText={<>{formState.errors.phoneCountryCode?.message}</>}
                />
              )}
              name="phoneCountryCode"
              control={control}
            />
          </Box>
          <Controller
            render={({ field, formState }) => (
              <TextField
                {...field}
                label={t('myProfile.form.phone.name')}
                variant="outlined"
                type="text"
                error={!!formState.errors.phoneNumber?.message}
                helperText={<>{formState.errors.phoneNumber?.message}</>}
              />
            )}
            name="phoneNumber"
            control={control}
          />
        </Box>
        <Typography variant="button" onClick={onSendPasswordResetLink}>
          {t('myProfile.changePassword')}
        </Typography>

        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            onClick={
              handleSubmit(onChangePersonalDetail)}
            disabled={!isDirty || isSubmitting}
          >
            {t('myProfile.button.saveChanges')}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default MyProfile;
