import React, { useState } from 'react';
import { Box, Paper } from '@mechis/elements';
import { Button, Dialog, Stack, Typography } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { IProps } from './types';
import { useCreateCashPaymentByAmountMutation } from '@state/mechis-backend/generated/schema';
import CountUp from 'react-countup';

const creditTiers: Array<{
  mechisCredit: number
  priceCZK: number
}> = [
  {
    mechisCredit: 100,
    priceCZK: 100,
  },
  {
    mechisCredit: 200,
    priceCZK: 190,
  }, {
    mechisCredit: 300,
    priceCZK: 280,
  }, {
    mechisCredit: 500,
    priceCZK: 460,
  }, {
    mechisCredit: 1000,
    priceCZK: 900,
  },
];

const CreditWidget: React.FC<IProps> = ({ creditBalance}) => {
  const [ isDialogOpen, toggleDialogOpen ] = useState<boolean>(false);
  const { t } = useTranslation();

  const [ createCashPayment ] = useCreateCashPaymentByAmountMutation({
    onCompleted: (data) => {
      window.location.href = data.createCashPayment?.url ?? '';
    },
  });

  return (
    <Box>
      <Typography variant="sectionHeadline">
        {t('cloud.credit.title')}
      </Typography>
      <Paper sx={{ minHeight: '147px' }}>
        <Stack spacing={3} p={3} textAlign="center">
          <Typography 
            variant="h3"
            sx={{
              color: (theme) => theme.palette.primary.main, 
            }}
          >
            <CountUp
              start={0}
              end={creditBalance}
              duration={1.5}
              separator=" "
            /> <span style={{textDecoration: 'line-through'}}>M</span>
          </Typography>
          <Button
            variant="contained"
            onClick={() => toggleDialogOpen(!isDialogOpen)}
          >
            {t('cloud.credit.add')}
          </Button>
        </Stack>
      </Paper>
      <Dialog open={isDialogOpen}>
        <Box
          p={1}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={1}
        >
          <Typography variant="h1">{t('cloud.credit.add')}</Typography>
          <Box>
            <Stack
              gap={1}
            >
              {creditTiers.map(({
                // eslint-disable-next-line
                mechisCredit,
                priceCZK,
              }, index: number) => {
                return (
                  <Box key={index}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={1}
                    p={1}
                    borderRadius={1}
                  >
                    <Typography fontWeight={600} color={'black'}>
                      {mechisCredit} <span style={{textDecoration: 'line-through'}}>M</span>
                    </Typography>
                    <Typography>{t('cloud.credit.dialog.for')}</Typography>
                    <Typography fontWeight={600} color={'black'}>
                      {priceCZK},- Kč
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        await createCashPayment({
                          variables: {
                            cashPaymentData: {
                              amountPaid: priceCZK,
                            },
                          },
                        });
                      }}
                    >{t('cloud.credit.dialog.buy')}
                    </Button>
                  </Box>
                );
              })}
            </Stack>
          </Box>
          <Box>
            <Button variant="text" onClick={() => toggleDialogOpen(false)}>
              {t('cloud.credit.dialog.close')}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default CreditWidget;
