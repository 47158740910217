import React, { FC } from 'react';
import { IExpensesProps } from '../types';
import { useExpensesByEventQuery } from '@state/mechis-backend/generated/schema';
import { useTranslation } from 'react-i18next';
import { Box } from '@mechis/elements';
import Loader from '@mechis/blocks/Loader';
import SimpleTable from '@mechis/blocks/SimpleTable';
import ErrorIcon from '@mui/icons-material/Error';
import { MAX_EXPENSES_FOR_LIST_IN_DETAIL_OF_EVENT } from '@configurations/constants/app';

const Preview: FC<IExpensesProps> = ({ id, onUpdate }) => {
  const { t } = useTranslation();

  const { data: expensesInfo, loading: expensesInfoLoading, error: expensesInfoError } = useExpensesByEventQuery({
    variables: {
      eventId: Number(id),
      offset: 0,
      limit: MAX_EXPENSES_FOR_LIST_IN_DETAIL_OF_EVENT,
    },
  });

  if (expensesInfoLoading) {
    return (
      <Loader />
    );
  }

  if (expensesInfoError) {
    return (
      <div>
        <ErrorIcon />
      </div>
    );
  }

  if (expensesInfo?.expensesByEvent?.expense.length === 0) {
    return <></>;
  }

  return (
    <Box mb={2}>
      <SimpleTable
        title={t(
          'maintenanceNewEvent.preview.totalPrice',
          {
            totalPrice: expensesInfo?.expensesByEvent?.expense[0].event?.priceTotal ?? '0',
          })
        }
        data={expensesInfo?.expensesByEvent?.expense.map((expense) => ({
          label: expense.tag.tagName,
          value: expense.price + ' Kč',
        })) ?? []}
        ctas={[
          {          
            title: 'Upravit',
            color: 'secondary',
            onClick: () => onUpdate('expenses'),
          },
        ]}
      />
    </Box>
  );
};

export default Preview;
