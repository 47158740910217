import React, { FC, useEffect, useState } from 'react';
import { IPopperComponentProps, ITagFiltersProps, ITagOption } from './types';
import { IOwnerState, ControlState } from '../types';
import { StyledInput, StyledAutocompletePopper } from './styles';
import {
  Control,
  ControlLabel,
} from '../styles';
import {
  IconButton,
  Dialog,
  DialogContent,
  Box,
  Stack,
  Button,
  Typography,
  Autocomplete,
  DialogActions,
  Tabs,
  Tab,
  Chip,
} from '@mechis/elements';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_SELECTIONS } from '@state/queries/selections';
import { ISelectionsQuery } from '@state/queries/selections/types';
import selectionsControl from '@state/mutations/selections';
import { getColorByState } from '../Selections';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useTranslation } from 'react-i18next';
import useTagManager from '@hooks/useTagManager';
import useAuth from '@hooks/useAuth';

const PopperComponent = (props: IPopperComponentProps) => {
  // eslint-disable-next-line
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
};

const TagFilters: FC<ITagFiltersProps> = ({
  customControl,
  localTags,
  setLocalTags,
  preselectedTags,
}) => {
  const { t } = useTranslation();
  const {
    tagsOptions,
    tagsOptionsLatest,
    getIconByCategory,
    loading,
    error,
  } = useTagManager();
  const [ state, setState ] = useState<ControlState>('default');
  const [ openTagFilters, setOpenTagFilters ] = useState(false);
  const { data } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const { setTags } = selectionsControl;
  const tags = data?.selections.tags;
  const [ tabValue, setTabValue ] = useState<number>(0);
  const [ isForcedTagsLoaded, setIsForcedTagsLoaded ] = useState<boolean>(false);
  const { isComgateUser } = useAuth();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const onDelete = (tagId: string) => {
    const newValue = _.filter(customControl ? localTags : tags, (item) => {
      return item.tagId !== tagId;
    });

    if (newValue.length === 0) {
      setState('default');
    }

    if (customControl) {
      // @ts-ignore
      setLocalTags(newValue);
    } else {
      setTags(newValue);
    }
  };

  const onCloseModal = () => {
    setOpenTagFilters(false);
  };

  const onSelect = () => {
    setOpenTagFilters(false);
    setState('filled');
  };

  const onReset = () => {
    setTags([]);
    setState('default');
  };
  
  const getForcedTags = () => {
    const forcedTags: ITagOption[] = [];
    preselectedTags?.forEach((preselectedTag) => {
      // @ts-expect-error
      const foundTag = tagsOptions.find((tagOption: ITagOption) => tagOption.tagId === preselectedTag);
      if (foundTag) {
        forcedTags.push(foundTag);
      }
    });
    return forcedTags;
  };

  // eslint-disable-next-line
  const onSetTags = (tags: ITagOption[]) => {
    if (customControl && setLocalTags) {
      if (preselectedTags && preselectedTags.length > 0) {
        const forcedTags = getForcedTags();

        // Pro účel editace existujícího záznamu je potřeba zajistit aby forcedTags nebyly zduplikovány
        const tagIds = tags.map((tag) => tag.tagId);
        const reducedDuplicatedForcedTags = forcedTags.filter((tag) => !tagIds.includes(tag.tagId));

        const tagsWithPreselected = reducedDuplicatedForcedTags.length === 0 ?
          [ ...tags ] :
          [ ...forcedTags, ...tags ].reduce<ITagOption[]>((accumulator, currentTag) => {
            if (!accumulator.find((tag: ITagOption) => tag.tagId === currentTag.tagId)) {
              accumulator.push(currentTag);
            }
            return accumulator;
          }, []);
        
        setLocalTags(tagsWithPreselected);
      } else {
        setLocalTags(tags);
      }
    } else {
      setTags(tags);
    }
  };

  useEffect(() => {
    if (customControl && setLocalTags) {
      if (preselectedTags && preselectedTags.length > 0) {
        if (tagsOptions && !loading && !error) {
          if (!isForcedTagsLoaded && localTags && localTags?.length === 0) {
            const forcedTags = getForcedTags();
            setLocalTags(forcedTags);
            setIsForcedTagsLoaded(true);
          }
        }
      }
    }
  }, [ tagsOptions, loading, error, isForcedTagsLoaded ]);

  const ownerState: IOwnerState = {
    state,
  };
  
  if (isComgateUser()) {
    return <></>;
  }
  
  return (
    <>
      {customControl ? (
        <div onClick={() => setOpenTagFilters(true)}>{customControl}</div>
      ) : (
        <Control>
          <IconButton
            size="small"
            color={getColorByState(state)}
            isSquared
            onClick={() => setOpenTagFilters(true)}
          >
            <LocalOfferIcon />
          </IconButton>
          <ControlLabel ownerState={ownerState}>{tags?.length === 0 ? 'Tags' : tags?.length}</ControlLabel>
        </Control>
      )}
      
      <Dialog
        onClose={() => setOpenTagFilters(false)}
        open={openTagFilters}
        maxWidth="xs"
        fullScreen={true}
        sx={{
          '& .MuiDialogContent-root': {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        
      >
        <DialogContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="sectionHeadline">
              {t('tagManager.title')}
            </Typography>
            <IconButton
              size="small"
              onClick={onCloseModal}
            >
              <HighlightOffIcon />
            </IconButton>
          </Stack>
          <Autocomplete
            // @ts-expect-error
            options={tabValue === 0 ? tagsOptions : tagsOptionsLatest}
            filterOptions={(options, autocompleteState) => {
              const searchPhrases = autocompleteState.inputValue.split(' ');
              
              return options.filter((option: ITagOption) => {
                const matchedPhrases = searchPhrases
                  .filter((phrase) => option.tagTranslation.toLocaleLowerCase().includes(phrase.toLocaleLowerCase()));
                return matchedPhrases.length === searchPhrases.length;
              });
            }}
            value={customControl ? localTags : tags}
            // eslint-disable-next-line
            onChange={(event, newValue, reason) => {
              const tagOption = newValue[newValue.length - 1];

              if (!tagOption) return;

              const isWithinTags = _.find(customControl ? localTags : tags, { tagId: tagOption.tagId });
              if (isWithinTags) {
                onDelete(tagOption.tagId);
              } else {
                onSetTags(newValue);
              }
            }}
            renderInput={(params) => (
              <>
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={t('tagManager.searchTags')}
                />
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  sx={{
                    my: 1,
                  }}
                >
                  <Box sx={{ width: '100%' }}>
                    {customControl ?
                      localTags?.map(({ tagId, tagTranslation }) => (
                        <Chip
                          key={tagId}
                          label={tagTranslation}
                          size="small"
                          color="secondary"
                          variant="outlined"
                          onDelete={tagId !== 'tag_other' ? () => onDelete(tagId) : undefined}
                          sx={{
                            mr: 0.5,
                            my: 0.25,
                          }}
                        />
                      ))
                      :
                      tags?.map(({ tagId, tagTranslation }) => (
                        <Chip
                          key={tagId}
                          label={tagTranslation}
                          size="small"
                          color="secondary"
                          variant="outlined"
                          onDelete={() => onDelete(tagId)}
                          sx={{
                            mr: 0.5,
                            my: 0.25,
                          }}
                        />
                      ))
                    }
                  </Box>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                      marginTop: (theme) => theme.spacing(0.5),
                      minHeight: '32px',
                      '& button': {
                        minHeight: '32px',
                        minWidth: 'initial',
                        padding: '0 12px',
                        fontSize: '0.75rem',
                      },
                    }}
                  >
                    <Tab label={t('tagManager.tabs.all')} />
                    <Tab label={t('tagManager.tabs.recent')} />
                  </Tabs>
                </Stack>
              </>
            )}
            renderOption={(props, option) => {
              let visible = true;
              const tagId = option.tagId;
              const isWithinTags = _.find(customControl ? localTags : tags, { tagId });
              
              // @ts-expect-error
              if (!option?.isTranslation) {
                visible = false;
              }

              return (
                <li {...props} style={{
                  alignItems: 'center',
                  display: !visible ? 'none' : undefined,
                }}>
                  <IconButton
                    color={isWithinTags ? 'secondary' : 'primary'}
                    size="small"
                    isSquared
                  >
                    {getIconByCategory(option.tagCategory.name)}
                  </IconButton>
                  <Box ml={2} sx={{ fontWeight: isWithinTags ? 600 : 100 }}>
                    {option.tagTranslation}
                  </Box>
                </li>
              );
            }}
            open
            multiple
            PopperComponent={PopperComponent}
            noOptionsText={t('tagManager.noTagFound')}
            getOptionLabel={(option) => option.tagTranslation}
          />
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-between"
            width={'100%'}
            pb={1}
            px={2}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={onReset}
              disabled={tags?.length === 0}
              sx={{ mt: -5 }}
            >
              {t('tagManager.resetFiltersBtn')}
            </Button>
            {customControl ? (
              <Button
                variant="contained"
                color="secondary"
                disabled={localTags?.length === 0}
                onClick={onSelect}
                sx={{ mt: -5, zIndex: 1400 }}
              >
                {t('tagManager.selectTagsBtn')}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                disabled={tags?.length === 0}
                onClick={onSelect}
                sx={{ mt: -5, zIndex: 1400 }}
              >
                {t('tagManager.selectTagsBtn')}
              </Button>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TagFilters;

