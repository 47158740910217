import button from '@mechis/elements/Button/styleOverrides';
import toolbar from '@mechis/elements/Toolbar/styleOverrides';
import bottomNavigation from '@mechis/elements/BottomNavigation/styleOverrides';
import bottomNavigationAction from '@mechis/elements/BottomNavigationAction/styleOverrides';
import paper from '@mechis/elements/Paper/styleOverrides';
import textField from '@mechis/elements/TextField/styleOverrides';
import accordion from '@mechis/elements/Accordion/styleOverrides';
import menuItem from '@mechis/elements/MenuItem/styleOverrides';
import inputBase from '@mechis/elements/InputBase/styleOverrides';
import tooltip from '@mechis/elements/Tooltip/styleOverrides';
import dialog from '@mechis/elements/Dialog/styleOverrides';

const styleOverrides = {
  button,
  toolbar,
  bottomNavigation,
  bottomNavigationAction,
  paper,
  textField,
  accordion,
  menuItem,
  inputBase,
  tooltip,
  dialog,
};

export default styleOverrides;
