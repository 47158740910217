import React from 'react';
import {
  Box,
  Button,
  Paper,
  Typography,
  Stack,
} from '@mechis/elements';
import { IProps } from './types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import MechisTurtle from '@mechis/icons/MechisTurtle';
import MechisHare from '@mechis/icons/MechisHare';
import MechisCougar from '@mechis/icons/MechisCougar';
import TurnedInIcon from '@mui/icons-material/TurnedIn';

const AccountVariant: FC<IProps> = ({
  membershipName,
  isActive,
  annumPrice,
  onClick,
  isFullInfo,
  deviceCountMembership,
  dataFree,
}) => {
  const { t } = useTranslation();
 
  return (
    <>
      <Paper 
        sx={{
          p: 2,
          ...(!isActive && {
            background: 'transparent',
            border: '2px dashed lightgrey',
            boxShadow: 'none',
          }),
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={2} alignItems="center">
            <Box
              sx={{
                //borderRadius: '50%',
                borderRadius: '12px',
                background: '#01ab6d14',
                width: '50px',
                height: '50px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {membershipName === 'starter' && (
                <MechisTurtle color="secondary" fontSize="large" />
              )}
              {membershipName === 'advanced' && (
                <MechisHare color="secondary" fontSize="large" />
              )}
              {membershipName === 'professional' && (
                <MechisCougar color="secondary" fontSize="large" />
              )}
            </Box>
            <Box>
              <Typography variant="h5" color="primary">{t(`mechis.membership.${membershipName}`)}</Typography>
            </Box>
          </Stack>
          <Box>
            <Button
              variant={!isActive ? 'contained' : 'outlined'}
              color="secondary"
              size="small"
              onClick={onClick}
            >
              {!isFullInfo ? 'Změnit' : (
                <>
                  {isActive ? t('components.accountVariant.active') : t('components.accountVariant.activate')}
                </>
              )}
            </Button>
          </Box>
        </Stack>
        {isFullInfo && (
          <Stack mt={2} spacing={1.5}>
            <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
              <TurnedInIcon fontSize="small" color="primary" />
              <Typography sx={{ lineHeight: 1 }}><small>{annumPrice} kreditů / měsíc</small></Typography> 
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
              <TurnedInIcon fontSize="small" color="primary" />
              <Typography sx={{ lineHeight: 1 }}><small> Počet kusů techniky: {deviceCountMembership}</small></Typography> 
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1} pl={0.5}>
              <TurnedInIcon fontSize="small" color="primary" />
              <Typography sx={{ lineHeight: 1 }}><small>Datový limit na cloudu:  {dataFree} MB</small></Typography> 
            </Stack>
          </Stack>
        )}
      </Paper>
    </>
  );
};

export default AccountVariant;
