import React from 'react';
import { useParams } from 'react-router-dom';

const Other = () => {
  const { stepId } = useParams();

  return (
    <div>Other - {stepId}</div>
  );
};

export default Other;
