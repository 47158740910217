import React, { FC } from 'react';
import { Stack } from '@mechis/elements';
import RecordList from '@mechis/sections/RecordList';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import ControlMenu from '../ControlMenu';

const Records: FC = () => {
  useHeader({
    headline: 'appBar.headline.records',
    widget: (
      <Selections
        tagFilters={{ isSelection: true, order: 1 }}
        generalFilters={{ 
          isSelection: true,
          order: 2,
          isDate: true,
          isOdometer: true,
          isPrice: true,
          isRecordType: true,
        }}
      >
        <ControlMenu />
      </Selections>
    ),
  });

  return (
    <Stack spacing={1}>
      <RecordList />
    </Stack>
  );
};

export default Records;
