import React, { FC, useEffect } from 'react';
import { IProps } from './types';
import {
  Layout,
  Sidebar,
  Navigation,
  SidebarFooter,
  Main,
  Header,
  Footer,
} from './styles';
import {isMobile} from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import {
  IconButton,
  Stack,
  Link,
  Box,
} from '@mechis/elements';
import { Outlet } from 'react-router-dom';
import RootLayout from '@mechis/layouts/RootLayout';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';
import Logo from '@mechis/blocks/Logo';
import Loader from '@mechis/blocks/Loader';
import { useIsDesktopSupported } from '@hooks/useIsDesktopSupported';

const ROUTES_WITHOUT_NAVIGATION = [ 'email-verification' ];

const FrontEndLayout: FC<IProps> = () => {
  const { isLoggedIn, isLoggedInLoading } = useAuth();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  const canRenderNavigation = !ROUTES_WITHOUT_NAVIGATION.some((route) => pathname.includes(route));

  useIsDesktopSupported();

  useEffect(() => {
    if (isLoggedIn && !isLoggedInLoading) {
      navigate('/app');
    }
  }, [ isLoggedIn, isLoggedInLoading ]);

  if (isLoggedInLoading) {
    return (
      <Box sx={{width: '100vw', height: '100vh'}}>
        <Loader />
      </Box>
    );
  }

  return (
    <RootLayout>
      <Layout>
        <Sidebar>
          {!isMobile && (
            <>
              <Logo variant="white" withText />
              {canRenderNavigation && (
                <Navigation>
                  <Link
                    onClick={() => navigate('/')}
                    component="button"
                    underline="none"
                    className={pathname === '/' ? 'active' : 'not-active'}
                  >
                    {t('login.form.signIn')}
                  </Link>
                  <Link
                    onClick={() => navigate('/registration')}
                    component="button"
                    underline="none"
                    className={pathname === '/registration' ? 'active' : 'not-active'}
                  >
                    {t('registration.title')}
                  </Link>
                </Navigation>
              )}
              <SidebarFooter />
            </>
          )}
        </Sidebar>
        <Main>
          {!isMobile && (
            <>
              <Header>
                <Stack spacing={1} direction="row">
                  <IconButton 
                    size="large"
                    onClick={() => i18n.changeLanguage('en')}
                  >
                    <ReactCountryFlag
                      countryCode="GB"
                      svg
                      title="English"
                      style={{
                        opacity: i18n.language === 'en' ? 0.8 : 0.4,
                      }}
                    />
                  </IconButton>
                  <IconButton
                    size="large"
                    onClick={() => i18n.changeLanguage('cs')}
                  >
                    <ReactCountryFlag
                      countryCode="CZ"
                      svg
                      title="Čeština"
                      style={{
                        opacity: i18n.language === 'cs' ? 0.8 : 0.4,
                      }}
                    />
                  </IconButton>
                </Stack>
              </Header>
            </>
          )}
          <Outlet />
          <Footer>
            Copyright © {new Date().getFullYear()} All rights reserved
          </Footer>
        </Main>
      </Layout>
    </RootLayout>
  );
};

export default FrontEndLayout;
