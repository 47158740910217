import { styled } from '@mui/material/styles';
import { IOwnerState } from './types';

export const Block = styled('div', {
  name: 'CreateBlock',
  slot: 'Root',
})<{ ownerState: IOwnerState }>(({ ownerState, theme }) => ({
  width: '100%',
  minHeight: '120px',
  padding: theme.spacing(2),
  borderRadius: `${theme.radiuses.large}px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  
  ...(ownerState.type === 'default' && {
    background: '#eaf1f5',
  }),
  ...(ownerState.type === 'odometer' && {
    background: 'red',
  }),
}));

