import React, { FC, useState, useEffect } from 'react';
import { IProps } from './types';
import { useQuery } from '@apollo/client';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { GET_SELECTIONS } from '@state/queries/selections';
import { Attachment, Filter, useAttachmentByDeviceAndFiltersAndTagsQuery } from '@state/mechis-backend/generated/schema';
import { filterSelectionToQuery } from '@hooks/utils';
import ErrorIcon from '@mui/icons-material/Error';
import Loader from '@mechis/blocks/Loader';
import { isObjectEmpty } from '@utilities/app';
import { Stack } from '@mechis/elements';
import QuoteBlock from '@mechis/blocks/QuoteBlock';
import { ATTACHMENT_LIMIT_WIDGET } from '@configurations/constants/app';
import { useAttachments } from '@hooks/useAttachments';
import Lightbox from 'yet-another-react-lightbox';
import AttachmentsPreview from '../AttachmentsPreview';
import { useTranslation } from 'react-i18next';
import useTech from '@hooks/useTech';

const AttachmentListWidget: FC<IProps> = () => {
  const { t } = useTranslation();
  const [ tags, setTags ] = useState<number[]>([]);
  const [ filters, setFilters ] = useState<Filter>({});
  const [ offset, setOffset ] = useState<number>(0);
  const [ attachmentsToRender, setAttachmentsToRender ] = useState<Attachment[]>([]);
  const { techId } = useTech();
  const { isImageType, getImageUrlByStorageName } = useAttachments();
  const [ lightBoxSources, setLightBoxSources ] = useState<Array<Partial<Attachment>>>([]);
  const [ lightBoxOpened, setLightBoxOpened ] = useState<boolean>(false);
  const [ clickedImageIndex, setClickedImageIndex ] = useState<number>(0);
  const { data: selections } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const {
    data: attachments,
    loading: attachmentsLoading,
    error: attachmentsError,
  } = useAttachmentByDeviceAndFiltersAndTagsQuery({
    variables: {
      deviceId: techId,
      offset,
      limit: ATTACHMENT_LIMIT_WIDGET,
      tags,
      filters,
    },
  });

  useEffect(() => {
    if (!selections?.selections.filterIsModalOpen) {
      if (!isObjectEmpty(filterSelectionToQuery(selections?.selections))) {
        setOffset(0);
        setAttachmentsToRender([]);
      }
      setFilters(filterSelectionToQuery(selections?.selections));
    }

    if (selections?.selections?.tags) {
      setTags(selections?.selections?.tags.map((tag) => Number(tag.id)));
      setOffset(0);
      setAttachmentsToRender([]);
    }

  }, [ selections ]);

  useEffect(() => {
    if (attachments && attachments.attachmentByDeviceAndFiltersAndTags) {
      setAttachmentsToRender([ ...attachments.attachmentByDeviceAndFiltersAndTags.attachment ]);
      setLightBoxSources(
        attachments.attachmentByDeviceAndFiltersAndTags.attachment.filter(
          (attachment) => isImageType(attachment.mimeType ?? '')
        )
      );
    }
  }, [ attachments, attachmentsLoading, attachmentsError ]);

  const getLightBoxSources = () => {
    return (
      <Lightbox
        open={lightBoxOpened}
        close={() => setLightBoxOpened(false)}
        index={clickedImageIndex}
        slides={
          lightBoxSources.map((attachment) => {
            return {
              src: getImageUrlByStorageName(attachment.storageName ?? ''),
            };
          })
        }
      />
    );
  };

  if (attachmentsError) {
    return (
      <ErrorIcon />
    );
  }

  if (attachmentsLoading) {
    return (
      <Loader />
    );
  }

  if (attachmentsToRender.length === 0) {
    return (
      <Stack mt={6} spacing={4} alignItems="center" >
        <QuoteBlock>
          {t('components.attachmentNoData.forDevice')}
        </QuoteBlock>
      </Stack>
    );
  }

  return (
    <>
      <Stack spacing={{ xs: 1, sm: 2 }} direction="row" sx={{ overflowX: 'scroll' }}>
        {attachmentsToRender.map((attachment, index) => {
          return (
            <AttachmentsPreview
              key={attachment.id}
              attachment={attachment}
              index={index}
              setClickedImageIndex={() => {
                setClickedImageIndex(lightBoxSources.findIndex((source) => source.id === attachment.id)); 
              }}
              setLightBoxOpened={setLightBoxOpened}
            />
          );
        })}
      </Stack>
      {lightBoxSources.length > 0 && getLightBoxSources()}
    </>
  );
};

export default AttachmentListWidget;
