import React from 'react';
import { useParams } from 'react-router-dom';
import { IAddFlow } from '@state/models/app/types';
import ConstructionsMachine from './ConstructionsMachine';
import GardenMachinery from './GardenMachinery';
import Motorcycle from './Motorcycle';
import Other from './Other';
import PassengerCar from './PassengerCar';
import Racing from './Racing';
import Trailer from './Trailer';
import Truck from './Truck';
import Veteran from './Veteran';

const NewTech = () => {
  const { addFlow } = useParams();

  switch (addFlow as IAddFlow) {
    case 'constructions-machine':
      return <ConstructionsMachine />;
    case 'garden-machinery':
      return <GardenMachinery />;
    case 'motorcycle':
      return <Motorcycle />;
    case 'other':
      return <Other />;
    case 'passenger-car':
      return <PassengerCar />;
    case 'racing':
      return <Racing />;
    case 'trailer':
      return <Trailer />;
    case 'truck':
      return <Truck />;
    case 'veteran':
      return <Veteran />;
    default:
      return <></>;
  }
};

export default NewTech;
