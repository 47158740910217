import { ChangeEvent, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import attachmentsState from '@state/models/attachments';
import {
  Attachment,
  useCreateAttachmentMutation,
} from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';
import { FileWithPreview } from '@state/models/attachments/types';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref, uploadBytes, getMetadata } from 'firebase/storage';
import { v4 } from 'uuid';
import {
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from '@configurations/constants/app';

const useAttachments = () => {
  const attachments = useReactiveVar(attachmentsState);
  const [ createAttachment ] = useCreateAttachmentMutation();
  const { setSnackbar } = snackbarCacheControl;
  const [ isUploading, setIsUploading ] = useState<boolean>(false);

  const setIsModalOpen = (isModalOpened: boolean) => {
    attachmentsState({
      ...attachments,
      isModalOpened,
    });
  };

  const setView = (view: 'list' | 'grid') => {
    attachmentsState({
      ...attachments,
      view,
    });
  };

  const setSelectedFiles = (selectedFiles: FileWithPreview[]) => {
    attachmentsState({
      ...attachments,
      selectedFiles,
    });
  };

  const setUploadedFiles = (uploadedFiles: Partial<Attachment>[]) => {
    attachmentsState({
      ...attachments,
      uploadedFiles,
    });
  };
  
  const setFilter = (filter: 'all' | 'image' | 'pdf') => {
    attachmentsState({
      ...attachments,
      filter,
    });
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, acceptedTypes: string[]) => {
    const files = Array.from(event.target.files || []);
    const filteredFiles = files.filter((file) => 
      file.size <= 5 * 1024 * 1024 && // 5 MB limit
      acceptedTypes.includes(file.type) 
    ) as FileWithPreview[];
    
    const filesWithPreview = filteredFiles.map((file) => {
      if (file.type.startsWith('image/')) {
        file.preview = URL.createObjectURL(file);
      }
      return file;
    });
  
    setSelectedFiles(filesWithPreview);
  };

  const handleRemoveFile = (fileToRemove: FileWithPreview) => {
    const newAttachments = {...attachments};
    newAttachments.selectedFiles = newAttachments.selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(newAttachments.selectedFiles);
    if (fileToRemove.preview) {
      URL.revokeObjectURL(fileToRemove.preview);
    }
  };

  const selectedFilesTotalSize = () => {
    const totalSize = attachments.selectedFiles.reduce((sum, file) => {
      return sum + file.size;
    }, 0);
  
    return +(totalSize / (1024 * 1024)).toFixed(2);
  };

  const getFilteredFiles = () => {
    switch (attachments.filter) {
      case 'image':
        return attachments.selectedFiles.filter((file) =>
          [ 'image/jpeg', 'image/png' ].includes(file.type)
        );
      case 'pdf':
        return attachments.selectedFiles.filter((file) =>
          file.type === 'application/pdf'
        );
      case 'all':
      default:
        return attachments.selectedFiles;
    }
  };

  // todo: type should be from mime enum type
  const isSelectedUploaded = (name: string, size: number, type: string) => {
    return attachments.uploadedFiles.some((file) =>
      file.originalName === name && file.size === size && file.mimeType === type
    );
  };

  // FIREBASE DECLARATIONS
  const firebaseConfig: FirebaseOptions = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? '',
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
  };
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const uploadFile = async (file: File) => {
    const folder = 'attachments';
    const imageRef = ref(storage, `${folder}/${file.name + v4()}`);

    try {
      const snapshot = await uploadBytes(imageRef, file);
      const metadata = await getMetadata(snapshot.ref);

      const resultAttachment = await createAttachment({
        variables: {
          attachment: {
            originalName: file.name,
            storageName: metadata.fullPath,
            version: 1,
            mimeType: metadata.contentType ?? 'text/plain',
            size: metadata.size,
          },
        },
      });

      if (resultAttachment.data?.createAttachment?.id) {
        const newAttachment: Partial<Attachment> = {
          id: Number(resultAttachment.data.createAttachment.id),
          originalName: file.name,
          storageName: metadata.fullPath,
          mimeType: metadata.contentType ?? 'text/plain',
          size: metadata.size,
        };

        return newAttachment;
      }
      
    } catch (error) {
      setSnackbar('error', 'FIREBASE_UPLOAD_ERROR');
    }
  };

  const uploadFiles = async () => {
    if (attachments.selectedFiles.length === 0) {
      console.log('No files selected for upload.');
      return false;
    }

    setIsUploading(true);
  
    const newUploadedFiles = [ ...attachments.uploadedFiles ];
    for (const file of attachments.selectedFiles) {
      if (isSelectedUploaded(file.name, file.size, file.type)) {
        console.log(`File already uploaded: ${file.name}`);
        continue;
      }
  
      try {
        const uploadedFile = await uploadFile(file);
        if (uploadedFile) {
          newUploadedFiles.push(uploadedFile);
        }
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
        // todo: reenable setSnackbar here
        // setSnackbar('error', 'UPLOAD_FAILED', file.name);
      } finally {
        setIsUploading(false);
      }
    }

    setUploadedFiles(newUploadedFiles);
    return true;
  };

  return {
    isModalOpened: attachments.isModalOpened,
    setIsModalOpen,
    view: attachments.view,
    setView,
    selectedFilesState: attachments.selectedFiles,
    selectedFiles: getFilteredFiles(),
    setSelectedFiles,
    uploadedFiles: attachments.uploadedFiles,
    setUploadedFiles,
    handleFileChange,
    handleRemoveFile,
    selectedFilesTotalSize: selectedFilesTotalSize(),
    filter: attachments.filter,
    setFilter,
    uploadFiles,
    isUploading,
    isSelectedUploaded,
  };
};

export default useAttachments;
