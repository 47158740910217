import React, { FC } from 'react';
import { IProps } from './types';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  blurTarget, 
  ensureNumericZeroIsNotDisplayed,
} from '@utilities/app';
import SpeedIcon from '@mui/icons-material/Speed';
import { maximumOdometerValue } from '@configurations/constants/inputConstraints';
import {useAddDeviceSetValidationError} from '@hooks/validationHooks/useAddDeviceSetValidatonError';
import useUnits from '@hooks/useUnits';
import NumberField from '@mechis/elements/NumberField';

const inputIdentifier = 'initialOdometerState';
const InitialOdometerState: FC<IProps> = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { odometerUnitsData } = useUnits();
  const { setInitialOdometerState, setOdometerUnit } = addDeviceCacheControl;

  const odometerUnitID = Number(data?.addDevice.odometerUnit.id);

  const schema = yup.object({
    initialOdometerState: yup.number()
      .transform((value, originalValue) => originalValue === '' ? null : value)
      .nullable()
      .max(maximumOdometerValue, `${t('addDevice.inputValidation.maxValue') + maximumOdometerValue}`),
  }).required();

  const { control, handleSubmit, formState: { errors } } = useForm<{ initialOdometerState: number | null }>({
    defaultValues: {
      initialOdometerState: ensureNumericZeroIsNotDisplayed(data?.addDevice.initialOdometerState),
    },
    resolver: yupResolver(schema),
  });

  useAddDeviceSetValidationError(inputIdentifier, errors.initialOdometerState?.message);

  const onSave = async ({ initialOdometerState }: { initialOdometerState: number | null }) => {
    setInitialOdometerState(initialOdometerState);
  };

  return (
    <Controller
      render={({ field, formState }) => (
        <NumberField
          {...field}
          onKeyUp={() => {
            handleSubmit(onSave)();
          }}
          onWheel={blurTarget}
          label={t('addDevice.components.initialOdometerState.name')}
          value={field.value}
          id={inputIdentifier}
          error={!!formState.errors.initialOdometerState?.message}
          helperText={formState.errors.initialOdometerState?.message}
          variant="outlined"
          unitIcon={<SpeedIcon fontSize="small" />}
          units={odometerUnitsData?.odometerUnits.map((unit) => ({
            id: Number(unit.id),
            name: unit.unitName,
            isSelected: odometerUnitID === Number(unit.id),
          }))}
          onUnitChange={(unitId) => setOdometerUnit(unitId)}
          onlyInteger
        />
      )}
      name="initialOdometerState"
      control={control}
    />
  );
};

export default InitialOdometerState;
