import { ReactiveVar } from '@apollo/client';
import snackbar from '@state/models/snackbar';
import { IFlashMessage } from '@state/models/snackbar/types';

const setSnackbar = (snackbarModel: ReactiveVar<IFlashMessage>) => {
  return (severity: 'error' | 'success', messageCode: string) => {
    const model = {
      severity,
      messageCode,
      timestamp: Date.now(),
    };
    snackbarModel(model);
  };
};

const snackbarCacheControl = {
  setSnackbar: setSnackbar(snackbar),
};

export default snackbarCacheControl;
