import React, { FC } from 'react';
import { IProps } from './types';
import { InputAdornment } from '@mechis/elements';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  blurTarget,
  ensureNumericZeroIsNotDisplayed,
} from '@utilities/app';
import SpeedIcon from '@mui/icons-material/Speed';
import { maximumRpm } from '@configurations/constants/inputConstraints';
import {useAddDeviceSetValidationError} from '@hooks/validationHooks/useAddDeviceSetValidatonError';
import NumberField from '@mechis/elements/NumberField';

const inputIdentifier = 'powerRpm';
const PowerRpm: FC<IProps> = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { setPowerRpm } = addDeviceCacheControl;

  const schema = yup.object({
    powerRpm: yup
      .number()
      .transform((value, originalValue) => originalValue === '' ? null : value)
      .nullable()
      .max(maximumRpm, `${t('addDevice.inputValidation.maxValue') + maximumRpm}`),
  }).required();

  const { control, handleSubmit, formState: { errors}} = useForm<{ powerRpm: number | null }>({
    defaultValues: {
      powerRpm: ensureNumericZeroIsNotDisplayed(data?.addDevice.powerRpm),
    },
    resolver: yupResolver(schema),
  });
  
  useAddDeviceSetValidationError(inputIdentifier, errors.powerRpm?.message);

  const onSave = async ({ powerRpm }: { powerRpm: number | null }) => {
    setPowerRpm(powerRpm);
  };

  return (
    <Controller
      render={({ field, formState }) => (
        <NumberField
          {...field}
          onKeyUp={() => {
            handleSubmit(onSave)();
          }}
          onWheel={blurTarget}
          label={t('addDevice.components.powerRpm.name')}
          id={inputIdentifier}
          error={!!formState.errors.powerRpm?.message}
          helperText={formState.errors.powerRpm?.message}
          InputProps={{
            endAdornment: 
              <InputAdornment position="end">
                <small>
                  {t('addDevice.components.powerRpm.endAdornment')}
                </small>
              </InputAdornment>,
            startAdornment: (
              <InputAdornment position="start">
                <SpeedIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onlyInteger
        />
      )}
      name="powerRpm"
      control={control}
    />
  );
};

export default PowerRpm;
