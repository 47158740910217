import React, { FC } from 'react';
import { IProps } from './types';
import { Section } from './styles';
import {
  Box,
  Paper,
} from '@mechis/elements';
// import Logo from '@mechis/blocks/Logo';
import { isMobile } from 'react-device-detect';
import Logo2 from '@mechis/blocks/Logo/Logo2';

const FormWrapper: FC<IProps> = ({ children }) => {
  return (
    <Section>
      <Box sx={{ maxWidth: '520px' }}>
        <Box sx={{
          margin: '0 auto',
          position: 'relative',
          top: isMobile ? '0px' : '50px',
          width: 'fit-content',
          mb: 3.5,
          // opacity: isMobile ? 0.6 : 1,
        }}>
          {/*<Logo
            size={isMobile ? 160 : 180}
            variant={isMobile ? 'white' : 'default'}
      />*/}
          <Logo2 />
        </Box>
        <Paper>
          <Box
            px={isMobile ? 4 : 8}
            py={isMobile ? 6 : 8}
          >
            {children}
          </Box>
        </Paper>
      </Box>
    </Section>
  );
};

export default FormWrapper;
