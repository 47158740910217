import { styled } from '@mui/material/styles';

export const Block = styled('div', {
  name: 'Expense',
  slot: 'Root',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));
