import React, { FC, useEffect, useState } from 'react';
import { IAlert, IProps } from './types';
import {
  Snackbar,
  Alert,
} from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { FLASH_MESSAGE_AUTO_DISMISS_TIME } from '@configurations/constants/app';
import { useReactiveVar } from '@apollo/client';
import snackbar from '@state/models/snackbar';
import { init } from '@state/models/snackbar';
import { Z_INDEX_FLASH_MESSAGES } from '@configurations/constants/zIndexOverloads';
import snackbarCacheControl from '@state/mutations/snackbar';

const FlashMessage: FC<IProps> = () => {
  const [ alert, setAlert ] = useState<IAlert>({ severity: 'error', messageCode: null });
  const { t } = useTranslation();
  const reactiveSnackbar = useReactiveVar(snackbar);
  const {setSnackbar} = snackbarCacheControl;

  useEffect(() => {
    if (reactiveSnackbar?.messageCode) {
      setAlert(reactiveSnackbar);
      const timer = setTimeout(() => {
        setAlert({severity: 'error', messageCode: null});
        setSnackbar(init.severity, init.messageCode ?? '');
      }, FLASH_MESSAGE_AUTO_DISMISS_TIME);
      return () => clearTimeout(timer);
    }
  }, [ reactiveSnackbar ]);

  if (!!alert.messageCode) {
    return (
      <Snackbar
        open
        sx={{
          bottom: 57,
          zIndex: Z_INDEX_FLASH_MESSAGES,
        }}
      >
        <Alert
          severity={alert.severity}
        >
          {t('snackbar.messages.' + alert.messageCode)}
        </Alert>
      </Snackbar>
    );
  } else {
    return <></>;
  }
};

export default FlashMessage;
