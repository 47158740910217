const colors = {
  primary: {
    100: '#eaf1f5',
    150: '#d6e3eb',
    200: '#c1d5e1',
    250: '#acc7d7',
    300: '#98b9cd',
    350: '#83abc2',
    400: '#6e9db8',
    450: '#598fae',
    500: '#4581a4',
    550: '#2b688b',
    600: '#265c7b',
    650: '#22516c',
    700: '#1d455c',
    750: '#183a4d',
    800: '#132e3e',
    850: '#0e232e',
    900: '#0a171f',
  },
  secondary: {
    100: '#ecf6f5',
    150: '#daedea',
    200: '#c7e4e0',
    250: '#b5dbd5',
    300: '#a2d2cb',
    350: '#8fc8c1',
    400: '#7dbfb6',
    450: '#6ab6ac',
    500: '#58ada1',
    550: '#3e9488',
    600: '#378379',
    650: '#30736a',
    700: '#29625b',
    750: '#23524c',
    800: '#1c423c',
    850: '#15312d',
    900: '#0e211e',
  },
  grey: {
    100: '#ececec',
    150: '#d9d9d9',
    200: '#c6c6c6',
    250: '#b3b3b3',
    300: '#a0a0a0',
    350: '#8d8d8d',
    400: '#7a7a7a',
    450: '#676767',
    500: '#545454',
    550: '#3b3b3b',
    600: '#343434',
    650: '#2e2e2e',
    700: '#272727',
    750: '#212121',
    800: '#1a1a1a',
    850: '#141414',
    900: '#0d0d0d',
  },
  blue: {
    100: '#dbe2ee',
    200: '#b8c5dd',
    300: '#94a7cd',
    400: '#718abc',
    500: '#4d6dab',
    600: '#3e5789',
    700: '#2e4167',
    800: '#1f2c44',
    900: '#0f1622',
  },
  cyan: {
    100: '#ccf1f0',
    200: '#99e2e1',
    300: '#67d4d2',
    400: '#34c5c3',
    500: '#01B7B4',
    600: '#019290',
    700: '#016e6c',
    800: '#003736',
    900: '#002524',
  },
  purple: {
    100: '#f3dbeb',
    200: '#e7b7d8',
    300: '#dc94c4',
    400: '#d070b1',
    500: '#c44c9d',
    600: '#9d3d7e',
    700: '#762e5e',
    800: '#4e1e3f',
    900: '#270f1f',
  },
  red: {
    100: '#fa9ba6',
    200: '#f88694',
    300: '#f77282',
    400: '#f65e70',
    500: '#F4364C',
    600: '#dc3144',
    700: '#c32b3d',
    800: '#ab2635',
    900: '#92202e',
  },
  orange: {
    100: '#ffd494',
    200: '#ffcb7e',
    300: '#ffc369',
    400: '#ffba53',
    500: '#ffa928',
    600: '#e69824',
    700: '#cc8720',
    800: '#b37d45',
    900: '#996b3b',
  },
  green: {
    100: '#dcf4dd',
    200: '#b8eabb',
    300: '#95df9a',
    400: '#71d578',
    500: '#4eca56',
    600: '#3ea245',
    700: '#2f7934',
    800: '#1f5122',
    900: '#102811',
  },
  white: '#ffffff',
  lightGrey: {
    300: '#f6f6f6',
    500: '#d3d3d3',
  },
};

export default colors;
