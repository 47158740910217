import React, { FC, useState } from 'react';
import { IOwnerState, ControlState } from '@mechis/sections/Selections/types';
import {
  Control,
  ControlLabel,
} from '@mechis/sections/Selections/styles';
import { useNavigate } from 'react-router-dom';
import { getColorByState } from '@mechis/sections/Selections';
import {
  IconButton,
  Dialog,
  DialogContent,
  Box,
  Grid,
} from '@mechis/elements';
import CreateBlock from '@mechis/blocks/CreateBlock';
import useIcon from '@mechis/icons/useIcon';
import AppsIcon from '@mui/icons-material/Apps';
import useTech from '@hooks/useTech';

const ControlMenu: FC = () => {
  const [ state ] = useState<ControlState>('default');
  const [ openMenu, setOpenMenu ] = useState(false);
  const navigate = useNavigate();
  const { techId } = useTech();

  const ownerState: IOwnerState = {
    state,
  };

  const redirectAndClose = (path: string) => {
    navigate(path);
    setOpenMenu(false);
  };
  
  return (
    <>
      <Control>
        <IconButton
          size="small"
          color={getColorByState(state)}
          isSquared
          onClick={() => setOpenMenu(true)}
        >
          <AppsIcon />
        </IconButton>
        <ControlLabel ownerState={ownerState}>Menu</ControlLabel>
      </Control>
      
      <Dialog
        onClose={() => setOpenMenu(false)}
        open={openMenu}
        maxWidth="xs"
      >
        <DialogContent>
          <Box mt={2} mb={2}>
            <Grid item xs={6} mb={2}>
              <CreateBlock
                disabled={false}
                title="Nástěnka"
                onClick={() => redirectAndClose(`/app/${techId}/tech-detail`)}
                icon={useIcon('dashboard', {
                  color: 'primary',
                  sx: {
                    fontSize: '36px',
                  },
                })}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CreateBlock
                  disabled={false}
                  title="Údaje"
                  onClick={() => redirectAndClose(`/app/${techId}/tech-detail/info`)}
                  icon={useIcon('info', {
                    color: 'primary',
                    sx: {
                      fontSize: '36px',
                    },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <CreateBlock
                  disabled={false}
                  title="Záznamy"
                  onClick={() => redirectAndClose(`/app/${techId}/tech-detail/records`)}
                  icon={useIcon('record', {
                    color: 'primary',
                    sx: {
                      fontSize: '36px',
                    },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <CreateBlock
                  disabled={false}
                  title="Přílohy"
                  onClick={() => redirectAndClose(`/app/${techId}/tech-detail/attachments`)}
                  icon={useIcon('attachment', {
                    color: 'primary',
                    sx: {
                      fontSize: '36px',
                    },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <CreateBlock
                  disabled={false}
                  title="Plán údržby"
                  onClick={() => redirectAndClose(`/app/${techId}/tech-detail/maintenance-plan`)}
                  icon={useIcon('maintenance', {
                    color: 'primary',
                    sx: {
                      fontSize: '36px',
                    },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <CreateBlock
                  disabled={false}
                  title="Analýza"
                  onClick={() => redirectAndClose(`/app/${techId}/tech-detail/analysis`)}
                  icon={useIcon('analysis', {
                    color: 'primary',
                    sx: {
                      fontSize: '36px',
                    },
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <CreateBlock
                  disabled={false}
                  title="Nastavení"
                  onClick={() => redirectAndClose(`/app/${techId}/tech-detail/settings`)}
                  icon={useIcon('techSettings', {
                    color: 'primary',
                    sx: {
                      fontSize: '36px',
                    },
                  })}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ControlMenu;
