import React, { FC } from 'react';
import { IProps } from './types';
import { Block } from './styles';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  Box,
  IconButton,
  Button,
  Stack,
} from '@mechis/elements';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { useTranslation } from 'react-i18next';

const WarningModal: FC<IProps> = ({ 
  open,
  onClose,
  title,
  children,
  onConfirm,
  confirmBtnText,
  hideClose,
}) => {
  const { t } = useTranslation();

  return (
    <Block>
      <Dialog 
        open={open}
        onClose={() => onClose(!open)}
      >
        <Box p={2}>
          <DialogContent sx={{ overflow: 'initial' }}>
            {title && (
              <Typography
                variant="h4"
                textAlign="center"
                mb={4}
                color="secondary"
              >
                {title}
              </Typography>
            )}
            <Paper variant="outlined" sx={{ position: 'relative' }}>
              <FormatQuoteIcon 
                color="disabled"
                fontSize="large"
                sx={{ 
                  position: 'absolute',
                  top: '8px',
                  left: '8px',
                  transform: 'rotate(180deg)',
                  opacity: '0.4',
                }}
              />
              <Box p={2} textAlign="center">
                <IconButton
                  color="warning"
                  size="large"
                  sx={{
                    position: 'relative',
                    mt: -6,
                  }}
                >
                  <EmojiObjectsIcon fontSize="large" />
                </IconButton>
                <Typography mb={2}>
                  <small>
                    {children}
                  </small>
                </Typography>
              </Box>
              <FormatQuoteIcon 
                color="disabled"
                fontSize="large"
                sx={{ 
                  position: 'absolute',
                  bottom: '8px',
                  right: '8px',
                  opacity: '0.4',
                }}
              />
            </Paper>
          </DialogContent>
          <DialogActions>
            <Stack
              sx={{
                width: '100%',
                pr: 2,
                pl: 2,
              }}
              direction="row" 
              justifyContent="space-around"
            >
              {!hideClose && (
                <Button
                  onClick={() => onClose(false)}
                  variant="outlined"
                  color="primary"
                >
                  {t('components.warningModal.btnClose')}
                </Button>
              )}
              
              {onConfirm && (
                <Button
                  onClickCapture={() => {
                    onClose(false);
                    onConfirm();
                  }}
                  variant="contained"
                  color="secondary"
                >
                  {confirmBtnText ?? t('components.warningModal.btnConfirm')}
                </Button>
              )}
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
    </Block>
  );
};

export default WarningModal;
