import React, { FC } from 'react';
import { ICreateTechModalProps } from './types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from '@mechis/elements';
import TechSelector from '@mechis/blocks/TechSelector';
import useIcon from '@mechis/icons/useIcon';
import { useQuery } from '@apollo/client';
import { IAddFlow } from '@state/models/app/types';
import { GET_APP } from '@state/queries/app';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { IAppQuery } from '@state/queries/app/types';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useAddDevice from '@hooks/useAddDevice';
import resolveIcon from '@utilities/resolveDeviceCategoryIcon';

const CreateTechModal: FC<ICreateTechModalProps> = ({ isOpen, onClose }) => {
  const { onCreate } = useAddDevice();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useQuery<IAppQuery>(GET_APP);
  const { data: addDeviceTmpStore } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const addFlow = data?.app.addFlow as IAddFlow;
  const { setName } = addDeviceCacheControl;
  
  const schema = yup.object({
    machineNickname: yup.string().required(t('addDevice.components.machineNickname.required')),
  }).required();

  const { control, handleSubmit } = useForm<{ machineNickname: string }>({
    defaultValues: {
      machineNickname: addDeviceTmpStore?.addDevice.name,
    },
    resolver: yupResolver(schema),
  });

  const onContinue = async ({ machineNickname }: { machineNickname: string }) => {
    setName(machineNickname);
    navigate(`/app/new-tech/${addFlow}/${0}`);
  };

  const onSubmitCreate = async ({ machineNickname }: { machineNickname: string }) => {
    setName(machineNickname);
    onCreate(machineNickname);
  };

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      maxWidth="xs"
      sx={{
        overflow: 'inherit',
      }}
      PaperProps={{
        sx: {
          overflow: 'inherit',
        },
      }}
    >
      <Box p={1} textAlign="center">
        <DialogContent sx={{ overflow: 'inherit' }}>
          <Box
            sx={{
              width: '140px',
              marginLeft: 'auto',
              marginRight: 'auto',
              position: 'relative',
              top: (theme) => theme.spacing(-8),
            }}
            mb={-4}
          >
            <TechSelector
              title={t(`deviceCategories.${addFlow}`)}
              icon={useIcon(resolveIcon(addFlow), {
                color: 'primary',
                sx: {
                  fontSize: '56px',
                },
              })}
              disabled={false}
            />
          </Box>
          <Box>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  label={t('addDevice.components.machineNickname.name')}
                  id="machineNickname"
                  error={!!formState.errors.machineNickname?.message}
                  helperText={formState.errors.machineNickname?.message}
                  variant="outlined"
                />
              )}
              name="machineNickname"
              control={control}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ flexDirection: 'column' }}>
          <Button
            onClick={handleSubmit(onContinue)}
            variant="contained"
            color="secondary"
          >
            {t('addDevice.continue')}
          </Button>
          <Button
            onClick={handleSubmit(onSubmitCreate)}
            size="small"
            sx={{
              mt: 2,
              lineHeight: '1.25em',
              textDecoration: 'underline',
              maxWidth: '140px',
            }}
          >
            {t('addDevice.createWithoutParams')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateTechModal;
