import React, { FC } from 'react';
import { Typography, Box, Button } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useConfirmRegistrationMutation } from '@state/mechis-backend/generated/schema';
import FormWrapper from '@mechis/sections/FormWrapper';
import Loader from '@mechis/blocks/Loader';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { isDesktop } from 'react-device-detect';
import QRCode from '@assets/svgs/qr-code.svg';

const EmailVerified: FC = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();
  const [ confirmRegistration, { data, loading, error } ] = useConfirmRegistrationMutation();

  useEffectOnce(() => {
    if (token) {
      try {
        confirmRegistration({ variables: { confirmationToken: token }});
      } catch (e) {
        //dunno
      }
    }
  });

  const redirectToLogin = () => {
    if (isDesktop) {
      return (
        <Box textAlign="center" mt={4}>
          <Typography variant="h6" color={'secondary'}>
            {t('emailVerified.pleaseScanQR')}
          </Typography>
          <img src={QRCode} alt="" width={300} />
        </Box>
      );
    }

    return (
      <Box textAlign="center" pt={2}>
        <Button variant="contained" onClick={() => navigate('/')}>
          {t('emailVerified.login')}
        </Button>
      </Box>
    );
  };

  return (
    <FormWrapper>
      {loading && (
        <>
          <Typography variant="h1" color={'secondary'} textAlign={'center'}>
            {t('emailVerified.waiting.title')}
          </Typography>
          <Box textAlign="center" mt={4}>
            <Loader />
          </Box>
        </>
      )}
      {error && (
        <>
          <Typography variant="h1" color={'secondary'} textAlign={'center'}>
            {t('emailVerified.error.title')}
          </Typography>
          <Typography
            mt={4}
            variant="h4"
            color={'secondary'}
            textAlign={'center'}
          >
            {t('emailVerified.error.subtitle')}
          </Typography>
          {redirectToLogin()}
        </>
      )}
      {!loading && !error && data && (
        <>
          <Box textAlign="center">
            <CheckCircleOutlineIcon
              style={{ fontSize: '6rem' }}
              color="secondary"
            />
          </Box>
          <Typography variant="h1" color={'secondary'} textAlign={'center'}>
            {t('emailVerified.done.title')}
          </Typography>
          <Typography
            mt={4}
            variant="h4"
            color={'secondary'}
            textAlign={'center'}
          >
            {t('emailVerified.done.subtitle')}
          </Typography>
          {redirectToLogin()}
        </>
      )}
    </FormWrapper>
  );
};

export default EmailVerified;
