const styleOverrides = {
  root: ({ theme }: any) => ({
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: `${theme.radiuses.medium}px`,
    },
  }),
};

export default styleOverrides;
