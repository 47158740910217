import { IAddFlow } from '@state/models/app/types';
import {
  MOTORCYCLE_DEFAULT_VEHICLE_TYPE_SUPERSPORT_ID,
  MOTORCYCLE_DEVICE_CATEGORY_ID,
  PASSENGER_CAR_DEFAULT_VEHICLE_TYPE_SEDAN_ID,
  PASSENGER_CAR_DEVICE_CATEGORY_ID,
} from './types';

export const getDefaultFlowPrerequisiteByFlow = (flow: IAddFlow) => {
  switch (flow) {
    case 'passenger-car':
      return {
        deviceCategory: { id: PASSENGER_CAR_DEVICE_CATEGORY_ID },
        vehicleType: { id: PASSENGER_CAR_DEFAULT_VEHICLE_TYPE_SEDAN_ID },
      };
    case 'motorcycle':
      return {
        deviceCategory: { id: MOTORCYCLE_DEVICE_CATEGORY_ID },
        vehicleType: { id: MOTORCYCLE_DEFAULT_VEHICLE_TYPE_SUPERSPORT_ID },
      };
    /*case 'constructions-machine':
      return {
        deviceCategory: 'constructions-machine',
        vehicleType: 'constructions-machine',
      };
    case 'garden-machinery':
      return {
        deviceCategory: 'garden-machinery',
        vehicleType: 'garden-machinery',
      };
   
    case 'other':
      return {
        deviceCategory: 'other',
        vehicleType: 'other',
      };
    
    case 'racing':
      return {
        deviceCategory: 'racing',
        vehicleType: 'racing',
      };
    case 'trailer':
      return {
        deviceCategory: 'trailer',
        vehicleType: 'trailer',
      };
    case 'truck':
      return {
        deviceCategory: 'truck',
        vehicleType: 'truck',
      };
    case 'veteran':
      return {
        deviceCategory: 'veteran',
        vehicleType: 'veteran',
      };*/
    default:
      return {
        deviceCategory: { id: PASSENGER_CAR_DEVICE_CATEGORY_ID },
        vehicleType: { id: PASSENGER_CAR_DEFAULT_VEHICLE_TYPE_SEDAN_ID },
      };
  }
};
