import React, { FC } from 'react';
import { IProps } from './types';
import {
  TextField,
  InputAdornment,
} from '@mechis/elements';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import useIcon from '@mechis/icons/useIcon';

const Model: FC<IProps> = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { setModel } = addDeviceCacheControl;

  const schema = yup.object({
    model: yup.string(),
  }).required();

  const { control, handleSubmit } = useForm<{ model: string | null }>({
    defaultValues: {
      model: data?.addDevice.model,
    },
    resolver: yupResolver(schema),
  });

  const onSave = async ({ model }: { model: string | null }) => {
    setModel(model);
  };

  return (
    <Controller
      render={({ field, formState }) => (
        <TextField
          {...field}
          onKeyUp={() => {
            handleSubmit(onSave)();
          }}
          label={t('addDevice.components.model.name')}
          id="model"
          error={!!formState.errors.model?.message}
          helperText={formState.errors.model?.message}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {useIcon('mechisModel', { fontSize: 'small' })}
              </InputAdornment>
            ),
          }}
        />
      )}
      name="model"
      control={control}
    />
  );
};

export default Model;
