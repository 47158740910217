import React from 'react';
import {useTransmissionsQuery} from '@state/mechis-backend/generated/schema';
import addDeviceCacheControl from '@state/mutations/addDevice';
import NativeSelect from '@mechis/elements/NativeSelect';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import {IAddDeviceQuery} from '@state/queries/addDevice/types';
import {GET_ADD_DEVICE_TMP_STORE} from '@state/queries/addDevice';

export const Transmission:React.FC = () => {
  const { t } = useTranslation();
  const { data: transmissions } = useTransmissionsQuery();
  const { setTransmission } = addDeviceCacheControl;
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);

  const onChange = (e: any) => {
    setTransmission({
      id: parseInt(e.currentTarget.value),
    });
  };
  
  const getOptions = () => {
    return transmissions?.transmissions.map((transmission) => {
      return <option color="black" key={transmission.id} value={transmission.id}>{t(`transmission.${transmission.name}`)}</option>;
    });
  };

  return (
    <NativeSelect 
      label={t('addDevice.components.transmission.name')}
      id="transmission"
      fullWidth
      defaultValue={data?.addDevice.transmission?.id}
      onChange={onChange}
    >
      {getOptions()}
    </NativeSelect>
  );
};

Transmission.displayName = 'Transmission';
