import { ReactiveVar } from '@apollo/client';
import newExpense from '@state/models/newExpense';
import { INewExpenseModel, IPrice } from '../models/newExpense/types';
import { ITagOption } from '@mechis/sections/Selections/TagFilters/types';

const setExpenses = (newExpenseModel: ReactiveVar<INewExpenseModel>) => {
  return (expenses: ITagOption[]) => {
    const model = {
      ...newExpenseModel(),
      expenses,
    };
    newExpenseModel(model);
  };
};

const setPrices = (newExpenseModel: ReactiveVar<INewExpenseModel>) => {
  return (prices: IPrice[]) => {
    const model = {
      ...newExpenseModel(),
      prices,
    };
    newExpenseModel(model);
  };
};

const setPriceTotal = (newExpenseModel: ReactiveVar<INewExpenseModel>) => {
  return (priceTotal: number) => {
    const model = {
      ...newExpenseModel(),
      priceTotal,
    };
    newExpenseModel(model);
  };
};

const setLocationFrom = (newExpenseModel: ReactiveVar<INewExpenseModel>) => {
  return (locationFrom: string) => {
    const model = {
      ...newExpenseModel(),
      locationFrom,
    };
    newExpenseModel(model);
  };
};

const newExpenseCacheControl = {
  setExpenses: setExpenses(newExpense),
  setPrices: setPrices(newExpense),
  setPriceTotal: setPriceTotal(newExpense),
  setLocationFrom: setLocationFrom(newExpense),
};

export default newExpenseCacheControl;
