import React, { useMemo } from 'react';
import { DeviceCategoriesQuery } from '@state/mechis-backend/generated/schema';
import { useTranslation } from 'react-i18next';

export function useGetDeviceCategoryOptions(deviceCategories: DeviceCategoriesQuery | undefined): JSX.Element[] {
  const { t } = useTranslation();
  // Remove or adjust this array and filter to get more categories
  const allowedCategoryIds = [ '1', '6' ];

  const options = useMemo(() => {
    return deviceCategories?.deviceCategories
      .filter((category) => allowedCategoryIds.includes(category.id))
      .map((category) => (
        <option key={category.id} value={category.id}>
          {t(`deviceCategories.${category.name}`)}
        </option>
      ));
  }, [ deviceCategories, t ]);

  return options ?? [];
}
