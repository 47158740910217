import { styled } from '@mui/material/styles';

export const Block = styled('div', {
  name: 'Logo',
  slot: 'Root',
})(() => ({
  width: 'fit-content',
  display: 'flex',
}));

