import React from 'react';
import Typography from '@mechis/elements/Typography';
import {Box, Paper} from '@mechis/elements';
import { useTranslation } from 'react-i18next';

export const NotSupportedPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      p={4}
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper sx={{ maxWidth: '480px'}}>
        <Box p={2} textAlign="center">
          <Typography variant="h2">{t('notSupportedPage.title')}</Typography>
          <Box mt={2}>
            <Typography>{t('notSupportedPage.description')}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
