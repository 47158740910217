import React, { FC, useState } from 'react';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import ControlMenu from '../ControlMenu';
import {
  Box,
  Paper,
  IconButton,
  Typography,
  Button,
  Stack,
} from '@mechis/elements';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { CloudinaryWidgetSources, ICloudinaryWidgetConfiguration } from '@mechis/blocks/Cloudinary/types';
import {
  CLAUDINARY_CLOUD_NAME,
  CLAUDINARY_UPLOAD_PRESET,
  CLOUDINARY_MAX_IMAGE_SIZE_IN_BYTES,
  CLOUDINARY_MAX_IMAGE_WIDTH_IN_PX,
} from '@configurations/constants/app';
import {
  Attachment,
  DeviceThumbnailImageDocument,
  DevicesDocument,
  useDeleteAttachmentMutation,
  useDeleteDeviceAttachmentMutation,
  useDeviceThumbnailImageQuery,
  useUpdateDeviceAvatarMutation,
} from '@state/mechis-backend/generated/schema';
import { Editable } from '@screens/NewTech/components/Avatar/styles';
import Edit from '@mui/icons-material/Edit';
import ClaudinaryImageBuilder from '@mechis/blocks/ClaudinaryImageBuilder';
import CloudinaryUploadWidget from '@mechis/blocks/Cloudinary/CloudinaryUploadWidget/CloudinaryUploadWidget';
import snackbarCacheControl from '@state/mutations/snackbar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useTech from '@hooks/useTech';

const cloudinaryConfiguration: ICloudinaryWidgetConfiguration = {
  cloudName: CLAUDINARY_CLOUD_NAME,
  uploadPreset: CLAUDINARY_UPLOAD_PRESET,
  multiple: false,
  cropping: true,
  showAdvancedOptions: true,
  sources: [ CloudinaryWidgetSources.Local, CloudinaryWidgetSources.Url ],
  folder: 'profile',
  maxImageFileSize: CLOUDINARY_MAX_IMAGE_SIZE_IN_BYTES, //restrict file size to less than 2MB
  maxImageWidth: CLOUDINARY_MAX_IMAGE_WIDTH_IN_PX, //Scales the image down to a width of 2000 pixels before uploading
  theme: 'white',
};

const EditAvatar: FC = () => {
  useHeader({
    headline: 'appBar.headline.editAvatar',
    widget: (
      <Selections>
        <ControlMenu />
      </Selections>
    ),
  });
  const { techId } = useTech();
  const { t } = useTranslation();
  const { setSnackbar } = snackbarCacheControl;
  const navigate = useNavigate();
  const [ uploadedImage, setUploadedImage ] = useState<Partial<Attachment> | undefined>();
  const [ runDeleteDeviceAttachment ] = useDeleteDeviceAttachmentMutation();
  const [ runDeleteAttachment ] = useDeleteAttachmentMutation();
  const { data, loading, error } = useDeviceThumbnailImageQuery({
    variables: {
      deviceId: techId,
    },
  });

  const [ updateAvatar ] = useUpdateDeviceAvatarMutation({
    onCompleted: () => setSnackbar('success', 'AVATAR_UPDATE_SUCCESSFUL'),
    onError: () => setSnackbar('error','AVATAR_UPDATE_ERROR'),
    refetchQueries: [
      {
        query: DevicesDocument,
      },
      {
        query: DeviceThumbnailImageDocument,
        variables: {
          deviceId: techId,
        },
      },
    ],
  });

  const submit = async () => {
    if (uploadedImage && !loading && !error) {
      await updateAvatar({
        variables: {
          device: {
            id: techId,
            thumbnailImage: {
              id: uploadedImage.id,
            },
          },
        },
      });

      if (data?.device?.thumbnailImage?.id) {
        await runDeleteDeviceAttachment({
          variables: {
            attachmentId: Number(data?.device?.thumbnailImage?.id),
            deviceId: techId,
          },
        });
    
        await runDeleteAttachment({
          variables: {
            deleteAttachmentId: Number(data?.device?.thumbnailImage?.id),
          },
        });
      }
    } else {
      setSnackbar('error','AVATAR_UPDATE_ERROR');
    }
    navigate(`/app/${techId}/tech-detail/settings`);
  };

  const renderImage = (storageName: string | undefined, handleOpenWidget: () => void) => {
    if (storageName) {
      return (
        <Editable>
          <IconButton
            color="primary"
            size="large"
            isSquared
          >
            <Edit
              fontSize="small"
              onClick={handleOpenWidget}
            />
          </IconButton>
          <ClaudinaryImageBuilder
            imageId={storageName}
            width={238}
            height={238}
          />
        </Editable>
      );
    } else {
      return (
        <Box
          p={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
          onClick={handleOpenWidget}
        >
          <IconButton
            color="primary"
            size="large"
          >
            <CloudUploadIcon fontSize="large" />
          </IconButton>
          <Typography variant="h6">
            {t('editAvatar.pickPhoto')}
          </Typography>
        </Box>
      );
    }
  };

  return (
    <>
      <Box
        sx={{
          width: '260px',
          height: '260px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            height: '260px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CloudinaryUploadWidget
            setCloudinaryAttachmentInfo={setUploadedImage}
            cloudinaryConfiguration={cloudinaryConfiguration}
          >
            {({widget }: any) => renderImage(uploadedImage?.storageName, () => widget.open())}
          </CloudinaryUploadWidget>
        </Paper>
      </Box>
      <Stack alignItems="center" spacing={1.5} sx={{ pt: 2.5 }}>
        <Button
          variant="contained"
          color="secondary"
          disabled={!uploadedImage}
          onClick={submit}
        >
          {t('editAvatar.save')}
        </Button>
      </Stack>
    </>
  );
};

export default EditAvatar;
