import { Box, Paper, Typography, Stack, Button } from '@mechis/elements';
import React, { FC, useState, useDeferredValue, memo } from 'react';
import { IProps } from './types';
import { useTranslation } from 'react-i18next';
import AnalyzeData from '../AnalyzeData/AnalyzeData';
import { AnalyzeCommand } from '@state/mechis-backend/generated/schema';
import { useQuery } from '@apollo/client';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { GET_SELECTIONS } from '@state/queries/selections';
import { AnalyzeCategory as AnalyzeCategoryType } from '@state/mechis-backend/generated/schema';
import useIcon from '@mechis/icons/useIcon';
import { CommonIcons } from '@mechis/icons/types';

const AnalyzeCategory: FC<IProps> = ({
  categoryName,
  fuel,
}) => {
  const { t } = useTranslation();
  const [ command, setCommand ] = useState<AnalyzeCommand>(AnalyzeCommand.Avg);
  const deferredCommand = useDeferredValue(command);
  const { data: selections } = useQuery<ISelectionsQuery>(GET_SELECTIONS);

  if (
    selections &&
    selections?.selections?.tags.length > 0 &&
    (
      categoryName === AnalyzeCategoryType.Refuelings
    )
    
  ) return <></>;

  return (
    <Stack>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="space-between"
      >
        {(fuel || categoryName !== AnalyzeCategoryType.Refuelings) ? (
          <Typography variant="sectionHeadline" sx={{ ml: 1 }}>
            {fuel ? '' : t(`analyzeCategoryTypes.${categoryName}`)}
            {fuel ? (
              <Box justifyContent="center" flexDirection="column" alignItems="center" mt={1}>
                <div style={{ textAlign: 'center', lineHeight: 0}}>{useIcon(`fuel-${fuel.fuelType?.fuelName}` as CommonIcons)}</div>
                <div>{t(`fuelTypes.${fuel.fuelType?.fuelName}`)}</div>
              </Box>
            ) : ''}
          </Typography>  
        ) : (
          <Typography variant="sectionHeadline" sx={{ ml: 1 }}>Celkem</Typography>
        )}
        <Stack direction="row" spacing={0.25} sx={{ position: 'relative', bottom: '-16px' }} mt={-2}>
          <Button 
            variant={command === AnalyzeCommand.Avg ? 'contained' : 'outlined'} 
            size="small"
            sx={{ background: command !== AnalyzeCommand.Avg ? 'white' : undefined }}
            onClick={() => setCommand(AnalyzeCommand.Avg)}
          >
            Průměr
          </Button>
          <Button 
            variant={command === AnalyzeCommand.Max ? 'contained' : 'outlined'} 
            size="small"
            sx={{ background: command !== AnalyzeCommand.Max ? 'white' : undefined }}
            onClick={() => setCommand(AnalyzeCommand.Max)}
          >
            Maximum
          </Button>
          <Button 
            variant={command === AnalyzeCommand.Min ? 'contained' : 'outlined'} 
            size="small"
            sx={{ background: command !== AnalyzeCommand.Min ? 'white' : undefined }}
            onClick={() => setCommand(AnalyzeCommand.Min)}
          >
            Minimum
          </Button>
          <Button 
            variant={command === AnalyzeCommand.Sum ? 'contained' : 'outlined'} 
            size="small"
            sx={{ background: command !== AnalyzeCommand.Sum ? 'white' : undefined }}
            onClick={() => setCommand(AnalyzeCommand.Sum)}
          >
            Součet
          </Button>
        </Stack>

        {/*todo: please refactor this */}
        {/*<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <NativeSelect
            label="Zobrazit"
            id="command"
            size="small"
            defaultValue={command}
            onChange={(e) => setCommand(e.target.value as AnalyzeCommand)}
          >
            {options}
          </NativeSelect>
        </FormControl>
      */}

      </Stack>
      <Paper>
        <Box p={2}>
          <AnalyzeData
            category={categoryName}
            command={deferredCommand}
            fuelId={fuel ? parseInt(fuel.id) : undefined}
          />
        </Box>
      </Paper>
    </Stack>
  );
};

export default memo(AnalyzeCategory);
