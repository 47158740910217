import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  Button,
  Stack,
  NativeSelect,
  TextField,
  Typography,
  InputAdornment,
} from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import ConfirmationModal from '@mechis/blocks/ConfirmationModal';
import { IUpdateOperationProps } from '../types';
import snackbarCacheControl from '@state/mutations/snackbar';
import { DAYS_IN_MONTH } from '@configurations/constants/app';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import DescriptionIcon from '@mui/icons-material/Description';
import { useOperationTypesQuery } from '@state/mechis-backend/generated/schema';
import useTech, { UnitType } from '@hooks/useTech';
import NumberField from '@mechis/elements/NumberField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const UpdateOperation: FC<IUpdateOperationProps> = ({
  open,
  setOpen,
  intervalId,
  updateOperation,
  deleteOperation,
  operationData,
  error,
}) => {
  const { setSnackbar } = snackbarCacheControl;
  const { t } = useTranslation();
  const [ selectedOperationType, setSelectedOperationType ] = useState(1);
  const [ isConfirmationModalOpened, setIsConfirmationModalOpened ] = useState(false);
  const { getTechUnit } = useTech();

  const schema = yup.object({
    operationName: yup.string().required(t('newMaintenance.form.maintenanceName.required')),
    distanceInterval: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .nullable()
      .test('is-not-zero', t('newMaintenance.form.distanceInterval.required'), (value) => {
        return value === null || value !== 0;
      }),
    timeInterval: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .nullable()
      .test('is-not-zero', t('newMaintenance.form.timeInterval.required'), (value) => {
        return value === null || value !== 0;
      }),
  }).required();

  const { data: operationTypes } = useOperationTypesQuery();

  const getOperationTypesOptions = () => {
    return operationTypes?.operationTypes.map((type) => {
      return <option color="black" key={type.id} value={type.id}>{t(`operation.type.${type.name}`)}</option>;
    });
  };

  useEffect(() => {
    reset({
      operationName: operationData?.name,
      operationDescription: operationData?.description,
      timeInterval: operationData?.periodDay !== null ? Math.ceil((operationData?.periodDay?? 0) / DAYS_IN_MONTH) : null,
      distanceInterval: operationData?.periodDistance,
    });
  }, [ operationData ]);

  const onUpdateOperation = async (data: any) => {
    const periodDay = data.timeInterval !== null ? Number(data.timeInterval) * DAYS_IN_MONTH : null;
    const periodDistance = data.distanceInterval !== null ? Number(data.distanceInterval) : null;

    await updateOperation({
      variables: {
        operation: {
          id: Number(operationData.id),
          name: data.operationName,
          maintenanceInterval: {
            id: Number(intervalId),
          },
          operationType: {
            id: Number(selectedOperationType),
          },
          periodDay,
          periodDistance,
          description: data.operationDescription,
        },
      },
    });

    error ? setSnackbar('error', 'OPERATION_CREATE_ERROR') : setSnackbar('success', 'OPERATION_CREATE_SUCCESSFUL');
    setOpen(false);

  };

  const onDeleteOperation = async () => {

    await deleteOperation({
      variables: {
        deleteOperationId: Number(operationData.id),
      },
    });

    error ? setSnackbar('error', 'OPERATION_DELETE_ERROR') : setSnackbar('success', 'OPERATION_DELETE_SUCCESSFUL');
    setIsConfirmationModalOpened(false);
    setOpen(false);
  };

  // eslint-disable-next-line
  const { control, handleSubmit, formState: { isDirty, dirtyFields, isSubmitting }, reset } = useForm({
    defaultValues: {
      operationName: operationData?.name,
      operationDescription: operationData?.description,
      timeInterval:  operationData?.periodDay !== null ? Math.ceil((operationData?.periodDay?? 0) / DAYS_IN_MONTH) : null,
      distanceInterval: operationData?.periodDistance,
    },
    resolver: yupResolver(schema),
  });

  return (
    <>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        maxWidth="xs"
      >
        <Box textAlign="center">
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <Typography variant="sectionHeadline">{t('updateOperation.title')}</Typography>
              <Stack direction="column" spacing={2}>
                <NativeSelect
                  label={t('newOperation.form.type')}
                  isLabelWhite
                  id="deviceCategory"
                  fullWidth
                  defaultValue={selectedOperationType}
                  onChange={(e) => {
                    setSelectedOperationType(+e.currentTarget.value);
                  }}
                >
                  {getOperationTypesOptions()}
                </NativeSelect>
                <Controller
                  render={({ field, formState }) => (
                    <TextField
                      {...field}
                      label={t('newOperation.form.name')}
                      variant="outlined"
                      type="text"
                      id={'operationName'}
                      error={!!formState.errors.operationName?.message}
                      helperText={<>{formState.errors.operationName?.message}</>}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BuildCircleIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  name="operationName"
                  control={control}
                />
                <Controller
                  render={({ field, formState }) => (
                    <TextField
                      {...field}
                      label={t('newOperation.form.description')}
                      variant="outlined"
                      type="text"
                      id={'operationDescription'}
                      error={!!formState.errors.operationDescription?.message}
                      helperText={<>{formState.errors.operationDescription?.message}</>}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DescriptionIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  name="operationDescription"
                  control={control}
                />
                <Controller
                  render={({ field, formState }) => (
                    <NumberField
                      {...field}
                      label={t('newOperation.form.timeInterval')}
                      variant="outlined"
                      id={'timeInterval'}
                      error={!!formState.errors.timeInterval?.message}
                      helperText={formState.errors.timeInterval?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarMonthIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      onlyInteger
                    />
                  )}
                  name="timeInterval"
                  control={control}
                />
                <Controller
                  render={({ field, formState }) => (
                    <NumberField
                      {...field}
                      label={t('newOperation.form.distanceInterval', { unit: getTechUnit(UnitType.Odometer) })}
                      variant="outlined"
                      id={'distanceInterval'}
                      error={!!formState.errors.distanceInterval?.message}
                      helperText={<>{formState.errors.distanceInterval?.message}</>}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SpeedIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      onlyInteger
                    />
                  )}
                  name="distanceInterval"
                  control={control}
                />
              </Stack>
              <Stack
                spacing={2}
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  onClick={() => setOpen(false)}
                  variant="outlined"
                >
                  {t('newOperation.button.cancel')}
                </Button>
                <Button
                  onClick={handleSubmit(onUpdateOperation)}
                  variant="contained"
                  color="secondary"
                >
                  {t('newOperation.button.save')}
                </Button>
              </Stack>
              <Button 
                onClick={() => setIsConfirmationModalOpened(true)} 
                color="error"
                variant="outlined"
                sx={{
                  alignSelf: 'center',
                }}
              >
                {t('buttons.delete')}
              </Button>
            </Stack>
          </DialogContent>
        </Box>
      </Dialog>
      <ConfirmationModal
        isOpen={isConfirmationModalOpened}
        onClose={() => setIsConfirmationModalOpened(false)}
        onConfirm={onDeleteOperation}
        content={t('updateOperation.confirmationModal.content')}
        confirmButtonText={t('buttons.delete')}
      />
    </>
  );
};

export default UpdateOperation;
