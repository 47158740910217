import React, { FC } from 'react';
import { IProps } from './types';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ShortTextIcon from '@mui/icons-material/ShortText';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TableChartIcon from '@mui/icons-material/TableChart';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { Typography } from '@mechis/elements';
import { MimeTypes } from '@configurations/types/global';

export const getIconByMimeType = (mimeType: MimeTypes) => {
  switch (mimeType) {
    case 'text/plain':
      return <ShortTextIcon fontSize="large" />;
    case 'audio/mpeg':
      return <MusicNoteIcon fontSize="large" />;
    case 'video/mp4':
      return <SmartDisplayIcon fontSize="large" />;
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <TableChartIcon fontSize="large" />;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
      return <TextSnippetIcon fontSize="large" />;
    case 'application/pdf':
      return <PictureAsPdfIcon fontSize="large" />;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return <CoPresentIcon fontSize="large" />;
    default:
      return <FilePresentIcon fontSize="large" />;
  }
};

const FirebaseAttachmentBuilder: FC<IProps> = ({ name, mimeType, size = 75 }) => {
  return (
    <div style={{ textAlign: 'center', width: size, height: size }}>
      <Typography variant="h1" fontSize="large">
        {getIconByMimeType(mimeType)}
      </Typography>
      <span style={{ fontSize: 'xx-small'}}>
        {name}
      </span>
    </div>
  );
};

export default FirebaseAttachmentBuilder;
