import { Navigation, NavigationFooter, NavigationHeader, Section } from '@mechis/sections/AppNavigation/styles';
import { 
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mechis/elements';
import Logo from '@mechis/blocks/Logo';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SelectedTech from '@mechis/sections/SelectedTech';
import DirectionsCarFilledRoundedIcon from '@mui/icons-material/DirectionsCarFilledRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '@hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { IProps } from '@mechis/sections/AppNavigation/AppBrowserNavigation/types';

const AppBrowserNavigation: React.FC<IProps> = ({
  // eslint-disable-next-line
  onHandleCreateLink,
}) => {
  const { onLogout } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const { t } = useTranslation();

  const displaySelectedTech = pathname !== '/app' &&
    pathname !== '/app/new-tech';

  return (
    <Section>
      <Navigation>
        <Box>
          <NavigationHeader>
            <IconButton
              size="large"
              color="white"
              onClick={() => navigate('/app/mechis')}
            >
              <Logo
                variant="white"
                size={32}
              />
            </IconButton>
            <IconButton
              color="white"
              onClick={() => navigate('/app/my-profile')}
            >
              <AccountCircleIcon
                htmlColor="#ffffff"
                sx={{ opacity: 0.8 }}
              />
            </IconButton>
          </NavigationHeader>
          {displaySelectedTech ? (
            <Box p={2} sx={{
              position: 'relative',
              cursor: 'pointer',
              //prevents layout shift
              minHeight: '100px',
            }}>
              <SelectedTech variant="desktop" />
            </Box>
          ): (
            <Box sx={{ height: '100px'}} />
          )}
          <MenuList>
            <MenuItem onClick={() => navigate('/app')}>
              <ListItemIcon>
                <DirectionsCarFilledRoundedIcon
                  htmlColor="#ffffff"
                  sx={{ opacity: 0.8 }}
                />
              </ListItemIcon>
              <ListItemText variant="white">Přehled techniky</ListItemText>
            </MenuItem>
            {/*todo: refactor this after mobile approach is done - DESKTOP NOT VISIBLE NOW */}
            {/*createMenu.map((item: IMenuItem) => {
              const isDisabled = hasUserNoDevices && !item.isPageWithoutContext;
              return isDisabled ? (
                <Tooltip
                  title={t('deviceListing.noDeviceNavigationTooltip')}
                  placement="left"
                  key={item.title}
                >
                  <span>
                    <AppNavigationMenuItem
                      item={item}
                      // onClick={() => onHandleCreateLink('', true)}
                      disabled={isDisabled}
                    />
                  </span>
                </Tooltip>
              ) : (
                <AppNavigationMenuItem
                  key={item.title}
                  item={item}
                  // onClick={() => onHandleCreateLink('', true}
                />
              );
            })*/}
          </MenuList>
        </Box>
        <NavigationFooter>
          <IconButton
            id="cy-desktop-logout"
            color="white"
            onClick={onLogout}
          >
            <LogoutIcon
              htmlColor="#ffffff"
              sx={{ opacity: 0.8 }}
            />
          </IconButton>
          <IconButton
            color="white"
            onClick={() => navigate('/app/settings')}
          >
            <SettingsIcon
              htmlColor="#ffffff"
              sx={{ opacity: 0.8 }}
            />
          </IconButton>
        </NavigationFooter>
      </Navigation>
    </Section>
  );
};

export default AppBrowserNavigation;
