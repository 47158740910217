import React, { useState, useEffect } from 'react';
import { IOwnerState } from '../types';
import {
  Control,
  ControlLabel,
} from '../styles';
import {
  IconButton,
  Dialog,
  DialogContent,
  Box,
  DialogTitle,
  Stack,
  Button,
} from '@mechis/elements';
import { getColorByState } from '../Selections';
import SpeedIcon from '@mui/icons-material/Speed';
import OdometerBlock from '@mechis/blocks/OdometerBlock';
import { useQuery } from '@apollo/client';
import { GET_SELECTIONS } from '@state/queries/selections';
import { ISelectionsQuery } from '@state/queries/selections/types';
import selectionsCacheControl from '@state/mutations/selections';
import { useActualOdometerStateByDeviceIdQuery } from '@state/mechis-backend/generated/schema';
import useTech, { UnitType } from '@hooks/useTech';

const Odometer = () => {
  const [ openOdometer, setOpenOdometer ] = useState(false);
  const { data } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const { setOdometerValue, setOdometerState } = selectionsCacheControl;
  const odometer = data?.selections.odometerValue || 0;
  const state = data?.selections.odometerState || 'default';
  const [ currentOdometerValue, setCurrentOdometerValue ] = useState<number>(0);
  const { techId, getTechUnit } = useTech();

  const {
    data: actualOdometerData,
    loading: actualOdometerDataLoading,
    error: actualOdometerDataError,
  } = useActualOdometerStateByDeviceIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      deviceId: techId,
    },
  });

  useEffect(() => {
    if (!actualOdometerDataLoading && !actualOdometerDataError) {
      setCurrentOdometerValue(actualOdometerData?.actualOdometerCalculated ?? 0);
    }
  }, [ actualOdometerData, actualOdometerDataLoading, actualOdometerDataError ]);

  const ownerState: IOwnerState = {
    state,
  };

  const onConfirm = () => {
    if (odometer === 0) {
      setOdometerState('missing');
    } else {
      setOdometerState('filled');
      setOpenOdometer(false);
    }
  };

  const onClose = () => {
    setOpenOdometer(false);
    if (odometer === 0) {
      setOdometerState('missing');
    } else {
      setOdometerState('filled');
    }
  };
  
  return (
    <>
      <Control>
        <IconButton
          size="small"
          color={getColorByState(state)}
          isSquared
          onClick={() => setOpenOdometer(true)}
        >
          <SpeedIcon />
        </IconButton>
        <ControlLabel ownerState={ownerState}>{`${odometer}${getTechUnit(UnitType.Odometer, true)}`}</ControlLabel>
      </Control>
      
      <Dialog
        onClose={onClose}
        open={openOdometer}
        maxWidth="xs"
      >
        <DialogContent>
          <Box textAlign="center">
            <DialogTitle fontSize={'1.3rem !important'} color={'primary'}>
             Tachometr
            </DialogTitle>
          </Box>
          <Box mt={2} mb={4}>
            <OdometerBlock
              odometerValue={odometer}
              setOdometerValue={(value: string) => setOdometerValue(parseInt(value))}
              currentOdometerValue={currentOdometerValue}
              odometerState={state}
            />
          </Box>
          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
            >
              <Button
                sx={{ marginRight: 2 }}
                variant="outlined"
                onClick={() => setOdometerValue(currentOdometerValue)}
              >
                Vyplnit poslední hodnotou
              </Button>
              <Button
                variant="contained"
                disabled={state === 'missing' && odometer === 0}
                onClick={onConfirm}
              >
                Potvrdit
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Odometer;
