import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mechis/elements';

const MechisEngineDisplacement: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 36 36" {...props}>
      {/* eslint-disable-next-line */}
      <path d="M25.88,32H12a4,4,0,0,1-4-4V11.46L2.31,5.77a1,1,0,0,1-.22-1.09A1,1,0,0,1,3,4.06H28.86a1,1,0,0,1,1,1V28A4,4,0,0,1,25.88,32ZM5.43,6l4.28,4.34a.75.75,0,0,1,.21.63v17A2.13,2.13,0,0,0,12,30H25.88A2.1,2.1,0,0,0,28,28V6Z" className="clr-i-outline clr-i-outline-path-1"></path>
      {/* eslint-disable-next-line */}
      <path d="M33,16a1,1,0,0,1-1-1V6H28.86a.92.92,0,0,1-1-.9,1,1,0,0,1,1-1H33a1,1,0,0,1,1,1V15A1,1,0,0,1,33,16Z" className="clr-i-outline clr-i-outline-path-2"></path>
      <path d="M24,11H18a1,1,0,1,1,0-2H24a1,1,0,1,1,0,2Z" className="clr-i-outline clr-i-outline-path-3"></path>
      <path d="M24,15H21a1,1,0,1,1,0-2H24a1,1,0,1,1,0,2Z" className="clr-i-outline clr-i-outline-path-4"></path>
      <path d="M24,19H18a1,1,0,1,1,0-2H24a1,1,0,1,1,0,2Z" className="clr-i-outline clr-i-outline-path-5"></path>
      <path d="M24,27H18a1,1,0,1,1,0-2H24a1,1,0,1,1,0,2Z" className="clr-i-outline clr-i-outline-path-6"></path>
      <path d="M24,23H21A1,1,0,1,1,21,21H24A1,1,0,1,1,24,23Z" className="clr-i-outline clr-i-outline-path-7"></path>
      <rect x="0" y="0" width="36" height="36" fillOpacity="0"/>
    </SvgIcon>
  );
};

export default MechisEngineDisplacement;
