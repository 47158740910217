import { styled, lighten } from '@mui/material/styles';
import { colors } from '@mui/material';

export const CustomGrid = styled('div', {
  name: 'AppMobileNavigation',
  slot: 'CustomGrid',
})(() => ({
  height: '320px',
  display: 'grid',
  gridTemplateRows: 'repeat(3, 1fr)', 
  gridTemplateColumns: 'repeat(3, 1fr)', 
  gap: '12px',
  gridTemplateAreas: '"newTech newTech reminders" "newTech newTech maintenance" "odometer refueling expenses"',
}));

// todo: fix it with variables from design system
export const CustomGridItem = styled('div', {
  name: 'AppMobileNavigation',
  slot: 'CustomGridItem',
})(({ theme }) => ({
  // border: '1px dashed lightgrey',
  borderRadius: '12px',
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  minHeight: '100px',
  
  // color: theme.palette.text.disabled,
  // fontFamily: 'Poppins',
  position: 'relative',

  '& > span': {
    fontSize: '0.65em',
    fontWeight: '100',
    maxWidth: '70%',
    lineHeight: '1.2em',
    marginTop: '4px',
    textShadow: '0 0 3px #ffffff, 0 0 3px #ffffff',
  },

  '&:before': {
    content: '\'\'',
    display: 'block',
    position: 'absolute',
    top: '3%',
    left: '3%',
    width: '94%',
    height: '94%',
    // background: 'limegreen',
    borderRadius: '12px',
    zIndex: -1,
    // opacity: 0.65,

    // background: '#06beb6',
    // background: '-webkit-linear-gradient(to right, #48b1bf, #06beb6)',
    // background: `linear-gradient(to right, #48b1bf, #06beb6)`,
    // background: `linear-gradient(120deg, ${theme.palette.primary.light}, ${theme.palette.secondary.light})`,
    background: lighten(theme.palette.primary.main, 0.65),

    boxShadow:
    `inset 0 -3em 3em rgba(0, 0, 0, 0.1),
    0 0 0 2px #d2d2d2,
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3)`,
  },

  '&.bg-odd:before': {
    background: theme.palette.primary.main,
    opacity: 0.25,
  },
  '&.bg-even:before': {
    background: theme.palette.secondary.main,
    opacity: 0.25,
  },
  '&.bg-disabled:before': {
    background: theme.palette.text.disabled,
    opacity: 0.25,
  },
}));

export const NewTech = styled(CustomGridItem, {
  name: 'AppMobileNavigation',
  slot: 'NewTech',
})(() => ({
  gridArea: 'newTech',
  width: '135px',
  height: '135px',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '-70px',
  marginBottom: '16px',
  '& > span': {
    fontSize: '0.8em',
    fontWeight: '800',
  },
}));

export const Odometer = styled(CustomGridItem, {
  name: 'AppMobileNavigation',
  slot: 'Odometer',
})(() => ({
  gridArea: 'odometer',
  background: `linear-gradient(135deg, ${colors['lime']['200']}, ${colors['lime']['100']})`,
}));

export const Refueling = styled(CustomGridItem, {
  name: 'AppMobileNavigation',
  slot: 'Refueling',
})(() => ({
  gridArea: 'refueling',
  background: `linear-gradient(135deg, ${colors['indigo']['200']}, ${colors['indigo']['100']})`,
}));

export const Expenses = styled(CustomGridItem, {
  name: 'AppMobileNavigation',
  slot: 'Expenses',
})(() => ({
  gridArea: 'expenses',
  background: `linear-gradient(135deg, ${colors['red']['200']}, ${colors['red']['100']})`,
}));

export const Maintenance = styled(CustomGridItem, {
  name: 'AppMobileNavigation',
  slot: 'Maintenance',
})(() => ({
  gridArea: 'maintenance',
  background: `linear-gradient(135deg, ${colors['amber']['200']}, ${colors['amber']['100']})`,
}));

export const Reminders = styled(CustomGridItem, {
  name: 'AppMobileNavigation',
  slot: 'Reminders',
})(() => ({
  gridArea: 'reminders',
}));
