import { styled } from '@mui/material/styles';

export const Block = styled('div', {
  name: 'Stepper',
  slot: 'Root',
})(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(0.5),
  '& > div': {
    background: 'transparent',
    border: 0,
    boxShadow: 'none',
    '& > .MuiLinearProgress-root': {
      width: '65%',
      height: theme.spacing(1),
      borderRadius: '3px',
      marginTop: '1px',
      '& > .MuiLinearProgress-bar': {
        opacity: 0.6,
      },
    },
    button: {
      paddingLeft: 0,
      paddingRight: 0,
      '&.Mui-disabled': {
        color: 'rgba(255,255,255,0.36)',
      },
      '& > #previous-icon': {
        marginLeft: '-1em',
      },
      '& > #next-icon': {
        marginRight: '-1em',
      },
    },
  },
}));
