import { IAddFlow } from '@state/models/app/types';

const resolveIcon = (addFlow: IAddFlow) => {
  switch (addFlow) {
    case 'constructions-machine': return 'mechisCrane';
    case 'garden-machinery': return 'mechisMower';
    case 'motorcycle': return 'moto';
    case 'other': return 'mechisCar';
    case 'passenger-car': return 'mechisCar';
    case 'racing': return 'mechisSportCar';
    case 'trailer': return 'mechisTrailer';
    case 'truck': return 'mechisTruck';
    case 'veteran': return 'mechisVintageCar';
    default: return 'mechisCar';
  }
};

export default resolveIcon;
