import React, { FC, useEffect, useState } from 'react';
import { IMaintenancePlanWidgetInterval, IProps } from './types';
import { Alert, Box, Divider, Paper, Skeleton, Stack } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import Summary from '../MaintenanceChecklist/components/Summary';
import { useMaintenanceIntervalsByDeviceQuery } from '@state/mechis-backend/generated/schema';
import QuoteBlock from '@mechis/blocks/QuoteBlock';
import { calculateDueDate, getLowestDueDate } from '@utilities/app';
import normalizeToPercentage from '@utilities/normaliseToPercentage';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import useTech from '@hooks/useTech';

const MAX_INTERVALS = 3;

const MaintenancePlanWidget: FC<IProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { techId } = useTech();
  const [ intervals, setIntervals ] = useState<IMaintenancePlanWidgetInterval[]>([]);

  const { data, loading, error } = useMaintenanceIntervalsByDeviceQuery({
    variables: {
      deviceId: techId,
    },
  });

  const calcScoreForDistance = (dueDistance?: number | null, periodDistance?: number | null) => {
    if (!dueDistance || !periodDistance) return null;

    const result = normalizeToPercentage(dueDistance, periodDistance);
    return Math.round(result);
  };

  const calcScoreForDay = (periodEndDate?: string | null, periodDay?: number | null) => {
    if (!periodEndDate || !periodDay) return null;

    const dueDate = calculateDueDate(periodEndDate);
    const result = normalizeToPercentage(dueDate, periodDay);
    return Math.round(result);
  };

  useEffect(() => {
    if (
      data?.maintenanceIntervalsByDevice &&
      data?.maintenanceIntervalsByDevice.length > 0
    ) {
      
      const result = data.maintenanceIntervalsByDevice.map((interval) => {
        const lowestDueDate = getLowestDueDate(interval);
        const scoreDistance = calcScoreForDistance(interval.dueDistance, interval.periodDistance);
        const scoreDay = calcScoreForDay(lowestDueDate?.periodEndDate, lowestDueDate?.periodDay);
        const score = scoreDistance && scoreDay ? (scoreDistance + scoreDay) / 2 : scoreDistance || scoreDay;

        if (score === null) return undefined;

        return {
          id: interval.id,
          periodDay: interval.periodDay,
          periodDistance: interval.periodDistance,
          name: interval.name,
          lowestDueDate: lowestDueDate,
          dueDistance: interval.dueDistance,
          score: score,
        };
      });

      const validResult = result.filter((el) => el) as IMaintenancePlanWidgetInterval[];
      const worstIntervals = validResult.sort((a, b) => a.score - b.score).slice(0, MAX_INTERVALS);
      setIntervals(worstIntervals);
    }
  }, [ data ]);

  if (
    data?.maintenanceIntervalsByDevice &&
    data?.maintenanceIntervalsByDevice.length === 0
  ) {
    return (
      <Stack mt={6} spacing={4} alignItems="center">
        <QuoteBlock>{t('maintenancePlanWidget.noData')}</QuoteBlock>
      </Stack>
    );
  }

  if (loading) {
    return (
      <Paper>
        <Stack p={3} spacing={1}>
          <Skeleton variant="rounded" sx={{ height: '24x' }} />
          <Skeleton variant="rounded" sx={{ height: '24x' }} />
        </Stack>
      </Paper>
    );
  }

  const handleIntervalClick = (interval: IMaintenancePlanWidgetInterval) => {
    navigate(`/app/${techId}/new-maintenance/${interval.id}`);
  };

  return (
    <Paper>
      <Box p={3}>
        {error && (
          <Alert severity="error">{t('maintenancePlanWidget.error')}</Alert>
        )}
        {intervals.map((interval, index) => (
          <div key={uuid()} onClick={() => handleIntervalClick(interval)}>
            <Summary
              periodDay={interval.periodDay}
              periodDistance={interval.periodDistance}
              name={interval.name}
              lowestDueDate={interval.lowestDueDate}
              dueDistance={interval.dueDistance}
              isWidget
            />
            {index < intervals.length - 1 && <Divider sx={{ borderStyle: 'dashed', my: 2 }} />}
          </div>
        ))}
      </Box>
    </Paper>
  );
};

export default MaintenancePlanWidget;
