import React from 'react';
import {
  Paper,
  Box,
  TextField,
} from '@mechis/elements';
import useHeader from '@hooks/useHeader';
import Stepper from '@mechis/blocks/Stepper';
import { useNavigate } from 'react-router-dom';
import useMaintenance from '@hooks/useMaintenance';
import { useTranslation } from 'react-i18next';
import useTech from '@hooks/useTech';

const Step2 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { techId } = useTech();
  const { description, setDescription } = useMaintenance({ keepOperations: true });

  useHeader({
    headline: t('newMaintenance.step2.headline'),
    widget: (
      <Stepper
        steps={4}
        defaultStep={2}
        onBack={() => navigate(`/app/${techId}/new-maintenance/1`)}
        onNext={() => navigate(`/app/${techId}/new-maintenance/3`)}
      />
    ),
  });

  return (
    <Box mt={-4}>
      <Paper>
        <Box py={3} px={2.5}>
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label={t('newMaintenance.step2.note')}
            multiline
            rows={8}
            variant="outlined"
            type="text"
            id="newMaintenanceNote"
            InputProps={{
              startAdornment: <></>,
            }}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default Step2;
