import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  NativeSelect,
  TextField,
} from '@mechis/elements';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useDeviceCategoriesQuery,
} from '@state/mechis-backend/generated/schema';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Loader from '@mechis/blocks/Loader';
import { Error } from '@mui/icons-material';
import { IEditBasicInfoProps, IEditBasicInfoFormType } from './types';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { format } from 'date-fns';
import snackbarCacheControl from '@state/mutations/snackbar';
import { useGetDeviceCategoryOptions } from '@hooks/deviceHooks/useGetPowerUnitOptions';
import UpdateInfoModalHeader from '@screens/TechDetail/Info/modals/UpdateInfoModalHeader';
import { PASSENGER_CAR_DEVICE_CATEGORY_ID } from '@screens/NewTech/types';
import useTech from '@hooks/useTech';
import useSortedVehicleTypes from '@hooks/useSortedVehicleTypes';

const EditBasicInfo: React.FC<IEditBasicInfoProps> = ({
  isOpen, 
  onClose, 
  deviceBasicInformation, 
  updateBasicInformation,
  errorBasicInformation,
}) => {
  const { t } = useTranslation();
  const { techId } = useTech();
  const { data: deviceCategories, loading: deviceCategoriesLoading, error: deviceCategoriesError } = useDeviceCategoriesQuery();
  const { setSnackbar } = snackbarCacheControl;

  const schema = yup.object({
    color: yup.string(),
    machineNickname: yup.string().required(t('addDevice.components.machineNickname.required')),
    manufacturer: yup.string(),
    model: yup.string(),
    spz: yup.string(),
    vin: yup.string().matches(/^[A-HJ-NPR-Z-a-hj-npr-z-0-9-/]{8,17}$/, {
      excludeEmptyString: true,
      message: t('addDevice.components.vin.invalidCharacters'),
    }),
  }).required();

  const {
    control,
    handleSubmit,
    formState: {
      isDirty,
      isSubmitting,
    },
    reset,
    watch,
  } = useForm<IEditBasicInfoFormType>({
    defaultValues: {
      color: deviceBasicInformation?.device?.color,
      deviceCategory: deviceBasicInformation?.device?.deviceCategory?.id,
      machineNickname: deviceBasicInformation?.device?.name,
      manufacturedYearMonth: deviceBasicInformation?.device?.manufacturedYearMonth,
      manufacturer: deviceBasicInformation?.device?.manufacturer,
      model: deviceBasicInformation?.device?.model,
      spz: deviceBasicInformation?.device?.spz,
      vehicleType: deviceBasicInformation?.device?.vehicleType?.id,
      vin: deviceBasicInformation?.device?.vin,
    },
    resolver: yupResolver(schema),
  });

  const deviceCategory = watch('deviceCategory');

  const { 
    sortedVehicleTypes, 
    vehicleTypesLoading, 
    vehicleTypesError, 
  } = useSortedVehicleTypes(Number(deviceCategory) || PASSENGER_CAR_DEVICE_CATEGORY_ID);

  const onChangeBasicInfo = async (data: any) => {
    await updateBasicInformation({
      variables: {
        device: {
          color: data.color,
          deviceCategory: { id: Number(data.deviceCategory)},
          id: techId,
          manufacturedYearMonth: format(new Date(data.manufacturedYearMonth), 'yyyy-MM-01'),
          manufacturer: data.manufacturer,
          model: data.model,
          name: data.machineNickname,
          spz: data.spz,
          vehicleType: { id: Number(data.vehicleType)},
          vin: data.vin.toUpperCase(),
        },
      },
    });
    errorBasicInformation ? setSnackbar('error', 'DEVICE_UPDATE_FAILED') : setSnackbar('success', 'DEVICE_UPDATE_SUCCESS');
    onClose();
  };

  if (deviceCategoriesError || vehicleTypesError) {
    return (
      <Error />
    );
  }

  if (deviceCategoriesLoading || vehicleTypesLoading) {
    return (
      <Loader />
    );
  }

  const getOptions = () => {
    return sortedVehicleTypes.map((vehicleTypeItem) => {
      return <option color="black" key={vehicleTypeItem.id} value={vehicleTypeItem.id}>{t(`vehicleTypes.${vehicleTypeItem.name}`)}</option>;
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullScreen={true}
      open={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
    >
      <DialogContent>
        <UpdateInfoModalHeader
          onClose={onClose}
          tParam="techDetail.headlines.basicInfo"
        />
        <Box textAlign="center">
          <Box mb={2}>
            <Controller
              render={({ field }) => (
                <NativeSelect
                  label={t('addDevice.category.name')}
                  isLabelWhite
                  {...field}
                  fullWidth
                  id="deviceCategory"
                >
                  {useGetDeviceCategoryOptions(deviceCategories)}
                </NativeSelect>
              )}
              name="deviceCategory"
              control={control}
            />
          </Box>
          <Box mb={2}>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  id="machineNickname"
                  label={t('addDevice.components.machineNickname.name')}
                  variant="outlined"
                  type="text"
                  error={!!formState.errors.machineNickname?.message}
                  helperText={<>{formState.errors.machineNickname?.message}</>}
                />
              )}
              name="machineNickname"
              control={control}
            />
          </Box>
          <Box mb={2}>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  id="manufacturer"
                  label={t('addDevice.components.manufacturer.name')}
                  variant="outlined"
                  error={!!formState.errors.manufacturer?.message}
                  helperText={<>{formState.errors.manufacturer?.message}</>}
                />
              )}
              name="manufacturer"
              control={control}
            />
          </Box>
          <Box mb={2}>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  id="model"
                  label={t('addDevice.components.model.name')}
                  error={!!formState.errors.model?.message}
                  helperText={<>{formState.errors.model?.message}</>}
                  variant="outlined"
                />
              )}
              name="model"
              control={control}
            />
          </Box>
          <Box mb={2}>
            <Controller render={({ field, formState}) => (
              <NativeSelect
                label={t('addDevice.components.vehicleType.name')}
                isLabelWhite
                {...field}
                id="vehicleType"
                fullWidth
                error={!!formState.errors.vehicleType?.message}
              >
                {getOptions()}
              </NativeSelect>
            )}
            name="vehicleType"
            control={control}
            />
          </Box>
          <Box mb={2}>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  id="color"
                  label={t('addDevice.components.color.name')}
                  error={!!formState.errors.color?.message}
                  helperText={<>{formState.errors.color?.message}</>}
                  variant="outlined"
                />
              )}
              name="color"
              control={control}
            />
          </Box>
          <Box mb={2}>
            <Controller 
              render={({field}) => (
                <MobileDatePicker
                  label={t('addDevice.components.yearOfManufacture.name')}
                  inputFormat="MM/yyyy"
                  disableFuture
                  value={field.value}
                  onChange={field.onChange}
                  renderInput={(params) => <TextField id="manufacturedYearMonth" {...params} />}
                  views={[ 'month', 'year' ]}
                />
              )} 
              name="manufacturedYearMonth"
              control={control}
            />
          </Box>
          <Box mb={2}>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  id="vin"
                  label={t('addDevice.components.vin.name')}
                  error={!!formState.errors.vin?.message}
                  helperText={<>{formState.errors.vin?.message}</>}
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      style: {
                        textTransform: 'uppercase',
                      },
                    },
                  }}
                />
              )}
              name="vin"
              control={control}
            />
          </Box>
          <Box mb={2}>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  id="spz"
                  label={t('addDevice.components.licensePlate.name')}
                  error={!!formState.errors.spz?.message}
                  helperText={<>{formState.errors.spz?.message}</>}
                  variant="outlined"
                />
              )}
              name="spz"
              control={control}
            />
          </Box>
        </Box>
        <Box display={'flex'} justifyContent={'center'} width={'100%'}>
          <Button
            variant="contained"
            onClick={handleSubmit(onChangeBasicInfo)}
            disabled={!isDirty || isSubmitting}
          >
            {t('myProfile.button.saveChanges')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditBasicInfo;
