import { ISimpleTableData } from '@mechis/blocks/SimpleTable';
import { tableOutputNumber } from '@utilities/app';
import { IAddDeviceModelFuel } from '@state/models/addDevice/types';
import { ConsumptionUnitsQuery, VolumeUnitsQuery } from '@state/mechis-backend/generated/schema';

export const getFuelsDecoratedForDeviceDetailTable = (fuelParameters: any, translationFn: (a: string) => string):
  Array<ISimpleTableData> => fuelParameters.map((tank: any) => [
  {label: 'Typ nádrže', value: translationFn(`fuelTypes.${tank.fuelType.fuelName}`)},
  {
    label: translationFn(`tankVolume.${tank.fuelType.fuelName}`), 
    value: `${tableOutputNumber(tank.tankVolume)} ${tank.volumeUnit.unitName}`},
  {
    label: 'Počáteční spotřeba',
    value: `${tableOutputNumber(tank.initialConsumption)} ${tank.consumptionUnit.unitName}`,
  },
  {label: 'Aktuální spotřeba', value: `${tableOutputNumber(tank.actualConsumption)} ${tank.consumptionUnit.unitName}`},
]);

export const getFuelConsumptionUnitNameOrFallbackToId = (
  consumptionUnits: ConsumptionUnitsQuery | undefined,
  id: string): string => {
  const consumptionUnit = consumptionUnits?.consumptionUnits.find((c) => c.id === id);

  return consumptionUnit?.unitName ?? id;
};
export const getVolumeUnitNameOrFallbackToId = (
  volumeUnits: VolumeUnitsQuery | undefined,
  id: string): string => {
  const volumeUnit = volumeUnits?.volumeUnits.find((c) => c.id === id);

  return volumeUnit?.unitName ?? id;
};
export const getFuelsDecoratedForRecapitulationTable = (
  fuels: IAddDeviceModelFuel[] | undefined, 
  translationFn: (a: string) => string,
  consumptionUnits: ConsumptionUnitsQuery | undefined,
  volumeUnits: VolumeUnitsQuery | undefined
): any =>
  fuels?.filter((fuel) => fuel.isSelected).map((fuel: IAddDeviceModelFuel) => [
    {label: 'Typ nádrže', value: translationFn(`fuelTypes.${fuel.fuelName}`)},
    {
      label: translationFn(`tankVolume.${fuel.fuelName}`),
      value: `${tableOutputNumber(fuel.tankVolume)} 
      ${getVolumeUnitNameOrFallbackToId(volumeUnits, String(fuel.volumeUnit.id))}`},
    {
      label: 'Počáteční spotřeba',
      value: `${tableOutputNumber(fuel.initialConsumption)} 
      ${getFuelConsumptionUnitNameOrFallbackToId(consumptionUnits, String(fuel.consumptionUnit.id))}`,
    },
  ]);
