import React from 'react';
import { useParams } from 'react-router-dom';

const Trailer = () => {
  const { stepId } = useParams();

  return (
    <div>Trailer - {stepId}</div>
  );
};

export default Trailer;
