import React, { FC } from 'react';
import { IProps } from './types';
import { InputAdornment } from '@mechis/elements';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  blurTarget,
  ensureNumericZeroIsNotDisplayed,
} from '@utilities/app';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import NumberField from '@mechis/elements/NumberField';

const GuaranteeMonths: FC<IProps> = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { setGuaranteeMonths } = addDeviceCacheControl;

  const schema = yup.object({
    guaranteeMonths: yup
      .number()
      .transform((value, originalValue) => originalValue === '' ? null : value)
      .nullable(),
  }).required();

  const { control, handleSubmit } = useForm<{ guaranteeMonths: number | null }>({
    defaultValues: {
      guaranteeMonths: ensureNumericZeroIsNotDisplayed(data?.addDevice.guaranteeMonths),
    },
    resolver: yupResolver(schema),
  });

  const onSave = async ({ guaranteeMonths }: { guaranteeMonths: number | null }) => {
    setGuaranteeMonths(guaranteeMonths);
  };

  return (
    <Controller
      render={({ field, formState }) => (
        <NumberField
          {...field}
          onKeyUp={() => {
            handleSubmit(onSave)();
          }}
          onWheel={blurTarget}
          label={t('addDevice.components.guaranteeMonths.name')}
          id="guaranteeMonths"
          error={!!formState.errors.guaranteeMonths?.message}
          helperText={formState.errors.guaranteeMonths?.message}
          variant="outlined"
          onlyInteger
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormatListNumberedIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      )}
      name="guaranteeMonths"
      control={control}
    />
  );
};

export default GuaranteeMonths;
