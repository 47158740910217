import React, { FC } from 'react';
import { IProps } from './types';
import ClaudinaryImageBuilder from '@mechis/blocks/ClaudinaryImageBuilder';
import FirebaseAttachmentBuilder from '@mechis/blocks/FirebaseAttachmentBuilder';
import { Paper } from '@mechis/elements';
import { ImageContainer } from '@mechis/elements/Image/styles';
import 'yet-another-react-lightbox/styles.css';
import { useAttachments } from '@hooks/useAttachments';

const AttachmentsPreview: FC<IProps> = ({ attachment, index, setClickedImageIndex, setLightBoxOpened }) => {
  const { isImageType, handleDownload } = useAttachments();
  
  return (
    <Paper key={attachment.id} sx={{ width: 'fit-content' }} variant={`bg-${index % 2 === 0 ? 'primary' : 'secondary'}-150`}>
      <ImageContainer
        ownerState={{
          radius: 8,
          fit: 'cover',
          padding: 'small',
        }}
        sx={{
          width: 100,
          height: 100,
        }}
        onClick={() => {
          if (isImageType(attachment.mimeType ?? '')) {
            typeof setClickedImageIndex === 'function' && setClickedImageIndex();
            typeof setLightBoxOpened === 'function' && setLightBoxOpened(true);
          } else {
            handleDownload(attachment);
          }
        }}
      >
        {isImageType(attachment.mimeType ?? '') ? (
          <ClaudinaryImageBuilder
            imageId={attachment.storageName ?? ''}
            width={100}
            height={100}
          />
        ) : (
          <>
            <FirebaseAttachmentBuilder
              mimeType={attachment?.mimeType ?? ''}
              name={attachment?.originalName ?? ''}
            />
          </>
        )}
      </ImageContainer>
    </Paper>
  );
};

export default AttachmentsPreview;
