import React, { FC } from 'react';
import { IProps } from './types';
import { Box, Dialog, DialogContent, Grid, Typography } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import CloudinaryCreateEventAttachmentWidget from '../Cloudinary/CloudinaryCreateEventAttachmentWidget';
import TechSelector from '../TechSelector';
import useIcon from '@mechis/icons/useIcon';
import FirebaseUploadWidget from '../FirebaseUploadWidget';
import { VisuallyHiddenInput } from '../FirebaseUploadWidget/styles';
import { useReactiveVar } from '@apollo/client';
import selections from '@state/models/selections';
import UploadedImageAttachmentsList from '@mechis/sections/Selections/UploadedImageAttachmentList';
import UploadedFileAttachmentsList from '@mechis/sections/Selections/UploadedFileAttachmentsList';
import selectionsCacheControl from '@state/mutations/selections';
import { EventAttachmentsByEventIdDocument, useCreateEventAttachmentMutation } from '@state/mechis-backend/generated/schema';
import {IconButton} from '@mechis/elements';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const EditEventAttachmentsDialog: FC<IProps> = ({ 
  open,
  onClose,
  eventId,
  setCloudinaryAttachmentInfo,
  setFirebaseAttachmentInfo,
  cloudinaryConfiguration,
  firebaseConfiguration,
}) => {
  const { t } = useTranslation();
  const { setImageAttachments, setFileAttachments } = selectionsCacheControl;
  const reactiveSelections = useReactiveVar(selections);

  const handleRemoveAttachmentById = (attachmentId: string) => {
    return setImageAttachments(reactiveSelections.imageAttachments.filter((attach) => attach.id !== attachmentId));
  };

  const handleRemoveFileAttachmentById = (attachmentId: string) => {
    return setFileAttachments(reactiveSelections.fileAttachments.filter((attach) => attach.id !== attachmentId));
  };

  const [ createEventAttachment ] = useCreateEventAttachmentMutation({
    refetchQueries: [
      {
        query: EventAttachmentsByEventIdDocument,
        variables: {
          eventId: Number(eventId),
        },
      },
    ],
  });

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth="xs"
      fullScreen={true}
    >
      <DialogContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4">
            {t('components.attachmentDialog.attachments')}
          </Typography>
          <IconButton variant="default" size="small" onClick={() => onClose()}>
            <CloseRoundedIcon color="primary" />
          </IconButton>
        </Box>
        <Grid spacing={2} container>
          <Grid xs={6} item>
            <CloudinaryCreateEventAttachmentWidget
              setCloudinaryAttachmentInfo={setCloudinaryAttachmentInfo}
              cloudinaryConfiguration={cloudinaryConfiguration}
              linkingCallback={(attachmentId: number) => createEventAttachment({
                variables: {
                  attachmentId,
                  eventId,
                },
              })}
            >
              {({ widget }: any) => (
                <TechSelector
                  title={t('components.attachmentDialog.photo')}
                  onClick={() => widget.open()}
                  icon={useIcon('photo', {
                    color: 'primary',
                    sx: {
                      fontSize: '48px',
                    },
                  })}
                  disabled={false}
                />
              )}
            </CloudinaryCreateEventAttachmentWidget>
          </Grid>
          <Grid xs={6} item>
            <FirebaseUploadWidget
              setFirebaseAttachmentInfo={setFirebaseAttachmentInfo}
              firebaseConfig={firebaseConfiguration}
              folder="attachments"
              linkingCallback={(attachmentId: number) => createEventAttachment({
                variables: {
                  attachmentId,
                  eventId,
                },
              })}
            >
              {({ setAttachmentUploadValidated, hasValidationError }: any) => (
                <>
                  <TechSelector
                    onClick={() => {
                      const hiddenFileInput = document.getElementById('hiddenFileInput');
                      hiddenFileInput?.click();
                    }}
                    sx={{
                      border: hasValidationError ? '1px solid red' : undefined,
                    }}
                    title={t('components.attachmentDialog.file')}
                    icon={useIcon('attachment', {
                      color: 'primary',
                      sx: {
                        fontSize: '48px',
                      },
                    })}
                    disabled={false}
                  />
                  {hasValidationError && (
                    <Typography variant="caption" color="red">
                      {t('components.attachmentDialog.fileValidationError')}
                    </Typography>
                  )}
                  <VisuallyHiddenInput
                    type="file"
                    id="hiddenFileInput"
                    onChange={(event) => {
                      if (event.target.files?.[0]) {
                        setAttachmentUploadValidated(event.target.files[0]);
                      }
                    }}
                  />
                </>
              )}
            </FirebaseUploadWidget>
          </Grid>
        </Grid>
        <Box mt={2}>
          {reactiveSelections.imageAttachments.length > 0 && (
            <UploadedImageAttachmentsList
              eventId={eventId}
              imageAttachments={reactiveSelections.imageAttachments}
              setImageAttachments={handleRemoveAttachmentById}
            />
          )}
          {reactiveSelections.fileAttachments.length > 0 && (
            <UploadedFileAttachmentsList
              firebaseConfig={firebaseConfiguration}
              fileAttachments={reactiveSelections.fileAttachments}
              setFileAttachments={handleRemoveFileAttachmentById}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditEventAttachmentsDialog;
