import React, { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useHeader from '@hooks/useHeader';
import ExpensesEdit from './expenses/Edit';
import MaintenanceEdit from './maintenance/Edit';
import RefuelingEdit from './refueling/Edit';

const UpdateEvent: FC = () => {
  const navigate = useNavigate();
  const { type: updateType, id: eventId } = useParams();
  useHeader({ headline: `techDetail.updateEvent.${updateType}.title`, backButtonVisible: false, menuButtonVisible: false });

  const handleBack = () => navigate(-1);

  return (
    <>
      {updateType === 'expenses' && <ExpensesEdit eventId={Number(eventId)} onBack={handleBack} />}
      {updateType === 'maintenance' && <MaintenanceEdit eventId={eventId ?? ''} onBack={handleBack} />}
      {updateType === 'refueling' && <RefuelingEdit eventId={Number(eventId)} onBack={handleBack} />}
    </>
  );
};

export default UpdateEvent;
