import { TypographyOptions } from '@mui/material/styles/createTypography';
import breakpoints from './breakpoints';
import palette from './palette';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    sectionHeadline: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    sectionHeadline?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    sectionHeadline: true
  }
}

const typography: TypographyOptions = {
  fontFamily: '"Montserrat", sans-serif',
  htmlFontSize: 16,
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 700,
    fontSize: '1.75rem',
    lineHeight: 1.357142857,
    color: palette.text.headline,
    [`@media (min-width: ${breakpoints.md}px)`]: {
      fontSize: '1.875rem',
      lineHeight: 1.333333333,
    },
    [`@media (min-width: ${breakpoints.lg}px)`]: {
      fontSize: '2.0625rem',
      lineHeight: 1.303030303,
    },
  },
  h2: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 700,
    fontSize: '1.5625rem',
    lineHeight: 1.36,
    color: palette.text.headline,
    [`@media (min-width: ${breakpoints.md}px)`]: {
      fontSize: '1.6875rem',
      lineHeight: 1.333333333,
    },
    [`@media (min-width: ${breakpoints.lg}px)`]: {
      fontSize: '1.8125rem',
      lineHeight: 1.310344827,
    },
  },
  h3: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 700,
    fontSize: '1.375rem',
    lineHeight: 1.409090909,
    color: palette.text.headline,
    [`@media (min-width: ${breakpoints.md}px)`]: {
      fontSize: '1.4375rem',
      lineHeight: 1.391304347,
    },
    [`@media (min-width: ${breakpoints.lg}px)`]: {
      fontSize: '1.625rem',
      lineHeight: 1.346153846,
    },
  },
  h4: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 700,
    fontSize: '1.1875rem',
    lineHeight: 1.473684210,
    color: palette.text.headline,
    [`@media (min-width: ${breakpoints.md}px)`]: {
      fontSize: '1.3125rem',
      lineHeight: 1.428571428,
    },
    [`@media (min-width: ${breakpoints.lg}px)`]: {
      fontSize: '1.4375rem',
      lineHeight: 1.391304347,
    },
  },
  h5: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: 1.5,
    color: palette.text.headline,
    [`@media (min-width: ${breakpoints.md}px)`]: {
      fontSize: '1.125rem',
      lineHeight: 1.5,
    },
    [`@media (min-width: ${breakpoints.lg}px)`]: {
      fontSize: '1.25rem',
      lineHeight: 1.4,
    },
  },
  h6: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: 1.571428571,
    [`@media (min-width: ${breakpoints.md}px)`]: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    [`@media (min-width: ${breakpoints.lg}px)`]: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
  sectionHeadline: {
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 700,
    fontSize: '0.875rem',
    lineHeight: 2.5,
    color: palette.text.sectionHeadline,
    [`@media (min-width: ${breakpoints.md}px)`]: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    [`@media (min-width: ${breakpoints.lg}px)`]: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
  subtitle1: {
    fontSize: '.7rem',
  },
  body1: {
    color: palette.text.paragraph,
  },
  button: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 600,
  },
};

export default typography;
