import { ReactiveVar } from '@apollo/client';
import addDevice, { init } from '@state/models/addDevice';
import {
  IAddDeviceModel,
  IAddDeviceModelFuel, ValidationErrorObject,
} from '../models/addDevice/types';
import _ from 'lodash';

const resetToInit = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return () => {
    addDeviceModel(init);
  };
};

const setFlowPrerequisite = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (deviceCategory: IAddDeviceModel['deviceCategory'], vehicleType: IAddDeviceModel['vehicleType']) => {
    const model = {
      ...addDeviceModel(),
      deviceCategory,
      vehicleType,
    };
    addDeviceModel(model);
  };
};

const setName = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (name: IAddDeviceModel['name']) => {
    const model = {
      ...addDeviceModel(),
      name,
    };
    addDeviceModel(model);
  };
};

const setManufacturerName = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (manufacturer: IAddDeviceModel['manufacturer']) => {
    const model = {
      ...addDeviceModel(),
      manufacturer,
    };
    addDeviceModel(model);
  };
};

const setModel = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (model: IAddDeviceModel['model']) => {
    const modelObj = {
      ...addDeviceModel(),
      model,
    };
    addDeviceModel(modelObj);
  };
};

const setThumbnailImage = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (thumbnailImage: IAddDeviceModel['thumbnailImage']) => {
    const model = {
      ...addDeviceModel(),
      thumbnailImage,
    };
    addDeviceModel(model);
  };
};

const setVin = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (vin: IAddDeviceModel['vin']) => {
    const model = {
      ...addDeviceModel(),
      vin,
    };
    addDeviceModel(model);
  };
};

const setYearOfManufacture = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (manufacturedYearMonth: IAddDeviceModel['manufacturedYearMonth']) => {
    const model = {
      ...addDeviceModel(),
      manufacturedYearMonth,
    };
    addDeviceModel(model);
  };
};

const setLicensePlate = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (spz: IAddDeviceModel['spz']) => {
    const model = {
      ...addDeviceModel(),
      spz,
    };
    addDeviceModel(model);
  };
};

const setVehicleType = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (vehicleType: IAddDeviceModel['vehicleType']) => {
    const model = {
      ...addDeviceModel(),
      vehicleType,
    };
    addDeviceModel(model);
  };
};

const setColor = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (color: IAddDeviceModel['color']) => {
    const model = {
      ...addDeviceModel(),
      color,
    };
    addDeviceModel(model);
  };
};

const setEngineSpecification = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (engineSpecification: IAddDeviceModel['engineSpecification']) => {
    const model = {
      ...addDeviceModel(),
      engineSpecification,
    };
    addDeviceModel(model);
  };
};

const setEngineDisplacementCc = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (engineDisplacementCc: IAddDeviceModel['engineDisplacementCc']) => {
    const model = {
      ...addDeviceModel(),
      engineDisplacementCc,
    };
    addDeviceModel(model);
  };
};

const setSerialNumber = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (serialNumber: IAddDeviceModel['serialNumber']) => {
    const model = {
      ...addDeviceModel(),
      serialNumber,
    };
    addDeviceModel(model);
  };
};

const setPower = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (power: IAddDeviceModel['power']) => {
    const model = {
      ...addDeviceModel(),
      power,
    };
    addDeviceModel(model);
  };
};

const setPowerUnit = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (powerUnitId: number) => {
    const model = {
      ...addDeviceModel(),
      powerUnit: {
        id: powerUnitId,
      },
    };
    addDeviceModel(model);
  };
};

const setPowerRpm = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (powerRpm: IAddDeviceModel['powerRpm']) => {
    const model = {
      ...addDeviceModel(),
      powerRpm,
    };
    addDeviceModel(model);
  };
};

const setTorqueNm = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (torqueNm: IAddDeviceModel['torqueNm']) => {
    const model = {
      ...addDeviceModel(),
      torqueNm,
    };
    addDeviceModel(model);
  };
};

const setTorqueRpm = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (torqueRpm: IAddDeviceModel['torqueRpm']) => {
    const model = {
      ...addDeviceModel(),
      torqueRpm,
    };
    addDeviceModel(model);
  };
};

const setTransmission = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (transmission: IAddDeviceModel['transmission']) => {
    const model = {
      ...addDeviceModel(),
      transmission,
    };
    addDeviceModel(model);
  };
};

const setTransmissionType = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (transmissionType: IAddDeviceModel['transmissionType']) => {
    const model = {
      ...addDeviceModel(),
      transmissionType,
    };
    addDeviceModel(model);
  };
};

const setTransmissionNumber = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (transmissionNumber: IAddDeviceModel['transmissionNumber']) => {
    const model = {
      ...addDeviceModel(),
      transmissionNumber,
    };
    addDeviceModel(model);
  };
};

const setFuels = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (fuels: IAddDeviceModelFuel[]) => {
    const model = {
      ...addDeviceModel(),
      fuels,
    };
    addDeviceModel(model);
  };
};

const setFuel = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (fuel: IAddDeviceModelFuel) => {
    const fuels = [ ...addDeviceModel().fuels ];
    const index = _.findIndex(fuels,
      {
        fuelType: {
          id: fuel?.fuelType?.id,
        },
      }
    );
    if (index !== -1) {
      fuels[index] = fuel;
    } else {
      console.error('No index found in fuels for targeted fuel! Check for setFuel method in state addDevice mutation');
    }
    const model = {
      ...addDeviceModel(),
      fuels,
    };
    addDeviceModel(model);
  };
};

const setValidationErrors = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (validationError: ValidationErrorObject) => {
    // @ts-ignore
    const validationErrors = [ ...addDeviceModel().validationErrors ];

    const index = _.findIndex(validationErrors, {
      name: validationError.name,
    });
    if (index !== -1) {
      validationErrors[index] = validationError;
    } else {
      validationErrors.push(validationError);
    }

    const model= {
      ...addDeviceModel(),
      validationErrors,
    };
    addDeviceModel(model);
  };
};

const setFirstRegistrationDate = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (firstRegistrationDate: IAddDeviceModel['firstRegistrationDate']) => {
    const model = {
      ...addDeviceModel(),
      firstRegistrationDate,
    };
    addDeviceModel(model);
  };
};

const setAcquisitionDate = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (acquisitionDate: IAddDeviceModel['acquisitionDate']) => {
    const model = {
      ...addDeviceModel(),
      acquisitionDate,
    };
    addDeviceModel(model);
  };
};

const setInitialOdometerState = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (initialOdometerState: IAddDeviceModel['initialOdometerState']) => {
    const model = {
      ...addDeviceModel(),
      initialOdometerState,
    };
    addDeviceModel(model);
  };
};

const setGuaranteeDate = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (guaranteeDate: IAddDeviceModel['guaranteeDate']) => {
    const model = {
      ...addDeviceModel(),
      guaranteeDate,
    };
    addDeviceModel(model);
  };
};

const setGuaranteeMonths = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (guaranteeMonths: IAddDeviceModel['guaranteeMonths']) => {
    const model = {
      ...addDeviceModel(),
      guaranteeMonths,
    };
    addDeviceModel(model);
  };
};

const setGuaranteeDistance = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (guaranteeDistance: IAddDeviceModel['guaranteeDistance']) => {
    const model = {
      ...addDeviceModel(),
      guaranteeDistance,
    };
    addDeviceModel(model);
  };
};

const setActualOdometerState = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (actualOdometerState: IAddDeviceModel['actualOdometerState']) => {
    const model = {
      ...addDeviceModel(),
      actualOdometerState,
    };
    addDeviceModel(model);
  };
};

const setOdometerUnit = (addDeviceModel: ReactiveVar<IAddDeviceModel>) => {
  return (odometerUnitId: number) => {
    const model = {
      ...addDeviceModel(),
      odometerUnit: {
        id: odometerUnitId,
      },
    };
    addDeviceModel(model);
  };
};

const addDeviceCacheControl = {
  resetToInit: resetToInit(addDevice),
  setFlowPrerequisite: setFlowPrerequisite(addDevice),
  setName: setName(addDevice),
  setManufacturerName: setManufacturerName(addDevice),
  setModel: setModel(addDevice),
  setThumbnailImage: setThumbnailImage(addDevice),
  setVin: setVin(addDevice),
  setYearOfManufacture: setYearOfManufacture(addDevice),
  setLicensePlate: setLicensePlate(addDevice),
  setVehicleType: setVehicleType(addDevice),
  setColor: setColor(addDevice),
  setEngineSpecification: setEngineSpecification(addDevice),
  setEngineDisplacementCc: setEngineDisplacementCc(addDevice),
  setSerialNumber: setSerialNumber(addDevice),
  setPower: setPower(addDevice),
  setPowerUnit: setPowerUnit(addDevice),
  setPowerRpm: setPowerRpm(addDevice),
  setTorqueNm: setTorqueNm(addDevice),
  setTorqueRpm: setTorqueRpm(addDevice),
  setTransmission: setTransmission(addDevice),
  setTransmissionType: setTransmissionType(addDevice),
  setTransmissionNumber: setTransmissionNumber(addDevice),
  setFuels: setFuels(addDevice),
  setFuel: setFuel(addDevice),
  setFirstRegistrationDate: setFirstRegistrationDate(addDevice),
  setAcquisitionDate: setAcquisitionDate(addDevice),
  setInitialOdometerState: setInitialOdometerState(addDevice),
  setGuaranteeDate: setGuaranteeDate(addDevice),
  setGuaranteeMonths: setGuaranteeMonths(addDevice),
  setGuaranteeDistance: setGuaranteeDistance(addDevice),
  setActualOdometerState: setActualOdometerState(addDevice),
  setValidationErrors: setValidationErrors(addDevice),
  setOdometerUnit: setOdometerUnit(addDevice),
};

export default addDeviceCacheControl;
