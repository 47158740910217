import { gql } from '@apollo/client';

export const GET_NEW_MAINTENANCE = gql`
  query GetNewMaintenance {
    newMaintenance @client {
      description
      operations
      expanded
    }
  }
`;
