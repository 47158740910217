import { styled } from '@mui/material/styles';
import { Typography } from '@mechis/elements';
import { shouldForwardProp as sysShouldForwardProp } from '@mui/system';
import { IOwnerState } from './types';

export const Section = styled('div', {
  name: 'SelectedTech',
  slot: 'Root',
  shouldForwardProp: (prop) => sysShouldForwardProp(prop) && prop !== 'ownerState',
})<{ ownerState: IOwnerState }>(({ ownerState }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',

  ...(ownerState.variant === 'mobile' && {
    // todo: declarations here
  }),

  ...(ownerState.variant === 'desktop' && {
    h3: {
      fontSize: '1.2rem',
    },
  }),

  '& > div > div': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const Title = styled(Typography, {
  name: 'SelectedTech',
  slot: 'Title',
})(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontWeight: '400',
  marginBottom: theme.spacing(0.5),
}));

