import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mechis/elements';

const MechisVintageCar: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 58.938 58.938" {...props}>
      <g>
        <path d="M45.392,28.92V21H33.831v15.233h9.18L45.392,28.92z M34.84,22.428h9.546v5.116H34.84L34.84,22.428L34.84,22.428z
		 M56.574,23.928l-7.225-2.62l4.066-6.103l-0.354-0.234l-4.126,6.188l-4.101-1.485H21.071l-2.721,7.92
		c-5.41-0.627-15.802,1.481-15.802,1.481v-0.826H0v6.707h2.547v0.836l0.76-0.648c-0.481,0.826-0.76,1.783-0.76,2.805
		c0,3.096,2.507,5.604,5.599,5.604c3.091,0,5.598-2.51,5.598-5.604c0-3.092-2.508-5.6-5.598-5.6c-0.555,0-1.086,0.084-1.592,0.23
		c0.387-0.293,0.657-0.529,0.657-0.633l5.834,0.15l1.272,5.391h18.348l12.404,0.025h0.395c-0.043,0.281-0.071,0.564-0.071,0.855
		c0,3.094,2.509,5.6,5.601,5.6c3.09,0,5.602-2.506,5.602-5.6c0-3.092-2.51-5.604-5.602-5.604c-1.338,0-2.562,0.473-3.522,1.252
		l0.397-1.611h6.504l2.543,5.146l1.843,0.004l0.104-8.098C58.863,29.467,59.579,25.83,56.574,23.928z M8.146,35.42
		c1.395-0.002,2.53,1.135,2.53,2.525c0,1.398-1.134,2.531-2.53,2.531c-1.395,0-2.531-1.135-2.531-2.531
		C5.616,36.553,6.751,35.42,8.146,35.42z M21.02,36.176l-0.071-0.123l0.12,0.123H21.02z M32.933,32.123l-0.02,4.479H21.494
		l-0.423-0.424h11.456V32V21.027h-9.818l-2.627,7.623v5.938l0.866,1.463l-1.289-1.289l0.012-6.214l2.736-7.943h10.544L32.933,32.123
		z M22.697,27.546v-5.117h8.697v5.117H22.697z M45.807,29.016l-2.485,7.642H33.41V20.575h12.41L45.807,29.016z M50.992,35.842
		c1.396,0,2.531,1.137,2.531,2.529c0,1.395-1.136,2.529-2.531,2.529c-1.395,0-2.528-1.137-2.528-2.529
		C48.458,36.979,49.597,35.842,50.992,35.842z"/>
      </g>
    </SvgIcon>
  );
};

export default MechisVintageCar;
