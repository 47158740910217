import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mechis/elements';

const MechisTrailer: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 128 128" {...props}>
      <path d="M44.2,85.4H23.4c-7.2,0-13.1-5.9-13.1-13.1l0-22.9c0-9.8,7.9-17.8,17.7-17.8h60.4c9.8,0,17.7,8,17.7,17.8l0,32.3h9.2
	c2,0,3.6,1.7,3.6,3.6H72.2c-0.5-7.3-6.5-13.1-14-13.1C50.7,72.2,44.7,78,44.2,85.4 M91.6,56.3c2,0,3.7-1.7,3.7-3.7v-6.1
	c0-2-1.6-3.7-3.7-3.7H78.8c-2,0-3.7,1.7-3.7,3.7v6.1c0,2,1.6,3.7,3.7,3.7H91.6z M55.2,56.3c2,0,3.7-1.7,3.7-3.7v-6.1
	c0-2-1.6-3.7-3.7-3.7H27.6c-2,0-3.7,1.7-3.7,3.7v6.1c0,2,1.6,3.7,3.7,3.7H55.2z"/>
      <path d="M48.1,86.3c0-5.6,4.5-10.1,10.1-10.1c5.6,0,10.1,4.5,10.1,10.1c0,5.6-4.5,10.1-10.1,10.1C52.6,96.3,48.1,91.8,48.1,86.3
	 M58.2,90.6c2.4,0,4.4-1.9,4.4-4.4s-2-4.4-4.4-4.4c-2.4,0-4.4,2-4.4,4.4S55.8,90.6,58.2,90.6"/>
    </SvgIcon>
  );
};

export default MechisTrailer;
