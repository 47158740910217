import React, { FC, useEffect } from 'react';
import { IProps } from './types';
import AddDeviceLayout from '@mechis/layouts/AddDeviceLayout';
import {
  useFuelTypesQuery,
} from '@state/mechis-backend/generated/schema';
import {
  FormGroup,
  Stack,
} from '@mechis/elements';
import Fuel, { Skeleton as FuelSkeleton } from '../Fuel';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { useQuery } from '@apollo/client';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';

const FuelList: FC<IProps> = () => {
  const { data, loading, error } = useFuelTypesQuery();
  const { setFuels } = addDeviceCacheControl;
  const { data: storeData } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);

  useEffect(() => {
    if (data && !loading && !error) {
      if (storeData?.addDevice.fuels && storeData.addDevice.fuels.length === 0) {
        setFuels(data.fuelTypes.map((obj) => ({
          ...obj,
          isSelected: false,
          consumptionUnit: { id: 1 },
          fuelType: { id: +obj.id },
          initialConsumption: 0,
          tankVolume: 0,
          volumeUnit: { id: 1 },
        })));
      }
    }
  }, [ data, loading, error ]);

  return (
    <AddDeviceLayout>
      <FormGroup sx={{ mt: -2 }}>
        {loading && (
          <Stack spacing={2}>
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
            <FuelSkeleton />
          </Stack>
        )}
        {data && !loading && !error && (
          <Stack spacing={2}>
            {storeData?.addDevice.fuels.map((fuel) => (
              <Fuel
                key={fuel.fuelType?.id as number}
                id={fuel.fuelType?.id as number}
              />
            ))}
          </Stack>
        )}
        
      </FormGroup>
    </AddDeviceLayout>
  );
};

export default FuelList;
