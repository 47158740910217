import React from 'react';
import { DeviceListing } from '@mechis/sections/DeviceListing/DeviceListing';
import useHeader from '@hooks/useHeader';

const TechOverview: React.FC = () => {
  useHeader({ headline: 'appBar.headline.deviceOverview' });
  
  return (
    <DeviceListing />
  );
};

export default TechOverview;
