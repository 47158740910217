const replaceUrlSegment = (url: string, replacement: string, index: number): string => {
  const parts: string[] = url.split('/');
  const filteredParts: string[] = parts.filter((part) => part !== '');

  if (index >= 0 && index < filteredParts.length) {
    filteredParts[index] = replacement;
    return `/${filteredParts.join('/')}`;
  }

  return url;
};

export default replaceUrlSegment;
