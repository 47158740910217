import { styled } from '@mui/material/styles';
import { shouldForwardProp as sysShouldForwardProp } from '@mui/system';
import { OwnerState } from './types';

// todo: fix typescript definition here
export const ImageContainer = styled('div', {
  name: 'ImageContainer',
  slot: 'Root',
  shouldForwardProp: (prop) => sysShouldForwardProp(prop) && prop !== 'ownerState',
})<{ ownerState: OwnerState, theme?: any }>(({ theme, ownerState }) => ({
  display: 'flex',
  '& > span > img': {
    display: 'block',
    ...(ownerState.fit && {
      objectFit: ownerState.fit,
    }),
  },
  '& > svg': {
    marginLeft: 'auto',
    marginRight: 'auto',
    alignSelf: 'center',
  },

  // margins
  // taken from desing system @mui/system

  ...(ownerState.radius && {
    borderRadius: `${ownerState.radius}px`,
    '& img': {
      borderRadius: `${ownerState.radius}px`,
    },
  }),
  ...(ownerState.containerProps?.bg && {
    backgroundColor: theme.palette[ownerState.containerProps?.bg]?.default,
  }),
  ...(ownerState.padding && ownerState.padding === 'large' && {
    padding: '16px',
  }),
  ...(ownerState.padding && ownerState.padding === 'medium' && {
    padding: '10px',
  }),
  ...(ownerState.padding && ownerState.padding === 'small' && {
    padding: '4px',
  }),
}));

