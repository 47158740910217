import { ReactiveVar, makeVar } from '@apollo/client';
import { INewMaintenanceModel } from './types';

export const init: INewMaintenanceModel = {
  description: '',
  operations: [],
  expanded: [],
};

const newMaintenance: ReactiveVar<INewMaintenanceModel> = makeVar<INewMaintenanceModel>(init);

export default newMaintenance;
