import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from '@state/client';
import { ThemeProvider } from '@mui/material/styles';
import theme from '@configurations/themes';
import CssBaseline from '@mechis/elements/CssBaseline';
import Router from '@router/Router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import cs from 'date-fns/locale/cs';
import en from 'date-fns/locale/cs';
import { CookiesBar } from '@mechis/blocks/CookieBar/CookiesBar';
import PortraitOrientationWrapper from '@mechis/sections/PortraitOrientationWrapper';

const App: FC = () => {
  const { i18n: { language }, t } = useTranslation();
  const getAdapterLocale = () => {
    switch (language) {
      case 'cs': return cs;
      case 'en': return en;
      default: throw new Error('Non-existent translation');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getAdapterLocale()} localeText={{
        cancelButtonLabel: t('datePicker.cancel'),
      }}>
        <PortraitOrientationWrapper>
          <CssBaseline />
          <BrowserRouter>
            <ApolloProvider client={client}>
              <Router />
            </ApolloProvider>
          </BrowserRouter>
        </PortraitOrientationWrapper>
      </LocalizationProvider>
      <CookiesBar />
    </ThemeProvider>
  );
};

export default App;
