import React, { FC, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { IProps } from '../types';
import {
  useCreateAttachmentMutation,
  useCreateDeviceAttachmentMutation,
} from '@state/mechis-backend/generated/schema';
import useTech from '@hooks/useTech';

// WIDGET API INFO: https://cloudinary.com/documentation/upload_widget_reference
const CloudinaryUploadWidget: FC<IProps> = ({
  setCloudinaryAttachmentInfo,
  cloudinaryConfiguration,
  children,
}) => {
  const [ widget, setWidget ] = useState<any>();
  const [ createAttachment ] = useCreateAttachmentMutation();
  const [ createDeviceAttachment ] = useCreateDeviceAttachmentMutation();
  const { techId } = useTech();

  useEffectOnce(() => {
    setWidget((window as any).cloudinary.createUploadWidget(
      cloudinaryConfiguration,
      (error: any, result: any) => {
        if (!error && result && result.event === 'success') {
          console.log('File is successfully uploaded to cloudinary: ', result);
          createAttachment({
            variables: {
              attachment: {
                originalName: result.info.original_filename,
                storageName: result.info.public_id,
                version: result.info.version,
                mimeType: result.info.format,
                size: result.info.bytes,
              },
            },
          }).then((resultAttachment) => {
            console.log('File is successfully uploaded to database: ', resultAttachment);
            createDeviceAttachment({
              variables: {
                attachmentId: Number(resultAttachment.data?.createAttachment?.id),
                deviceId: techId,
              },
            }).then((resultDeviceAttachment) => {
              console.log('File is successfully linked to device: ', resultDeviceAttachment);
              setCloudinaryAttachmentInfo({
                id: Number(resultAttachment.data?.createAttachment?.id),
                originalName: result.info.original_filename,
                storageName: result.info.public_id,
                mimeType: result.info.format,
                size: result.info.bytes,
              });
            });
          });
        }
      }
    ));
  });

  return (
    <>
      {children({ widget })}
    </>
  );
};

export default CloudinaryUploadWidget;
