import React from 'react';
import useHeader from '@hooks/useHeader';
import AddFlowStepper from '@mechis/sections/AddFlowStepper';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import useAddDevice from '@hooks/useAddDevice';
import AddDeviceLayout from '@mechis/layouts/AddDeviceLayout';
import SimpleTable from '@mechis/blocks/SimpleTable';
import { Button } from '@mechis/elements';
import { OutputDateFormats } from '@configurations/types/global';
import {
  getTransmissionNameOrFallbackToId,
  getVehicleTypeNameOrFallbackToId,
  tableOutputDate,
} from '@utilities/app';
import {
  getFuelsDecoratedForRecapitulationTable, 
} from '@utilities/getFuelsDecoratedForDeviceDetailTable';
import {
  useConsumptionUnitsQuery, useTransmissionsQuery,
  useVehicleTypesQuery,
  useVolumeUnitsQuery,
} from '@state/mechis-backend/generated/schema';
import Loader from '@mechis/blocks/Loader';
import useUnits from '@hooks/useUnits';

const Step5 = () => {
  useHeader({
    headline: 'addDevice.motorcycle.step4.title',
    widget: (
      <AddFlowStepper />
    ),
  });

  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const device = data?.addDevice;
  const { data: vehicleTypes, loading: vehicleTypesLoading } = useVehicleTypesQuery();
  const { data: consumptionsData, loading: loadingConsumptionsUnit} = useConsumptionUnitsQuery();
  const { data: volumeUnits, loading: volumeUnitsLoading } = useVolumeUnitsQuery();
  const { data: transmissions } = useTransmissionsQuery();
  const { t } = useTranslation();
  const { onCreate, onReset, ResetModal } = useAddDevice();
  const { getOdometerUnitById, getPowerUnitById } = useUnits();

  if (vehicleTypesLoading || loadingConsumptionsUnit || volumeUnitsLoading) {
    return (
      <Loader />
    );
  }

  return (
    <AddDeviceLayout>
      <ResetModal />
      <SimpleTable 
        title=""
        data={[
          // základní údaje
          { 
            label: t('addDevice.components.manufacturer.name'),
            value: device?.manufacturer || '-',
          },
          { 
            label: t('addDevice.components.model.name'),
            value: device?.model || '-',
          },
          { 
            label: t('addDevice.components.vin.name'),
            value: device?.vin || '-',
          },
          { 
            label: t('addDevice.components.serialNumber.name'),
            value: device?.serialNumber || '-',
          },
          { 
            label: t('addDevice.components.yearOfManufacture.name'),
            value: tableOutputDate(device?.manufacturedYearMonth, OutputDateFormats.ManufacturedDate),
          },
          { 
            label: t('addDevice.components.licensePlate.name'),
            value: device?.spz || '-',
          },
          { 
            label: t('addDevice.components.vehicleType.name'),
            value: getVehicleTypeNameOrFallbackToId(vehicleTypes,device?.vehicleType?.id+'', t) || '-',
          },
          { 
            label: t('addDevice.components.color.name'),
            value: device?.color || '-',
          },

          // technicke parametry
          { 
            label: t('addDevice.components.engineSpecification.name'),
            value: device?.engineSpecification || '-',
          },
          { 
            label: t('addDevice.components.engineDisplacementCc.name'),
            value: t('addDevice.components.engineDisplacementCc.ccm', { value: device?.engineDisplacementCc ?? '-' }),
          },
          { 
            label: t('addDevice.components.power.name'),
            value: `${device?.power ?? '-'} ${getPowerUnitById(device?.powerUnit.id as number)}`,
          },
          { 
            label: t('addDevice.components.powerRpm.name'),
            value: t('addDevice.components.powerRpm.value', { value: device?.powerRpm ?? '-' }),
          },
          { 
            label: t('addDevice.components.torqueNm.name'),
            value: t('addDevice.components.torqueNm.value', { value: device?.torqueNm ?? '-' }),
          },
          { 
            label: t('addDevice.components.torqueRpm.name'),
            value: t('addDevice.components.torqueRpm.value', { value: device?.torqueRpm ?? '-' }),
          },
          {
            label: t('addDevice.components.transmission.name'),
            value: getTransmissionNameOrFallbackToId(transmissions,device?.transmission?.id+'', t) || '-',
          },
          // TODO: MVP ready
          /*{
            label: t('addDevice.components.transmissionType.name'),
            value: device?.transmissionType || '-',
          },*/
          { 
            label: t('addDevice.components.transmissionNumber.name'),
            value: device?.transmissionNumber ?? '-',
          },
          ...getFuelsDecoratedForRecapitulationTable(device?.fuels, t, consumptionsData, volumeUnits).flat(),
          // provozni udaje
          { 
            label: t('addDevice.components.firstRegistrationDate.name'),
            value: tableOutputDate(device?.firstRegistrationDate, OutputDateFormats.ManufacturedDate),
          },
          { 
            label: t('addDevice.components.acquisitionDate.name'),
            value: tableOutputDate(device?.acquisitionDate, OutputDateFormats.ManufacturedDate),
          },
          { 
            label: t('addDevice.components.initialOdometerState.name'),
            value: `${device?.initialOdometerState ?? '-'} ${getOdometerUnitById(device?.odometerUnit.id as number)}`,
          },

          // zaruční udaje
          { 
            label: t('addDevice.components.guaranteeDate.name'),
            value: tableOutputDate(device?.guaranteeDate, OutputDateFormats.ManufacturedDate),
          },
          { 
            label: t('addDevice.components.guaranteeMonths.name'),
            value: device?.guaranteeMonths ?? '-',
          },
          { 
            label: t('addDevice.components.guaranteeDistance.name'),
            value: `${device?.guaranteeDistance ?? '-'} ${getOdometerUnitById(device?.odometerUnit.id as number)}`,
          },
        ]}
      />
      <Button
        onClick={() => onCreate()}
        size="medium"
        variant="contained"
        color="secondary"
        sx={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: (theme) => theme.spacing(2),
        }}
      >
        {t('addDevice.create')}
      </Button>
      <Button
        onClick={onReset}
        size="small"
        variant="text"
        color="primary"
        sx={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: (theme) => theme.spacing(2),
          textDecoration: 'underline',
          maxWidth: '150px',
        }}
      >
        {t('addDevice.reset')}
      </Button>
    </AddDeviceLayout>
  );
};

export default Step5;
