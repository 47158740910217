import React, { FC } from 'react';
import useAttachments from './useAttachments';
import { IAttachmentsContentProps } from './types';
import {
  Dialog,
  DialogActions,
  Box,
  Typography,
  DialogContent,
  Stack,
  Button,
  LinearProgress,
  IconButton,
} from '@mechis/elements';
import CancelIcon from '@mui/icons-material/Cancel';
import CountUp from 'react-countup';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

const AttachmentsModal: FC<IAttachmentsContentProps> = ({ children, variant = 'attachments' }) => {
  const {
    isModalOpened,
    setIsModalOpen,
    selectedFilesTotalSize,
    handleFileChange,
    uploadFiles,
  } = useAttachments();
  
  return (
    <Dialog
      open={isModalOpened}
      onClose={() => setIsModalOpen(false)}
      fullScreen
      PaperProps={{
        sx: {
          // backgroundColor: '#f6f6f6',
        },
      }}
    >
      <CancelIcon
        onClick={() => setIsModalOpen(false)}
        sx={{
          position: 'absolute',
          right: '16px',
          top: '16px',
          color: 'lightgrey',
        }}
      />
      <DialogContent sx={{ padding: 0 }}>
        <Box p={2}>
          <Typography
            variant="h5"
            textAlign="center"
            mb={1}
            mt={1}
          >
            {variant === 'attachments' && 'Přílohy'}
            {variant === 'avatar' && 'Avatar'}
          </Typography>
          <div>{children}</div>
        </Box>
      </DialogContent>

      {variant === 'attachments' && (
        <DialogActions>
          <Stack
            direction="row"
            alignItems="end"
            justifyContent="space-between"
            sx={{ width: '100%', p:2 }}
            spacing={8}
          >
            <Box sx={{ flexGrow: 1 }} textAlign="center">
              <Typography variant="body2" sx={{ opacity: 0.8 }}><small><em>Celkem</em></small></Typography>
              <Typography variant="h4" mt={-0.5}>
                <CountUp
                  start={0}
                  decimals={2}
                  end={selectedFilesTotalSize}
                  duration={0.5}
                  suffix=" MB"
                />
              </Typography>
              <LinearProgress
                color="secondary"
                variant="determinate"
                value={40}
                valueBuffer={80}
                sx={{
                  height: 5,
                  borderRadius: 5,
                }}
              />
            </Box>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Button size="small" variant="outlined">Reset</Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => uploadFiles()}
              >
                Nahrát
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      )}

    </Dialog>
  );
};

export default AttachmentsModal;
