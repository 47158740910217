import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
// eslint-disable-next-line
import { initTranslations } from '@utilities/i18n';
// import { register } from '@utilities/serviceWorkerRegistration';

initTranslations();

const ReloadOnFirstVisit: FC = () => {
  useEffect(() => {
    const hasVisitedThisSession = sessionStorage.getItem('hasVisitedThisSession');
    if (!hasVisitedThisSession) {
      sessionStorage.setItem('hasVisitedThisSession', 'true');
      window.location.reload();
    }
  }, []);

  return null;
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
    <ReloadOnFirstVisit />
  </React.StrictMode>
);

// register();
reportWebVitals();

