import React, { FC, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { IProps } from '../types';
import {
  useCreateAttachmentMutation,
} from '@state/mechis-backend/generated/schema';

const CloudinaryCreateEventAttachmentWidget: FC<IProps> = ({
  setCloudinaryAttachmentInfo,
  linkingCallback,
  cloudinaryConfiguration,
  children,
}) => {
  const [ widget, setWidget ] = useState<any>();
  const [ createAttachment ] = useCreateAttachmentMutation();

  const cloudinaryCallback = async (error: any, result: any) => {
    if (!error && result && result.event === 'success') {
      console.log('File is successfully uploaded to cloudinary: ', result);

      const createAttachmentResult = await createAttachment({
        variables: {
          attachment: {
            originalName: result.info.original_filename,
            storageName: result.info.public_id,
            version: result.info.version,
            mimeType: result.info.format,
            size: result.info.bytes,
          },
        },
      });
      
      const attachmentId = createAttachmentResult.data?.createAttachment?.id;
      console.log('File is successfully uploaded to database: ', createAttachmentResult);

      if (linkingCallback) {
        linkingCallback(Number(createAttachmentResult.data?.createAttachment?.id));
      }
      
      setCloudinaryAttachmentInfo({
        id: attachmentId,
        originalName: result.info.original_filename,
        storageName: result.info.public_id,
        mimeType: result.info.format,
        size: result.info.bytes,
      });
    }
  };

  useEffectOnce(() => {
    setWidget((window as any).cloudinary.createUploadWidget(
      cloudinaryConfiguration,
      cloudinaryCallback,
    ));
  });

  return (
    <>
      {children({ widget })}
    </>
  );
};

export default CloudinaryCreateEventAttachmentWidget;
