import React, { forwardRef } from 'react';
import { default as MuiIconButton } from '@mui/material/IconButton';
import { IconButtonProps } from './types';
import { styled, lighten } from '@mui/material/styles';

const Element = styled(MuiIconButton, {
  name: 'IconButton',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<any>(({ theme, ownerState }) => ({
  ...(ownerState.isSquared && {
    borderRadius: '12px' ,
  }),
  ...(ownerState.color && ownerState.variant === 'default' && {
    background: lighten(theme.palette[ownerState.color].main, 0.75),
    '& > svg': {
      color: theme.palette[ownerState.color].main,
    },
    '&:hover': {
      background: lighten(theme.palette[ownerState.color].main, 0.75),

      '& > svg': {
        // color: theme.palette.common.white,
      },
    },
  }),
  ...(ownerState.variant === 'transparent' && {
    // background: 'rgb(255 255 255 / 30%)',
    '& > svg': {
      color: theme.palette.common.white,
      opacity: 0.8,
    },
    '&:hover': {
      background: 'rgb(255 255 255 / 20%)',
      '& > svg': {
        color: theme.palette.common.white,
        opacity: 0.9,
      },
    },
  }),
}));

const IconButton = forwardRef<any, IconButtonProps>((props, ref) => {
  const { isSquared, color, variant = 'default', ...other } = props;

  const ownerState = {
    isSquared,
    color,
    variant,
  };

  return <Element ownerState={ownerState} {...other} ref={ref} />;
});

IconButton.displayName = 'IconButton';

export default IconButton;
