import React, { FC } from 'react';
import { IProps } from './types';
import { InputAdornment } from '@mechis/elements';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  blurTarget, 
  ensureNumericZeroIsNotDisplayed,
} from '@utilities/app';
import useIcon from '@mechis/icons/useIcon';
import { maximumTorque } from '@configurations/constants/inputConstraints';
import {useAddDeviceSetValidationError} from '@hooks/validationHooks/useAddDeviceSetValidatonError';
import NumberField from '@mechis/elements/NumberField';

const inputIdentifier = 'torqueNm';
const TorqueNm: FC<IProps> = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { setTorqueNm } = addDeviceCacheControl;

  const schema = yup.object({
    torqueNm: yup
      .number()
      .transform((value, originalValue) => originalValue === '' ? null : value)
      .nullable()
      .max(maximumTorque, `${t('addDevice.inputValidation.maxValue') + maximumTorque}`),
  }).required();

  const { control, handleSubmit, formState: { errors } } = useForm<{ torqueNm: number | null }>({
    defaultValues: {
      torqueNm: ensureNumericZeroIsNotDisplayed(data?.addDevice.torqueNm),
    },
    resolver: yupResolver(schema),
  });

  useAddDeviceSetValidationError(inputIdentifier, errors.torqueNm?.message);
  
  const onSave = async ({ torqueNm }: { torqueNm: number | null }) => {
    setTorqueNm(torqueNm);
  };

  return (
    <Controller
      render={({ field, formState }) => (
        <NumberField
          {...field}
          onKeyUp={() => {
            handleSubmit(onSave)();
          }}
          onWheel={blurTarget}
          label={t('addDevice.components.torqueNm.name')}
          id={inputIdentifier}
          error={!!formState.errors.torqueNm?.message}
          helperText={formState.errors.torqueNm?.message}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
              >
                <small>
                  {t('addDevice.components.torqueNm.endAdornment')}
                </small>
              </InputAdornment>
            ),
            startAdornment: (
              <InputAdornment position="start">
                {useIcon('mechisTorque', { fontSize: 'small' })}
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      )}
      name="torqueNm"
      control={control}
    />
  );
};

export default TorqueNm;
