
import React, { forwardRef } from 'react';
import { default as MuiPaper } from '@mui/material/Paper';
import { PaperProps } from './types';

const Paper = forwardRef<any, PaperProps>((props, ref) => {
  return <MuiPaper {...props as any} ref={ref} />;
});

Paper.displayName = 'Paper';

export default Paper;
