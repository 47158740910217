import { ReactiveVar, makeVar } from '@apollo/client';
import { IAddMaintenanceEventModel } from './types';

const MAINTENANCE_EVENT_TYPE_ID = 3;
const CZECH_CURRENCY_ID = 1;

// todo: delete whole model after refactoring
export const init: IAddMaintenanceEventModel = {
  name: 'Údržba',
  priceTotal: 0,
  stateOdometer: 0,
  description: '',
  periodDay: 0,
  device: {
    id: 0,
  },
  currency: {
    id: CZECH_CURRENCY_ID,
  },
  eventType: {
    id: MAINTENANCE_EVENT_TYPE_ID,
  },
  eventOperation: [],
};

const addMaintenanceEvent: ReactiveVar<IAddMaintenanceEventModel> = makeVar<IAddMaintenanceEventModel>(init);

export default addMaintenanceEvent;
