import { useParams } from 'react-router-dom';
import { DEVICE_ID } from '@configurations/constants/app';
import { CZK_UNIT_NAME, LITRE_UNIT_NAME, NON_BREAKING_SPACE } from '@configurations/constants/app';
import { useDeviceUnitsByDeviceIdQuery } from '@state/mechis-backend/generated/schema';

// eslint-disable-next-line no-shadow
export enum UnitType {
  Litre = 'litre',
  Odometer = 'odometer',
  Power = 'power',
  Currency = 'currency',
}

const useTech = () => {
  const { techId: paramTechId } = useParams();
  const localStorageTechId = localStorage.getItem(DEVICE_ID);
  const techId = Number(paramTechId ?? localStorageTechId);

  const { data } = useDeviceUnitsByDeviceIdQuery({ variables: { deviceId: techId } });
  const odometerUnit = data?.device?.odometerUnit?.unitName;
  const powerUnit = data?.device?.powerUnit?.unitName;
  const fuelsConsumptionUnit = data?.device?.fuel.map((fuel) => ({
    name: fuel.fuelType?.fuelName,
    unitName: fuel.consumptionUnit?.unitName,
  }));

  const getTechUnit = (unit: UnitType, withNonBreakingSpace?: boolean) => {
    let result;

    switch (unit) {
      case UnitType.Litre:
        result = LITRE_UNIT_NAME;
        break;
      case UnitType.Odometer:
        result = odometerUnit;
        break;
      case UnitType.Power:
        result = powerUnit;
        break;
      case UnitType.Currency:
        result = CZK_UNIT_NAME;
        break;
      default:
        return '';
    }

    return result && result.length > 0 && withNonBreakingSpace ? `${NON_BREAKING_SPACE}${result}` : result;
  };

  const getConsumptionUnitByFuelName = (fuelName: string, withNonBreakingSpace?: boolean) => {
    const findFuel = fuelsConsumptionUnit?.find((fuel) => fuel.name === fuelName);
    return findFuel?.unitName ? (withNonBreakingSpace ? `${NON_BREAKING_SPACE}${findFuel.unitName}` : findFuel.unitName) : '';
  };

  return {
    techId,
    settings: {
      units: {
        litre: LITRE_UNIT_NAME,
        odometer: odometerUnit,
        power: powerUnit,
        currency: CZK_UNIT_NAME,
        fuelsConsumption: fuelsConsumptionUnit,
      },
    },
    getTechUnit,
    getConsumptionUnitByFuelName,
  };
};

export default useTech;
