import React from 'react';
import useHeader from '@hooks/useHeader';
import AddFlowStepper from '@mechis/sections/AddFlowStepper';
import AddDeviceLayout from '@mechis/layouts/AddDeviceLayout';
import ManufacturerName from '../components/ManufacturerName';
import Model from '../components/Model';
import Vin from '../components/Vin';
import YearOfManufacture from '../components/YearOfManufacture';
import LicensePlate from '../components/LicensePlate';
import TypePassengerCar from '../components/TypePassengerCar';
import Color from '../components/Color';
import SerialNumber from '../components/SerialNumber';
import { Grid } from '@mechis/elements';

const Step1 = () => {
  useHeader({
    headline: 'addDevice.passengerCar.step1.title',
    widget: (
      <AddFlowStepper />
    ),
  });

  return (
    <AddDeviceLayout>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ManufacturerName />
        </Grid>
        <Grid item xs={6}>
          <Model />
        </Grid>
        <Grid item xs={12}>
          <Vin />
        </Grid>
        <Grid item xs={12}>
          <SerialNumber />
        </Grid>
        <Grid item xs={6}>
          <YearOfManufacture />
        </Grid>
        <Grid item xs={6}>
          <LicensePlate />
        </Grid>
        <Grid item xs={6}>
          <TypePassengerCar />
        </Grid>
        <Grid item xs={6}>
          <Color />
        </Grid>
      </Grid>
    </AddDeviceLayout>
  );
};

export default Step1;
