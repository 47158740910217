import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Paper, IconButton } from '@mechis/elements';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

export const EmptyDeviceListingMessage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  return (
    <>
      <Paper variant="outlined" sx={{ position: 'relative' }}>
        <FormatQuoteIcon 
          color="disabled"
          fontSize="large"
          sx={{ 
            position: 'absolute',
            top: '8px',
            left: '8px',
            transform: 'rotate(180deg)',
            opacity: '0.4',
          }}
        />
        <Box p={2} textAlign="center">
          <IconButton
            color="warning"
            size="large"
            sx={{
              position: 'relative',
              mt: -6,
            }}
          >
            <EmojiObjectsIcon fontSize="large" />
          </IconButton>
          <Typography mb={2}>
            <small>{t('deviceListing.noDevicesMessage')}</small>
          </Typography>
        </Box>
        <FormatQuoteIcon 
          color="disabled"
          fontSize="large"
          sx={{ 
            position: 'absolute',
            bottom: '8px',
            right: '8px',
            opacity: '0.4',
          }}
        />
      </Paper>
      <Box sx={{ alignSelf: 'center', height: '100%', textAlign: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={2}>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            onClick={() => navigate('/app/new-tech')}
          >
            Přidat techniku
          </Button>
        </Box>
      </Box>
    </>
  );
};
