import React, { FC, useState } from 'react';
import { IProps, IOwnerState } from './types';
import { 
  Section,
  HeaderWidget,
  AppHeadline,
  AppHeadlineMobile,
} from './styles';
import { useQuery } from '@apollo/client';
import { GET_APP } from '@state/queries/app';
import appCacheControl from '@state/mutations/app';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile, isDesktop } from 'react-device-detect';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  HideOnScroll,
  Skeleton,
} from '@mechis/elements';
import Logo2 from '@mechis/blocks/Logo/Logo2';
import AppMenu from '@mechis/sections/AppMenu';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { IAppQuery } from '@state/queries/app/types';
import useAppHeaderWidget from '@hooks/useAppHeaderWidget';
import useAuth from '@hooks/useAuth';

const AppHeader: FC<IProps> = () => {
  const { isLoggedInLoading } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { widget } = useAppHeaderWidget();
  const { data } = useQuery<IAppQuery>(GET_APP);
  const { setIsMenuOpened } = appCacheControl;
  const app = data?.app;
  const isMenuOpened = app?.isMenuOpened ?? false;

  const [ isTrigger, setIsTrigger ] = useState<boolean>(false);
  const onTrigger = (trigger: boolean) => setIsTrigger(trigger);

  const ownerState: IOwnerState = {
    isTrigger,
    isMenuOpened,
    isSmallHeader: widget ? false : true,
  };

  return (
    <Section ownerState={ownerState}>
      {isDesktop && (
        <Box px={2.5} py={2}>
          <AppHeadline variant="h1">
            {app?.toolbarHeadline}
          </AppHeadline>
        </Box>
      )}
      {isMobile && (
        <AppBar position={isMobile ? 'fixed' : 'static'} variant="mechis">
          <Box px={2.5} py={2}>
            <Toolbar disableGutters>
              {isMenuOpened ? (
                <IconButton
                  variant="transparent"
                  size="small"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => navigate(-1)}
                >
                  <SearchRoundedIcon />
                </IconButton>
              ) : (
                <>
                  {pathname !== '/app' ? (
                    <>
                      {app?.menuButtonVisible && (
                        <IconButton
                          variant="transparent"
                          size="small"
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                          onClick={() => navigate(-1)}
                        >
                          <KeyboardBackspaceIcon />
                        </IconButton>
                      )}
                    </>
                  ) : (
                    <IconButton
                      variant="transparent"
                      onClick={() => navigate('/app/mechis')}
                      sx={{ p: 0 }}
                    >
                      {/* <Logo variant="white" size={28} /> */}
                      <Logo2 variant="small" />
                    </IconButton>
                  )}
                </>
              )}
              <AppHeadlineMobile variant="h4">
                {isLoggedInLoading ? (
                  <Skeleton
                    variant="text"
                    sx={{
                      width: '200px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      height: '30px',
                    }}
                  />
                ) : (
                  <>{app?.toolbarHeadline}</>
                )}
              </AppHeadlineMobile>
              {app?.menuButtonVisible && (
                <IconButton
                  variant="transparent"
                  id="cy-hamburger"
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={() => setIsMenuOpened(!isMenuOpened)}
                >
                  {isMenuOpened ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
                </IconButton>
              )}
            </Toolbar>
            {!isMenuOpened && (
              <HideOnScroll onTrigger={onTrigger}>
                <HeaderWidget>
                  {widget}
                </HeaderWidget>
              </HideOnScroll>
            )}
            {isMenuOpened && <AppMenu />}
          </Box>
        </AppBar>
      )}
    </Section>
  );
};

export default AppHeader;
