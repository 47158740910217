import React from 'react';
import { Box, IconButton, Typography } from '@mechis/elements';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTranslation } from 'react-i18next';
import { IProps } from './types';

const UpdateInfoModalHeader: React.FC<IProps> = ({onClose, tParam}) => {
  const { t } = useTranslation();
  
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <Typography variant="h4">
        {t(tParam)}
      </Typography>
      <IconButton variant="default" size="small" onClick={() => onClose()}>
        <CloseRoundedIcon color="primary" />
      </IconButton>
    </Box>
  );
};

export default UpdateInfoModalHeader;
