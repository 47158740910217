import { styled } from '@mui/material/styles';
import { Typography, Paper, Box } from '@mechis/elements';

export const Block = styled('div', {
  name: 'Record',
  slot: 'Root',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',

  '& .kilometers-sign': {
    fontWeight: 'bold',
    // fontFamily: 'Poppins',
    fontSize: '0.75em',
    color: 'lightgrey',
  },
}));

export const Value = styled(Typography, {
  name: 'Record',
  slot: 'Value',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  fontSize: '0.9em',
  color: theme.palette.secondary.dark,
  '& > span.negative': {
    color: theme.palette.error.dark,
  },
}));

export const PaperBlock = styled(Paper, {
  name: 'Record',
  slot: 'PaperBlock',
})(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(-1),
}));

export const InnerPaperBlock = styled(Box, {
  name: 'Record',
  slot: 'PaperBlock',
})(() => ({
  
}));

export const PaperBlockHeader = styled('div', {
  name: 'Record',
  slot: 'PaperBlockHeader',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const PaperBlockHeaderName = styled('div', {
  name: 'Record',
  slot: 'PaperBlockHeaderName',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

