import React, { FC } from 'react';
import {
  Typography,
  Box,
} from '@mechis/elements';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import FormWrapper from '@mechis/sections/FormWrapper';

const RegistrationEmailSended: FC = () => {
  const { t } = useTranslation();
  const { email } = useParams();
  
  return (
    <FormWrapper>
      <Box textAlign="center">
        <EmailIcon
          sx={{
            fontSize: 56,
            color: (theme) => theme.palette.primary.dark,
          }}
        />
      </Box>
      <Typography
        variant="h1"
        sx={{ 
          color: (theme) => theme.palette.primary.dark,
          textAlign: 'center',
          marginBottom: (theme) => theme.spacing(4),
        }}
      >
        {t('registrationEmailSended.title')}
      </Typography>
      <Box textAlign="center">
        <Typography>{t('registrationEmailSended.description', { email })}</Typography>
      </Box>
    </FormWrapper>
  );
};

export default RegistrationEmailSended;
