import { gql } from '@apollo/client';

export const GET_NEW_EXPENSE = gql`
  query GetNewExpense {
    newExpense @client {
      expenses
      prices
      priceTotal
      locationFrom
    }
  }
`;
