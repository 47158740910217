import { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { useLocalStorage } from 'react-use';
import { IS_DESKTOP_SUPPORTED } from '@configurations/constants/app';
import { useLocation, useNavigate } from 'react-router-dom';

const NON_BLOCKED_ROUTES = [ 'email-verification' ];

export const useIsDesktopSupported = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [ isDesktopSupported ] = useLocalStorage<boolean>(IS_DESKTOP_SUPPORTED);

  useEffect(() => {
    if (isDesktop && !isDesktopSupported && !NON_BLOCKED_ROUTES.some((route) => pathname.includes(route))) {
      navigate('/not-supported');
    }
  },[]);
};
