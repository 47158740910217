import React from 'react';
import { IOwnerState } from '../types';
import {
  Control,
  ControlLabel,
} from '../styles';
import {
  IconButton,
  TextField,
} from '@mechis/elements';
import { getColorByState } from '../Selections';
import TodayIcon from '@mui/icons-material/Today';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useQuery } from '@apollo/client';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { GET_SELECTIONS } from '@state/queries/selections';
import selectionsCacheControl from '@state/mutations/selections';

const Date = () => {
  const { data } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const { setDateValue, setDateState } = selectionsCacheControl;
  const date = data?.selections.dateValue || 0;
  const state = data?.selections.dateState || 'default';

  const ownerState: IOwnerState = {
    state,
  };

  const handleChange = (newValue: any) => {
    setDateState('filled');
    setDateValue(newValue);
  };

  const handleClick = () => {
    const element = document.getElementById('selection-datepicker');
    element?.click();
  };
  
  return (
    <Control>
      <IconButton
        size="small"
        color={getColorByState(state)}
        isSquared
        onClick={() => handleClick()}
      >
        <TodayIcon />
      </IconButton>
      <ControlLabel ownerState={ownerState}>
        <MobileDatePicker
          inputFormat="d/M/yy"
          value={date}
          onChange={handleChange}
          renderInput={(params) => <TextField id="selection-datepicker" {...params} />}
        />
      </ControlLabel>
    </Control>
  );
};

export default Date;
