import { styled } from '@mui/material/styles';
import { Typography } from '@mechis/elements';

export const Block = styled('div', {
  name: 'FormBlock',
  slot: 'Root',
})(() => ({
  
}));

export const Headline = styled(Typography, {
  name: 'FormBlock',
  slot: 'Headline',
})(({ theme }) => ({
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: 'fit-content',
  padding: theme.spacing(2),
  marginTop: theme.spacing(-7.5),
  background: theme.palette.background.default,
}));

