import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVehicleTypesByDeviceCategoryQuery } from '@state/mechis-backend/generated/schema';

const useSortedVehicleTypes = (deviceCategoryId: number) => {
  const { t } = useTranslation();
  const [ sortedVehicleTypes, setSortedVehicleTypes ] = useState<Array<{ __typename?: 'VehicleTypeDevice', id: string, name: string }>>([]);

  const {
    data: vehicleTypes,
    loading: vehicleTypesLoading,
    error: vehicleTypesError,
  } = useVehicleTypesByDeviceCategoryQuery({
    variables: { deviceCategoryId },
    onCompleted: (data) => {
      const sortedData = [ ...data.vehicleTypesByDeviceCategory ].sort((a, b) => {
        const translatedA = t(`vehicleTypes.${a.name}`);
        const translatedB = t(`vehicleTypes.${b.name}`);
        return translatedA.localeCompare(translatedB);
      });

      setSortedVehicleTypes(sortedData);
    },
  });

  return {
    sortedVehicleTypes,
    vehicleTypes,
    vehicleTypesLoading,
    vehicleTypesError,
  };
};

export default useSortedVehicleTypes;
