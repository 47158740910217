import React, { FC } from 'react';
import {
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
  Stack, Link,
} from '@mechis/elements';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { IFormInputs } from './types';
import { useNavigate } from 'react-router-dom';
import { useCreateUserMutation } from '@state/mechis-backend/generated/schema';
import FormWrapper from '@mechis/sections/FormWrapper';

const Registration: FC = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const forceRegister = urlParams.get('forceRegister');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ createUser, { loading: createUserInProgress } ] = useCreateUserMutation();
  const schema = yup.object({
    email: yup.string().email(t('registration.form.email.format'))
      .required(t('registration.form.email.required')),
    password: yup.string().required(t('registration.form.password.required')).min(8, t('registration.form.password.min')),
    repeatPassword: yup.string().required(t('registration.form.repeatPassword.required'))
      .oneOf([ yup.ref('password'), null ], t('registration.form.repeatPassword.noMatch')),
  }).required();

  const { control, handleSubmit } = useForm<IFormInputs>({
    defaultValues: {
      email: '',
      password: '',
      repeatPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email, password }: IFormInputs) => {
    try {
      await createUser({ variables: {user: {login: email, password, forceRegister: forceRegister ? true : false }} });
      navigate(`/registration-email-sended/${email}`);
    } catch (e) { 

    }
  };

  return (
    <div>
      <FormWrapper>
        <Typography 
          variant="h1" 
          sx={{ 
            color: (theme) => theme.palette.secondary.main,
            textAlign: 'center',
            marginBottom: (theme) => theme.spacing(4),
          }}
        >
          {t('registration.title')}
        </Typography>
        <Box component="form">
          <Stack spacing={4}>
            <Controller 
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  label={t('registration.form.email.name')}
                  variant="outlined"
                  type="email"
                  error={!!formState.errors.email?.message}
                  helperText={formState.errors.email?.message}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(onSubmit)();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              name="email"
              control={control}
            />
            <Controller 
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  label={t('registration.form.password.name')}
                  variant="outlined"
                  type="password"
                  error={!!formState.errors.password?.message}
                  helperText={formState.errors.password?.message}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(onSubmit)();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              name="password"
              control={control}
            />
            <Controller 
              render={({ field, formState }) => (
                <TextField
                  {...field}
                  label={t('registration.form.repeatPassword.name')}
                  variant="outlined"
                  type="password"
                  error={!!formState.errors.repeatPassword?.message}
                  helperText={formState.errors.repeatPassword?.message}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(onSubmit)();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              name="repeatPassword"
              control={control}
            />
          </Stack>
        </Box>
      </FormWrapper>
      <Button
        isLoading={createUserInProgress}
        variant="contained"
        size="large"
        color="secondary"
        onClick={handleSubmit(onSubmit)}
        sx={{
          margin: 'auto',
          mt: 3,
          display: 'block',
          minWidth: '251px',
        }}
      >
        {t('registration.form.submit')}
      </Button>
      <Link
        id="cy-forgotten-password-link"
        variant="body2"
        component="button"
        onClick={() => navigate('/')}
        sx={{
          margin: 'auto',
          mt: 2,
          display: 'block',
          minWidth: '251px',
        }}
      >
        {t('registration.form.loginLink')}
      </Link>
    </div>
  );
};

export default Registration;
