import { styled } from '@mui/material/styles';

export const Section = styled('div', {
  name: 'AppNavigation',
  slot: 'Root',
})(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
}));

export const Navigation = styled('nav', {
  name: 'AppNavigation',
  slot: 'Navigation',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: `${theme.radiuses.medium}px`,
  // todo: remove this afterwards
  // ...theme.mixins.backgroundGradientCustomizable(180),
  ...theme.mixins.bgGradientSolidVault,

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const NavigationHeader = styled('div', {
  name: 'AppNavigation',
  slot: 'NavigationHeader',
})(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const NavigationFooter = styled('div', {
  name: 'AppNavigation',
  slot: 'NavigationFooter',
})(({ theme }) => ({
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

