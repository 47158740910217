import { ReactiveVar } from '@apollo/client';
import newRefueling from '@state/models/newRefueling';
import { INewRefuelingModel, IFuelState } from '../models/newRefueling/types';

const getState = (newRefuelingModel: ReactiveVar<INewRefuelingModel>) => {
  return () => newRefuelingModel().state;
};

const setFuelTypeId = (newRefuelingModel: ReactiveVar<INewRefuelingModel>) => {
  return (fuelTypeId: string) => {
    const model = {
      ...newRefuelingModel(),
      fuelTypeId,
    };
    newRefuelingModel(model);
  };
};

const setState = (newRefuelingModel: ReactiveVar<INewRefuelingModel>) => {
  return (state: IFuelState[]) => {
    const model = {
      ...newRefuelingModel(),
      state,
    };
    newRefuelingModel(model);
  };
};

const setDescription = (newRefuelingModel: ReactiveVar<INewRefuelingModel>) => {
  return (description: string) => {
    const model = {
      ...newRefuelingModel(),
      description,
    };
    newRefuelingModel(model);
  };
};

const updateStateByFuel = (newRefuelingModel: ReactiveVar<INewRefuelingModel>) => {
  return (fuelTypeId: string, state: IFuelState) => {
    const newState = [ ...newRefuelingModel().state ];
    const newMappedState = newState.map((fuel) => {
      if (fuel.fuelTypeId === fuelTypeId) {
        return state;
      } else {
        return fuel;
      }
    });

    const model = {
      ...newRefuelingModel(),
      state: newMappedState,
    };
    newRefuelingModel(model);
  };
};

const newRefuelingCacheControl = {
  setFuelTypeId: setFuelTypeId(newRefueling),
  setState: setState(newRefueling),
  getState: getState(newRefueling),
  updateStateByFuel: updateStateByFuel(newRefueling),
  setDescription: setDescription(newRefueling),
};

export default newRefuelingCacheControl;
