import { Box, CircularProgress } from '@mechis/elements';
import React, { FC } from 'react';
import { IProps } from './types';

const Loader: FC<IProps> = ({size}) => {
  return (
    <Box sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <CircularProgress size={size ?? 80} />
    </Box>
  );
};

export default Loader;
