import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth, { INewPasswordInputs } from '@hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Typography,
  Box,
  Stack,
  TextField,
  InputAdornment,
  Button,
  Paper,
} from '@mechis/elements';
import FormWrapper from '@mechis/sections/FormWrapper';
import KeyIcon from '@mui/icons-material/Key';
import LockResetIcon from '@mui/icons-material/LockReset';

const PasswordReset = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = Object.fromEntries(new URLSearchParams(location.search));

  const {
    onSetNewPassword,
    setNewPasswordSchema,
    setNewPassword: {
      data,
      loading,
      error,
    },
  } = useAuth();

  useEffect(() => {
    if (!id) navigate('/');
  }, [ id ]);

  useEffect(() => {
    if (error) navigate('/');
  }, [ error ]);

  const { control, handleSubmit } = useForm<INewPasswordInputs>({
    defaultValues: {
      passwordTemplate: '',
      password: '',
      resetToken: id,
    },
    resolver: yupResolver(setNewPasswordSchema),
  });

  return (
    <div>
      <FormWrapper>
        <Typography
          variant="h1"
          sx={{ 
            color: (theme) => theme.palette.primary.dark,
            textAlign: 'center',
            marginBottom: (theme) => theme.spacing(4),
          }}
        >
          {t('passwordReset.title')}
        </Typography>
        {data && !loading && !error ? (
          <Paper variant="outlined">
            <Box p={2} textAlign="center">
              <LockResetIcon
                color="success"
                sx={{
                  fontSize: '4rem',
                  position: 'relative',
                  top: '-44px',
                }}
              />
              <Typography
                sx={{
                  marginTop: '-44px',
                }}
              >
                {t('passwordReset.form.successMsg')}
              </Typography>
            </Box>
          </Paper>
        ) : (
          <Box component="form">
            <Stack spacing={4}>
              <Controller
                render={({ field, formState }) => (
                  <TextField
                    id="cy-newPassword-passwordTemplate-input"
                    {...field}
                    label={t('passwordReset.form.passwordTemplate.name')}
                    type="password"
                    error={!!formState.errors.passwordTemplate?.message}
                    helperText={formState.errors.passwordTemplate?.message}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit(onSetNewPassword)();
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <KeyIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                name="passwordTemplate"
                control={control}
              />
              <Controller
                render={({ field, formState }) => (
                  <TextField
                    id="cy-newPassword-passwordT-input"
                    {...field}
                    label={t('passwordReset.form.password.name')}
                    type="password"
                    error={!!formState.errors.password?.message}
                    helperText={formState.errors.password?.message}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit(onSetNewPassword)();
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <KeyIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                name="password"
                control={control}
              />
            </Stack>
          </Box>
        )}
      </FormWrapper>
      {!(data && !loading && !error) && (
        <Button
          isLoading={loading}
          id="cy-passwordReset-confirm-btn"
          variant="contained"
          size="large"
          color="primary"
          onClick={handleSubmit(onSetNewPassword)}
          sx={{
            margin: 'auto',
            mt: 3,
            display: 'block',
            minWidth: '251px',
          }}
        > 
          {t('passwordReset.form.confirm')}
        </Button>
      )}
      {(data && !loading && !error) && (
        <Button
          id="cy-passwordReset-login-btn"
          variant="contained"
          size="large"
          color="primary"
          onClick={() => navigate('/')}
          sx={{
            margin: 'auto',
            mt: 3,
            display: 'block',
            minWidth: '251px',
          }}
        > 
          {t('passwordReset.form.redirectToLoginBtn')}
        </Button>
      )}
    </div>
  );
};

export default PasswordReset;
