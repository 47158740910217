import React from 'react';
import { Box, Typography, Stack } from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { IProps } from './types';
import Transaction from '@mechis/blocks/Transaction';

const TransactionsWidget: React.FC<IProps> = ({ transactions }) => {
  const { t } = useTranslation();

  return (
    <Box mt={2}>
      <Typography variant="sectionHeadline">
        {t('cloud.transactions.title')}
      </Typography>
      <Stack spacing={2} mt={2}>
        {transactions?.map((transaction, index) => {
          return (
            <Transaction
              key={index}
              date={transaction?.date}
              priceTotal={transaction?.value}
              eventId={4}
              isEven={index % 2 === 0}
              title={transaction?.description ?? ''}
              kilometersDifference={0}
              type={5}
              kilometers={0}
            />
          );
        })}
        {!transactions && (
          <Box>
            <Typography>{t('cloud.transactions.noTransactions')}</Typography>
          </Box>
        )}
      </Stack>
    </Box>

  );
};

export default TransactionsWidget;
