
import React, { useState, forwardRef } from 'react';
import { default as MuiTextField } from '@mui/material/TextField';
import { default as MuiInputAdornment } from '@mui/material/InputAdornment';
import { default as MuiDialog } from '@mui/material/Dialog';
import { default as MuiDialogContent } from '@mui/material/DialogContent';
import { default as MuiTypography } from '@mui/material/Typography';
import { default as MuiBox } from '@mui/material/Box';
import { default as MuiStack } from '@mui/material/Stack';
import { default as MuiButton } from '@mui/material/Button';
import BoltIcon from '@mui/icons-material/Bolt';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { TextFieldProps, TextFieldUnit } from './types';

const TextField = forwardRef<any, TextFieldProps>((props, ref) => {
  const {
    color = 'primary',
    variant = 'outlined',
    units,
    onUnitChange,
    unitIcon,
    ...other
  } = props;

  const [ isOpen, setIsOpen ] = useState<boolean>(false);

  const activeItems = units?.filter((unit) => unit.isSelected === true);
  const activeItem = activeItems?.[0].name;

  return (
    <>
      {units && onUnitChange && (
        <MuiDialog
          onClose={() => setIsOpen(false)}
          open={isOpen}
          sx={{
            maxWidth: '320px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <MuiBox p={2}>
            <MuiDialogContent>
              <MuiStack
                textAlign="center"
                spacing={2}
                sx={{
                  maxWidth: '120px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <MuiTypography variant="sectionHeadline">
                  Vyber jednotku
                </MuiTypography>
                {units.map((unit: TextFieldUnit) => (
                  <MuiButton 
                    key={unit.name} 
                    variant={unit.isSelected ? 'contained' : 'outlined'}
                    onClick={() => {
                      onUnitChange(unit.id);
                      setIsOpen(false);
                    }}
                  >
                    {unit.name}
                  </MuiButton>
                ))}
              </MuiStack>
            </MuiDialogContent>
          </MuiBox>
        </MuiDialog>
      )}
      <MuiTextField
        ref={ref}
        color={color}
        variant={variant}
        InputProps={units && {
          endAdornment: (
            <MuiInputAdornment
              position="end"
              onClick={() => setIsOpen(true)}
            >
              <small>
                {activeItem}
                <ExpandCircleDownIcon
                  fontSize="small"
                  sx={{
                    position: 'relative',
                    top: '5px',
                    marginRight: '-8px',
                    marginLeft: '2px',
                    opacity: '0.6',
                  }}
                />
              </small>
            </MuiInputAdornment>
          ),
          startAdornment: (
            <MuiInputAdornment position="start">
              {unitIcon ?? <BoltIcon fontSize="small" />}
            </MuiInputAdornment>
          ),
        }}
        {...other}
      />
    </>
  );
});

TextField.displayName = 'TextField';

export default TextField;
