import React, { FC } from 'react';
import { IProps } from './types';
import { InputAdornment } from '@mechis/elements';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  blurTarget, 
  ensureNumericZeroIsNotDisplayed,
} from '@utilities/app';
import useIcon from '@mechis/icons/useIcon';
import { maximumDisplacement } from '@configurations/constants/inputConstraints';
import {useAddDeviceSetValidationError} from '@hooks/validationHooks/useAddDeviceSetValidatonError';
import NumberField from '@mechis/elements/NumberField';

const inputIdentifier = 'engineDisplacementCc';
const EngineDisplacementCc: FC<IProps> = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { setEngineDisplacementCc } = addDeviceCacheControl;

  const schema = yup.object({
    engineDisplacementCc: yup
      .number()
      .transform((value, originalValue) => originalValue === '' ? null : value)
      .nullable()
      .max(maximumDisplacement, `${t('addDevice.inputValidation.maxValue') + maximumDisplacement}`),
  });

  const { control, handleSubmit, formState: { errors }} = useForm<{ engineDisplacementCc: number | null }>({
    defaultValues: {
      engineDisplacementCc: ensureNumericZeroIsNotDisplayed(data?.addDevice.engineDisplacementCc),
    },
    resolver: yupResolver(schema),
  });

  useAddDeviceSetValidationError(inputIdentifier, errors.engineDisplacementCc?.message);

  const onSave = async ({ engineDisplacementCc }: { engineDisplacementCc: number | null }) => {
    setEngineDisplacementCc(engineDisplacementCc);
  };

  return (
    <Controller
      render={({ field, formState }) => (
        <NumberField
          {...field}
          onKeyUp={() => {
            handleSubmit(onSave)();
          }}
          onWheel={blurTarget}
          label={t('addDevice.components.engineDisplacementCc.name')}
          id={inputIdentifier}
          error={!!formState.errors.engineDisplacementCc?.message}
          helperText={formState.errors.engineDisplacementCc?.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {useIcon('mechisEngineDisplacement', { fontSize: 'small' })}
              </InputAdornment>
            ),
            endAdornment: <InputAdornment position="end"><small>cm³</small></InputAdornment>,
          }}
          variant="outlined"
        />
      )}
      name="engineDisplacementCc"
      control={control}
    />
  );
};

export default EngineDisplacementCc;
