import { Box, Button, Dialog, DialogContent, Stack } from '@mechis/elements';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IProps } from './types';

export const ConfirmationModal: FC<IProps> = ({ onClose, onConfirm, isOpen, content, confirmButtonText, cancelButtonText }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
      maxWidth="xs"
    >
      <Box p={2} textAlign="center">
        <DialogContent>
          <Stack spacing={2} direction={'column'}>
            <Box>
              {content}
            </Box>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="space-around"
            >
              <Button
                onClick={onClose}
                variant="outlined"
              >
                {cancelButtonText ? cancelButtonText : t('newMaintenance.button.cancel')}
              </Button>
              <Button
                onClick={onConfirm}
                variant="contained"
                color="error"
              >
                {confirmButtonText ? confirmButtonText : t('newMaintenance.button.save')}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ConfirmationModal;
