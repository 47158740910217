import { ReactiveVar } from '@apollo/client';
import newMaintenance from '@state/models/newMaintenance';
import { INewMaintenanceModel } from '../models/newMaintenance/types';

const setOperations = (newMaintenanceModel: ReactiveVar<INewMaintenanceModel>) => {
  return (operations: number[]) => {
    const model = {
      ...newMaintenanceModel(),
      operations,
    };
    newMaintenanceModel(model);
  };
};

const setExpanded = (newMaintenanceModel: ReactiveVar<INewMaintenanceModel>) => {
  return (expanded: string[]) => {
    const model = {
      ...newMaintenanceModel(),
      expanded,
    };
    newMaintenanceModel(model);
  };
};

const setDescription = (newMaintenanceModel: ReactiveVar<INewMaintenanceModel>) => {
  return (description: string) => {
    const model = {
      ...newMaintenanceModel(),
      description,
    };
    newMaintenanceModel(model);
  };
};

const newMaintenanceCacheControl = {
  setOperations: setOperations(newMaintenance),
  setExpanded: setExpanded(newMaintenance),
  setDescription: setDescription(newMaintenance),
};

export default newMaintenanceCacheControl;
