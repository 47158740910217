import { Attachment } from '@state/mechis-backend/generated/schema';

// eslint-disable-next-line
export enum CloudinaryWidgetSources {
  Local = 'local',
  Url = 'url'
}

export interface ICloudinaryWidgetConfiguration {
  readonly cloudName: string
  readonly uploadPreset: string
  readonly multiple: boolean
  readonly cropping: boolean
  readonly showAdvancedOptions: boolean
  readonly sources: Array<CloudinaryWidgetSources>
  readonly folder: string
  readonly maxImageFileSize: number
  readonly maxImageWidth: number
  readonly theme: 'white'
}

export interface IProps {
  readonly attachmentData?: Array<Partial<Attachment>>
  linkingCallback?: (attachmentId: number) => void // mutation to link attachment to device/event
  readonly setCloudinaryAttachmentInfo: (info: Partial<Attachment>) => void
  readonly cloudinaryConfiguration: ICloudinaryWidgetConfiguration
  readonly children: any
}
