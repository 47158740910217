import React from 'react';
import {
  Typography,
} from '@mechis/elements';
import useHeader from '@hooks/useHeader';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '@mechis/blocks/LanguageSwitcher';

const Settings = () => {
  useHeader({ headline: 'appBar.headline.settings' });
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h1">
        {t('settings.title')}
      </Typography>
      <LanguageSwitcher />
    </>
  );
};

export default Settings;
