import React, { FC } from 'react';
import { IProps } from './types';
import { Block, Divider } from './styles';

const RecordList: FC<IProps> = ({ children }) => {
  return (
    <Block spacing={2}>
      <Divider />
      {children}
    </Block>
  );
};

export default RecordList;
