import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mechis/elements';

const MechisHydrogen: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 3000 3000" {...props}>
      {/* eslint-disable-next-line */}
      <path d="M2365.6,732.6l-155.2-155.2c-26.4-26.4-69.1-26.4-95.5,0c-26.4,26.4-26.4,69.1,0,95.5l48,48c-1.1,2.7-1.8,5.6-1.8,8.7V897
	c0,6,2.4,11.7,6.6,15.9l105.1,105.1v887.2c0,52.8-42.9,95.7-95.7,95.7c-52.8,0-95.7-42.9-95.7-95.7v-463.4
	c0-102.4-83.3-185.7-185.7-185.7h-46.5V619c0-121.7-98.6-220.3-220.3-220.3H953.8c-121.7,0-220.3,98.6-220.3,220.3v1760.5h-48.5
	c-38.9,0-70.5,31.6-70.5,70.5v80.8c0,38.9,31.6,70.5,70.5,70.5h1212.7c38.9,0,70.5-31.6,70.5-70.5V2450c0-38.9-31.6-70.5-70.5-70.5
	h-48.4V1346.1h46.5c52.8,0,95.7,42.9,95.7,95.7v463.4c0,102.4,83.3,185.7,185.7,185.7c102.4,0,185.7-83.3,185.7-185.7V992.8
	c13.8-12.4,22.5-30.3,22.5-50.3V780.4C2385.4,762.5,2378.3,745.3,2365.6,732.6z M2250.3,931.8l-44.2-44.2V764.1l44.2,44.2V931.8z
	 M1307.8,1804.2h-64.7v-225.7H997v225.7h-64.4V1316H997v209.9h246.1V1316h64.7V1804.2z M1650.3,1972.8h-251.1v-35l132.6-147.4
	c19.7-22.3,33.2-40.4,40.7-54.3c7.5-13.9,11.2-28.4,11.2-43.3c0-20-6.1-36.4-18.2-49.2c-12.1-12.8-28.2-19.2-48.4-19.2
	c-24.2,0-43,6.9-56.4,20.6c-13.4,13.8-20.1,32.9-20.1,57.5h-48.7c0-35.3,11.4-63.8,34.1-85.5c22.7-21.7,53.1-32.6,91.2-32.6
	c35.6,0,63.8,9.3,84.5,28c20.7,18.7,31.1,43.6,31.1,74.6c0,37.7-24.1,82.6-72.1,134.7l-102.6,111.3h192.4V1972.8z M1650.3,974.9
	c0,52.2-42.3,94.5-94.4,94.5h-528.8c-52.1,0-94.4-42.3-94.4-94.5V704.6c0-52.2,42.3-94.4,94.4-94.4h528.8
	c52.1,0,94.4,42.3,94.4,94.4V974.9z"/>
    </SvgIcon>
  );
};

export default MechisHydrogen;
