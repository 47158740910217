import React, { FC } from 'react';
import useAuth from '@hooks/useAuth';
import {useNavigate, useParams} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Typography,
  Box,
  TextField,
  Button,
  InputAdornment,
  Stack,
  Paper,
} from '@mechis/elements';
import FormWrapper from '@mechis/sections/FormWrapper';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

const ForgottenPassword: FC = () => {
  const { t } = useTranslation();
  const { email } = useParams();
  const navigate = useNavigate();

  const {
    onPasswordReset,
    passwordResetSchema,
    passwordReset: {
      data,
      loading,
      error,
    },
  } = useAuth();

  const { control, handleSubmit } = useForm<{ email: string }>({
    defaultValues: { email },
    resolver: yupResolver(passwordResetSchema),
  });

  return (
    <div>
      <FormWrapper>
        <Typography
          variant="h1"
          sx={{ 
            color: (theme) => theme.palette.primary.dark,
            textAlign: 'center',
            marginBottom: (theme) => theme.spacing(4),
          }}
        >
          {t('forgottenPassword.title')}
        </Typography>
        {(data && !loading && !error) ? (
          <Paper variant="outlined">
            <Box p={2} textAlign="center">
              <MarkEmailReadIcon
                color="success"
                sx={{
                  fontSize: '4rem',
                  position: 'relative',
                  top: '-44px',
                }}
              />
              <Typography
                sx={{
                  marginTop: '-44px',
                }}
              >
                {t('forgottenPassword.success.prefix')} <br /><br />
                <strong><em>{control._fields.email?._f.value}</em></strong> <br /><br />
                {t('forgottenPassword.success.suffix')}
              </Typography>
            </Box>
          </Paper>
        ) : (
          <Box component="form">
            <Stack spacing={4}>
              <Controller
                render={({ field, formState }) => (
                  <TextField
                    id="cy-forgottenPassword-email-input"
                    {...field}
                    label={t('forgottenPassword.form.email.name')}
                    type="email"
                    error={!!formState.errors.email?.message}
                    helperText={formState.errors.email?.message}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit(onPasswordReset)();
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                name="email"
                control={control}
              />
            </Stack>
          </Box>
        )}
      </FormWrapper>
      {(data && !loading && !error) && (
        <Box mt={3} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={() => navigate('/')}>{t('forgottenPassword.redirectToRoot')}</Button>
        </Box>
      )}
      {!data && (
        <Button
          isLoading={loading}
          id="cy-forgottenPassword-btn"
          variant="contained"
          size="large"
          color="primary"
          onClick={handleSubmit(onPasswordReset)}
          sx={{
            margin: 'auto',
            mt: 3,
            display: 'block',
            minWidth: '251px',
          }}
        > 
          {t('forgottenPassword.form.confirm')}
        </Button>
      )}

    </div>
  );
};

export default ForgottenPassword;
