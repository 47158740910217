import React, { FC } from 'react';
import {
  Stack,
  IconButton,
  Typography,
} from '@mechis/elements';
import { IProps } from './types';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SpeedIcon from '@mui/icons-material/Speed';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const StatCounter: FC<IProps> = ({ headline, subHeadline, type }) => {
  const getIconButton = () => {
    switch (type) {
      case 'recordsNumber':
        return (
          <IconButton color="primary" size="large" isSquared>
            <ReceiptLongIcon fontSize="medium" />
          </IconButton>
        );
      case 'date':
        return (
          <IconButton color="secondary" size="medium" isSquared>
            <CalendarMonthIcon fontSize="small" />
          </IconButton>
        );
      case 'odometer':
        return (
          <IconButton color="secondary" size="medium" isSquared>
            <SpeedIcon fontSize="small" />
          </IconButton>
        );
      default: return <></>;
    }
  };

  return (
    <Stack
      direction={headline ? 'column' : 'row'}
      alignItems="center"
      spacing={headline ? 0 : 1.5}
    >
      {getIconButton()}
      <Stack
        sx={{ minHeight: '50px' }}
        justifyContent="center"
        alignItems="center"
      >
        {headline && (
          <Typography variant="h3" sx={{ mb: -1 }}>{headline}</Typography>
        )}
        {typeof subHeadline === 'string' ? <Typography><small>{subHeadline}</small></Typography> : subHeadline}
      </Stack>
    </Stack>
  );
};

export default StatCounter;
