import React, { FC } from 'react';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import ControlMenu from '../ControlMenu';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Stack,
} from '@mechis/elements';
import { useNavigate } from 'react-router-dom';

const ChangeCurrency: FC = () => {
  useHeader({
    headline: 'appBar.headline.changeCurrency',
    widget: (
      <Selections>
        <ControlMenu />
      </Selections>
    ),
  });

  const navigate = useNavigate();

  return (
    <Stack spacing={2}>
      <FormControl sx={{
        border: '2px dashed lightgrey',
        p: 4,
        width: '100%',
        borderRadius: '12px',
      }}>
        <FormLabel id="demo-radio-buttons-group-label">Vyber měnu</FormLabel>
        <RadioGroup defaultValue="CZK" sx={{ mt:2 }}>
          <FormControlLabel value="CZK" control={<Radio />} label="CZK" />
          {/* <FormControlLabel value="USD" control={<Radio />} label="USD" /> */}
        </RadioGroup>
      </FormControl>
      <Button color="secondary" variant="contained" sx={{ alignSelf: 'center' }} onClick={() => navigate(-1)}>Potvrdit</Button>
    </Stack>
  );
};

export default ChangeCurrency;
