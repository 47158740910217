import React, { forwardRef } from 'react';
import { default as MuiAppBar } from '@mui/material/AppBar';
import { AppBarProps } from './types';

const AppBar = forwardRef<any, AppBarProps>((props, ref) => {
  return <MuiAppBar {...props as any} ref={ref} />;
});

AppBar.displayName = 'AppBar';

export default AppBar;
