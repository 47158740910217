import React, { useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  useLogoutUserMutation,
  useLoginUserMutation,
  useUserLazyQuery,
  useResetPasswordMutation,
  useSetNewPasswordMutation,
  useDevicesLazyQuery,
  Role,
} from '@state/mechis-backend/generated/schema';
import appCacheControl from '@state/mutations/app';
import { useLocalStorage } from 'react-use';
import { ACCESS_TOKEN, DEVICE_ID, USER_ID } from '@configurations/constants/app';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Stack,
} from '@mechis/elements';
import client from '@state/client';
import { compareDesc } from 'date-fns';
import { USER_ROLE_COMGATE } from '@configurations/constants/userRole';
import {init} from '@state/models/snackbar';
import snackbarCacheControl from '@state/mutations/snackbar';

export interface ILoginInputs {
  email: string
  password: string
  extendedExpiration?: boolean
}
export interface INewPasswordInputs {
  passwordTemplate: string
  password: string
  resetToken: string
}

const useAuth = () => {
  const { t } = useTranslation();
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
  const [ login, { loading: loginLoading } ] = useLoginUserMutation();
  const [ logout ] = useLogoutUserMutation();
  const [ , setAccessToken ] = useLocalStorage(ACCESS_TOKEN, '');
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  const [ userId, setUserId ] = useLocalStorage(USER_ID, '');
  const [ , setUserRole ] = useState<Role | null>(null);
  const navigate = useNavigate();
  const { setIsMenuOpened } = appCacheControl;
  const { setSnackbar } = snackbarCacheControl;
  const [ passwordReset, {
    data: passwordResetData,
    loading: passwordResetLoading,
    error: passwordResetError,
  } ] = useResetPasswordMutation();
  const [ setNewPassword, {
    data: setNewPasswordData,
    loading: setNewPasswordLoading,
    error: setNewPasswordError,
  } ] = useSetNewPasswordMutation();
  const [ getDevices ] = useDevicesLazyQuery();

  const LogoutConfirmModal = () => {
    return (
      <Dialog
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        maxWidth="xs"
      >
        <Box p={2} textAlign="center">
          <DialogTitle sx={{
            fontSize: '1rem !important',
            maxWidth: '230px',
            marginLeft: 'auto',
            marginRight: 'auto',
            color: (theme) => theme.palette.primary.main,
          }}
          >
            {t('logoutModal.title')}
          </DialogTitle>
          <DialogContent>
            <Stack
              spacing={2}
              direction="column-reverse"
              justifyContent="space-around"
              alignItems="center"
            >
              <Button
                onClick={() => setIsModalOpen(false)}
                variant="text"
                size="small"
              >
                {t('logoutModal.cancel')}
              </Button>
              <Button
                id="cy-logout-confirm"
                onClick={logOut}
                variant="contained"
                color="error"
              >
                {t('logoutModal.logout')}
              </Button>
            </Stack>
          </DialogContent>
        </Box>
      </Dialog>
    );
  };

  const navigateToLatestEditedTech = async () => {
    const result = await getDevices();
    getDevices().then(() => {
      const devicesCopy = [ ...result.data?.devices || [] ];
      const sortedDevices = devicesCopy
        .sort((a, b) => compareDesc(new Date(a.lastEditedDate ?? ''), new Date(b.lastEditedDate ?? '')));

      if (sortedDevices.length > 0) {
        navigate(`/app/${sortedDevices[0].id}/tech-detail`);
      } else {
        navigate('/app');
      }
    });
  };

  const loginFormSchema = yup.object({
    email: yup.string().email(t('login.form.email.format'))
      .required(t('login.form.email.required')),
    password: yup.string().required(t('login.form.password.required')),
  }).required();

  const onLogin = async ({ email, password, extendedExpiration }: ILoginInputs) => {
    try {
      await login({
        variables: {
          user: {
            login: email,
            password,
            extendedExpiration,
          },
        },
      }).then((result) => {
        if (result.data !== null && result.data !== undefined) {
          // Reset state to initial value to prevent unwanted snackbar pop up
          setSnackbar(init.severity, init.messageCode ?? '');

          setUserId(result.data.loginUser?.user.id);
          setAccessToken(result.data.loginUser?.accessToken ?? '');
          setUserRole(result.data.loginUser?.user.role || null);
          navigateToLatestEditedTech();
        }
      });
    } catch (e) { /* The error will take effect in the error variable from the useLoginUserMutation. */ }
  };

  const logOut = async () => {
    if (userId) {
      await logout({ variables: { logoutUserId: +userId } });
      await client.clearStore();
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(USER_ID);
      localStorage.removeItem(DEVICE_ID);

      // Reset state to initial value to prevent unwanted snackbar pop up
      setSnackbar(init.severity, init.messageCode ?? '');

      setIsMenuOpened(false);
      navigate('/');
    }
  };

  const onLogout = () => {
    setIsModalOpen(true);
  };

  const [ loadUser, { called, loading, data, error } ] = useUserLazyQuery({
    variables: {
      userId: userId ? +userId : 0,
    },
  });

  if ((accessToken && userId) && !called) {
    loadUser();
  }

  const passwordResetSchema = yup.object({
    email: yup.string().email(t('forgottenPassword.form.email.format')).required(t('forgottenPassword.form.email.required')),
  }).required();

  const onPasswordReset = async ({ email }: { email: string }) => {
    await passwordReset({ variables: { email } });
    return null;
  };

  const setNewPasswordSchema = yup.object({
    passwordTemplate: yup.string().required(t('passwordReset.form.passwordTemplate.required')),
    password: yup.string().required(t('passwordReset.form.password.required'))
      .oneOf([ yup.ref('passwordTemplate'), null ], t('passwordReset.form.password.noMatch')),
    resetToken: yup.string().required(''),
  }).required();

  const onSetNewPassword = async (newPassword: INewPasswordInputs) => {
    const { password, resetToken } = newPassword;
    await setNewPassword({
      variables: {
        resetPassword: {
          password,
          resetToken,
        },
      },
    });
    return null;
  };

  const isComgateUser = (): boolean => {
    return data?.user?.role?.name === USER_ROLE_COMGATE;
  };

  return {
    isLoggedIn: !!(called && !loading && !error && data),
    isLoggedInLoading: loading,
    isComgateUser,
    onLogin,
    loginLoading,
    onLogout,
    logOut,
    loginFormSchema,
    user: data?.user,
    isDeviceCountExceeded: data?.user?.isDeviceCountExceeded,
    membership: data?.user?.membership,
    LogoutConfirmModal,
    passwordResetSchema,
    onPasswordReset,
    passwordReset: {
      data: passwordResetData,
      loading: passwordResetLoading,
      error: passwordResetError,
    },
    setNewPasswordSchema,
    onSetNewPassword,
    setNewPassword: {
      data: setNewPasswordData,
      loading: setNewPasswordLoading,
      error: setNewPasswordError,
    },
  };
};

export default useAuth;
