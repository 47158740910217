import React, { FC, useState, useEffect } from 'react';
import { IDescriptionProps } from './types';
import {
  BasicInfoAboutEventDocument,
  useBasicInfoAboutEventQuery,
  useUpdateEventDescriptionMutation,
} from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
} from '@mechis/elements';
import { useTranslation } from 'react-i18next';

const Description: FC<IDescriptionProps> = ({ id }) => {
  const [ mode, setMode ] = useState<'preview' | 'edit'>('preview');
  const [ description, setDescription ] = useState('');
  const { setSnackbar } = snackbarCacheControl;
  const { t } = useTranslation();

  const { data, loading, error } = useBasicInfoAboutEventQuery({
    variables: {
      eventId: Number(id),
    },
  });

  const [ updateEventDescriptionMutation ] = useUpdateEventDescriptionMutation({
    onCompleted: () => setSnackbar('success', 'EVENT_UPDATE_SUCCESSFUL'),
    onError: () => setSnackbar('error','EVENT_UPDATE_ERROR'),
    refetchQueries: [
      {
        query: BasicInfoAboutEventDocument,
        variables: {
          eventId: Number(id),
        },
      },
    ],
  });

  useEffect(() => {
    if (data && data.event) {
      setDescription(data.event.description as string);
    }
  }, [ data ]);

  const onUpdate = async () => {
    await updateEventDescriptionMutation({
      variables: {
        event: {
          id: Number(id),
          description,
        },
      },
    });
    setMode('preview');
  };

  if (loading) return <>loading</>;

  if (error) return <>error</>;

  return (
    <Box mt={2}>
      <Typography variant="sectionHeadline">{t('eventDetail.description.title')}</Typography>
      <Paper>
        <Box p={2}>
          {mode === 'preview' &&
              <Typography>{(description?.length === 0 || !description) ? t('eventDetail.description.emptyState') : description}</Typography>
          }
          {mode === 'edit' && (
            <TextField
              multiline
              rows={4}
              variant="outlined"
              defaultValue={data?.event?.description ?? ''}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          )}
        </Box>
      </Paper>
      {mode === 'preview' && (
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => setMode('edit')}
          sx={{ float: 'right', display: 'block', mt: -2 }}
        >
          {t('eventDetail.description.edit')}
        </Button>
      )}
      {mode === 'edit' && (
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={onUpdate}
          sx={{ float: 'right', display: 'block', mt: -2 }}
        >
          {t('eventDetail.description.save')}
        </Button>
      )}
    </Box>
  );
};

export default Description;

