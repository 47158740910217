import { ReactiveVar, makeVar } from '@apollo/client';
import { INewRefuelingModel } from './types';

export const init: INewRefuelingModel = {
  fuelTypeId: '',
  state: [ {
    fuelTypeId: '',
    fullTank: true,
    skipTank: false,
  } ],
  description: '',
};

const newRefueling: ReactiveVar<INewRefuelingModel> = makeVar<INewRefuelingModel>(init);

export default newRefueling;
