import { Z_INDEX_BOTTOM_NAVIGATION } from '@configurations/constants/zIndexOverloads';
import palette from '@configurations/themes/default/palette';

const styleOverrides = {
  root: {
    // todo: webpack error for spacing here
    // todo: fix 32px to be taken from theme.spacing(2)*2 cause from both sides
    width: 'calc(100% - 32px)',
    position: 'fixed',
    bottom: 0,
    // todo: theme.spacing(2) = 16px
    left: '16px',
    zIndex: Z_INDEX_BOTTOM_NAVIGATION,
    background: palette.background.default,
  },
};

export default styleOverrides;
