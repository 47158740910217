import { useOdometerUnitsQuery, usePowerUnitsQuery } from '@state/mechis-backend/generated/schema';

const useUnits = () => {
  const { data: powerUnitsData, loading: powerUnitsLoading } = usePowerUnitsQuery();
  const { data: odometerUnitsData, loading: odometerUnitsLoading } = useOdometerUnitsQuery();

  const getPowerUnitById = (id: number) => {
    const powerUnit = powerUnitsData?.powerUnits.find((unit) => Number(unit.id) === id);
    
    return powerUnit?.unitName || '';
  };

  const getPowerUnitIdByName = (name: string): number => {
    const powerUnit = powerUnitsData?.powerUnits.find((unit) => unit.unitName === name);
    
    return powerUnit?.id ? Number(powerUnit.id) : 0;
  };

  const getOdometerUnitById = (id: number) => {
    const odometerUnit = odometerUnitsData?.odometerUnits.find((unit) => Number(unit.id) === id);
    
    return odometerUnit?.unitName || '';
  };

  const getOdometerUnitIdByName = (name: string): number => {
    const odometerUnit = odometerUnitsData?.odometerUnits.find((unit) => unit.unitName === name);
    
    return odometerUnit?.id ? Number(odometerUnit.id) : 0;
  };

  return {
    powerUnitsData,
    powerUnitsLoading,
    getPowerUnitById,
    getPowerUnitIdByName,
    odometerUnitsData,
    odometerUnitsLoading,
    getOdometerUnitById,
    getOdometerUnitIdByName,
  };
};

export default useUnits;
