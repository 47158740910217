import React, { FC } from 'react';
import { IOperationProps } from '../types';
import { OperationContainer } from '../styles';
import {
  Stack,
  Typography,
} from '@mechis/elements';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import { getColorOfChip } from '../utils';
import { calculateDueDate } from '@utilities/app';
import { THOUSAND_SEPARATOR } from '@configurations/constants/app';
import { strSign } from '@utilities/formatters';
import NumberFormat from 'react-number-format';
import { DAYS_IN_MONTH } from '@configurations/constants/app';
import useIcon from '@mechis/icons/useIcon';
import { CommonIcons } from '@mechis/icons/types';
import useTech, { UnitType } from '@hooks/useTech';

const Operation: FC<IOperationProps> = ({ 
  periodEndDate,
  dueDistance,
  name,
  children,
  isLast = false,
  description,
  periodDay,
  periodDistance,
  type,
}) => {
  const { getTechUnit } = useTech();

  const resolveType = (): CommonIcons => {
    switch (type.name) {
      case 'check': return 'mechisCheck';
      case 'replacement': return 'mechisReplacement';
      case 'adjustment': return 'mechisAdjustment';
      case 'top up': return 'mechisAddition';
      case 'revision': return 'mechisRevision';
      case 'calibration': return 'mechisCalibration';
      case 'other': return 'settings';
      default: return 'mechisCalibration';
    }
  };

  return (
    <OperationContainer direction="row" className={isLast ? 'disable-line' : undefined}>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Stack direction="row" spacing={1}>
          {(periodDay || periodDay !== 0) && (!periodEndDate && !dueDistance) && (
            <Stack direction="row" spacing={0.5} alignItems="center" >
              <CalendarMonthIcon sx={{ fontSize: '1em' }} color="primary" />
              <Typography color="primary" sx={{ fontSize: '0.7em' }}>
                <NumberFormat
                  value={Math.ceil((periodDay as number) / DAYS_IN_MONTH)}
                  suffix=" měsíců"
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                />
              </Typography>
            </Stack>
          )}
          {(periodDistance || periodDistance) !== 0 && (!periodEndDate && !dueDistance) && (
            <Stack direction="row" spacing={0.5} alignItems="center" >
              <SpeedIcon sx={{ fontSize: '1em' }} color="secondary" />
              <Typography color="secondary" sx={{ fontSize: '0.7em' }}>
                <NumberFormat
                  value={periodDistance}
                  suffix={getTechUnit(UnitType.Odometer, true)}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                />
              </Typography>
            </Stack>
          )}
          {(periodEndDate && periodEndDate !== 0) && (!periodDay && !periodDistance) && (
            <Stack direction="row" spacing={0.5} alignItems="center" >
              <CalendarMonthIcon sx={{ fontSize: '1em' }} color={getColorOfChip(calculateDueDate(periodEndDate))} />
              <Typography 
                sx={{
                  color: (theme) => 
                    getColorOfChip(calculateDueDate(periodEndDate)) === 'error' ? 
                      theme.palette.error.main : 
                      theme.palette.success.main,
                  fontSize: '0.7em',
                }}
                color="primary"
              >
                <NumberFormat
                  prefix={`${strSign(calculateDueDate(periodEndDate))}`}
                  value={Math.abs(calculateDueDate(periodEndDate))}
                  suffix=" dnů"
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                />
              </Typography>
            </Stack>
          )}
          {(dueDistance || dueDistance !== 0) && (!periodDay && !periodDistance) && (
            <Stack direction="row" spacing={0.5} alignItems="center" >
              <SpeedIcon sx={{ fontSize: '1em' }} color={getColorOfChip(dueDistance || 0)} />
              <Typography 
                sx={{
                  color: (theme) => 
                    getColorOfChip(dueDistance || 0) === 'error' ? 
                      theme.palette.error.main : 
                      theme.palette.success.main,
                  fontSize: '0.7em',
                }}
                color={getColorOfChip(dueDistance || 0)}
              >
                <NumberFormat
                  prefix={`${strSign(dueDistance || 0)}`}
                  value={Math.abs(dueDistance || 0)}
                  suffix={getTechUnit(UnitType.Odometer, true)}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                />
              </Typography>
            </Stack>
          )}
        </Stack>
        <Typography 
          sx={{ 
            fontSize: '0.8em',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '4px',
          }}
        >
          {useIcon(resolveType(), {
            sx: {
              marginRight: '4px',
              fontSize: '1.25em',
            },
          })} {name}
        </Typography>
        <Typography sx={{ fontSize: '0.65em', fontStyle: 'italic', opacity: .75 }}>
          {description || 'Bez popisku'}
        </Typography>
      </Stack>
      <div>
        {children}
      </div>
    </OperationContainer>
  );
};

export default Operation;
