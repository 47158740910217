import React, { FC } from 'react';
import { IProps } from './types';
import FlashMessage from '@mechis/blocks/FlashMessage/FlashMessage';

const RootLayout: FC<IProps> = ({ children }) => {
  return (
    <>
      {children}
      <FlashMessage />
    </>
  );
};

export default RootLayout;
