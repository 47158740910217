import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mechis/elements';

const MechisRevision: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <g>
        <path className="st0" d="M469.797,93.801c0-8.828-1.453-17.5-4.625-25.625c-2.391-6.063-5.734-11.781-10.063-16.875
		c-6.5-7.656-15.172-13.781-25.516-17.828c-10.344-4.063-22.328-6.125-35.875-6.125v26.594c9.641,0,17.531,1.313,23.859,3.453
		c9.484,3.25,15.406,8.078,19.438,14.031c4,5.953,6.156,13.469,6.188,22.375c0.016,11.594-3.828,25.359-11.609,38.828
		c-5.141,8.953-12.328,20.094-20.469,32.094l-6.297-4.25v-0.016c-15.844,23.359-35.563,50.281-52.063,72.531
		c-8.25,11.156-15.688,21.141-21.516,29.016c-2.906,3.938-5.422,7.359-7.438,10.203c-1.031,1.406-1.922,2.688-2.719,3.844
		s-1.469,2.156-2.172,3.297c-0.875,1.438-2.453,3.469-4.563,5.516c-3.188,3.094-7.547,6.266-12.734,8.531
		c-5.203,2.281-11.172,3.75-18.109,3.75c-9.219,0-16.828-2.609-23-6.266c-3.078-1.844-5.734-3.938-7.844-6.016
		c-2.125-2.047-3.688-4.078-4.563-5.516c-0.703-1.141-1.375-2.141-2.172-3.297c-1.5-2.172-3.359-4.766-5.641-7.906
		c-7.969-10.906-20.906-28.141-35.203-47.547c-14.281-19.422-29.906-41-42.891-60.141l-6.297,4.266
		c-8.156-12.016-15.313-23.125-20.484-32.094c-7.75-13.469-11.609-27.234-11.594-38.828c0-5.938,0.984-11.266,2.797-15.906
		c1.359-3.484,3.203-6.578,5.563-9.359c3.547-4.141,8.281-7.656,14.984-10.297c6.656-2.625,15.281-4.297,26.141-4.297V27.348
		c-12.063,0-22.859,1.625-32.375,4.859c-14.266,4.766-25.672,13.375-33.031,24.391c-7.375,11-10.672,23.984-10.672,37.203
		c0.016,17.297,5.453,35.219,15.141,52.109c5.688,9.875,13.156,21.422,21.516,33.766l-6.297,4.266
		c16.516,24.297,36.453,51.5,53.078,73.953c8.313,11.203,15.75,21.188,21.469,28.938c2.844,3.859,5.281,7.156,7.109,9.719
		c0.906,1.266,1.672,2.328,2.234,3.188c0.563,0.828,0.969,1.438,1.031,1.531c2.922,4.75,6.594,9.25,11,13.547
		c6.609,6.453,14.969,12.391,25.078,16.844c4.344,1.922,9.047,3.516,14.016,4.734v79.594c0,7.281-1.594,14.094-4.531,20.453
		c-4.375,9.516-11.953,17.969-21.797,24.031c-9.828,6.078-21.844,9.734-34.984,9.734c-17.516,0-33.063-6.5-44.031-16.531
		c-5.484-5.031-9.813-10.891-12.75-17.234c-2.938-6.359-4.531-13.172-4.531-20.453v-0.438c12.234-3.656,23.203-10.266,31.969-19.047
		c13.734-13.719,22.266-32.813,22.25-53.734c0.016-20.969-8.516-40.047-22.25-53.75c-13.719-13.734-32.781-22.281-53.734-22.266
		c-20.969-0.016-40.031,8.531-53.766,22.266c-13.719,13.703-22.25,32.781-22.25,53.75c0,20.922,8.531,40.016,22.25,53.734
		c9.188,9.172,20.797,15.954,33.734,19.485c-0.016,13.453,3,26.422,8.406,38.016c8.063,17.453,21.359,31.922,37.766,42.047
		s36.031,15.953,56.938,15.953c27.891-0.016,53.453-10.328,72.234-27.469c9.391-8.594,17.109-18.906,22.484-30.531
		c5.391-11.594,8.406-24.563,8.391-38.016v-79.61c8.797-2.156,16.734-5.531,23.563-9.625c5.969-3.578,11.141-7.641,15.531-11.938
		c4.438-4.297,8.078-8.797,11-13.547c0.078-0.094,0.469-0.703,1.047-1.531c1.078-1.578,2.813-3.984,4.953-6.953
		c7.625-10.453,20.672-27.813,35.094-47.406c14.453-19.625,30.281-41.516,43.828-61.438l-6.281-4.266
		c8.344-12.328,15.813-23.922,21.516-33.766C464.344,129.02,469.781,111.098,469.797,93.801z M118.219,395.958
		c-14.75-0.016-27.969-5.938-37.625-15.578C70.938,370.708,65,357.473,65,342.77c0-14.75,5.938-27.969,15.594-37.656
		c9.656-9.609,22.875-15.547,37.625-15.563c14.719,0.016,27.953,5.953,37.594,15.563c9.672,9.688,15.594,22.906,15.609,37.656
		c-0.016,14.703-5.938,27.938-15.609,37.609C146.172,390.02,132.938,395.942,118.219,395.958z"/>
        <path className="st0" d="M232.813,79.286c3.906-2.734,6.25-7.219,6.25-12V14.614c0-4.766-2.344-9.25-6.25-11.969
		c-3.906-2.75-8.906-3.391-13.391-1.75L187.5,12.614v56.703l31.922,11.688C223.906,82.661,228.906,82.004,232.813,79.286z"/>
        <path className="st0" d="M334.469,79.286c3.906,2.719,8.922,3.375,13.406,1.734l31.922-11.703V12.614L347.875,0.895
		c-4.484-1.656-9.5-1-13.406,1.75c-3.906,2.734-6.234,7.203-6.234,11.969v52.672C328.234,72.067,330.563,76.551,334.469,79.286z"/>
        <path className="st0" d="M118.219,313.973c-15.906,0-28.797,12.891-28.797,28.797c0,15.875,12.891,28.75,28.797,28.75
		c15.891,0,28.766-12.875,28.766-28.75C146.984,326.864,134.109,313.973,118.219,313.973z M130.5,355.036
		c-3.172,3.156-7.484,5.078-12.281,5.094c-4.828-0.016-9.125-1.938-12.313-5.094c-3.141-3.156-5.063-7.469-5.078-12.266
		c0.016-4.828,1.938-9.156,5.078-12.297c3.188-3.156,7.484-5.094,12.313-5.109c4.797,0.016,9.109,1.953,12.281,5.109
		c3.156,3.141,5.078,7.469,5.078,12.297C135.578,347.567,133.656,351.879,130.5,355.036z"/>
      </g>
    </SvgIcon>
  );
};

export default MechisRevision;
