import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mechis/elements';

const MechisAdjustment: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 511.999 511.999" {...props}>
      <g>
        <g>
          <path d="M508.718,21.437l-18.6-18.601c-3.562-3.562-9.262-3.797-13.106-0.539l-63.204,53.573c-2.065,1.75-3.302,4.284-3.414,6.987
			s0.914,5.331,2.828,7.244l2.609,2.609L250.907,237.632l-26.549-26.549c-10.38-10.377-27.177-10.38-37.559,0.001l-20.236,20.236
			c-5.877,5.877-6.6,15.157-1.707,21.873c7.449,10.221,4.946,26.049-5.82,36.817c-11.119,11.118-26.998,12.978-36.817,5.822
			c-6.717-4.894-15.998-4.171-21.873,1.707l-84.143,84.141c-21.009,21.008-21.009,55.193,0,76.201l38.324,38.324
			c21.059,21.059,55.141,21.06,76.201,0l84.143-84.143c5.877-5.877,6.6-15.157,1.707-21.873c-7.449-10.221-4.946-26.049,5.82-36.817
			c11.119-11.119,26.997-12.977,36.817-5.82c6.717,4.894,15.998,4.171,21.873-1.707l20.237-20.236
			c10.354-10.355,10.354-27.204-0.001-37.559L274.776,261.5L439.699,96.578l1.755,1.755c1.914,1.914,4.54,2.939,7.244,2.828
			c2.704-0.111,5.238-1.349,6.987-3.414l53.573-63.204C512.515,30.699,512.281,24.999,508.718,21.437z M265.987,313.211
			c-22.577-8.473-49.245-1.918-67.457,16.294c-18.515,18.516-24.439,45.383-16.267,67.429l-75.403,75.403
			c-7.867,7.866-20.597,7.866-28.465,0L40.07,434.012c-7.848-7.848-7.848-20.618,0-28.465l75.376-75.375
			c22.624,8.489,49.284,1.879,67.456-16.294c18.517-18.516,24.443-45.383,16.267-67.429l6.409-6.409l66.789,66.789L265.987,313.211z
			"/>
        </g>
      </g>
    </SvgIcon>
  );
};

export default MechisAdjustment;
