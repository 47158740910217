import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  Operation,
} from '@apollo/client';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { onError } from '@apollo/client/link/error';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { setContext } from '@apollo/client/link/context';
import cache from './cache';
import {
  GRAPHQL_ENDPOINT,
  REFRESH_TOKEN_URL,
} from '@configurations/constants/app';
import { ACCESS_TOKEN } from '@configurations/constants/app';
import snackbarCacheControl from './mutations/snackbar';

const { setSnackbar } = snackbarCacheControl;
let isRefreshing = false; // Flag to track if the refresh is in progress
let pendingRequests: (() => void)[] = []; // Queue of pending requests

const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN)?.replace('"', '').replace('"', '');

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const refreshLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',
  isTokenValidOrUndefined: (operation: Operation) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (operation.operationName === 'LoginUser') return true;
    if (!accessToken) return true;
    try {
      const { exp } = jwtDecode<JwtPayload>(accessToken);
      if (!exp) return false;
      return Date.now() < exp * 1000;
    } catch {
      return false;
    }
  },
  fetchAccessToken: () => {
    isRefreshing = true; // Set the flag to indicate refresh is in progress
    return fetch(REFRESH_TOKEN_URL, {
      method: 'POST',
      credentials: 'include',
    }).finally(() => {
      isRefreshing = false; // Reset the flag when done
      pendingRequests.forEach((callback) => callback());
      pendingRequests = []; // Clear pending requests
    });
  },
  handleFetch: (accessToken, operation: Operation) => {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  },
  handleError: (err: Error, operation: Operation) => {
    console.error('[TokenRefreshLink]', operation.operationName, err);
  },
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions && extensions.code === 'FORBIDDEN') {
        if (!isRefreshing) {
          pendingRequests.push(() => forward(operation)); // Queue the request
        }
        return; // Do not show error snackbar during refresh
      } else {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        setSnackbar('error', extensions.code as string); // Show error snackbar for other errors
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    setSnackbar('error', 'HTTP_CALL_ERROR');
  }
});

const client = new ApolloClient({
  // eslint-disable-next-line
  link: ApolloLink.from([ errorLink, refreshLink, authLink, httpLink ]),
  cache,
});

export default client;
