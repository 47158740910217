import { styled } from '@mui/material/styles';
import { Paper } from '@mechis/elements';

export const Block = styled(Paper, {
  name: 'DeviceAvatar',
  slot: 'Root',
})<any>(({ ownerState }) => ({
  background: 'white',
  ...(ownerState.variant === 'small' && {
    '& img': {
      maxWidth: '34px',
    },
  }),
  ...(ownerState.variant === 'medium' && {
    '& img': {
      maxWidth: '52px',
    },
  }),
  ...(ownerState.variant === 'big' && {
    '& img': {
      maxWidth: '67px',
    },
  }),
}));
