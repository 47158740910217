import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mechis/elements';
import QuoteBlock from '@mechis/blocks/QuoteBlock';
import useTech from '@hooks/useTech';

const NoFuel = () => {
  const navigate = useNavigate();
  const { techId } = useTech();

  return (
    <>
      <QuoteBlock>
        <Typography>Chybí informace o palivu</Typography>
        <Typography>Pro zajištění přesnějších informací o údržbě a spotřebě, prosím nastav druh paliva pro své vozidlo.</Typography>
      </QuoteBlock>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => navigate(`/app/${techId}/tech-detail/info?openModalFuels=true`)}
        sx={{
          margin: '16px auto',
          display: 'block',
        }}
      >
        Upravit palivo
      </Button>
    </>
  );
};

export default NoFuel;
