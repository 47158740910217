import React, { FC } from 'react';
import useAuth, { ILoginInputs } from '@hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Checkbox, FormControlLabel, InputAdornment, Link, Stack, TextField, Typography } from '@mechis/elements';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import FormWrapper from '@mechis/sections/FormWrapper';
import { getPathToLegalFile, LegalFileType } from '@utilities/legalFilesPath';

const Login: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    onLogin,
    loginFormSchema,
    loginLoading,
  } = useAuth();

  const { control, handleSubmit, register } = useForm<ILoginInputs>({
    defaultValues: {
      email: '',
      password: '',
      extendedExpiration: false,
    },
    resolver: yupResolver(loginFormSchema),
  });
  
  return (
    <div>
      <FormWrapper>
        <Typography
          variant="h1"
          sx={{ 
            color: (theme) => theme.palette.secondary.main,
            textAlign: 'center',
            marginBottom: (theme) => theme.spacing(4),
          }}
        >
          {t('login.title')}
        </Typography>
        <Box component="form">
          <Stack spacing={4}>
            <Controller
              render={({ field, formState }) => (
                <TextField
                  id="cy-login-email-input"
                  {...field}
                  label={t('login.form.email.name')}
                  type="email"
                  error={!!formState.errors.email?.message}
                  helperText={formState.errors.email?.message}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(onLogin)();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              name="email"
              control={control}
            />
            <Controller
              render={({ field, formState }) => (
                <TextField
                  id="cy-login-password-input"
                  {...field}
                  label={t('login.form.password.name')}
                  type="password"
                  autoComplete="current-password"
                  error={!!formState.errors.password?.message}
                  helperText={formState.errors.password?.message}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(onLogin)();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              name="password"
              control={control}
            />
          </Stack>
        </Box>
      </FormWrapper>
      <FormControlLabel
        label={t('login.form.keepLoggedIn')} 
        control={
          <Checkbox
            color="primary"
            {...register('extendedExpiration')}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        }
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '180px',
          marginTop: '8px',
        }}
      />
      <Button
        isLoading={loginLoading}
        id="cy-login-login-btn"
        variant="contained"
        size="large"
        color="secondary"
        onClick={handleSubmit(onLogin)}
        sx={{
          margin: 'auto',
          mt: 3,
          display: 'block',
          minWidth: '251px',
        }}
      > 
        {t('login.form.signIn')}
      </Button>
      <Link
        id="cy-forgotten-password-link"
        variant="body2"
        component="button"
        onClick={() => navigate(`/forgotten-password/${control._fields.email?._f.value}`)}
        sx={{
          margin: 'auto',
          mt: 2,
          display: 'block',
          minWidth: '251px',
        }}
      >
        {t('login.links.forgottenPassword')}
      </Link>
      <Button
        id="cy-login-registration-btn"
        variant="outlined"
        size="medium"
        color="primary"
        onClick={() => navigate('/registration')}
        sx={{
          margin: 'auto',
          mt: 3,
          display: 'block',
          minWidth: 'initial',
        }}
      >
        {t('registration.title')}
      </Button>
      <Box mt={2}>
        <Link
          href={getPathToLegalFile(LegalFileType.TermsAndConditions)}
          download
          variant="body2"
          component="a"
          sx={{
            margin: 'auto',
            display: 'block',
            minWidth: '251px',
            paddingBottom: 0.5,
            textAlign: 'center',
            fontSize: '0.7em',
          }}
        >
          {t('login.links.generalTermsAndConditions')}
        </Link>
        <Link
          href={getPathToLegalFile(LegalFileType.Cookies)}
          download
          variant="body2"
          component="a"
          sx={{
            margin: 'auto',
            display: 'block',
            minWidth: '251px',
            paddingBottom: 0.5,
            textAlign: 'center',
            fontSize: '0.7em',
          }}
        >
          {t('login.links.cookies')}
        </Link>
        <Link
          href={getPathToLegalFile(LegalFileType.GDPR)}
          download
          variant="body2"
          component="a"
          sx={{
            margin: 'auto',
            display: 'block',
            minWidth: '251px',
            textAlign: 'center',
            fontSize: '0.7em',
          }}
        >
          {t('login.links.gdpr')}
        </Link>
      </Box>
    </div>
  );
};

export default Login;
