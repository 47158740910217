import { styled } from '@mui/material/styles';
import { shouldForwardProp as sysShouldForwardProp } from '@mui/system';
import { IOwnerState } from './types';

export const Block = styled('div', {
  name: 'Vrp',
  slot: 'Root',
  shouldForwardProp: (prop) => sysShouldForwardProp(prop) && prop !== 'ownerState',
})<{ ownerState: IOwnerState, theme?: any }>(({ theme }) => ({
  minWidth: '81px',
  width: 'auto',
  border: `2px solid ${theme.palette.divider}`,
  borderRadius: `${theme.radiuses.small}px`,
  display: 'inline-flex',
  flexDirection: 'row',
  overflow: 'hidden',
  background: theme.palette.background.default,
}));

export const NationStripe = styled('div', {
  name: 'Vrp',
  slot: 'NationStripe',
})(() => ({
  width: '22px',
  display: 'flex',
  background: '#0033cc',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& > div > img': {
    maxWidth: '11px',
    display: 'block',
    position: 'relative',
    top: '-0.25px',
  },
}));

export const VrpId = styled('div', {
  name: 'Vrp',
  slot: 'Id',
  shouldForwardProp: (prop) => sysShouldForwardProp(prop) && prop !== 'ownerState',
})<{ ownerState: IOwnerState, theme?: any }>(({ ownerState, theme }) => ({
  width: '100%',
  display: 'flex',
  fontFamily: '"Poppins", sans-serif',
  fontSize: '12px',
  fontWeight: '600',
  textAlign: 'center',
  marginTop: '1px',
  color: theme.palette.text.paragraph,
  ...(!!ownerState.vrp && {
    textAlign: 'center',
    display: 'block',
  }),
}));

