import { gql } from '@apollo/client';

export const GET_APP = gql`
  query GetApp {
    app @client {
      toolbarHeadline
      isMenuOpened
      snackbar
      addFlow
      addFlowStep
      backButtonVisible
      menuButtonVisible
    }
  }
`;
