import { Box, Button, ClickAwayListener, Snackbar, Typography, Link, Paper } from '@mechis/elements';
import React from 'react';
import { useLocalStorage } from 'react-use';
import { COOKIES_BAR_SUBMITTED } from '@configurations/constants/app';
import { useTranslation } from 'react-i18next';
import { getPathToLegalFile, LegalFileType } from '@utilities/legalFilesPath';

export const CookiesBar: React.FC = () => {
  const [ cookieBarClosed, setCookieBarClosed ] = useLocalStorage(COOKIES_BAR_SUBMITTED);
  const { t }= useTranslation();
  return (
    <>
      {!cookieBarClosed && (
        <ClickAwayListener onClickAway={() => null}>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={!cookieBarClosed}
          >
            <Paper>
              <Box sx={{background: 'white'}} p={2} pt={4} borderRadius={2} textAlign="center">
                <Box>
                  <Typography variant="h1" sx={{ fontSize: '1.25em' }}>{t('cookiesBar.headline')}</Typography>
                  <Typography variant="body2">{t('cookiesBar.subHeadline')}</Typography>
                </Box>
                <Box mt={2}>
                  <Link
                    href={getPathToLegalFile(LegalFileType.Cookies)}
                    download
                    variant="body2"
                    component="a"
                    sx={{
                      margin: 'auto',
                      display: 'block',
                      minWidth: '251px',
                      paddingBottom: 0.5,
                      textAlign: 'center',
                    }}
                  >
                    {t('login.links.cookies')}
                  </Link>
                </Box>
                <Box display="flex" mr="auto" ml="auto" mt={4} justifyContent="center">
                  <Box mr={2}>
                    <Button 
                      size="small" 
                      variant="outlined" 
                      onClick={() => setCookieBarClosed(true)}
                    >
                      {t('cookiesBar.selectedCookiesButton')}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      size="small"
                      variant="contained" 
                      onClick={() => setCookieBarClosed(true)}
                    >
                      {t('cookiesBar.allCookiesButton')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Paper>

          </Snackbar>
        </ClickAwayListener>
      )}
    </>
  );
};
