import { alpha } from '@mui/material/styles';

const variants = [
  {
    props: { color: 'white' },
    style: ({ theme }: any) => ({
      color: alpha(theme.palette.common.white, 0.7),
      '&:hover': {
        backgroundColor: 'transparent',
        '@media (hover:none)': {
          backgroundColor: 'transparent',
        },
      },
    }),
  },
  {
    props: { color: 'white', variant: 'outlined' },
    style: ({ theme }: any) => ({
      borderColor: alpha(theme.palette.common.white, 0.5),
      backgroundColor: 'transparent',
      '&:hover': {
        borderColor: alpha(theme.palette.common.white, 0.7),
        backgroundColor: 'transparent',
        '@media (hover:none)': {
          backgroundColor: 'transparent',
        },
      },
    }),
  },
  {
    props: { color: 'white', variant: 'contained' },
    style: ({ theme }: any) => ({
      color: theme.palette.secondary.dark,
      backgroundColor: alpha(theme.palette.common.white, 0.7),
      boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.9),
        '@media (hover:none)': {
          backgroundColor: 'transparent',
        },
      },
    }),
  },
  {
    props: { size: 'small' },
    style: ({ theme }: any) => ({
      borderRadius: `${theme.radiuses.small}px`,
      fontSize: '0.7rem',
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    }),
  },
  {
    props: { size: 'medium' },
    style: ({ theme }: any) => ({
      borderRadius: `${theme.radiuses.medium}px`,
      padding: `${theme.spacing(1)} ${theme.spacing(2.25)}`,
    }),
  },
  {
    props: { size: 'large' },
    style: ({ theme }: any) => ({
      borderRadius: `${theme.radiuses.large}px`,
      fontSize: '1rem',
      padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    }),
  },
];

export default variants;
