// eslint-disable-next-line
export enum LegalFileType {
  TermsAndConditions = 'TermsAndConditions',
  Cookies = 'Cookies',
  GDPR = 'GDPR',
}

export const getPathToLegalFile = (fileType: LegalFileType) => {
  switch (fileType) {
    case LegalFileType.TermsAndConditions:
      return process.env.PUBLIC_URL + '/files/Mechis - Všeobecné obchodní podmínky.pdf';

    case LegalFileType.Cookies:
      return process.env.PUBLIC_URL + '/files/Mechis - Zásady zpracování cookies.pdf';

    case LegalFileType.GDPR:
      return process.env.PUBLIC_URL + '/files/Mechis - Zásady ochrany osobních údajů.pdf';

    default:
      return '';
  }
};
