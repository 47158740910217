import React, { FC } from 'react';
import {
  Stack,
  IconButton,
  Typography,
  Box,
} from '@mechis/elements';
import { IBaseProps } from './types';
import useEvent from '@hooks/useEvent';
import Attachments from '@mechis/sections/Selections/Attachments';
import MutableOdometer from '@mechis/sections/Selections/MutableOdometer/MutableOdometer';
import { useEventByIdQuery } from '@state/mechis-backend/generated/schema';
import MutableDate from '@mechis/sections/Selections/MutableDate/MutableDate';

const Base: FC<IBaseProps> = ({ id, eventType, children }) => {
  const { name, eventIcon } = useEvent(+id);

  const { data: eventDateAndOdometer } = useEventByIdQuery({
    variables: {
      eventId: Number(id),
    },
  });
  
  console.log(eventDateAndOdometer);

  return (
    <Box mt={-1}>
      <Stack direction="row" justifyContent="space-between">
        <IconButton color="primary" size="large" isSquared>
          {eventIcon(eventType, 'large')}
        </IconButton>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <MutableDate eventId={id} eventDateAndOdometer={eventDateAndOdometer} />
          <MutableOdometer eventId={id} eventDateAndOdometer={eventDateAndOdometer} />
          <Attachments />
        </Stack>
      </Stack>
      <Stack mt={2} sx={{ borderBottom: '2px dashed lightgrey '}} pb={2}>
        <Typography variant="h4" sx={{ lineHeight: 1.25 }}>
          {name}
        </Typography>
        {children}
      </Stack>
    </Box>
  );
};

export default Base;
