import React, { FC, useEffect, useState } from 'react';
import {
  Dialog,
  Box,
  DialogContent,
  Button,
  Stack,
  NativeSelect,
  TextField,
  Typography,
  InputAdornment,
} from '@mechis/elements';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { NewOperationData, IAddNewOperationProps } from '../types';
import snackbarCacheControl from '@state/mutations/snackbar';
import { DAYS_IN_MONTH } from '@configurations/constants/app';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import DescriptionIcon from '@mui/icons-material/Description';
import { useOperationTypesQuery } from '@state/mechis-backend/generated/schema';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useTech, { UnitType } from '@hooks/useTech';
import NumberField from '@mechis/elements/NumberField';

const NewOperation: FC<IAddNewOperationProps> = ({
  open,
  setOpen,
  maintenanceInterval,
  createOperation,
  error,
}) => {
  const { setSnackbar } = snackbarCacheControl;
  const { t } = useTranslation();
  const [ selectedOperationType, setSelectedOperationType ] = useState(1);
  const { getTechUnit } = useTech();

  const { data: operationTypes } = useOperationTypesQuery();

  useEffect(() => {
    reset({
      operationName: '',
      operationDescription: '',
      periodDay: maintenanceInterval?.periodDay !== null ? Math.ceil((maintenanceInterval?.periodDay ?? 0) / DAYS_IN_MONTH) : null,
      periodDistance: maintenanceInterval?.periodDistance,
    });
  }, [ maintenanceInterval ]);

  const getOperationTypesOptions = () => {
    return operationTypes?.operationTypes.map((type: any) => {
      return <option color="black" key={type.id} value={type.id}>{t(`operation.type.${type.name}`)}</option>;
    });
  };

  const onCreateOperation = async (data: NewOperationData) => {
    const periodDay = data.periodDay !== null ? Number(data.periodDay) * DAYS_IN_MONTH : null;
    const periodDistance = data.periodDistance !== null ? Number(data.periodDistance) : null;

    await createOperation({
      variables: {
        operation: {
          name: data.operationName,
          maintenanceInterval: {
            id: Number(maintenanceInterval?.id),
          },
          operationType: {
            id: Number(selectedOperationType),
          },
          periodDay,
          periodDistance,
          description: data.operationDescription,
        },
      },
    });

    error ? setSnackbar('error', 'OPERATION_CREATE_ERROR') : setSnackbar('success', 'OPERATION_CREATE_SUCCESSFUL');
    setOpen(false);

    reset({
      deviceCategory: 1,
      operationName: '',
      operationDescription: '',
      periodDay:  maintenanceInterval?.periodDay !== null ? Math.ceil((maintenanceInterval?.periodDay ?? 0) / DAYS_IN_MONTH) : null,
      periodDistance: maintenanceInterval?.periodDistance,
    });

  };

  const schema = yup.object({
    operationName: yup.string().required(t('newMaintenance.form.maintenanceName.required')),
    periodDay: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .nullable()
      .test('is-not-zero', t('newMaintenance.form.distanceInterval.required'), (value) => {
        return value === null || value !== 0;
      }),
    periodDistance: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .nullable()
      .test('is-not-zero', t('newMaintenance.form.timeInterval.required'), (value) => {
        return value === null || value !== 0;
      }),
  }).required();

  // eslint-disable-next-line
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      deviceCategory: 1,
      operationName: '',
      operationDescription: '',
      periodDay: maintenanceInterval?.periodDay !== null ? Math.ceil((maintenanceInterval?.periodDay ?? 0) / DAYS_IN_MONTH) : null,
      periodDistance: maintenanceInterval?.periodDistance,
    },
    resolver: yupResolver(schema),
  });

  return (
    <Dialog
      onClose={() => setOpen(false)}
      open={open}
      maxWidth="xs"
    >
      <Box textAlign="center">
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Typography variant="sectionHeadline">{t('newOperation.title')}</Typography>
            <Stack direction="column" spacing={3}>
              <NativeSelect
                label={t('newOperation.form.type')}
                isLabelWhite
                id={'deviceCategory'}
                fullWidth
                onChange={(e) => {
                  setSelectedOperationType(+e.currentTarget.value);
                }}
              >
                {getOperationTypesOptions()}
              </NativeSelect>
              <Controller
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    label={t('newOperation.form.name')}
                    variant="outlined"
                    type="text"
                    id={'operationName'}
                    error={!!formState.errors.operationName?.message}
                    helperText={formState.errors.operationName?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BuildCircleIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                name="operationName"
                control={control}
              />
              <Controller
                render={({ field, formState }) => (
                  <TextField
                    {...field}
                    label={t('newOperation.form.description')}
                    variant="outlined"
                    type="text"
                    id={'operationDescription'}
                    error={!!formState.errors.operationDescription?.message}
                    helperText={formState.errors.operationDescription?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DescriptionIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                name="operationDescription"
                control={control}
              />
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    label={t('newOperation.form.timeInterval')}
                    variant="outlined"
                    id={'timeInterval'}
                    error={!!formState.errors.periodDay?.message}
                    helperText={formState.errors.periodDay?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarMonthIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onlyInteger
                  />
                )}
                name="periodDay"
                control={control}
              />
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    label={t('newOperation.form.distanceInterval', { unit: getTechUnit(UnitType.Odometer) })}
                    variant="outlined"
                    id={'distanceInterval'}
                    error={!!formState.errors.periodDistance?.message}
                    helperText={formState.errors.periodDistance?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SpeedIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    onlyInteger
                  />
                )}
                name="periodDistance"
                control={control}
              />
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="space-between"
            >
              <Button
                onClick={() => setOpen(false)}
                variant="outlined"
              >
                {t('newOperation.button.cancel')}
              </Button>
              <Button
                onClick={handleSubmit(onCreateOperation)}
                variant="contained"
                color="secondary"
              >
                {t('newOperation.button.create')}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default NewOperation;
