import React, { useEffect, useState } from 'react';
import useHeader from '@hooks/useHeader';
import Selections from '@mechis/sections/Selections';
import useMaintenance from '@hooks/useMaintenance';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { getLowestDueDate } from '@utilities/app';
import {
  Tabs,
  Tab,
  Paper,
  Box,
  Typography,
  Stack,
  Checkbox,
  Button,
  FormControlLabel,
} from '@mechis/elements';
import Operation from '@mechis/sections/MaintenanceChecklist/components/Operation';
import Summary from '@mechis/sections/MaintenanceChecklist/components/Summary';
import selectionsCacheControl from '@state/mutations/selections';
import { useQuery } from '@apollo/client';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { GET_SELECTIONS } from '@state/queries/selections';
import useAuth from '@hooks/useAuth';
import { useTranslation } from 'react-i18next';
import snackbarCacheControl from '@state/mutations/snackbar';
import useTech from '@hooks/useTech';

const NewMaintenance = () => {
  const { t } = useTranslation();
  const { setSnackbar } = snackbarCacheControl;
  useHeader({
    headline: t('newMaintenance.maintanance'),
    widget: (
      <Selections
        date={{ isSelection: true, order: 1 }}
        odometer={{ isSelection: true, order: 2 }}
        attachments={{ isSelection: true, order: 3 }}
      />
    ),
  });

  const { data: selectionsData } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const odometerValue = selectionsData?.selections.odometerValue || 0;
  const odometerState = selectionsData?.selections.odometerState;

  const { 
    maintenanceIntervals,
    operations,
    setOperations,
  } = useMaintenance({});

  const navigate = useNavigate();
  const { intervalId } = useParams();
  const { techId } = useTech();
  const [ value, setValue ] = useState<string>();
  const { setOdometerState } = selectionsCacheControl;
  const { isComgateUser } = useAuth();

  if (isComgateUser()) {
    navigate('/');
  }

  useEffect(() => {
    if (!value && maintenanceIntervals && maintenanceIntervals?.length > 0) {
      setValue(maintenanceIntervals[0].id);
    }
  }, [ maintenanceIntervals ]);

  useEffect(() => {
    if (intervalId) setValue(intervalId);
  }, [ intervalId ]);
  
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onChangeCheckbox = (operationId: number) => {
    const operationsCopy = [ ...operations ];
    const index = operationsCopy.indexOf(operationId);

    if (index !== -1) {
      operationsCopy.splice(index, 1);
    } else {
      operationsCopy.push(operationId);
    }

    setOperations(operationsCopy);
  };

  // todo: fix typescript
  const onChangeAllCheckbox = (operationsSet: any) => {
    const isAll = isAllChecked(operationsSet);
    if (!isAll) {
      const operationsCopy = [ ...operations ];
      // todo: fix typescript
      operationsSet.forEach((operation: any) => {
        if (!operationsCopy.includes(+operation.id)) {
          operationsCopy.push(+operation.id);
        }
      });
      setOperations(operationsCopy);
    } else {
      const operationsCopy = 
        operations.filter((operation: any) => !operationsSet.some((setOperation: any) => +setOperation.id === operation));
      setOperations(operationsCopy);
    }
  };

  // todo: fix typescript
  const isAllChecked = (operationsSet: any) => {
    // todo: fix typescript
    return operationsSet.every((operation: any) => operations.includes(+operation.id));
  };

  if (maintenanceIntervals?.length === 0) {
    navigate(`/app/${techId}/tech-detail/maintenance-plan`);
  }

  return (
    <div>
      <Tabs 
        value={value ?? false}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        // todo: prepare variant
        sx={{
          border: '1px dashed lightgrey',
          borderRadius: '8px',
        }}
      >
        {maintenanceIntervals && 
          maintenanceIntervals?.length > 0 && 
          maintenanceIntervals?.map((interval) =>
            <Tab
              key={uuid()}
              label={interval.name}
              value={interval.id}
              wrapped
            />
          )}
      </Tabs>
      {maintenanceIntervals && 
          maintenanceIntervals?.length > 0 && 
          maintenanceIntervals?.map((interval) =>
            <Stack 
              key={uuid()}
              mt={2}
              spacing={2}
              sx={{ 
                display: interval.id === value ? 'block' : 'none',
              }}
            >
              <Box>
                <Paper>
                  <Box p={2}>
                    <Summary
                      periodDay={interval.periodDay}
                      periodDistance={interval.periodDistance}
                      name={interval.name}
                      lowestDueDate={getLowestDueDate(interval)}
                      dueDistance={interval.dueDistance}
                    />
                  </Box>
                  <Box textAlign="right" mr={1} sx={{ position: 'relative', bottom: '8px' }}>
                    <FormControlLabel
                      label={interval.operation?.length === 1 && !interval.operation[0].isVisible ? 'Vybrat' : 'Vybrat vše'}
                      control={
                        <Checkbox
                          size="large"
                          checked={isAllChecked(interval.operation)}
                          onClick={() => onChangeAllCheckbox(interval.operation)}
                          color="primary"
                        />
                      }
                      labelPlacement="start"
                      sx={{
                        paddingRight: '8px',
                        '& > span': {
                          fontSize: '12px',
                          fontStyle: 'italic',
                          opacity: 0.8,
                        },
                      }}
                    />
                  </Box>
                </Paper>
              </Box>
              <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography 
                    variant="sectionHeadline" 
                    sx={{ 
                      pl: 1,
                      display: interval.operation?.length === 1 && !interval.operation[0].isVisible ? 'none' : 'initial',
                    }}
                  >
                    {t('newMaintenance.tasks')}
                  </Typography>
                </Stack>
                <Paper
                  sx={{
                    visibility: interval.operation?.length === 1 && !interval.operation[0].isVisible ? 'hidden' : 'initial',
                  }}
                >
                  <Box p={2}>
                    {interval.operation?.map((operation, i) => 
                      <div key={uuid()}>
                        {operation.isVisible && (
                          <Operation
                            dueDistance={operation.dueDistance}
                            periodEndDate={operation.periodEndDate}
                            name={operation.name}
                            description={operation.description}
                            isLast={i === (interval?.operation?.length ?? 0) - 1}
                            type={operation.operationType}
                          >
                            <Checkbox
                              size="medium"
                              checked={operations.includes(+operation.id)}
                              onClick={() => onChangeCheckbox(+operation.id)}
                              color={operations.includes(+operation.id) ? 'secondary' : 'default'}
                            />
                          </Operation>
                        )}
                      </div>
                    )}
                  </Box>
                </Paper>
              </Box>
              <Stack alignItems="center" justifyContent="center">
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={odometerState === 'missing'}
                  onClick={() => {
                    if (odometerValue < 1) {
                      setOdometerState('missing');
                      setSnackbar('error', 'ODOMETER_MISSING');
                      window.scrollTo({
                        top: 0, 
                        behavior: 'smooth',
                      });
                      return;
                    }
                    navigate('1');
                  }}
                >
                  {t('newMaintenance.button.continue')}
                </Button>
              </Stack>
            </Stack>
          )}
    </div>
  );
};

export default NewMaintenance;
