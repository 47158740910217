import { radiuses } from '@configurations/themes/default/radiuses';

const variants = [
  {
    props: { variant: 'mechis' },
    style: ({ theme }: any) => ({
      height: 'auto',
      borderRadius: `${radiuses.medium}px`,
      // todo: refactor or delete it here
      // ...theme.mixins.backgroundGradientCustomizable(30),
      ...theme.mixins.bgGradientSolidVaultMobile,
    }),
  },
];

export default variants;
