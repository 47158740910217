import { gql } from '@apollo/client';

export const NAVIGATE_MODAL = gql`
  query GetNavigateModal {
    navigateModal @client {
      isBlocking
      pendingNavigation
    }
  }
`;
