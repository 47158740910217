import React from 'react';
import useHeader from '@hooks/useHeader';
import AddFlowStepper from '@mechis/sections/AddFlowStepper';
import { useTranslation } from 'react-i18next';
import AddDeviceLayout from '@mechis/layouts/AddDeviceLayout';
import EngineSpecification from '../components/EngineSpecification';
import EngineDisplacementCc from '../components/EngineDisplacementCc';
import Power from '../components/Power';
import PowerRpm from '../components/PowerRpm';
import TorqueNm from '../components/TorqueNm';
import TorqueRpm from '../components/TorqueRpm';
import TransmissionNumber from '../components/TransmissionNumber';
import { Grid, Stack } from '@mechis/elements';
import FormBlock from '@mechis/blocks/FormBlock';
import { Transmission } from '@screens/NewTech/components/Transmission/Transmission';

const Step2 = () => {
  useHeader({
    headline: 'addDevice.motorcycle.step2.title',
    widget: (
      <AddFlowStepper />
    ),
  });

  const { t } = useTranslation();

  return (
    <AddDeviceLayout>
      <Stack spacing={4}>
        <FormBlock headline={t('addDevice.headlines.motor')}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EngineSpecification />
            </Grid>
            <Grid item xs={6}>
              <EngineDisplacementCc />
            </Grid>
            <Grid item xs={6}>
              <Power />
            </Grid>
            <Grid item xs={6}>
              <PowerRpm />
            </Grid>
            <Grid item xs={6}>
              <TorqueNm />
            </Grid>
            <Grid item xs={6}>
              <TorqueRpm />
            </Grid>
          </Grid>
        </FormBlock>
        <FormBlock headline={t('addDevice.headlines.transmission')}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Transmission />
            </Grid>
            <Grid item xs={6}>
              <TransmissionNumber />
            </Grid>
            {/* TODO: MVP ready */}
            {/*
            <Grid item xs={6}>
              <TransmissionType />
            </Grid>
            */}
          </Grid>
        </FormBlock>
      </Stack>
    </AddDeviceLayout>
  );
};

export default Step2;
