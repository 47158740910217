import { styled } from '@mui/material/styles';

export const Block = styled('div', {
  name: 'TechSelector',
  slot: 'Root',
})(({ theme }) => ({
  width: '100%',
  minHeight: '120px',
  background: '#eaf1f5',
  padding: theme.spacing(2),
  borderRadius: `${theme.radiuses.large}px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
}));

