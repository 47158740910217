
import React, { forwardRef } from 'react';
import { default as MuiListItemText } from '@mui/material/ListItemText';
import { ListItemTextProps } from './types';

const ListItemText = forwardRef<any, ListItemTextProps>((props, ref) => {
  return <MuiListItemText {...props as any} ref={ref} />;
});

ListItemText.displayName = 'ListItemText';

export default ListItemText;
