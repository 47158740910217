import { ReactiveVar, makeVar } from '@apollo/client';
import { IAppModel } from './types';

export const init: IAppModel = {
  toolbarHeadline: 'Karta Techniky',
  isMenuOpened: false,
  snackbar: {
    severity: 'error',
    messageCode: null,
    timestamp: 0,
  },
  addFlow: false,
  addFlowStep: false,
  backButtonVisible: true,
  menuButtonVisible: true,
};

const app: ReactiveVar<IAppModel> = makeVar<IAppModel>(init);

export default app;
