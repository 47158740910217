import React, { FC, useEffect } from 'react';
import { IProps } from './types';
import { 
  IAddDeviceModelFuel,
} from '@state/models/addDevice/types';
import {
  useVolumeUnitsByFuelTypeQuery,
  useConsumptionUnitsByFuelTypeQuery,
} from '@state/mechis-backend/generated/schema';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import useIcon from '@mechis/icons/useIcon';
import { CommonIcons } from '@mechis/icons/types';
import {
  InputAdornment,
  Grid,
  Paper,
  Box,
  Stack,
  FormControlLabel,
  Switch,
  Typography,
  NativeSelect,
  Skeleton as MuiSkeleton,
} from '@mechis/elements';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import {
  blurTarget, 
  ensureNumericZeroIsNotDisplayed,
} from '@utilities/app';
import NumberField from '@mechis/elements/NumberField';

interface IForm {
  initialConsumption: number | null
  tankVolume: number | null
  volumeUnit: number
  consumptionUnit: number
}

const Fuel: FC<IProps> = ({ id }) => {
  const { t } = useTranslation();
  const { data: volumeUnits, loading: volumeUnitsLoading } = useVolumeUnitsByFuelTypeQuery({
    variables: {
      fuelTypeId: id || 0,
    },
  });
  const { data: consumptionUnits, loading: consumptionUnitsLoading } = useConsumptionUnitsByFuelTypeQuery({
    variables: {
      fuelTypeId: id || 0,
    },
  });

  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { setFuel } = addDeviceCacheControl;
  const fuels = data?.addDevice.fuels;
  const fuel = _.find(fuels, { 
    fuelType: { id },
  }) as IAddDeviceModelFuel;
  
  const schema = yup.object({
    initialConsumption: yup.number().typeError(''),
    tankVolume: yup.number().typeError(''),
  }).required();

  const { control, handleSubmit } = useForm<IForm>({
    defaultValues: {
      initialConsumption: ensureNumericZeroIsNotDisplayed(fuel.initialConsumption),
      tankVolume: ensureNumericZeroIsNotDisplayed(fuel.tankVolume),
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!consumptionUnitsLoading && !volumeUnitsLoading) {
      const changedFuel = {
        ...fuel,
        volumeUnit: {
          id: parseInt(volumeUnits?.volumeUnitsByFuelType[0]?.id || '') || 1,
        },
        consumptionUnit: {
          id: parseInt(consumptionUnits?.consumptionUnitsByFuelType[0]?.id || '') || 1,
        },
      };
      setFuel(changedFuel);
    }
  }, [ consumptionUnitsLoading, volumeUnitsLoading, volumeUnits, consumptionUnits ]);

  const getVolumeUnitsOptions = () => {
    return volumeUnits?.volumeUnitsByFuelType.map((volumeUnitItem) => {
      return (
        <option 
          color="black"
          key={volumeUnitItem.id}
          value={volumeUnitItem.id}
        >
          {volumeUnitItem.unitName}
        </option>
      );
    });
  };

  const getConsumptionUnitsOptions = () => {
    return consumptionUnits?.consumptionUnitsByFuelType.map((consumptionUnitItem) => {
      return (
        <option
          color="black"
          key={consumptionUnitItem.id}
          value={consumptionUnitItem.id}
        >
          {consumptionUnitItem.unitName}
        </option>
      );
    });
  };

  const onSave = async ({ initialConsumption, tankVolume }: IForm) => {
    const changedFuel = {
      ...fuel,
      initialConsumption,
      tankVolume,
    };

    setFuel(changedFuel);
  };

  const onChange = () => {
    const changedFuel = {
      ...fuel,
      isSelected: !fuel.isSelected,
    };

    setFuel(changedFuel);
  };

  return (
    <Paper variant="outlined">
      <Box py={1} px={1.5}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <FormControlLabel
            control={
              <Switch
                size="medium"
                checked={fuel.isSelected}
                onChange={onChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            } 
            label={
              <Typography 
                variant="h5"
                sx={{ 
                  color: (theme) => fuel.isSelected ? theme.palette.primary.main : theme.palette.text.disabled, 
                }}
              >
                {t(`fuelTypes.${fuel?.fuelName}`)}
              </Typography>
            } 
          />
          {useIcon(`fuel-${fuel.fuelName}` as CommonIcons, {
            color: fuel.isSelected ? 'primary' : 'disabled',
            sx: {
              fontSize: '1.75em',
            },
          })}
        </Stack>
      
        {fuel.isSelected && (
          <Grid container spacing={3} mt={-1} mb={2}>
            <Grid item xs={6}>
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    onKeyUp={() => {
                      handleSubmit(onSave)();
                    }}
                    onWheel={blurTarget}
                    label={t('addDevice.components.initialConsumption.name')}
                    id="initialConsumption"
                    error={!!formState.errors.initialConsumption?.message}
                    helperText={formState.errors.initialConsumption?.message}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DataUsageIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                name="initialConsumption"
                control={control}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                render={({ field, formState }) => (
                  <NumberField
                    {...field}
                    onKeyUp={() => {
                      handleSubmit(onSave)();
                    }}
                    onWheel={blurTarget}
                    label={fuel.fuelName === 'electric' 
                      ? t('addDevice.components.batteryCapacity.name') : t('addDevice.components.tankVolume.name')}
                    id="tankVolume"
                    error={!!formState.errors.tankVolume?.message}
                    helperText={formState.errors.tankVolume?.message}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {useIcon('mechisEngineDisplacement', { fontSize: 'small' })}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                name="tankVolume"
                control={control}
              />
            </Grid>
            <Grid item xs={6}>
              <NativeSelect
                label={t('addDevice.components.consumptionUnit.name')}
                id="consumptionUnit"
                fullWidth
                defaultValue={consumptionUnits?.consumptionUnitsByFuelType[0].id}
                onChange={(e) => {
                  const changedFuel = {
                    ...fuel,
                    consumptionUnit: {
                      id: +e.currentTarget.value,
                    },
                  };
                  setFuel(changedFuel);
                }}
              >
                {getConsumptionUnitsOptions()}
              </NativeSelect>
            </Grid>
            <Grid item xs={6}>
              <NativeSelect
                label={fuel.fuelName === 'electric' 
                  ? t('addDevice.components.batteryCapacityUnit.name') : t('addDevice.components.volumeUnit.name')}
                id="volumeUnit"
                fullWidth
                defaultValue={volumeUnits?.volumeUnitsByFuelType[0].id}
                onChange={(e) => {
                  const changedFuel = {
                    ...fuel,
                    volumeUnit: {
                      id: +e.currentTarget.value,
                    },
                  };
                  setFuel(changedFuel);
                }}
              >
                {getVolumeUnitsOptions()}
              </NativeSelect>
            </Grid>
          </Grid>
        )}
      </Box>
    </Paper>
  );
};

export const Skeleton: FC = () => {
  return (
    <Paper variant="outlined">
      <Box p={2}>
        <MuiSkeleton
          variant="text"
          width="100%"
          height={15}
        />
      </Box>
    </Paper>
  );
};

export default Fuel;
