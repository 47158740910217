import { ReactiveVar, makeVar } from '@apollo/client';
import { IAddDeviceModel } from './types';

export const init: IAddDeviceModel = {
  acquisitionDate: null,
  actualOdometerState: null,
  color: '',
  configuration: '',
  creationDate: null,
  deviceCategory: {
    id: 1,
  },
  engineDisplacementCc: null,
  serialNumber: '',
  engineSpecification: '',
  firstRegistrationDate: null,
  fuel: [],
  fuels: [],
  guaranteeDate: null,
  guaranteeDistance: null,
  guaranteeMonths: null,
  initialOdometerState: null,
  manufacturedYearMonth: new Date(),
  manufacturer: '',
  model: '',
  motorization: '',
  name: '',
  note: '',
  odometerUnit: {
    id: 1,
  },
  otherIdentification: '',
  power: null,
  powerRpm: null,
  powerUnit: {
    id: 1,
  },
  spz: '',
  torqueNm: null,
  torqueRpm: null,
  transmission: {
    id: 1,
  },
  transmissionNumber: null,
  transmissionType: '',
  vehicleType: {
    id: 1,
  },
  vin: '',
  thumbnailImage: null,
  validationErrors: [],
};

const addDevice: ReactiveVar<IAddDeviceModel> = makeVar<IAddDeviceModel>(init);

export default addDevice;
