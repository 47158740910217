import { radiuses } from '@configurations/themes/default/radiuses';

const styleOverrides = {
  root: {
    borderRadius: `${radiuses.medium}px`,
    '&:before': {
      background: 'transparent',
    },
    '&:last-of-type': {
      borderRadius: `${radiuses.medium}px`,
    },
    '&:first-of-type': {
      borderRadius: `${radiuses.medium}px`,
    },
  },
};

export default styleOverrides;
