import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  IconButton,
} from '@mechis/elements';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudinaryUploadWidget from '@mechis/blocks/Cloudinary/CloudinaryUploadWidget/CloudinaryUploadWidget';
import ClaudinaryImageBuilder from '@mechis/blocks/ClaudinaryImageBuilder';
import { Attachment } from '@state/mechis-backend/generated/schema';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { useQuery } from '@apollo/client';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import Edit from '@mui/icons-material/Edit';
import { Editable } from './styles';
import {CloudinaryWidgetSources, ICloudinaryWidgetConfiguration} from '@mechis/blocks/Cloudinary/types';
import {
  CLAUDINARY_CLOUD_NAME,
  CLAUDINARY_UPLOAD_PRESET,
  CLOUDINARY_MAX_IMAGE_SIZE_IN_BYTES, CLOUDINARY_MAX_IMAGE_WIDTH_IN_PX,
} from '@configurations/constants/app';

const cloudinaryConfiguration: ICloudinaryWidgetConfiguration = {
  cloudName: CLAUDINARY_CLOUD_NAME,
  uploadPreset: CLAUDINARY_UPLOAD_PRESET,
  multiple: false,
  cropping: true,
  showAdvancedOptions: true,
  sources: [ CloudinaryWidgetSources.Local, CloudinaryWidgetSources.Url ],
  folder: 'profile',
  maxImageFileSize: CLOUDINARY_MAX_IMAGE_SIZE_IN_BYTES, //restrict file size to less than 2MB
  maxImageWidth: CLOUDINARY_MAX_IMAGE_WIDTH_IN_PX, //Scales the image down to a width of 2000 pixels before uploading
  theme: 'white',
};

const Avatar = () => {
  const [ uploadedImage, setUploadedImage ] = useState<Partial<Attachment> | undefined>();
  const { setThumbnailImage } = addDeviceCacheControl;
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);

  //TODO: After MVP DeleteDeviceAttachement + DeleteAttachment after upload different image + do the same when user exit flow without finish

  useEffect(() => {
    if (data?.addDevice?.thumbnailImage?.storageName) {
      setUploadedImage({
        storageName: data.addDevice.thumbnailImage.storageName,
      });
    }
  }, [ data ]);

  useEffect(() => {
    if (uploadedImage?.id) {
      setThumbnailImage({
        storageName: uploadedImage.storageName,
        id: uploadedImage.id,
      });
    }
  }, [ uploadedImage ]);

  const renderImage = (storageName: string | undefined, handleOpenWidget: () => void) => {
    if (storageName) {
      return (
        <Editable>
          <IconButton
            color="primary"
            size="large"
            isSquared
          >
            <Edit fontSize="small" />
          </IconButton>
          <ClaudinaryImageBuilder
            imageId={storageName}
            width={238}
            height={238}
          />
        </Editable>
      );
    } else {
      return (
        <Box
          p={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <IconButton
            color="primary"
            size="large"
            onClick={() => handleOpenWidget()}
          >
            <CloudUploadIcon fontSize="large" />
          </IconButton>
        </Box>
      );
    }
  };

  return (
    <Box
      sx={{
        width: '260px',
        height: '260px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <Paper 
        variant="outlined"
        sx={{
          height: '260px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CloudinaryUploadWidget
          setCloudinaryAttachmentInfo={setUploadedImage}
          cloudinaryConfiguration={cloudinaryConfiguration}
        >
          {({widget }: any) => renderImage(uploadedImage?.storageName, () => widget.open())}
        </CloudinaryUploadWidget>
      </Paper>
    </Box>
  );
};

export default Avatar;
