import React, { FC } from 'react';
import { ISummaryProps } from '../types';
import {
  HeadlineDays,
  HeadlineKilometers,
  Labels,
  Intervals,
} from '../styles';
import {
  Stack,
  Typography,
  Divider,
  Chip,
  Button,
} from '@mechis/elements';
import { THOUSAND_SEPARATOR } from '@configurations/constants/app';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SpeedIcon from '@mui/icons-material/Speed';
import NumberFormat from 'react-number-format';
import { getColorOfChip } from '../utils';
import { strSign } from '@utilities/formatters';
import { calculateDueDate } from '@utilities/app';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import useTech, { UnitType } from '@hooks/useTech';

const Summary: FC<ISummaryProps> = ({ 
  periodDay,
  periodDistance,
  name,
  lowestDueDate,
  dueDistance,
  onEdit,
  isWidget,
}) => {
  const { getTechUnit } = useTech();

  return (
    <>
      <Intervals>
        <Stack direction="row" spacing={1} alignItems="center">
          <CalendarMonthIcon color="disabled" fontSize="small" />
          <HeadlineDays color="disabled">
            {periodDay} dnů
          </HeadlineDays>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <SpeedIcon color="disabled" fontSize="small" />
          <HeadlineKilometers color="disabled">
            <NumberFormat
              value={periodDistance}
              displayType="text"
              thousandSeparator={THOUSAND_SEPARATOR}
              suffix={getTechUnit(UnitType.Odometer, true)}
            />
          </HeadlineKilometers>
        </Stack>
      </Intervals>
      <Typography variant="h6" sx={{ mt: 0.5, mb: 0.5, lineHeight: '1.25em' }}>
        {name}
      </Typography>
      {!isWidget && <Divider sx={{ mb: 1.5, opacity: 0.6 }} />}
      {onEdit ? (
        <Button
          onClick={onEdit}
          variant="outlined"
          size="small"
          color="secondary"
          startIcon={<EditRoadIcon />}
        >
          Upravit interval
        </Button>
      ) : (
        <Labels>
          {lowestDueDate &&
          <Chip size="small" sx={{ mr: 1, pl: 0.5 }}
            icon={<CalendarMonthIcon fontSize="small" />}
            color={getColorOfChip(calculateDueDate(lowestDueDate.periodEndDate))}
            label={
              <NumberFormat
                prefix={`${strSign(calculateDueDate(lowestDueDate.periodEndDate))} `}
                value={Math.abs(calculateDueDate(lowestDueDate.periodEndDate))}
                suffix=" dny"
                displayType="text"
                thousandSeparator={THOUSAND_SEPARATOR}
              />
            }
          />
          }
          {dueDistance &&
          <Chip
            size="small"
            icon={<SpeedIcon fontSize="small" />}
            color={getColorOfChip(dueDistance)}
            sx={{ maxWidth: '126px', pl: 0.5 }}
            label={
              <NumberFormat
                prefix={`${strSign(dueDistance)} `}
                value={Math.abs(dueDistance)}
                suffix={getTechUnit(UnitType.Odometer, true)}
                displayType="text"
                thousandSeparator={THOUSAND_SEPARATOR}
              />
            }
          />
          }
        </Labels>
      )}
    </>
  );
};

export default Summary;
