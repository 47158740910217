import React, { FC } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IProps, OwnerState } from './types';
import { ImageContainer } from './styles';

// todo: create custom component here
// todo: image should have some ImageContainer -> with styling
const Image: FC<IProps> = ({
  src,
  alt,
  width,
  height,
  effect,
  placeholder,
  radius,
  fit,
  containerProps,
  padding = 'medium',
}) => {
  const ownerState: OwnerState = {
    radius,
    containerProps,
    fit,
    padding,
  };

  return (
    <ImageContainer ownerState={ownerState}>
      <LazyLoadImage
        src={src}
        alt={alt}
        width={width}
        height={height}
        effect={effect}
        placeholder={placeholder}
      />
    </ImageContainer>
  );
};

export default Image;
