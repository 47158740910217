import { styled } from '@mui/material/styles';
import {
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  ListItemText,
  MenuItem as MuiMenuItem, Link,
} from '@mechis/elements';

export const Section = styled('section', {
  name: 'AppMenu',
  slot: 'Root',
})(() => ({
  
}));

export const WhiteText = styled(Typography, {
  name: 'AppMenu',
  slot: 'WhiteText',
})(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const BottomNav = styled(BottomNavigation, {
  name: 'AppMenu',
  slot: 'BottomNavigation',
})(({ theme }) => ({
  bottom: theme.spacing(1),
  background: 'transparent',
  opacity: 0.9,
}));

export const BottomNavAction = styled(BottomNavigationAction, {
  name: 'AppMenu',
  slot: 'BottomNavigationAction',
})(({ theme }) => ({
  padding: 0,
  color: theme.palette.common.white,
  opacity: 0.7,
}));

export const ItemTextLink = styled(ListItemText, {
  name: 'AppMenu',
  slot: 'ItemTextHeadline',
})(() => ({
  '& > span': {
    fontSize: '0.85rem',
    lineHeight: 1,
    opacity: 0.7,
  },
}));

export const ItemFileLink = styled(Link, {
  name: 'AppMenu',
  slot: 'ItemTextHeadline',
})(() => ({
  '& > span': {
    fontSize: '0.85rem',
    lineHeight: 1,
    opacity: 0.7,
  },
}));

export const MenuItem = styled(MuiMenuItem, {
  name: 'AppMenu',
  slot: 'MenuItem',
})(({ theme }) => ({
  minHeight: theme.spacing(1),
  paddingTop: theme.spacing(1.2),
  paddingBottom: theme.spacing(1.2),
}));

