import {
  DEVICE_CATEGORY_CONSTRUCTIONS_MACHINE,
  DEVICE_CATEGORY_PASSANGER_CAR,
  DEVICE_CATEGORY_TRUCK,
  DEVICE_CATEGORY_TRAILER,
  DEVICE_CATEGORY_GARDEN_MACHINE,
  DEVICE_CATEGORY_MOTORCYCLE,
  DEVICE_CATEGORY_OTHER,
  DEVICE_CATEGORY_VETERAN,
  DEVICE_CATEGORY_RACING,
} from '@configurations/constants/deviceCategory';
import { CommonIcons } from './types';

const useIconNameResolverByDeviceCategoryId = (deviceCategoryId: number): CommonIcons => {
  switch (deviceCategoryId) {
    case DEVICE_CATEGORY_PASSANGER_CAR:
      return 'mechisCar';
    case DEVICE_CATEGORY_TRUCK:
      return 'mechisTruck';
    case DEVICE_CATEGORY_CONSTRUCTIONS_MACHINE:
      return 'mechisCrane';
    case DEVICE_CATEGORY_TRAILER:
      return 'mechisTrailer';
    case DEVICE_CATEGORY_GARDEN_MACHINE:
      return 'mechisMower';
    case DEVICE_CATEGORY_MOTORCYCLE:
      return 'moto';
    case DEVICE_CATEGORY_OTHER:
      return 'mechisCar';
    case DEVICE_CATEGORY_VETERAN:
      return 'mechisVintageCar';
    case DEVICE_CATEGORY_RACING:
      return 'mechisSportCar';
    default:
      return 'mechisCar';
  }
};

export default useIconNameResolverByDeviceCategoryId;
