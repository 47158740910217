import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

const LAYOUT_SPACING_DESKTOP = 1;
const LAYOUT_SPACING_MOBILE = 0;
const LAYOUT_BREAK_POINT = 'sm';

export const Layout = styled('div', {
  name: 'FrontEndLayout',
  slot: 'Root',
})(({ theme }) => ({
  width: '100%',
  height: '100vh',
  padding: theme.spacing(LAYOUT_SPACING_DESKTOP),
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.down(LAYOUT_BREAK_POINT)]: {
    flexDirection: 'column',
    padding: theme.spacing(LAYOUT_SPACING_MOBILE),
    height: 'auto',
  },
}));

export const Sidebar = styled('aside', {
  name: 'FrontEndLayout',
  slot: 'Sidebar',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '30vw',
  marginRight: '2vw',
  padding: theme.spacing(2),
  ...theme.mixins.bgGradientSolidVault,
  borderTopLeftRadius: `${theme.radiuses.medium}px`,
  borderBottomLeftRadius: `${theme.radiuses.medium}px`,

  '.MuiBox-root': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [theme.breakpoints.down(LAYOUT_BREAK_POINT)]: {
    width: '100%',
  },

  ...(isMobile && {
    height: '45vh',
    borderTopLeftRadius: '0px',
    borderBottomRightRadius: `${theme.radiuses.large}px`,
    borderBottomLeftRadius: `${theme.radiuses.large}px`,
    ...theme.mixins.bgGradientSolidVaultMobile,
  }),
}));

export const Navigation = styled('nav', {
  name: 'FrontEndLayout',
  slot: 'Navigation',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  position: 'relative',
  right: theme.spacing(-2),
  justifyContent: 'space-around',
  height: '180px',

  '& > button': {
    // todo: replace this with system color, DONT FORGET ITS A NEW COLOR
    color: '#ffffff',
    opacity: 0.8,
    padding: theme.spacing(2),
    fontSize: '1.1rem',
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 600,
    borderTopLeftRadius: `${theme.radiuses.large}px`,
    borderBottomLeftRadius: `${theme.radiuses.large}px`,

    '&.active': {
      // todo: replace this with system color
      background: '#f6f6f6',
      // todo: replace this with system color, DONT FORGET ITS A NEW COLOR
      color: '#3d85ae',
      opacity: 1,
    },
  },
}));

export const SidebarFooter = styled('div', {
  name: 'FrontEndLayout',
  slot: 'SidebarFooter',
})(() => ({
  minHeight: '24px',
}));

export const Main = styled('main', {
  name: 'FrontEndLayout',
  slot: 'Header',
})(({ theme }) => ({
  width: '70vw',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down(LAYOUT_BREAK_POINT)]: {
    width: '100%',
    marginTop: '-45vh',
    padding: theme.spacing(4),
  },
}));

export const Header = styled('header', {
  name: 'FrontEndLayout',
  slot: 'Header',
})(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row-reverse',
}));

export const Footer = styled('footer', {
  name: 'FrontEndLayout',
  slot: 'Footer',
})(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}));
