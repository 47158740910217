import React, { FC, useEffect, useState } from 'react';
import { IProps } from './types';
import { Layout, ContainerNavigation, ContainerContent } from './styles';
import { Outlet } from 'react-router-dom';
import RootLayout from '@mechis/layouts/RootLayout';
import AppNavigation from '@mechis/sections/AppNavigation';
import AppHeader from '@mechis/sections/AppHeader';
import AppContent from '@mechis/sections/AppContent';
import useAuth from '@hooks/useAuth';
import { DEVICE_ID } from '@configurations/constants/app';
import { DevicesIdsQuery, useDevicesIdsQuery } from '@state/mechis-backend/generated/schema';
import { useIsDesktopSupported } from '@hooks/useIsDesktopSupported';
import { AppHeaderWidgetProvider } from '@hooks/useAppHeaderWidget';
import useNavigate from '@hooks/useNavigate';
import WarningModal from '@mechis/blocks/WarningModal';
import useDevices from '@hooks/useDevices';

const getIdOfLatestAddedDevice = (devicesIds: DevicesIdsQuery | undefined) => {
  if (!devicesIds) {
    return null;
  }
  return devicesIds.devices.flatMap((n) => n.id).pop() ?? null;
};

const AppLayout: FC<IProps> = () => {
  const { isLoggedIn, isLoggedInLoading } = useAuth();
  const { 
    navigate,
    isBlocking,
    pendingNavigation,
    setPendingNavigation,
    confirmNavigation,
    pathname,
  } = useNavigate();
  const { userHasNoDevices} = useDevices();

  const localStorageDeviceID = localStorage.getItem(DEVICE_ID);
  const { data: devicesIds} = useDevicesIdsQuery({
    fetchPolicy: 'cache-first',
  });
  const latestDeviceId = getIdOfLatestAddedDevice(devicesIds);
  const [ isDialog, setIsDialog ] = useState(false);

  const handleCloseDialog = () => {
    setPendingNavigation(null);
    setIsDialog(false);
  };

  useIsDesktopSupported();

  useEffect(() => {
    if (isBlocking && pendingNavigation) {
      setIsDialog(true);
    }
  }, [ isBlocking, pendingNavigation ]);

  useEffect(() => {
    if (isLoggedIn) {
      if (localStorageDeviceID === null && latestDeviceId !== null) {
        localStorage.setItem(DEVICE_ID, latestDeviceId);
      }
    }
  }, [ isLoggedIn, latestDeviceId ]);

  useEffect(() => {
    if (!isLoggedIn && !isLoggedInLoading) {
      navigate('/');
    }
  }, [ isLoggedIn, isLoggedInLoading ]);

  useEffect(() => {
    const exceptions = [
      /^\/new-tech(\/.*)?$/,
      '/mechis',
      '/my-profile',
      '/settings',
    ];
  
    const isException = exceptions.some((exception) => {
      if (typeof exception === 'string') {
        return pathname === exception;
      }
      if (exception instanceof RegExp) {
        return exception.test(pathname);
      }
      return false;
    });
  
    if (userHasNoDevices && !isException) {
      navigate('/app');
    }
  }, [ userHasNoDevices, pathname ]);

  return (
    <RootLayout>
      <WarningModal
        open={isDialog}
        onClose={handleCloseDialog}
        confirmBtnText="Ano"
        onConfirm={confirmNavigation}
      >
        {(
          pathname === '/new-maintenance' ||
          pathname === '/new-maintenance/1' ||
          pathname === '/new-maintenance/2' ||
          pathname === '/new-maintenance/3' ||
          pathname === '/new-odometer' ||
          pathname === '/new-expenses' ||
          pathname === '/new-expenses/note' ||
          pathname === '/new-refueling' ||
          pathname === '/new-refueling/note'
        ) && (
          <>Opravdu chcete opustit rozpracovaný záznam? Vyplněné údaje se smažou.</>
        )}
      </WarningModal>
      <Layout>
        <ContainerContent>
          <AppHeaderWidgetProvider>
            <AppHeader />
            <AppContent>
              <Outlet />
            </AppContent>
          </AppHeaderWidgetProvider>
        </ContainerContent>
        <ContainerNavigation>
          <AppNavigation />
        </ContainerNavigation>
      </Layout>
    </RootLayout>
  );
};

export default AppLayout;
