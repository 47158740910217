import React, { FC, useState, useEffect } from 'react';
import { CloudinaryImage } from '@cloudinary/url-gen';
import {
  CLAUDINARY_CLOUD_NAME,
  CLAUDINARY_DEFAULT_IMAGE_HEIGHT,
  CLAUDINARY_DEFAULT_IMAGE_WIDTH,
} from '@configurations/constants/app';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { AdvancedImage } from '@cloudinary/react';
import { IProps } from './types';

const ClaudinaryImageBuilder: FC<IProps> = ({
  imageId,
  width,
  height,
}) => {

  const [ imgUrl, setImageUrl ] = useState<CloudinaryImage | undefined>();

  useEffect(() => {
    if (width && height) {
      setImageUrl(new CloudinaryImage(imageId, {cloudName: CLAUDINARY_CLOUD_NAME}).resize(
        fill()
          .width(width)
          .height(height)
      ));
    } else if (width && !height) {
      // pokud jen jeden rozměr = stejné poměry stran
      setImageUrl(new CloudinaryImage(imageId, {cloudName: CLAUDINARY_CLOUD_NAME}).resize(
        fill()
          .width(width)
          .height(width)
      ));
    } else {
      setImageUrl(new CloudinaryImage(imageId, {cloudName: CLAUDINARY_CLOUD_NAME}).resize(
        fill()
          .width(CLAUDINARY_DEFAULT_IMAGE_HEIGHT)
          .height(CLAUDINARY_DEFAULT_IMAGE_WIDTH)
      ));
    }
  }, [ imageId ]);

  return (
    <>
      {imgUrl !== undefined &&
        <AdvancedImage cldImg={imgUrl} id="advanced-image" />
      }
    </>
  );
};

export default ClaudinaryImageBuilder;
