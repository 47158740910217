import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { IAppQuery } from '@state/queries/app/types';
import { GET_APP } from '@state/queries/app';
import appCacheControl from '@state/mutations/app';
import addDeviceCacheControl from '@state/mutations/addDevice';
import {
  Grid,
  Typography,
  Button,
} from '@mechis/elements';
import useAuth from '@hooks/useAuth';
import useIcon from '@mechis/icons/useIcon';
import TechSelector from '@mechis/blocks/TechSelector';
import { useTranslation } from 'react-i18next';
import { IAddFlow } from '@state/models/app/types';
import CreateTechModal from './CreateTechModal';
import { getDefaultFlowPrerequisiteByFlow } from './FlowPrerequisite';
import useHeader from '@hooks/useHeader';
import QuoteBlock from '@mechis/blocks/QuoteBlock';

const TechSelection = () => {
  const [ isTechSelected, setIsTechSelected ] = useState<boolean>(false);
  const { t } = useTranslation();
  const { data } = useQuery<IAppQuery>(GET_APP);
  const { isDeviceCountExceeded, membership } = useAuth();
  const navigate = useNavigate();
  const addFlow = data?.app.addFlow;
  const addFlowStep = data?.app.addFlowStep;

  useEffect(() => {
    if (addFlow && addFlowStep) navigate(`/app/new-tech/${addFlow}/${addFlowStep}`);
  }, [ addFlow, addFlowStep ]);

  const { setAddFlow } = appCacheControl;
  const { setFlowPrerequisite } = addDeviceCacheControl;
  useHeader({ headline: 'addDevice.mainTitle' });

  const onAddFlow = (flow: IAddFlow) => {
    setIsTechSelected(true);
    const prerequisite = getDefaultFlowPrerequisiteByFlow(flow);
    setFlowPrerequisite(prerequisite.deviceCategory, prerequisite.vehicleType);
    setAddFlow(flow);
  };

  return (
    <>
      <CreateTechModal 
        isOpen={isTechSelected}
        onClose={() => setIsTechSelected(false)}
      />

      {!isDeviceCountExceeded && (
        <Grid spacing={2} container>
          <Grid xs={6} item>
            <TechSelector
              title={t('deviceCategories.passenger-car')}
              onClick={() => onAddFlow('passenger-car')}
              icon={useIcon('mechisCar', {
                color: 'primary',
                sx: {
                  fontSize: '56px',
                },
              })}
              disabled={isDeviceCountExceeded ? true : false}
            />
          </Grid>
          <Grid xs={6} item>
            <TechSelector
              title={t('deviceCategories.motorcycle')}
              onClick={() => onAddFlow('motorcycle')}
              icon={useIcon('moto', {
                color: 'primary',
                sx: {
                  fontSize: '56px',
                },
              })}
              disabled={isDeviceCountExceeded ? true : false}
            />
          </Grid>

          {/* NOTE: please do not delete this - just hidden for now, but will be redeclared in the near future
      <Grid xs={6} item>
        <TechSelector
          title="Nákladní Auto"
          onClick={() => onAddFlow('truck')}
          icon={useIcon('mechisTruck', {
            color: 'primary',
            sx: {
              fontSize: '56px',
            },
          })}
          disabled={true}
        />
      </Grid>
        */}

          {/*
      <Grid xs={6} item>
        <TechSelector
          title="Veterán"
          onClick={() => onAddFlow('veteran')}
          icon={useIcon('mechisVintageCar', {
            color: 'primary',
            sx: {
              fontSize: '56px',
            },
          })}
          disabled={true}
        />
      </Grid>
        */}

          {/*
      <Grid xs={6} item>
        <TechSelector
          title="Závodní"
          onClick={() => onAddFlow('racing')}
          icon={useIcon('mechisSportCar', {
            color: 'primary',
            sx: {
              fontSize: '56px',
            },
          })}
          disabled={true}
        />
      </Grid>
      */}

          {/*
      <Grid xs={6} item>
        <TechSelector
          title="Přívěs"
          onClick={() => onAddFlow('trailer')}
          icon={useIcon('mechisTrailer', {
            color: 'primary',
            sx: {
              fontSize: '56px',
            },
          })}
          disabled={true}
        />
      </Grid>
      */}

          {/*
      <Grid xs={6} item>
        <TechSelector
          title="Stavební stroj"
          onClick={() => onAddFlow('constructions-machine')}
          icon={useIcon('mechisCrane', {
            color: 'primary',
            sx: {
              fontSize: '56px',
            },
          })}
          disabled={true}
        />
      </Grid>
      */}

          {/*
      <Grid xs={6} item>
        <TechSelector
          title="Zahradní technika"
          onClick={() => onAddFlow('garden-machinery')}
          icon={useIcon('mechisMower', {
            color: 'primary',
            sx: {
              fontSize: '56px',
            },
          })}
          disabled={true}
        />
      </Grid>
        */}

          {/*
      <Grid xs={6} item>
        <TechSelector
          title="Ostatní"
          onClick={() => onAddFlow('other')}
          icon={<DevicesOtherIcon color="primary" sx={{ fontSize: '56px' }} />}
          disabled={false}
        />
      </Grid>
      */}
        </Grid>
      )}
      {isDeviceCountExceeded && (
        <div>
          <QuoteBlock>
            <Typography>
              V rámci uživatelského profilu <strong>{t(`membership.name.${membership?.name}`)}</strong> je 
              počet techniky limitován na {membership?.deviceCountMembership} kusů.
            </Typography>
            <Typography>Změnu profilu můžeš provést zde:</Typography>
          </QuoteBlock>

          <Button
            onClick={() => navigate('/app/mechis?openModalAccounts=true')}
            variant="contained"
            color="secondary"
            sx={{
              marginTop: '16px',
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
            }}
          >
            Změna profilu
          </Button>
        </div>
      )}
    </>
  );
};

export default TechSelection;
