import { styled } from '@mui/material/styles';
import { Stack } from '@mechis/elements';

export const Block = styled(Stack, {
  name: 'RecordListBlock',
  slot: 'Root',
})(() => ({
  position: 'relative',
}));

export const Divider = styled('div', {
  name: 'RecordListBlock',
  slot: 'Divider',
})(() => ({
  position: 'absolute',
  top: 0,
  left: '18px',
  width: '1px',
  height: '100%',
  borderRight: '1px dashed lightgrey',
  zIndex: -1,
}));

