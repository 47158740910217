export const EVENT_TYPE_ODOMETER = 1;
export const EVENT_TYPE_REFUELING = 2;
export const EVENT_TYPE_MAINTENANCE = 3;
export const EVENT_TYPE_REMINDER = 4;
export const EVENT_TYPE_EXPENSE = 5;
export const EVENT_TYPE_INCOME = 6;
export const EVENT_TYPE_FAULT = 7;

export const ALL_EVENT_TYPES = [
  EVENT_TYPE_ODOMETER,
  EVENT_TYPE_REFUELING,
  EVENT_TYPE_MAINTENANCE,
  EVENT_TYPE_REMINDER,
  EVENT_TYPE_EXPENSE,
  EVENT_TYPE_INCOME,
  EVENT_TYPE_FAULT,
];

export type EventType = 
  typeof EVENT_TYPE_ODOMETER | 
  typeof EVENT_TYPE_REFUELING | 
  typeof EVENT_TYPE_MAINTENANCE | 
  typeof EVENT_TYPE_REMINDER | 
  typeof EVENT_TYPE_EXPENSE | 
  typeof EVENT_TYPE_INCOME | 
  typeof EVENT_TYPE_FAULT
