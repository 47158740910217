import React, { FC } from 'react';
import { IProps } from './types';
import { Accordion, AccordionSummary, AccordionDetails } from './styles';
import { Typography, IconButton } from '@mechis/elements';
import { useNavigate } from 'react-router-dom';

const AccordionMenuItem: FC<IProps> = ({
  id,
  expanded,
  handleChange,
  children,
  link,
  icon,
  title,
  iconColor,
}) => {
  const navigate = useNavigate();

  const resolveChange = () => {
    if (!children && link) {
      navigate(link);
    } else {
      handleChange(id);
    }
  };

  return (
    <div>
      <Accordion expanded={expanded === id ? true : false} onChange={resolveChange}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <IconButton color={iconColor ?? 'primary'} isSquared size="small">
            {icon}
          </IconButton>
          <Typography fontSize="0.85em" sx={{ ml: 1.5, fontWeight: expanded === id ? 'bold' : 'normal' }}>
            {title}
          </Typography>
        </AccordionSummary>
        {children && (
          <AccordionDetails>
            {children}
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
};

export default AccordionMenuItem;
