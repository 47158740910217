import React, { FC, ChangeEvent } from 'react';
import { IProps } from './types';
import { NativeSelect } from '@mechis/elements';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import { MOTORCYCLE_DEVICE_CATEGORY_ID } from '@screens/NewTech/types';
import useSortedVehicleTypes from '@hooks/useSortedVehicleTypes';

const TypeMotorcycle: FC<IProps> = () => {
  const { t } = useTranslation();
  const { sortedVehicleTypes } = useSortedVehicleTypes(MOTORCYCLE_DEVICE_CATEGORY_ID);
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { setVehicleType } = addDeviceCacheControl;

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setVehicleType({
      id: parseInt(e.currentTarget.value),
    });
  };

  const getOptions = () => {
    return sortedVehicleTypes.map((vehicleTypeItem) => {
      return <option color="black" key={vehicleTypeItem.id} value={vehicleTypeItem.id}>{t(`vehicleTypes.${vehicleTypeItem.name}`)}</option>;
    });
  };

  return (
    <>
      <NativeSelect
        label={t('addDevice.components.vehicleType.name')}
        id="vehicleType"
        fullWidth
        defaultValue={data?.addDevice.vehicleType?.id}
        onChange={onChange}
      >
        {getOptions()}
      </NativeSelect>
    </>
  );
};

export default TypeMotorcycle;
