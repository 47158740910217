import { Box, Grid, Stack, Typography, Skeleton } from '@mechis/elements';
import TechCard from '@mechis/blocks/TechCard';
import React, { useMemo } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import { Dictionary } from 'lodash';
import { compareAsc } from 'date-fns';
import useDevices from '@hooks/useDevices';
import { IProps } from './types';

export const DeviceListingWithSelection: React.FC<IProps> = ({
  addSelectedDevice,
  isInModal,
  selectedDeviceIds,
}) => {
  const { data, loading, error } = useDevices();
  const { t } = useTranslation();

  const deviceCategoryPriority: Dictionary<number> = {
    'passenger-car': 0,
    motorcycle: 1,
    truck: 2,
    racing: 3,
    veteran: 4,
    'constructions-machine': 5,
    'garden-machinery': 6,
    trailer: 7,
    other: 8,
  };

  const sortDeviceCategoriesByPriority = (first: string | undefined, second: string | undefined) => {
    if (!first || !second) return 0;
    if (deviceCategoryPriority[first] < deviceCategoryPriority[second]) return -1;
    if (deviceCategoryPriority[first] > deviceCategoryPriority[second]) return 1;
    return 0;
  };

  const relevantDeviceCategories: Array<string | undefined> = useMemo(() => Array.from(
    new Set(data?.devices
      .map((device) => device.deviceCategory?.name)
    )), [ data ]);

  if (loading) {
    return (
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
          <Skeleton
            variant="rounded"
            sx={{
              minWidth: '60px',
              minHeight: '60px',
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: '60px',
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
          <Skeleton
            variant="rounded"
            sx={{
              minWidth: '60px',
              minHeight: '60px',
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: '60px',
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
          <Skeleton
            variant="rounded"
            sx={{
              minWidth: '60px',
              minHeight: '60px',
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: '60px',
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
          <Skeleton
            variant="rounded"
            sx={{
              minWidth: '60px',
              minHeight: '60px',
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: '60px',
            }}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={4}>
          <Skeleton
            variant="rounded"
            sx={{
              minWidth: '60px',
              minHeight: '60px',
            }}
          />
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: '60px',
            }}
          />
        </Stack>
      </Stack>
    );
  }

  if (error) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <ErrorIcon />
        <Typography variant="sectionHeadline">{t('snackbar.messages.HTTP_CALL_ERROR')}</Typography>
      </Grid>
    );
  }

  const opacityBySelection = (id: string) => {
    if (selectedDeviceIds.includes(Number(id))) {
      return 1;
    }
    return 0.5;
  };

  return (
    <>
      {relevantDeviceCategories
        .sort(sortDeviceCategoriesByPriority)
        .map((categoryName) => (
          <Box key={categoryName} mb={4}>
            <Typography variant="sectionHeadline">
              {isInModal ? (
                <small>
                  {t(`deviceCategories.${categoryName}`)}
                </small>
              ) : t(`deviceCategories.${categoryName}`)}
            </Typography>
            <Stack spacing={2}>
              {data?.devices
                .filter((device) => device.deviceCategory?.name === categoryName)
                .sort((a, b) => compareAsc(new Date(a.creationDate ?? ''), new Date(b.creationDate ?? '')))
                .map((device, i) =>
                  <Box key={device.id} style={{ opacity: opacityBySelection(device.id)}}>
                    <TechCard
                      isEven={i % 2 === 0}
                      title={device.name}
                      key={device.id}
                      vrp={device.spz}
                      deviceCategoryId={Number(device.deviceCategory?.id) ?? 1}
                      serialNumber={device.serialNumber}
                      imageId={device?.thumbnailImage?.storageName}
                      odometerState={device.actualOdometerState}
                      odometerUnit={device.odometerUnit?.unitName}
                      onClick={() => {
                        addSelectedDevice(Number(device.id));
                      }}
                    />
                  </Box>
                )}
            </Stack>
          </Box>
        ))}
    </>
  );
};

