import { ReactiveVar } from '@apollo/client';
import selections from '@state/models/selections';
import { ISelectionsModel } from '../models/selections/types';
import { ControlState } from '@mechis/sections/Selections/types';
import { ITagOption } from '@mechis/sections/Selections/TagFilters/types';
import { Attachment } from '@state/mechis-backend/generated/schema';

const addPendingAttachmentId = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (attachmentId: number) => {
    const currentModel = selectionsModel();
    const model: ISelectionsModel = {
      ...currentModel,
      pendingAttachmentIds: [ ...currentModel.pendingAttachmentIds, attachmentId ],
    };
    selectionsModel(model);
  };
};

const setDateValue = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (dateValue: Date) => {
    const model = {
      ...selectionsModel(),
      dateValue,
    };
    selectionsModel(model);
  };
};

const setDateState = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (dateState: ControlState) => {
    const model = {
      ...selectionsModel(),
      dateState,
    };
    selectionsModel(model);
  };
};

const setOdometerValue = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (odometerValue: number) => {
    const model = {
      ...selectionsModel(),
      odometerValue,
    };
    selectionsModel(model);
  };
};

const setOdometerState = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (odometerState: ControlState) => {
    const model = {
      ...selectionsModel(),
      odometerState,
    };
    selectionsModel(model);
  };
};

const setTags = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (tags: ITagOption[]) => {
    const model = {
      ...selectionsModel(),
      tags,
    };
    selectionsModel(model);
  };
};

const setFilterDateFrom = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterDateFrom: Date) => {
    const model = {
      ...selectionsModel(),
      filterDateFrom,
    };
    selectionsModel(model);
  };
};

const setFilterDateTo = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterDateTo: Date) => {
    filterDateTo.setHours(23, 59, 59, 999);
    const model = {
      ...selectionsModel(),
      filterDateTo,
    };
    selectionsModel(model);
  };
};

const setFilterOdometerFrom = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterOdometerFrom: number) => {
    const model = {
      ...selectionsModel(),
      filterOdometerFrom,
    };
    selectionsModel(model);
  };
};

const setFilterOdometerTo = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterOdometerTo: number) => {
    const model = {
      ...selectionsModel(),
      filterOdometerTo,
    };
    selectionsModel(model);
  };
};

const setFilterPriceFrom = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterPriceFrom: number) => {
    const model = {
      ...selectionsModel(),
      filterPriceFrom,
    };
    selectionsModel(model);
  };
};

const setFilterPriceTo = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterPriceTo: number) => {
    const model = {
      ...selectionsModel(),
      filterPriceTo,
    };
    selectionsModel(model);
  };
};

const setFilterIsDate = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterIsDate: boolean) => {
    const model = {
      ...selectionsModel(),
      filterIsDate,
    };
    selectionsModel(model);
  };
};

const setFilterIsOdometer = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterIsOdometer: boolean) => {
    const model = {
      ...selectionsModel(),
      filterIsOdometer,
    };
    selectionsModel(model);
  };
};

const setFilterIsPrice = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterIsPrice: boolean) => {
    const model = {
      ...selectionsModel(),
      filterIsPrice,
    };
    selectionsModel(model);
  };
};

const setFilterIsRecordOdometer = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterIsRecordOdometer: boolean) => {
    const model = {
      ...selectionsModel(),
      filterIsRecordOdometer,
    };
    selectionsModel(model);
  };
};

const setFilterIsRecordRefueling = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterIsRecordRefueling: boolean) => {
    const model = {
      ...selectionsModel(),
      filterIsRecordRefueling,
    };
    selectionsModel(model);
  };
};

const setFilterIsRecordExpenses = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterIsRecordExpenses: boolean) => {
    const model = {
      ...selectionsModel(),
      filterIsRecordExpenses,
    };
    selectionsModel(model);
  };
};

const setFilterIsRecordMaintenance = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterIsRecordMaintenance: boolean) => {
    const model = {
      ...selectionsModel(),
      filterIsRecordMaintenance,
    };
    selectionsModel(model);
  };
};

const setIsFilterModalOpen = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (filterIsModalOpen: boolean) => {
    const model = {
      ...selectionsModel(),
      filterIsModalOpen,
    };
    selectionsModel(model);
  };
};

const setImageAttachments = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (imageAttachments: Partial<Attachment>[]) => {
    const model = {
      ...selectionsModel(),
      imageAttachments: [ ...imageAttachments ],
    };
    selectionsModel(model);
  };
};

const setFileAttachments = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return (fileAttachments: Partial<Attachment>[]) => {
    const model = {
      ...selectionsModel(),
      fileAttachments: [ ...fileAttachments ],
    };
    selectionsModel(model);
  };
};

const resetMutationsInputSelections = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return () => {
    const model = {
      ...selectionsModel(),
      dateValue: new Date(),
      dateState: 'default' as ControlState,
      odometerValue: 0,
      odometerState: 'default' as ControlState,
      imageAttachments: [],
      fileAttachments: [],
    };
    selectionsModel(model);
  };
};

const resetPendingAttachmentIds = (selectionsModel: ReactiveVar<ISelectionsModel>) => {
  return () => {
    const model: ISelectionsModel = {
      ...selectionsModel(),
      pendingAttachmentIds: [],
    };
    selectionsModel(model);
  };
};

const selectionsCacheControl = {
  addPendingAttachmentId: addPendingAttachmentId(selections),
  setDateValue: setDateValue(selections),
  setDateState: setDateState(selections),
  setOdometerValue: setOdometerValue(selections),
  setOdometerState: setOdometerState(selections),
  setTags: setTags(selections),
  setFilterDateFrom: setFilterDateFrom(selections),
  setFilterDateTo: setFilterDateTo(selections),
  setFilterOdometerFrom: setFilterOdometerFrom(selections),
  setFilterOdometerTo: setFilterOdometerTo(selections),
  setFilterPriceFrom: setFilterPriceFrom(selections),
  setFilterPriceTo: setFilterPriceTo(selections),
  setFilterIsDate: setFilterIsDate(selections),
  setFilterIsOdometer: setFilterIsOdometer(selections),
  setFilterIsPrice: setFilterIsPrice(selections),
  setFilterIsRecordOdometer: setFilterIsRecordOdometer(selections),
  setFilterIsRecordRefueling: setFilterIsRecordRefueling(selections),
  setFilterIsRecordExpenses: setFilterIsRecordExpenses(selections),
  setFilterIsRecordMaintenance: setFilterIsRecordMaintenance(selections),
  setIsFilterModalOpen: setIsFilterModalOpen(selections),
  setImageAttachments: setImageAttachments(selections),
  setFileAttachments: setFileAttachments(selections),
  resetMutationsInputSelections: resetMutationsInputSelections(selections),
  resetPendingAttachmentIds: resetPendingAttachmentIds(selections),
};

export default selectionsCacheControl;
