import React, {
  useEffect,
  useContext,
  ReactNode,
  createContext,
  useState,
} from 'react';

export interface AppHeaderWidgetContextType {
  appHeaderWidget: ReactNode | null
  setAppHeaderWidget: (content: ReactNode | null) => void
}

export interface AppHeaderWidgetProviderProps {
  children: ReactNode
}

export const AppHeaderWidgetContext = createContext<AppHeaderWidgetContextType | undefined>(undefined);

export const AppHeaderWidgetProvider: React.FC<AppHeaderWidgetProviderProps> = ({ children }) => {
  const [ appHeaderWidget, setAppHeaderWidget ] = useState<ReactNode | null>(null);

  return (
    <AppHeaderWidgetContext.Provider value={{ appHeaderWidget, setAppHeaderWidget }}>
      {children}
    </AppHeaderWidgetContext.Provider>
  );
};

const useAppHeaderWidget = (widget?: ReactNode | null) => {
  // eslint-disable-next-line
  const { appHeaderWidget, setAppHeaderWidget } = useContext(AppHeaderWidgetContext) as AppHeaderWidgetContextType;

  useEffect(() => {
    if (widget) {
      setAppHeaderWidget(widget);
    }
    return () => {
      if (widget) {
        setAppHeaderWidget(null);
      }
    };
  }, []);

  return {
    widget: appHeaderWidget,
  };
};

export default useAppHeaderWidget;
