import React, { FC } from 'react';
import { IProps } from './types';
import {
  TextField,
  InputAdornment,
} from '@mechis/elements';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import useIcon from '@mechis/icons/useIcon';

const EngineSpecification: FC<IProps> = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { setEngineSpecification } = addDeviceCacheControl;

  const schema = yup.object({
    engineSpecification: yup.string(),
  }).required();

  const { control, handleSubmit } = useForm<{ engineSpecification: string | null }>({
    defaultValues: {
      engineSpecification: data?.addDevice.engineSpecification,
    },
    resolver: yupResolver(schema),
  });

  const onSave = async ({ engineSpecification }: { engineSpecification: string | null }) => {
    setEngineSpecification(engineSpecification);
  };

  return (
    <Controller
      render={({ field, formState }) => (
        <TextField
          {...field}
          onKeyUp={() => {
            handleSubmit(onSave)();
          }}
          label={t('addDevice.components.engineSpecification.name')}
          id="color"
          error={!!formState.errors.engineSpecification?.message}
          helperText={formState.errors.engineSpecification?.message}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {useIcon('mechisEngineSpecification', { fontSize: 'small' })}
              </InputAdornment>
            ),
          }}
        />
      )}
      name="engineSpecification"
      control={control}
    />
  );
};

export default EngineSpecification;
