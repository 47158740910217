import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mechis/elements';

const MechisMower: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 612.003 612.003" {...props}>
      {/* eslint-disable-next-line */}
      <g>
        <g>
          <path d="M598.875,373.125c-0.163-13.29-10.899-25.548-24.025-27.4l-98.208-14.777v-31.005c0-13.711-11.091-24.882-24.773-25.011
			l-153.323-13.104l-0.454-0.02c-13.793,0-25.014,11.22-25.014,25.013v13.5l-91.271-13.732L155.091,113.45
			c-1.959-12.693-12.787-24.788-25.188-28.134L16.796,54.807C9.69,52.887,2.379,57.095,0.463,64.2s2.289,14.416,9.393,16.333
			l113.108,30.511c2.245,0.605,5.439,4.174,5.794,6.471l26.951,174.668c-3.697,3.943-5.938,9.316-5.985,15.436l-13.111,186.167
			l-0.013,0.375c0,13.792,11.221,25.013,25.014,25.013h10.789l-1.569-6.568c-1.729-7.234-2.605-14.625-2.605-21.968
			c0-52.128,42.408-94.536,94.536-94.536s94.536,42.408,94.536,94.536c0,7.344-0.876,14.734-2.605,21.968l-1.569,6.568h70.799
			l-0.521-5.806c-0.117-1.309-0.198-2.456-0.246-3.526c-0.061-1.234-0.092-2.446-0.092-3.603c0-43.524,35.409-78.934,78.934-78.934
			c43.523,0,78.934,35.409,78.934,78.934c0,1.492-0.054,3.065-0.172,4.951l-0.489,7.861l7.479-2.468
			c11.723-3.87,24.248-11.792,24.248-22.421v-0.289L598.875,373.125z"/>
          <path d="M262.765,428.53c-34.246,0-62.107,27.862-62.107,62.107s27.862,62.107,62.107,62.107s62.107-27.862,62.107-62.107
			S297.01,428.53,262.765,428.53z M262.765,520.77c-16.615,0-30.132-13.518-30.132-30.132c0-16.614,13.518-30.132,30.132-30.132
			c16.614,0,30.132,13.518,30.132,30.132C292.897,507.252,279.379,520.77,262.765,520.77z"/>
          <path d="M502,454.822c-28.353,0-51.419,23.067-51.419,51.418c0,28.353,23.067,51.42,51.419,51.42
			c28.352,0,51.419-23.068,51.419-51.42S530.353,454.822,502,454.822z M502,531.014c-13.66,0-24.773-11.115-24.773-24.774
			c0-13.659,11.113-24.772,24.773-24.772s24.773,11.113,24.773,24.772C526.773,519.9,515.66,531.014,502,531.014z"/>
        </g>
      </g>
    </SvgIcon>
  );
};

export default MechisMower;
