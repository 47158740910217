import { ReactiveVar, makeVar } from '@apollo/client';
import { IFlashMessage } from './types';
 
export const init: IFlashMessage = {
  severity: 'error',
  messageCode: null,
  timestamp: 0,
};

const snackbar: ReactiveVar<IFlashMessage> = makeVar<IFlashMessage>(init);

export default snackbar;

