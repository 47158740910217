import variants from './variants';
import styleOverrides from './styleOverrides';

const components = {
  MuiInputBase: {
    styleOverrides: styleOverrides['inputBase'],
  },
  MuiAppBar: {
    variants: variants['appbar'],
  },
  MuiButton: {
    styleOverrides: styleOverrides['button'],
    variants: variants['button'],
  },
  MuiToolbar: {
    styleOverrides: styleOverrides['toolbar'],
  },
  MuiBottomNavigation: {
    styleOverrides: styleOverrides['bottomNavigation'],
  },
  MuiBottomNavigationAction: {
    styleOverrides: styleOverrides['bottomNavigationAction'],
  },
  MuiPaper: {
    styleOverrides: styleOverrides['paper'],
    variants: variants['paper'],
  },
  MuiTextField: {
    styleOverrides: styleOverrides['textField'],
    variants: variants['textField'],
  },
  MuiAccordion: {
    styleOverrides: styleOverrides['accordion'],
  },
  MuiListItemText: {
    variants: variants['listItemText'],
  },
  MuiIconButton: {
    variants: variants['iconButton'],
  },
  MuiMenuItem: {
    styleOverrides: styleOverrides['menuItem'],
  },
  MuiCheckbox: {
    variants: variants['checkbox'],
  },
  MuiTooltip: {
    styleOverrides: styleOverrides['tooltip'],
  },
  MuiDialog: {
    styleOverrides: styleOverrides['dialog'],
  },
};

export default components;
