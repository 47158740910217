import { ReactiveVar } from '@apollo/client';
import app from '@state/models/app';
import { IAppModel, IAddFlow } from '../models/app/types';

const setToolbarHeadline = (appModel: ReactiveVar<IAppModel>) => {
  return (toolbarHeadline: string) => {
    const model = {
      ...appModel(),
      toolbarHeadline,
    };
    appModel(model);
  };
};

const setIsMenuOpened = (appModel: ReactiveVar<IAppModel>) => {
  return (isMenuOpened: boolean) => {
    const model = {
      ...appModel(),
      isMenuOpened,
    };
    appModel(model);
  };
};

const setSnackbar = (appModel: ReactiveVar<IAppModel>) => {
  return (severity: 'error' | 'success', messageCode: string) => {
    const model = {
      ...appModel(),
      snackbar: {
        severity,
        messageCode,
        timestamp: Date.now(),
      },
    };
    appModel(model);
  };
};

const setAddFlow = (appModel: ReactiveVar<IAppModel>) => {
  return (addFlow: IAddFlow | boolean) => {
    const model = {
      ...appModel(),
      addFlow,
    };
    appModel(model);
  };
};

const setAddFlowStep = (appModel: ReactiveVar<IAppModel>) => {
  return (addFlowStep: number | boolean) => {
    const model = {
      ...appModel(),
      addFlowStep,
    };
    appModel(model);
  };
};

const setBackButtonVisibility = (appModel: ReactiveVar<IAppModel>) => {
  return (backButtonVisible: boolean) => {
    const model = {
      ...appModel(),
      backButtonVisible,
    };
    appModel(model);
  };
};

const setMenuButtonVisibility = (appModel: ReactiveVar<IAppModel>) => {
  return (menuButtonVisible: boolean) => {
    const model = {
      ...appModel(),
      menuButtonVisible,
    };
    appModel(model);
  };
};

const appCacheControl = {
  setToolbarHeadline: setToolbarHeadline(app),
  setIsMenuOpened: setIsMenuOpened(app),
  setSnackbar: setSnackbar(app),
  setAddFlow: setAddFlow(app),
  setAddFlowStep: setAddFlowStep(app),
  setBackButtonVisibility: setBackButtonVisibility(app),
  setMenuButtonVisibility: setMenuButtonVisibility(app),
};

export default appCacheControl;
