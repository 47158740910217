import { Attachment } from '@state/mechis-backend/generated/schema';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { CloudinaryWidgetSources, ICloudinaryWidgetConfiguration } from '@mechis/blocks/Cloudinary/types';
import {
  CLAUDINARY_CLOUD_NAME,
  CLAUDINARY_UPLOAD_PRESET,
  CLOUDINARY_MAX_IMAGE_SIZE_IN_BYTES,
  CLOUDINARY_MAX_IMAGE_WIDTH_IN_PX,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from '@configurations/constants/app';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { useReactiveVar } from '@apollo/client';
import selections from '@state/models/selections';

export const useAttachments = () => {
  const reactiveSelections = useReactiveVar(selections);

  const cloudinaryConfiguration: ICloudinaryWidgetConfiguration = {
    cloudName: CLAUDINARY_CLOUD_NAME,
    uploadPreset: CLAUDINARY_UPLOAD_PRESET,
    multiple: true,
    cropping: false,
    showAdvancedOptions: true,
    sources: [ CloudinaryWidgetSources.Local, CloudinaryWidgetSources.Url ],
    folder: 'attachment-photos',
    maxImageFileSize: CLOUDINARY_MAX_IMAGE_SIZE_IN_BYTES, //restrict file size to less than 2MB
    maxImageWidth: CLOUDINARY_MAX_IMAGE_WIDTH_IN_PX, //Scales the image down to a width of 2000 pixels before uploading
    theme: 'white',
  };
  
  const firebaseConfig: FirebaseOptions = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ?? '',
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
  };

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  const getSelectionsAttachmentIds = () => {
    return reactiveSelections.imageAttachments.map(
      (attachment) => Number(attachment.id)
    ).concat(
      reactiveSelections.fileAttachments.map(
        (attachment) => Number(attachment.id)
      ));
  };

  const isImageType = (type: string) => {
    const allowedImageTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/webp',
      'image/tiff',
      'image/svg+xml',
      'image/x-icon',
      'jpg',
      'jpeg',
      'png',
      'gif',
      'bmp',
      'webp',
    ];
  
    return allowedImageTypes.includes(type);
  };

  const handleDownload = (file: Partial<Attachment>) => {
    const fileRef = ref(storage, file.storageName ?? '');

    getDownloadURL(fileRef)
      .then((url) => {
        // Create a new anchor element
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', file.originalName ?? ''); 
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  };

  const getImageUrlByStorageName = (storageName: string) => {
    return new CloudinaryImage(storageName, {cloudName: CLAUDINARY_CLOUD_NAME}).resize(
      fill()
        .width(1200)
        .height(800)
    ).toURL();
  };

  return {
    cloudinaryConfiguration,
    firebaseConfig,
    firebase: app,
    claudinary: storage,
    getSelectionsAttachmentIds,
    isImageType,
    getImageUrlByStorageName,
    handleDownload,
  };
};
