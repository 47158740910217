import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mechis/elements';
import Delete from '@mui/icons-material/Delete';
import {
  useDeleteAttachmentMutation,
  useDeleteEventAttachmentMutation,
  useDestroySignatureLazyQuery,
} from '@state/mechis-backend/generated/schema';
import { useTranslation } from 'react-i18next';
import { getStorage, deleteObject, ref } from 'firebase/storage';
import { getIconByMimeType } from '@mechis/blocks/FirebaseAttachmentBuilder';
import { IProps } from './types';
import { MimeTypes } from '@configurations/types/global';
import { initializeApp } from 'firebase/app';

interface IAttachmentDetailState {
  readonly publicId: string
  readonly internalId: string
}

const detailEmptyState: IAttachmentDetailState = {
  publicId: '',
  internalId: '',
};

const UploadedFileAttachmentsList: React.FC<IProps> = ({ eventId, fileAttachments, setFileAttachments, firebaseConfig }) => {
  const { t } = useTranslation();
  const [ runDestroyQuery, { data, error, loading} ] = useDestroySignatureLazyQuery();
  const [ runDeleteEventAttachmentMutation ] = useDeleteEventAttachmentMutation();
  const [ runDeleteAttachmentMutation ] = useDeleteAttachmentMutation();
  const [ isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen ] = useState<boolean>(false);
  const [ attachmentDetails, setAttachmentDetails ] 
    = useState<IAttachmentDetailState>(detailEmptyState);

  const onDeleteAttachmentConfirm = async (attachment: IAttachmentDetailState) => {
    try {
      setIsDeleteConfirmModalOpen(false);
      const app = initializeApp(firebaseConfig);
      const storage = getStorage(app);

      const fileRef = ref(storage, attachment.publicId);

      await deleteObject(fileRef);

      if (eventId) {
        await runDeleteEventAttachmentMutation({
          variables: {
            attachmentId: Number(attachment.internalId),
            eventId: Number(eventId),
          },
        });
      }

      await runDeleteAttachmentMutation({ variables: { deleteAttachmentId: Number(attachment.internalId) }});
      
    } catch (e) {
      console.error('onDeleteAttachmentConfirm: Failed to proceed', e);
    } finally {
      setFileAttachments(attachmentDetails.internalId);
    }
  };

  const handleDeleteFileAttachment = async (attachment: IAttachmentDetailState) => {
    const { publicId} = attachment;
    try {
      setIsDeleteConfirmModalOpen(true);
      setAttachmentDetails(attachment);
      await runDestroyQuery({ variables: { publicId }});
    } catch (e) {
      console.error(e, error);
    }
  };
  
  return (
    <>
      <Box mb={1}>
        <Typography variant="body1">{t('components.attachmentDialog.attachments')}:</Typography>
      </Box>
      {fileAttachments.map((file) => (
        <Box key={file.id} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Box mr={1}>
            {getIconByMimeType(file?.mimeType as MimeTypes)}
          </Box>
          <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" flex={1}>{file.originalName}</Box>
          <div style={{ minWidth: '15%', textAlign: 'right' }}>
            <IconButton
              size="small"
              onClick={() => handleDeleteFileAttachment({
                internalId: file.id,
                publicId: file.storageName ?? '',
              })}
              color="error"
            >
              <Delete fontSize="small" />
            </IconButton>
          </div>
        </Box>
      ))}
      <Dialog
        onClose={() => {
          setIsDeleteConfirmModalOpen(false);
        }}
        open={isDeleteConfirmModalOpen}
        maxWidth="xs"
      >
        <DialogContent>
          <DialogTitle
            sx={{
              fontSize: '1.3rem !important',
              color: (theme) => theme.palette.primary.dark,
              textAlign: 'center',
            }}
          >
            {t('components.attachmentDialog.deleteEnsure')}
          </DialogTitle>

          <Box mt={4} justifyContent="space-evenly" display="flex" flexDirection="row">
            <Button
              variant="outlined"
              disabled={(loading && !data)}
              onClick={() => onDeleteAttachmentConfirm(attachmentDetails)}
            >
              {t('components.attachmentDialog.delete')}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsDeleteConfirmModalOpen(false);
              }}
            >
              {t('components.attachmentDialog.cancel')}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadedFileAttachmentsList;
