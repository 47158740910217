import { useTransmissionsQuery } from '@state/mechis-backend/generated/schema';
import { useTranslation } from 'react-i18next';
import React, {useMemo} from 'react';

export function useGetTransmissionOptions(): React.ReactNode[] {
  const {t} = useTranslation();
  const { data: transmissions } = useTransmissionsQuery();

  const options = useMemo(() => {
    return transmissions?.transmissions.map((transmission) => (
      <option color="white" key={transmission.id} value={transmission.id}>
        {t(`transmission.${transmission.name}`)}
      </option>
    ));
  }, [ transmissions, t ]);

  return options ?? [];
}
