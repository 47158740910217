import React, { FC, useState, useEffect } from 'react';
import { IProps } from './types';
import { Block } from './styles';
import { 
  Button,
  MobileStepper,
} from '@mechis/elements';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useParams, useNavigate } from 'react-router-dom';
import { IAddFlow } from '@state/models/app/types';
import appCacheControl from '@state/mutations/app';
import {useQuery} from '@apollo/client';
import {IAddDeviceQuery} from '@state/queries/addDevice/types';
import {GET_ADD_DEVICE_TMP_STORE} from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';

const AddFlowStepper: FC<IProps> = () => {
  const { t } = useTranslation();
  const { addFlow, stepId } = useParams();
  const [ activeStep, setActiveStep ] = useState(0);
  const navigate = useNavigate();
  const { setAddFlowStep } = appCacheControl;
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);

  const anyAddDevicePropertyIncorrect = data?.addDevice.validationErrors?.some((el) => el.hasError);

  useEffect(() => {
    if (stepId) setActiveStep(+stepId);
  }, [ stepId ]);

  const handleNext = () => {
    setAddFlowStep(activeStep+1);
    navigate(`/app/new-tech/${addFlow}/${activeStep+1}`);
  };

  const handleBack = () => {
    setAddFlowStep(activeStep-1);
    navigate(`/app/new-tech/${addFlow}/${activeStep-1}`);
  };

  const getStepsNumber = () => {
    switch (addFlow as IAddFlow) {
      case 'constructions-machine': return 0;
      case 'garden-machinery': return 0;
      case 'motorcycle': return 6;
      case 'other': return 0;
      case 'passenger-car': return 6;
      case 'racing': return 0;
      case 'trailer': return 0;
      case 'truck': return 0;
      case 'veteran': return 0;
      default: return 0;
    }
  };

  return (
    <Block>
      <MobileStepper
        variant="progress"
        steps={getStepsNumber()}
        position="static"
        activeStep={activeStep}
        LinearProgressProps={{
          color: 'secondary',
        }}
        backButton={
          <Button
            size="medium"
            color="white"
            onClick={handleBack}
            disabled={activeStep === 0 || anyAddDevicePropertyIncorrect}
          >
            <KeyboardArrowLeft id="previous-icon" />
            {t('components.stepper.back')}
          </Button>
        }
        nextButton={
          <Button
            size="medium"
            color="white"
            onClick={() => {
              if ((activeStep === getStepsNumber()-1)) {
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: 'smooth',
                });
              } else {
                handleNext();
              }
            }}
            disabled={anyAddDevicePropertyIncorrect}
          >
            {(activeStep === getStepsNumber()-1) && 
              <>
                {t('components.stepper.finish')}
              </>
            }
            {!(activeStep === getStepsNumber()-1) && 
              <>
                {t('components.stepper.next')}
              </>
            }
            <KeyboardArrowRight id="next-icon" />
          </Button>
        }
      />
    </Block>
  );
};

export default AddFlowStepper;
