import { styled } from '@mui/material/styles';

export const Layout = styled('div', {
  name: 'AddDeviceLayout',
  slot: 'Root',
})(({ theme }) => ({
  marginTop: theme.spacing(-6),
}));

export const AvatarNameContainer = styled('div', {
  name: 'AddDeviceLayout',
  slot: 'AvatarNameContainer',
})(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const Header = styled('div', {
  name: 'AddDeviceLayout',
  slot: 'Header',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px dashed ${theme.palette.divider}`,
  paddingBottom: theme.spacing(1.5),
}));

