import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { shouldForwardProp as sysShouldForwardProp } from '@mui/system';
import { IOwnerState } from './types';

export const Section = styled('main', {
  name: 'AppContent',
  slot: 'Root',
  shouldForwardProp: (prop) => sysShouldForwardProp(prop) && prop !== 'ownerState',
})<{ ownerState: IOwnerState }>(({ theme, ownerState }) => ({
  ...(isMobile && {
    marginTop: `calc(${theme.constants.appHeaderHeightSmall}px + 0px)`,
    ...(!ownerState.isSmallHeader && {
      marginTop: `calc(${theme.constants.appHeaderHeightLarge}px + 30px)`,
    }),
  }),
}));
