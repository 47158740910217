import React from 'react';
import {
  EVENT_TYPE_EXPENSE,
  EVENT_TYPE_FAULT,
  EVENT_TYPE_INCOME,
  EVENT_TYPE_MAINTENANCE,
  EVENT_TYPE_ODOMETER,
  EVENT_TYPE_REFUELING,
  EVENT_TYPE_REMINDER,
  EventType,
} from '@configurations/constants/eventTypes';
import { useEventQuery } from '@state/mechis-backend/generated/schema';
import { FirstAidKitIcon } from '@mechis/icons';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SpeedIcon from '@mui/icons-material/Speed';
import ConstructionIcon from '@mui/icons-material/Construction';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SavingsIcon from '@mui/icons-material/Savings';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { colors } from '@mui/material';

const useEvent = (eventId?: number) => {
  const { data, loading, error } = useEventQuery({
    variables: {
      eventId,
    },
  });

  // todo: fix typescript for fontSize variant
  const eventIcon = (eventType: EventType, fontSize?: 'large' | 'small') => {
    switch (eventType) {
      case EVENT_TYPE_ODOMETER:
        return <SpeedIcon fontSize={fontSize} htmlColor={colors['grey'][800]} />;
      case EVENT_TYPE_REFUELING:
        return <LocalGasStationIcon fontSize={fontSize} htmlColor={colors['indigo'][800]} />;
      case EVENT_TYPE_MAINTENANCE:
        return <ConstructionIcon fontSize={fontSize} htmlColor={colors['grey'][800]} />;
      case EVENT_TYPE_REMINDER:
        return <NotificationsIcon fontSize={fontSize} />;
      case EVENT_TYPE_EXPENSE:
        return <AttachMoneyIcon fontSize={fontSize} htmlColor={colors['red'][800]} />;
      case EVENT_TYPE_INCOME:
        return <SavingsIcon fontSize={fontSize} />;
      case EVENT_TYPE_FAULT:
        return <DangerousIcon fontSize={fontSize} />;
      default:
        return <FirstAidKitIcon fontSize={fontSize} />;
    }
  };

  return {
    name: data?.event?.name,
    loading,
    error,
    eventIcon,
  };
};

export default useEvent;
