import React from 'react';
import useHeader from '@hooks/useHeader';
import AddFlowStepper from '@mechis/sections/AddFlowStepper';
import { useTranslation } from 'react-i18next';
import AddDeviceLayout from '@mechis/layouts/AddDeviceLayout';
import FirstRegistrationDate from '../components/FirstRegistrationDate';
import AcquisitionDate from '../components/AcquisitionDate';
import InitialOdometerState from '../components/InitialOdometerState';
import GuaranteeDate from '../components/GuaranteeDate';
import GuaranteeMonths from '../components/GuaranteeMonths';
import GuaranteeDistance from '../components/GuaranteeDistance';
import { Grid, Stack } from '@mechis/elements';
import FormBlock from '@mechis/blocks/FormBlock';

const Step4 = () => {
  useHeader({
    headline: 'addDevice.passengerCar.step4.title',
    widget: (
      <AddFlowStepper />
    ),
  });

  const { t } = useTranslation();

  return (
    <AddDeviceLayout>
      <Stack spacing={4}>
        <FormBlock headline={t('addDevice.headlines.operationalData')}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FirstRegistrationDate />
            </Grid>
            <Grid item xs={6}>
              <AcquisitionDate />
            </Grid>
            <Grid item xs={12}>
              <InitialOdometerState />
            </Grid>
          </Grid>
        </FormBlock>
        <FormBlock headline={t('addDevice.headlines.warranty')}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <GuaranteeDate />
            </Grid>
            <Grid item xs={6}>
              <GuaranteeMonths />
            </Grid>
            <Grid item xs={12}>
              <GuaranteeDistance />
            </Grid>
          </Grid>
        </FormBlock>
      </Stack>
    </AddDeviceLayout>
  );
};

export default Step4;
