import React, { useEffect, useState } from 'react';
import { ControlState, IOwnerState } from '../types';
import { IProps } from './types';
import {
  Control,
  ControlLabel,
} from '../styles';
import {
  IconButton,
  TextField,
} from '@mechis/elements';
import { getColorByState } from '../Selections';
import TodayIcon from '@mui/icons-material/Today';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { EventByIdDocument, useUpdateEventDateMutation } from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';

const MutableDate: React.FC<IProps> = ({ eventDateAndOdometer, eventId }) => {
  const [ dateState, setDateState ] = useState<ControlState>('default');
  const [ dateValue, setDateValue ] = useState<number>(0);
  const { setSnackbar } = snackbarCacheControl;

  useEffect(() => {
    if (eventDateAndOdometer !== undefined) {
      setDateValue(eventDateAndOdometer.event?.eventDate);
    }
  }, [ eventDateAndOdometer ]);

  const ownerState: IOwnerState = {
    state: dateState,
  };

  const [ updateEventDateMutation ] = useUpdateEventDateMutation({
    onCompleted: () => setSnackbar('success', 'EVENT_UPDATE_SUCCESSFUL'),
    onError: () => setSnackbar('error','EVENT_UPDATE_ERROR'),
    refetchQueries: [
      {
        query: EventByIdDocument,
        variables: {
          eventId: Number(eventId),
        },
      },
    ],
  });

  const handleChange = async (newValue: any) => {
    setDateState('filled');
    setDateValue(newValue);
    await updateEventDateMutation({
      variables: {
        event: {
          id: Number(eventId),
          eventDate: newValue,
        },
      },
    });
  };

  const handleClick = () => {
    const element = document.getElementById('selection-datepicker');
    element?.click();
  };

  return (
    <Control>
      <IconButton
        size="small"
        color={getColorByState(dateState)}
        isSquared
        onClick={() => handleClick()}
      >
        <TodayIcon />
      </IconButton>
      <ControlLabel ownerState={ownerState}>
        <MobileDatePicker
          inputFormat="d/M/yy"
          value={dateValue}
          onChange={(newValue: any) => handleChange(newValue)}
          renderInput={(params) => <TextField id="selection-datepicker" {...params} />}
        />
      </ControlLabel>
    </Control>
  );
};

export default MutableDate;
