import { ReactiveVar } from '@apollo/client';
import editFuel from '@state/models/editFuel';
import _ from 'lodash';
import { IEditFuelItemModel, IEditFuelModel } from '@state/models/editFuel/types';

const setFuel = (editFuelModel: ReactiveVar<IEditFuelModel>) => {
  return (fuel: IEditFuelItemModel) => {
    const fuels = [ ...editFuelModel().fuels ];
    const index = _.findIndex(fuels,
      {
        fuelType: {
          id: fuel?.fuelType?.id,
        },
      },
    );
    if (index !== -1) {
      fuels[index] = fuel;
    } else {
      console.error('No index found in fuels for targeted fuel! Check for setFuel method in state addDevice mutation');
    }
    const model = {
      ...editFuelModel(),
      fuels,
    };
    editFuelModel(model);
  };
};

const setFuels = (editFuelModel: ReactiveVar<IEditFuelModel>) => {
  return (fuels: IEditFuelItemModel[]) => {
    const model = {
      ...editFuelModel(),
      fuels,
    };
    editFuelModel(model);
  };
};

const editFuelCacheControl = {
  setFuel: setFuel(editFuel),
  setFuels: setFuels(editFuel),
};

export default editFuelCacheControl;
