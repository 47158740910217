import { styled } from '@mui/material/styles';

export const ButtonFixed = styled('div', {
  name: 'ScrollButton',
  slot: 'ButtonFly',
})(() => ({
  position: 'fixed',
  right: '5%',
  bottom: '100px',
  fontSize: '3rem',
  textAlign: 'right',
  zIndex: '1',
  cursor: 'pointer',
}));
