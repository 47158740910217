import { alpha } from '@mui/material/styles';
import { radiuses } from '@configurations/themes/default/radiuses';

const variants = [
  {
    props: { color: 'white' },
    style: ({ theme }: any) => ({
      '& > svg': {
        color: `${alpha(theme.palette.common.white, 0.7)} !important`,
      },
    }),
  },
  {
    props: { size: 'small' },
    style: () => ({
      borderRadius: `${radiuses.small}px !important`,
    }),
  },
];

export default variants;
