import React, { FC } from 'react';
import { IProps } from './types';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  blurTarget, 
  ensureNumericZeroIsNotDisplayed,
} from '@utilities/app';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import useUnits from '@hooks/useUnits';
import NumberField from '@mechis/elements/NumberField';

const GuaranteeDistance: FC<IProps> = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { odometerUnitsData } = useUnits();
  const { setGuaranteeDistance, setOdometerUnit } = addDeviceCacheControl;

  const odometerUnitID = Number(data?.addDevice.odometerUnit.id);

  const schema = yup.object({
    guaranteeDistance: yup
      .number()
      .transform((value, originalValue) => originalValue === '' ? null : value)
      .nullable(),
  }).required();

  const { control, handleSubmit } = useForm<{ guaranteeDistance: number | null }>({
    defaultValues: {
      guaranteeDistance: ensureNumericZeroIsNotDisplayed(data?.addDevice.guaranteeDistance),
    },
    resolver: yupResolver(schema),
  });

  const onSave = async ({ guaranteeDistance }: { guaranteeDistance: number | null }) => {
    setGuaranteeDistance(guaranteeDistance);
  };

  return (
    <Controller
      render={({ field, formState }) => (
        <NumberField
          {...field}
          onWheel={blurTarget}
          label={t('addDevice.components.guaranteeDistance.name')}
          onKeyUp={() => {
            handleSubmit(onSave)();
          }}
          id="guaranteeDistance"
          error={!!formState.errors.guaranteeDistance?.message}
          helperText={formState.errors.guaranteeDistance?.message}
          onlyInteger
          variant="outlined"
          unitIcon={<FormatListNumberedIcon fontSize="small" />}
          units={odometerUnitsData?.odometerUnits.map((unit) => ({
            id: Number(unit.id),
            name: unit.unitName,
            isSelected: odometerUnitID === Number(unit.id),
          }))}
          onUnitChange={(unitId) => setOdometerUnit(unitId)}
        />
      )}
      name="guaranteeDistance"
      control={control}
    />
  );
};

export default GuaranteeDistance;
