import React, { FC } from 'react';
import { IProps } from './types';
import {
  Paper,
  Box,
  IconButton,
  Typography,
} from '@mechis/elements';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';

const QuoteBlock: FC<IProps> = ({ children }) => {
  return (
    <Paper variant="outlined" sx={{ position: 'relative' }}>
      <FormatQuoteIcon 
        color="disabled"
        fontSize="large"
        sx={{ 
          position: 'absolute',
          top: '8px',
          left: '8px',
          transform: 'rotate(180deg)',
          opacity: '0.4',
        }}
      />
      <Box p={2} textAlign="center">
        <IconButton
          color="warning"
          size="large"
          sx={{
            position: 'relative',
            mt: -6,
          }}
        >
          <EmojiObjectsIcon fontSize="large" />
        </IconButton>
        <Typography mb={2}>
          <small>
            {children}
          </small>
        </Typography>
      </Box>
      <FormatQuoteIcon 
        color="disabled"
        fontSize="large"
        sx={{ 
          position: 'absolute',
          bottom: '8px',
          right: '8px',
          opacity: '0.4',
        }}
      />
    </Paper>
  );
};

export default QuoteBlock;
