import { ReactiveVar, makeVar } from '@apollo/client';
import { IAttachmentsModel } from './types';

export const init: IAttachmentsModel = {
  isModalOpened: false,
  view: 'list',
  filter: 'all',
  selectedFiles: [],
  uploadedFiles: [],
};

const attachments: ReactiveVar<IAttachmentsModel> = makeVar<IAttachmentsModel>(init);

export default attachments;
