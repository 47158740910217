export interface IRadiusValue {
    readonly small: number
    readonly medium: number
    readonly large: number
    readonly xlarge: number
}

export const radiuses: IRadiusValue = {
  small: 8,
  medium: 12,
  large: 16,
  xlarge: 32,
};
