const styleOverrides = {
  root: ({ theme }: any) => ({
    '& .MuiNativeSelect-select': {
      border: `1px solid ${theme.palette.common.lightGrey}`,
      padding: `${theme.spacing(1.0625)} ${theme.spacing(1.75)}`,
      borderRadius: `${theme.radiuses.medium}px`,
    },
    '& .MuiNativeSelect-select:focus': {
      borderRadius: `${theme.radiuses.medium}px`,
    },
  }),
};

export default styleOverrides;
