import React, { FC } from 'react';
import { IProps } from './types';
import { Block } from './styles';
import { Typography } from '@mechis/elements';
import MechisLogo from '@assets/svgs/mechis-logo.svg';
import MechisLogoWhite from '@assets/svgs/mechis-logo-white.svg';

const Logo: FC<IProps> = ({ variant = 'default', withText, size = 48 }) => {
  const resolveVariant = () => {
    switch (variant) {
      case 'default': return MechisLogo;
      case 'white': return MechisLogoWhite;
      default: return MechisLogo;
    }
  };
  
  return (
    <Block>
      <img src={resolveVariant()} alt="" width={size} style={{ opacity: 0.8 }} />
      {withText && (
        <Typography sx={{
          fontSize: '26px',
          color: '#ffffff',
          opacity: 0.8,
          position: 'relative',
          top: '5px',
          left: '3px',
          fontWeight: 100,
        }}>
          echis
        </Typography>
      )}
    </Block>
  );
};

export default Logo;
