import button from '@mechis/elements/Button/variants';
import paper from '@mechis/elements/Paper/variants';
import listItemText from '@mechis/elements/ListItemText/variants';
import iconButton from '@mechis/elements/IconButton/variants';
import textField from '@mechis/elements/TextField/variants';
import appbar from '@mechis/elements/AppBar/variants';
import checkbox from '@mechis/elements/Checkbox/variants';

const variants = {
  appbar,
  button,
  paper,
  listItemText,
  iconButton,
  textField,
  checkbox,
};

export default variants;
