import React, { FC } from 'react';

interface ILogoProps {
  variant?: 'small' | 'default' | 'medium' | 'xsmall'
}

const Logo: FC<ILogoProps> = ({ variant = 'default' }) => {
  const resolveSize = () => {
    switch (variant) {
      case 'default': return 150;
      case 'medium': return 36;
      case 'small': return 30;
      case 'xsmall': return 24;
      default: return 150;
    }
  };

  return (
    <div style={{
      // todo: body bg with alpha 80% cca
      background: '#f6f6f6bf',
      borderRadius: '50%',
      width: `${resolveSize()}px`,
      height: `${resolveSize()}px`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 95 95">
        <g id="Group_3" transform="translate(-448 -515)">
          <circle id="Ellipse_1" cx="47.5" cy="47.5" r="47.5" transform="translate(448 515)" fill="transparent"/>
          <g id="Group_2" transform="translate(15.329 4.646)">
            {/* eslint-disable-next-line */}
            <path id="Path_38" d="M0,0V64.291a3.83,3.83,0,0,1-3.83-3.83V3.83A3.83,3.83,0,0,1,0,0" transform="translate(451.501 525.356)" fill="#07a267"/>
            {/* eslint-disable-next-line */}
            <path id="Path_39" d="M0,0-4.9,8.65l-.541.981-.569.98-.771,1.34-.92,1.6-.01.029-1.33,2.361-1.99,3.459-.389.68-.41.731-.961,1.669-2.231,3.9-.738,1.279.01.01-.42.721-.541.951-1.109,1.929-.01.01-.01-.031-.012.012-.009.009-.049-.089-1.49-2.6-.541-.91.009-.01-.728-1.279-2.24-3.9-.952-1.669-.42-.731-.378-.66-1.981-3.479-1.09-1.929V6.762l1.09,1.908,1.859,3.281.5.86.42.73.952,1.67,2.24,3.92,2.8,4.89.021-.031,6.42-11.179L-9.037,8.67-7.7,6.311l1.451-2.54L-4.121,0Z" transform="translate(502.337 525.356)" fill="#07a267"/>
            {/* eslint-disable-next-line */}
            <path id="Path_40" d="M0,0,3.85-6.12,3.827,29.807a4.066,4.066,0,0,1-3.87-2.834V22.412Z" transform="translate(498.104 559.834)" fill="#07a267"/>
            {/* eslint-disable-next-line */}
            <path id="Path_41" d="M0,0-4.118,7.25l-2.68,4.7-2.91,5.111-3.55,6.219-4.5,7.891-4.13,7.24-.96,1.67-2.74,4.82-2.31,4.039-5.06-8.859-.95-1.67-3.87-6.78V24.449l3.87,6.75.95,1.67,5.06,8.83,5.05-8.83.96-1.67,11.04-19.25L-4.04,0Z" transform="translate(512.399 525.356)" fill="#07a267"/>
            <path id="Path_42" d="M0,0V49.784a3.84,3.84,0,0,1-3.83,3.865V6.773Z" transform="translate(512.393 535.995)" fill="#07a267"/>
            {/* eslint-disable-next-line */}
            <path id="Path_43" d="M0,0V64.291a3.825,3.825,0,0,0,3.83-3.83V3.83A3.825,3.825,0,0,0,0,0" transform="translate(465.604 525.354)" fill="#07a267"/>
            <path id="Path_44" d="M156.466-304.035h-3.691v-11.541h3.691Z" transform="translate(304 840.93)" fill="#07a267"/>
            <path id="Path_45" d="M156.466-271.682h-3.691v-23.486h3.691Z" transform="translate(304 840.93)" fill="#07a267"/>
            <path id="Path_46" d="M156.466-251.285h-3.691v-11.2h3.691Z" transform="translate(304 840.93)" fill="#07a267"/>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Logo;
