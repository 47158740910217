import React, { FC } from 'react';
import { IProps } from './types';
import {
  Block,
  Value,
  PaperBlock,
  InnerPaperBlock,
  PaperBlockHeader,
  PaperBlockHeaderName,
} from './styles';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  IconButton,
} from '@mechis/elements';
import { THOUSAND_SEPARATOR } from '@configurations/constants/app';
import { OutputDateFormats } from '@configurations/types/global';
import { tableOutputDate } from '@utilities/app';
import useEvent from '@hooks/useEvent';
import useTech from '@hooks/useTech';

const Transaction: FC<IProps> = ({
  eventId,
  title,
  date,
  type,
  priceTotal,
  isEven = true,
}) => {
  const navigate = useNavigate();
  const { techId } = useTech();
  const { eventIcon } = useEvent(11);

  return (
    <Block onClick={() => navigate(`/app/${techId}/tech-detail/record/${eventId}`)}>
      <Box mr={2} sx={{ position: 'relative', top: '-8px' }}>
        <IconButton
          color={isEven ? 'primary' : 'secondary'}
          size="small"
          isSquared
          sx={{ width: 40, height: 40 }}
        >
          {eventIcon(type)}
        </IconButton>
      </Box>
      <PaperBlock>
        <InnerPaperBlock px={2} py={2}>
          <PaperBlockHeader>
            <PaperBlockHeaderName>
              <Typography
                variant="h6"
                color={isEven ? 'primary' : 'secondary'}
              >
                {title}
              </Typography>
  
            </PaperBlockHeaderName>
            <Value variant="h6">
              <Typography
                variant="subtitle1"
                component="span"
                sx={{
                  lineHeight: 1,
                }}
              >
                {tableOutputDate(date, OutputDateFormats.Classic)}
              </Typography>
 
              <span className={priceTotal && priceTotal < 0 ? 'negative' : undefined}>
                <NumberFormat
                  // prefix="- "
                  suffix=" kč"
                  value={priceTotal}
                  displayType="text"
                  thousandSeparator={THOUSAND_SEPARATOR}
                />
              </span>
            </Value>
          </PaperBlockHeader>
        </InnerPaperBlock>
      </PaperBlock>
    </Block>
  );
};

export default Transaction;
