import React, { FC, useEffect, useState } from 'react';
import { IProps } from './types';
import Paper from '@mechis/elements/Paper';
import { Box, Button, Typography } from '@mechis/elements';
import { Attachment, useEventAttachmentsByEventIdQuery } from '@state/mechis-backend/generated/schema';
import { useAttachments } from '@hooks/useAttachments';
import { getIconByMimeType } from '../FirebaseAttachmentBuilder';
import ClaudinaryImageBuilder from '../ClaudinaryImageBuilder';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import EditEventAttachmentsDialog from '../EditEventAttachmentsDialog';
import selectionsCacheControl from '@state/mutations/selections';
import { useReactiveVar } from '@apollo/client';
import selections from '@state/models/selections';

const EventDetailAttachments: FC<IProps> = ({ eventId }) => {
  const { t } = useTranslation();
  const {
    isImageType,
    cloudinaryConfiguration,
    firebaseConfig,
  } = useAttachments();
  const [ attachmentDialogOpen, setAttachmentDialogOpen ] = useState(false);
  const { setImageAttachments, setFileAttachments } = selectionsCacheControl;
  const reactiveSelections = useReactiveVar(selections);
  const { data } = useEventAttachmentsByEventIdQuery({
    variables: {
      eventId: Number(eventId),
    },
  });

  const imageAttachments = data?.eventAttachmentsByEventId.filter(({ attachment }) => isImageType(attachment.mimeType)) || [];
  const fileAttachments = data?.eventAttachmentsByEventId.filter(({ attachment }) => !isImageType(attachment.mimeType)) || [];

  useEffect(() => {
    if (attachmentDialogOpen) {
      setImageAttachments([ ...imageAttachments.map(({ attachment }) => attachment) ]);
      setFileAttachments([ ...fileAttachments.map(({ attachment }) => attachment) ]);
    }
  }, [ data, attachmentDialogOpen ]);

  const handleSetImageAttachment = (newImageAttachment: Partial<Attachment>) => {
    return setImageAttachments([ ...reactiveSelections.imageAttachments, newImageAttachment ]);
  };

  const handleSetFileAttachment = (newFileAttachment: Partial<Attachment>) => {
    return setFileAttachments([ ...reactiveSelections.fileAttachments, newFileAttachment ]);
  };

  return (
    <Box mt={4}>
      <Paper>
        <Box p={2}>
          <Box>
            <Typography variant="sectionHeadline">{t('components.eventAttachments.photos')}</Typography>
          </Box>
          {imageAttachments.length === 0 && (
            <Box>
              <Typography variant="body1">{t('components.eventAttachments.noPhotos')}</Typography>
            </Box>
          )}
          {imageAttachments?.map(({ attachment }) => (
            <Box key={attachment.id} display="flex" flexDirection="row" alignItems="center">
              <Box mr={1}>
                <ClaudinaryImageBuilder
                  imageId={attachment.storageName ?? ''}
                  width={35}
                  height={35}
                />
              </Box>
              <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" flex={1}>
                {attachment.originalName}
              </Box>
            </Box>
          ))}
          <Box>
            <Typography variant="sectionHeadline">{t('components.eventAttachments.attachments')}</Typography>
          </Box>
          {fileAttachments.length === 0 && (
            <Box>
              <Typography variant="body1">{t('components.eventAttachments.noAttachments')}</Typography>
            </Box>
          )}
          {fileAttachments?.map(({ attachment }) => (
            <Box key={attachment.id} display="flex" flexDirection="row" alignItems="center">
              <Box mr={1}>
                {getIconByMimeType(attachment.mimeType)}
              </Box>
              <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" flex={1}>
                {attachment.originalName}
              </Box>
            </Box>))}
        </Box>
        <Button
          key={uuid()}
          color="secondary"
          size="small"
          variant="contained"
          sx={{ position: 'relative', top: '10px', left: '80%' }}
          onClick={() => setAttachmentDialogOpen(true)}
        >
          {t('components.eventAttachments.edit')}
        </Button>
      </Paper>
      <EditEventAttachmentsDialog
        open={attachmentDialogOpen}
        onClose={() => {
          setAttachmentDialogOpen(false);
          setImageAttachments([]);
          setFileAttachments([]);
        }}
        eventId={Number(eventId)}
        cloudinaryConfiguration={cloudinaryConfiguration}
        firebaseConfiguration={firebaseConfig}
        setCloudinaryAttachmentInfo={handleSetImageAttachment}
        setFirebaseAttachmentInfo={handleSetFileAttachment}
      />
    </Box>
  );
};

export default EventDetailAttachments;
