import React, { FC } from 'react';
import { IProps } from './types';
import { Block } from './styles';
import Typography from '@mechis/elements/Typography';
import { Tooltip } from '@mechis/elements';
import { useTranslation } from 'react-i18next';

const TechSelector: FC<IProps> = ({ title, icon, onClick, disabled, sx }) => {
  const { t } = useTranslation();
  return (
    disabled ? (
      <Tooltip title={t('Prozatím není k dispozici!')} enterTouchDelay={0} placement="top">
        <Block sx={{opacity: 0.38}}>
          {icon}
          <Typography variant="h6">{title}</Typography>
        </Block>
      </Tooltip>
    ) : (
      <Block onClick={onClick} sx={sx}>
        {icon}
        <Typography variant="h6">{title}</Typography>
      </Block>
    ));
};

export default TechSelector;
