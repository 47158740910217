import { gql } from '@apollo/client';

export const GET_ADD_DEVICE_TMP_STORE = gql`
  query GetAddDeviceTmpStore {
    addDevice @client {
      acquisitionDate,
      actualOdometerState,
      color,
      configuration,
      creationDate,
      deviceCategory,
      engineDisplacementCc,
      serialNumber,
      engineSpecification,
      firstRegistrationDate,
      fuel,
      fuels,
      guaranteeDate,
      guaranteeDistance,
      guaranteeMonths,
      initialOdometerState,
      manufacturedYearMonth,
      manufacturer,
      model,
      motorization,
      name,
      odometerUnit,
      otherIdentification,
      power,
      powerRpm,
      powerUnit,
      spz,
      torqueNm,
      torqueRpm,
      transmission,
      transmissionNumber,
      transmissionType,
      thumbnailImage,
      vehicleType,
      vin,
      validationErrors,
    }
  }
`;
