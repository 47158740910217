import React, { FC, useState, useEffect } from 'react';
import { IProps } from './types';
import AttachmentsPreview from '../AttachmentsPreview';
import { useQuery } from '@apollo/client';
import { ISelectionsQuery } from '@state/queries/selections/types';
import { GET_SELECTIONS } from '@state/queries/selections';
import { Attachment, Filter, useAttachmentByDeviceAndFiltersAndTagsQuery } from '@state/mechis-backend/generated/schema';
import { filterSelectionToQuery, isFilterUsed } from '@hooks/utils';
import ErrorIcon from '@mui/icons-material/Error';
import Loader from '@mechis/blocks/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import ScrollButton from '@mechis/blocks/ScrollButton';
import { isObjectEmpty } from '@utilities/app';
import { Box, Stack } from '@mechis/elements';
import QuoteBlock from '@mechis/blocks/QuoteBlock';
import { ATTACHMENT_LIMIT_LIST } from '@configurations/constants/app';
import { useAttachments } from '@hooks/useAttachments';
import Lightbox from 'yet-another-react-lightbox';
import { useTranslation } from 'react-i18next';
import useTech from '@hooks/useTech';

const AttachmentListInfinityScroll: FC<IProps> = () => {
  const { t } = useTranslation();
  const [ tags, setTags ] = useState<number[]>([]);
  const [ filters, setFilters ] = useState<Filter>({});
  const [ offset, setOffset ] = useState<number>(0);
  const [ attachmentsToRender, setAttachmentsToRender ] = useState<Attachment[]>([]);
  const { techId } = useTech();
  const { isImageType, getImageUrlByStorageName } = useAttachments();
  const [ lightBoxSources, setLightBoxSources ] = useState<Array<Partial<Attachment>>>([]);
  const [ lightBoxOpened, setLightBoxOpened ] = useState<boolean>(false);
  const [ clickedImageIndex, setClickedImageIndex ] = useState<number>(0);
  const { data: selections } = useQuery<ISelectionsQuery>(GET_SELECTIONS);
  const {
    data: attachments,
    loading: attachmentsLoading,
    error: attachmentsError,
  } = useAttachmentByDeviceAndFiltersAndTagsQuery({
    variables: {
      deviceId: techId,
      offset,
      limit: ATTACHMENT_LIMIT_LIST,
      tags,
      filters,
    },
  });

  useEffect(() => {
    if (!selections?.selections.filterIsModalOpen) {
      if (!isObjectEmpty(filterSelectionToQuery(selections?.selections))) {
        setOffset(0);
        setAttachmentsToRender([]);
      }
      setFilters(filterSelectionToQuery(selections?.selections));
    }

    if (selections?.selections?.tags) {
      setTags(selections?.selections?.tags.map((tag) => Number(tag.id)));
      setOffset(0);
      setAttachmentsToRender([]);
    }

  }, [ selections ]);

  useEffect(() => {
    if (attachments && attachments.attachmentByDeviceAndFiltersAndTags) {
      setAttachmentsToRender([ ...attachmentsToRender, ...attachments.attachmentByDeviceAndFiltersAndTags.attachment ]);
      setLightBoxSources([
        ...lightBoxSources,
        ...attachments.attachmentByDeviceAndFiltersAndTags.attachment.filter(
          (attachment) => isImageType(attachment.mimeType ?? '')
        ),
      ]
      );
    }
  }, [ attachments, attachmentsLoading, attachmentsError ]);

  const getLightBoxSources = () => {
    return (
      <Lightbox
        open={lightBoxOpened}
        close={() => setLightBoxOpened(false)}
        index={clickedImageIndex}
        slides={
          lightBoxSources.map((attachment) => {
            return {
              src: getImageUrlByStorageName(attachment.storageName ?? ''),
            };
          })
        }
      />
    );
  };

  const fetchMoreData = () => {
    setOffset(offset + ATTACHMENT_LIMIT_LIST);
  };

  const hasMore =
    attachments?.attachmentByDeviceAndFiltersAndTags ? Boolean(!attachments?.attachmentByDeviceAndFiltersAndTags.isLast) : true;

  if (attachmentsError) {
    return (
      <ErrorIcon />
    );
  }

  if (attachmentsToRender.length === 0 && attachmentsLoading) {
    return (
      <Loader />
    );
  }

  if (attachmentsToRender.length === 0) {
    return (
      <Stack mt={6} spacing={4} alignItems="center" >
        <QuoteBlock>
          {
            (
              tags.length > 0 ||
              isFilterUsed(selections?.selections)
            ) ?
              t('components.attachmentNoData.forFilters') :
              t('components.attachmentNoData.forDevice')
          } 
        </QuoteBlock>
      </Stack>
    );
  }

  return (
    <>
      <InfiniteScroll
        dataLength={attachmentsToRender.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<Loader size={20} />}
        style={{ overflow: 'hidden' }}
        scrollableTarget="scrollableDiv"
      >
        <Box my={2}>
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent="space-between"
          >
            {attachmentsToRender.map((attachment, index) => {
              return (
                <AttachmentsPreview
                  key={attachment.id}
                  attachment={attachment}
                  index={index}
                  setClickedImageIndex={() => {
                    setClickedImageIndex(lightBoxSources.findIndex((source) => source.id === attachment.id));
                  }}
                  setLightBoxOpened={setLightBoxOpened}
                />
              );
            })}
          </Stack>
          {lightBoxSources.length > 0 && getLightBoxSources()}
        </Box>
      </InfiniteScroll>
      <ScrollButton />
    </>
  );
};

export default AttachmentListInfinityScroll;
