import { Box } from '@mechis/elements';
import React, { FC } from 'react';
import { DeviceListing } from '@mechis/sections/DeviceListing/DeviceListing';
import { useTranslation } from 'react-i18next';
import { DeviceHeaderDetailQuery } from '@state/mechis-backend/generated/schema';
import Dialog from '@mechis/elements/Dialog';
import Typography from '@mechis/elements/Typography';
import CancelIcon from '@mui/icons-material/Cancel';

interface IProps {
  isOpen: boolean
  onClose?: () => void
  data?: DeviceHeaderDetailQuery
}

export const ModalDeviceSwitcher: FC<IProps> = ({
  isOpen,
  onClose,
  data,
}: IProps) => {
  const [ t ] = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      PaperProps={{
        sx: {
          backgroundColor: '#f6f6f6',
        },
      }}
    >
      <CancelIcon
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: '16px',
          top: '16px',
          color: 'lightgrey',
        }}
      />
      <Box p={2}>
        <Typography
          variant="h5"
          textAlign="center"
          mb={1}
          mt={1}
        >
          {t('appBar.deviceSelect.title')}
        </Typography>
        <div>
          <DeviceListing
            onClick={onClose}
            excludedTechId={data?.device?.id}
            isInModal
          />
        </div>
      </Box>
    </Dialog>
  );
};
