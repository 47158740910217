import React, { useEffect } from 'react';
import { Page, Header, Content } from './styles';
import CountUp from 'react-countup';
import {
  Typography,
  Button,
} from '@mechis/elements';
import {
  useParams,
  useNavigate,
} from 'react-router-dom';
import { usePaymentResultPreviewQuery } from '@state/mechis-backend/generated/schema';
import { useLocalStorage } from 'react-use';
import { USER_ID } from '@configurations/constants/app';
import Loader from '@mechis/blocks/Loader';
import { useTranslation } from 'react-i18next';

const Payment = () => {
  const { status } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [ userId ] = useLocalStorage(USER_ID, '');
  const { data, loading, error } = usePaymentResultPreviewQuery({
    variables: {
      userId: Number(userId),
    },
  });
  
  useEffect(() => {
    const isSet = status === 'success' || status === 'failed' || status === 'pending' ? true : false;
    if (!isSet) {
      navigate('/');
    }
  }, [ status ]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    throw new Error('Unable to get credit data');
  }

  return (
    <Page>
      <Header>
        <CountUp
          start={256}
          end={data?.user?.creditBalance ?? 0}
          duration={1.5}
          prefix={t('paymentResult.countUp.prefix')}
          separator=" "
          suffix={t('paymentResult.countUp.suffix')}
        />
      </Header>
      <Content>
        {status === 'success' && (
          <>
            <Typography variant="h4">{t('paymentResult.success.header')}</Typography>
            <Typography variant="sectionHeadline">{t('paymentResult.success.description1')}</Typography>
            <Typography variant="sectionHeadline">{t('paymentResult.success.description2')}</Typography>
            <Typography variant="body2"><a href="https://www.mechis.cz/">Mechis.cz</a></Typography>
            <Typography variant="body2">{t('paymentResult.success.description3')}</Typography>
          </>
        )}
        {status === 'failed' && (
          <>
            <Typography variant="h4">{t('paymentResult.fail.header')}</Typography>
            <Typography variant="sectionHeadline">{t('paymentResult.fail.description1')}</Typography>
            <Typography variant="sectionHeadline">{t('paymentResult.fail.description2')}</Typography>
            <Typography variant="body2"><a href="https://www.mechis.cz/">Mechis.cz</a></Typography>
            <Typography variant="body2">{t('paymentResult.fail.description3')}</Typography>
          </>
        )}
        {status === 'pending' && (
          <>
            <Typography variant="h4">{t('paymentResult.pending.header')}</Typography>
            <Typography variant="sectionHeadline">{t('paymentResult.pending.description1')}</Typography>
            <Typography variant="sectionHeadline">{t('paymentResult.pending.description2')}</Typography>
            <Typography variant="sectionHeadline">{t('paymentResult.pending.description3')}</Typography>
            <Typography variant="body2"><a href="https://www.mechis.cz/">Mechis.cz</a></Typography>
            <Typography variant="body2">{t('paymentResult.pending.description4')}</Typography>
          </>
        )}
      </Content>
      {(status === 'success' || status === 'pending') && (
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => navigate('/app/mechis')}
        >
          {t('paymentResult.continue')}
        </Button>
      )}
      {status === 'failed' && (
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={() => navigate('/app/mechis')}
        >
          {t('paymentResult.back')}
        </Button>
      )}
    </Page>
  );
};

export default Payment;
