import React, { FC } from 'react';
import { IOwnerState, IProps } from './types';
import {
  Block,
  NationStripe,
  VrpId,
} from './styles';
import europeanStars from '@assets/svgs/european-stars.svg';

const Vrp: FC<IProps> = ({
  serialNumber,
  vrp,
  variant,
}) => {
  
  const ownerState: IOwnerState = {
    vrp,
    variant,
  };

  if (!!vrp) {
    return (
      <Block ownerState={ownerState}>
        <NationStripe>
          <div><img alt="europeanStars" src={europeanStars} /></div>
        </NationStripe>
        <VrpId ownerState={ownerState}>{vrp}</VrpId>
      </Block>
    );
  }

  if (!!serialNumber) {
    return (
      <Block ownerState={ownerState}>
        <VrpId sx={{
          maxWidth: '180px',
          display: 'inline-block',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        ownerState={ownerState}
        >
          {serialNumber}
        </VrpId>
      </Block>
    );
  }

  return null;
};

export default Vrp;
