import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import csTranslation from '@configurations/translations/cs.json';
import enTranslation from '@configurations/translations/en.json';

export const initTranslations = () => {
  const resources = {
    cs: { translation: csTranslation },
    en: { translation: enTranslation },
  };
  
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources,
      lng: 'cs',
      interpolation: {
        escapeValue: false,
      },
    });
};

export default i18n;
