import React, { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mechis/elements';

const MechisSerialNumber: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      {/* eslint-disable-next-line */}
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          {/* eslint-disable-next-line */}
          <path d="M1,.5v15a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5V.5A.5.5,0,0,1,.5,0,.5.5,0,0,1,1,.5ZM3.5,0h-1A.5.5,0,0,0,2,.5v15a.5.5,0,0,0,.5.5h1a.5.5,0,0,0,.5-.5V.5A.5.5,0,0,0,3.5,0Zm9,0h-3A.5.5,0,0,0,9,.5v15a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V.5A.5.5,0,0,0,12.5,0Zm-7,0A.5.5,0,0,0,5,.5v15a.5.5,0,0,0,1,0V.5A.5.5,0,0,0,5.5,0Zm10,0a.5.5,0,0,0-.5.5v15a.5.5,0,0,0,1,0V.5A.5.5,0,0,0,15.5,0Z"/>
        </g>
      </g>
    </SvgIcon>
  );
};

export default MechisSerialNumber;
