
import React, { forwardRef } from 'react';
import { default as MuiCheckbox } from '@mui/material/Checkbox';
import { CheckboxProps } from './types';

const Checkbox = forwardRef<any, CheckboxProps>((props, ref) => {
  const { color = 'default', ...other } = props;
  return <MuiCheckbox ref={ref} color={color} {...other} />;
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
