const styleOverrides = {
  root: ({ theme }: any) => ({
    // color: theme.palette.common.white,
    opacity: 0.9,
    fontFamily: '"Poppins",sans-serif',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  }),
};

export default styleOverrides;
