import React, { useState } from 'react';
import { IOwnerState } from '../types';
import { Control, ControlLabel } from '../styles';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mechis/elements';
import { getColorByState } from '../Selections';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TechSelector from '@mechis/blocks/TechSelector';
import useIcon from '@mechis/icons/useIcon';
import { useTranslation } from 'react-i18next';
import UploadedImageAttachmentsList from '@mechis/sections/Selections/UploadedImageAttachmentList';
import FirebaseUploadWidget from '@mechis/blocks/FirebaseUploadWidget';
import { VisuallyHiddenInput } from '@mechis/blocks/FirebaseUploadWidget/styles';
import UploadedFileAttachmentsList from '../UploadedFileAttachmentsList';
import { useAttachments } from '@hooks/useAttachments';
import { useReactiveVar } from '@apollo/client';
import selectionsCacheControl from '@state/mutations/selections';
import { Attachment } from '@state/mechis-backend/generated/schema';
import selections from '@state/models/selections';
import CloudinaryCreateEventAttachmentWidget from '@mechis/blocks/Cloudinary/CloudinaryCreateEventAttachmentWidget';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const Attachments = () => {
  const [ attachmentDialogOpened, setAttachmentDialogOpened ] = useState(false);
  const { setImageAttachments, setFileAttachments } = selectionsCacheControl;
  const { t } = useTranslation();
  const reactiveSelections = useReactiveVar(selections);

  const {
    cloudinaryConfiguration,
    firebaseConfig,
  } = useAttachments();

  const handleSetImageAttachment = (newImageAttachment: Partial<Attachment>) => {
    return setImageAttachments([ ...reactiveSelections.imageAttachments, newImageAttachment ]);
  };
  
  const handleRemoveAttachmentById = (attachmentId: string) => {
    return setImageAttachments(reactiveSelections.imageAttachments.filter((attach) => attach.id !== attachmentId));
  };

  const handleSetFileAttachment = (newFileAttachment: Partial<Attachment>) => {
    return setFileAttachments([ ...reactiveSelections.fileAttachments, newFileAttachment ]);
  };
  
  const handleRemoveFileAttachmentById = (attachmentId: string) => {
    return setFileAttachments(reactiveSelections.fileAttachments.filter((attach) => attach.id !== attachmentId));
  };

  const ownerState: IOwnerState = {
    state: (reactiveSelections.imageAttachments.length > 0 || reactiveSelections.fileAttachments.length > 0) ? 'filled' : 'default',
  };

  return (
    <>
      <Control>
        <IconButton
          size="small"
          color={getColorByState(ownerState.state)}
          isSquared
          onClick={() => setAttachmentDialogOpened(true)}
        >
          <AttachFileIcon />
        </IconButton>
        <ControlLabel ownerState={ownerState}>
          {(reactiveSelections.imageAttachments.length + reactiveSelections.fileAttachments.length)}
        </ControlLabel>
      </Control>
      
      <Dialog
        onClose={() => setAttachmentDialogOpened(false)}
        open={attachmentDialogOpened}
        fullScreen={true}
        maxWidth="xs"
      >
        <DialogContent>
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h4">
                {t('components.attachmentDialog.attachments')}
              </Typography>
              <IconButton variant="default" size="small" onClick={() => setAttachmentDialogOpened(false)}>
                <CloseRoundedIcon color="primary" />
              </IconButton>
            </Box>
            <Grid spacing={2} container>
              <Grid xs={6} item>
                <CloudinaryCreateEventAttachmentWidget
                  setCloudinaryAttachmentInfo={handleSetImageAttachment}
                  cloudinaryConfiguration={cloudinaryConfiguration}
                >
                  {({ widget }: any) => (
                    <TechSelector
                      title={t('components.attachmentDialog.photo')}
                      onClick={() => widget.open()}
                      icon={useIcon('photo', {
                        color: 'primary',
                        sx: {
                          fontSize: '48px',
                        },
                      })}
                      disabled={false}
                    />
                  )}
                </CloudinaryCreateEventAttachmentWidget>
              </Grid>
              <Grid xs={6} item>
                <FirebaseUploadWidget
                  setFirebaseAttachmentInfo={handleSetFileAttachment}
                  firebaseConfig={firebaseConfig}
                  folder="attachments"
                >
                  {({ setAttachmentUploadValidated, hasValidationError }: any) => (
                    <>
                      <TechSelector
                        onClick={() => {
                          const hiddenFileInput = document.getElementById('hiddenFileInput');
                          hiddenFileInput?.click();
                        }}
                        sx={{
                          border: hasValidationError ? '1px solid red' : undefined,
                        }}
                        title={t('components.attachmentDialog.file')}
                        icon={useIcon('attachment', {
                          color: 'primary',
                          sx: {
                            fontSize: '48px',
                          },
                        })}
                        disabled={false}
                      />
                      {hasValidationError && (
                        <Typography variant="caption" color="red">
                          {t('components.attachmentDialog.fileValidationError')}
                        </Typography>
                      )}
                      <VisuallyHiddenInput
                        type="file"
                        id="hiddenFileInput"
                        onChange={(event) => {
                          if (event.target.files?.[0]) {
                            setAttachmentUploadValidated(event.target.files[0]);
                          }
                        }}
                      />
                    </>
                  )}
                </FirebaseUploadWidget>
              </Grid>
            </Grid>
            <Box mt={2}>
              {reactiveSelections.imageAttachments.length > 0 && (
                <UploadedImageAttachmentsList 
                  imageAttachments={reactiveSelections.imageAttachments} 
                  setImageAttachments={handleRemoveAttachmentById}
                />
              )}
              {reactiveSelections.fileAttachments.length > 0 && (
                <UploadedFileAttachmentsList
                  firebaseConfig={firebaseConfig}
                  fileAttachments={reactiveSelections.fileAttachments}
                  setFileAttachments={handleRemoveFileAttachmentById}
                />
              )}
            </Box>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Attachments;
