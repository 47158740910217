import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  DateTime: any;
  Decimal: any;
};

export enum AnalyzeCategory {
  Refuelings = 'refuelings',
  Repairs = 'repairs',
  Sum = 'sum',
  Tags = 'tags'
}

export enum AnalyzeCommand {
  Avg = 'avg',
  Max = 'max',
  Min = 'min',
  Sum = 'sum'
}

export type AnalyzeRow = {
  __typename?: 'AnalyzeRow';
  rowDescriptionCategory?: Maybe<Scalars['String']>;
  rowDescriptionKey: Scalars['String'];
  value: Scalars['String'];
};

export type Attachment = {
  __typename?: 'Attachment';
  id: Scalars['BigInt'];
  mimeType: Scalars['String'];
  originalName: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
  storageName: Scalars['String'];
};

export type AttachmentCreateInput = {
  mimeType: Scalars['String'];
  originalName: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
  storageName: Scalars['String'];
  version: Scalars['Int'];
};

export type AttachmentInput = {
  mimeType: Scalars['String'];
  originalName: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
  storageName: Scalars['String'];
};

export type AttachmentScroll = {
  __typename?: 'AttachmentScroll';
  attachment: Array<Attachment>;
  isLast?: Maybe<Scalars['Boolean']>;
};

export type AttachmentUpdateInput = {
  id: Scalars['BigInt'];
  mimeType?: InputMaybe<Scalars['String']>;
  originalName?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  storageName?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserInput>;
};

export type AuthUser = {
  __typename?: 'AuthUser';
  accessToken: Scalars['String'];
  user: User;
};

export type CashPayment = {
  __typename?: 'CashPayment';
  amountPaid: Scalars['Int'];
  date: Scalars['DateTime'];
  id: Scalars['Int'];
  paymentStatus: CashPaymentStatus;
  purchasedCredits: Scalars['Int'];
  userId: Scalars['Int'];
};

export type CashPaymentCreateInput = {
  amountPaid: Scalars['Int'];
};

export enum CashPaymentStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Refunded = 'refunded',
  Refundrequested = 'refundrequested',
  Rejected = 'rejected'
}

export type ConsumptionUnit = {
  __typename?: 'ConsumptionUnit';
  id: Scalars['ID'];
  unitName: Scalars['String'];
};

export type ConsumptionUnitInput = {
  id: Scalars['Int'];
};

export type CreditTransaction = {
  __typename?: 'CreditTransaction';
  date: Scalars['DateTime'];
  description: Scalars['String'];
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<CreditTransactionType>;
  value: Scalars['Int'];
};

export type CreditTransactionCreateInput = {
  creditTransactionTypeId: Scalars['Int'];
  description: Scalars['String'];
  value: Scalars['Int'];
};

export type CreditTransactionCreateResponse = {
  __typename?: 'CreditTransactionCreateResponse';
  remainingCreditBalance: Scalars['Int'];
  transaction: CreditTransaction;
  userId: Scalars['Int'];
};

export type CreditTransactionType = {
  __typename?: 'CreditTransactionType';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type CurrencyInput = {
  id: Scalars['Int'];
};

export type DateFilter = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type DestroySignature = {
  __typename?: 'DestroySignature';
  signature: Scalars['String'];
  timestamp: Scalars['String'];
};

export type Device = {
  __typename?: 'Device';
  acquisitionDate?: Maybe<Scalars['DateTime']>;
  actualOdometerState?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  deviceAttachment?: Maybe<Array<DeviceAttachmentDevice>>;
  deviceCategory?: Maybe<DeviceCategoryDevice>;
  engineDisplacementCc?: Maybe<Scalars['Int']>;
  engineSpecification?: Maybe<Scalars['String']>;
  event?: Maybe<Array<Event>>;
  firstRegistrationDate?: Maybe<Scalars['DateTime']>;
  fuel: Array<FuelDevice>;
  guaranteeDate?: Maybe<Scalars['DateTime']>;
  guaranteeDistance?: Maybe<Scalars['Int']>;
  guaranteeMonths?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  initialOdometerState?: Maybe<Scalars['Int']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  lastEditedDate?: Maybe<Scalars['DateTime']>;
  manufacturedYearMonth?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  motorization?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  odometerUnit?: Maybe<OdometerUnit>;
  otherIdentification?: Maybe<Scalars['String']>;
  power?: Maybe<Scalars['Int']>;
  powerRpm?: Maybe<Scalars['Int']>;
  powerUnit?: Maybe<PowerUnit>;
  serialNumber?: Maybe<Scalars['String']>;
  spz?: Maybe<Scalars['String']>;
  suspendedDate?: Maybe<Scalars['DateTime']>;
  thumbnailImage?: Maybe<Attachment>;
  torqueNm?: Maybe<Scalars['Int']>;
  torqueRpm?: Maybe<Scalars['Int']>;
  transmission?: Maybe<TransmissionDevice>;
  transmissionNumber?: Maybe<Scalars['Int']>;
  transmissionType?: Maybe<Scalars['String']>;
  user?: Maybe<UserDevice>;
  vehicleType?: Maybe<VehicleTypeDevice>;
  vin?: Maybe<Scalars['String']>;
};

export type DeviceAttachment = {
  __typename?: 'DeviceAttachment';
  attachment: Attachment;
  device: DeviceNoAttachment;
};

export type DeviceAttachmentDevice = {
  __typename?: 'DeviceAttachmentDevice';
  attachment: Attachment;
};

export type DeviceAttachmentInput = {
  attachment: AttachmentInput;
};

export type DeviceAttachmentScroll = {
  __typename?: 'DeviceAttachmentScroll';
  deviceAttachment: Array<DeviceAttachment>;
  isLast?: Maybe<Scalars['Boolean']>;
};

export type DeviceCategory = {
  __typename?: 'DeviceCategory';
  device: Array<Device>;
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type DeviceCategoryDevice = {
  __typename?: 'DeviceCategoryDevice';
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type DeviceCategoryInput = {
  id: Scalars['Int'];
};

export type DeviceCreateInput = {
  acquisitionDate?: InputMaybe<Scalars['DateTime']>;
  actualOdometerState?: InputMaybe<Scalars['Int']>;
  color?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<Scalars['String']>;
  creationDate?: InputMaybe<Scalars['DateTime']>;
  deviceAttachment?: InputMaybe<Array<DeviceAttachmentInput>>;
  deviceCategory: DeviceCategoryInput;
  engineDisplacementCc?: InputMaybe<Scalars['Int']>;
  engineSpecification?: InputMaybe<Scalars['String']>;
  firstRegistrationDate?: InputMaybe<Scalars['DateTime']>;
  fuel: Array<DeviceFuelInput>;
  guaranteeDate?: InputMaybe<Scalars['DateTime']>;
  guaranteeDistance?: InputMaybe<Scalars['Int']>;
  guaranteeMonths?: InputMaybe<Scalars['Int']>;
  initialOdometerState?: InputMaybe<Scalars['Int']>;
  manufacturedYearMonth?: InputMaybe<Scalars['DateTime']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  motorization?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  odometerUnit: OdometerUnitInput;
  otherIdentification?: InputMaybe<Scalars['String']>;
  power?: InputMaybe<Scalars['Int']>;
  powerRpm?: InputMaybe<Scalars['Int']>;
  powerUnit: PowerUnitInput;
  serialNumber?: InputMaybe<Scalars['String']>;
  spz?: InputMaybe<Scalars['String']>;
  thumbnailImage?: InputMaybe<ThumbnailImageInput>;
  torqueNm?: InputMaybe<Scalars['Int']>;
  torqueRpm?: InputMaybe<Scalars['Int']>;
  transmission?: InputMaybe<TransmissionInput>;
  transmissionNumber?: InputMaybe<Scalars['Int']>;
  transmissionType?: InputMaybe<Scalars['String']>;
  vehicleType?: InputMaybe<VehicleTypeInput>;
  vin?: InputMaybe<Scalars['String']>;
};

export type DeviceFuelInput = {
  actualConsumption?: InputMaybe<Scalars['Float']>;
  consumptionUnit: ConsumptionUnitInput;
  fuelType?: InputMaybe<DeviceFuelTypeInput>;
  initialConsumption?: InputMaybe<Scalars['Float']>;
  tankVolume?: InputMaybe<Scalars['Int']>;
  volumeUnit: VolumeUnitInput;
};

export type DeviceFuelTypeInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type DeviceFuelUpdateInput = {
  actualConsumption?: InputMaybe<Scalars['Float']>;
  consumptionUnit?: InputMaybe<ConsumptionUnitInput>;
  fuelType?: InputMaybe<DeviceFuelTypeInput>;
  id: Scalars['Int'];
  initialConsumption?: InputMaybe<Scalars['Float']>;
  tankVolume?: InputMaybe<Scalars['Int']>;
  volumeUnit?: InputMaybe<VolumeUnitInput>;
};

export type DeviceInput = {
  id: Scalars['Int'];
};

export type DeviceNoAttachment = {
  __typename?: 'DeviceNoAttachment';
  acquisitionDate?: Maybe<Scalars['DateTime']>;
  actualOdometerState?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  deviceCategory?: Maybe<DeviceCategoryDevice>;
  engineDisplacementCc?: Maybe<Scalars['Int']>;
  engineSpecification?: Maybe<Scalars['String']>;
  firstRegistrationDate?: Maybe<Scalars['DateTime']>;
  fuel: Array<FuelDevice>;
  guaranteeDate?: Maybe<Scalars['DateTime']>;
  guaranteeDistance?: Maybe<Scalars['Int']>;
  guaranteeMonths?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  initialOdometerState?: Maybe<Scalars['Int']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  lastEditedDate?: Maybe<Scalars['DateTime']>;
  manufacturedYearMonth?: Maybe<Scalars['DateTime']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  motorization?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  odometerUnit?: Maybe<OdometerUnit>;
  otherIdentification?: Maybe<Scalars['String']>;
  power?: Maybe<Scalars['Int']>;
  powerRpm?: Maybe<Scalars['Int']>;
  powerUnit?: Maybe<PowerUnit>;
  serialNumber?: Maybe<Scalars['String']>;
  spz?: Maybe<Scalars['String']>;
  torqueNm?: Maybe<Scalars['Int']>;
  torqueRpm?: Maybe<Scalars['Int']>;
  transmission?: Maybe<TransmissionDevice>;
  transmissionNumber?: Maybe<Scalars['Int']>;
  transmissionType?: Maybe<Scalars['String']>;
  user?: Maybe<UserDevice>;
  vehicleType?: Maybe<VehicleTypeDevice>;
  vin?: Maybe<Scalars['String']>;
};

export type DeviceUpdateInput = {
  acquisitionDate?: InputMaybe<Scalars['DateTime']>;
  actualOdometerState?: InputMaybe<Scalars['Int']>;
  color?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<Scalars['String']>;
  creationDate?: InputMaybe<Scalars['DateTime']>;
  deviceCategory?: InputMaybe<DeviceCategoryInput>;
  engineDisplacementCc?: InputMaybe<Scalars['Int']>;
  engineSpecification?: InputMaybe<Scalars['String']>;
  firstRegistrationDate?: InputMaybe<Scalars['DateTime']>;
  fuel?: InputMaybe<Array<InputMaybe<DeviceFuelUpdateInput>>>;
  guaranteeDate?: InputMaybe<Scalars['DateTime']>;
  guaranteeDistance?: InputMaybe<Scalars['Int']>;
  guaranteeMonths?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  initialOdometerState?: InputMaybe<Scalars['Int']>;
  manufacturedYearMonth?: InputMaybe<Scalars['DateTime']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  motorization?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  odometerUnit?: InputMaybe<OdometerUnitInput>;
  otherIdentification?: InputMaybe<Scalars['String']>;
  power?: InputMaybe<Scalars['Int']>;
  powerRpm?: InputMaybe<Scalars['Int']>;
  powerUnit?: InputMaybe<PowerUnitInput>;
  serialNumber?: InputMaybe<Scalars['String']>;
  spz?: InputMaybe<Scalars['String']>;
  thumbnailImage?: InputMaybe<ThumbnailImageInput>;
  torqueNm?: InputMaybe<Scalars['Int']>;
  torqueRpm?: InputMaybe<Scalars['Int']>;
  transmission?: InputMaybe<TransmissionInput>;
  transmissionNumber?: InputMaybe<Scalars['Int']>;
  transmissionType?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserInput>;
  vehicleType?: InputMaybe<VehicleTypeInput>;
  vin?: InputMaybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  completionDay?: Maybe<Scalars['DateTime']>;
  creationDate: Scalars['DateTime'];
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  deviceId: Scalars['Int'];
  eventDate?: Maybe<Scalars['DateTime']>;
  eventType: EventType;
  exchangeRate?: Maybe<Scalars['Decimal']>;
  id: Scalars['BigInt'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  lastEditedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  periodDay?: Maybe<Scalars['Int']>;
  periodDistance?: Maybe<Scalars['Int']>;
  priceEstimated?: Maybe<Scalars['Decimal']>;
  priceTotal?: Maybe<Scalars['Decimal']>;
  refueling?: Maybe<Array<Refueling>>;
  stateMh?: Maybe<Scalars['Int']>;
  stateOdometer?: Maybe<Scalars['Int']>;
};

export type EventAttachment = {
  __typename?: 'EventAttachment';
  attachment: Attachment;
  event: Event;
};

export type EventAttachmentScroll = {
  __typename?: 'EventAttachmentScroll';
  eventAttachment: Array<Maybe<EventAttachment>>;
  isLast?: Maybe<Scalars['Boolean']>;
};

export type EventCreateInput = {
  completionDay?: InputMaybe<Scalars['DateTime']>;
  creationDate?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<CurrencyInput>;
  description?: InputMaybe<Scalars['String']>;
  device: DeviceInput;
  eventDate?: InputMaybe<Scalars['DateTime']>;
  eventOperation?: InputMaybe<Array<EventOperationOperationCreateInput>>;
  eventType: EventTypeInput;
  exchangeRate?: InputMaybe<Scalars['Float']>;
  lastEditedDate?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
  priceEstimated?: InputMaybe<Scalars['Float']>;
  priceTotal?: InputMaybe<Scalars['Float']>;
  stateMh?: InputMaybe<Scalars['Int']>;
  stateOdometer?: InputMaybe<Scalars['Int']>;
};

export type EventInput = {
  id: Scalars['BigInt'];
};

export type EventOperation = {
  __typename?: 'EventOperation';
  event: Event;
  operation: Operation;
};

export type EventOperationEventCreateInput = {
  event: EventInput;
};

export type EventOperationEventUpdateInput = {
  event: EventUpdateInputNoEventOperation;
};

export type EventOperationNoOperation = {
  __typename?: 'EventOperationNoOperation';
  event: Event;
};

export type EventOperationOperationCreateInput = {
  operation: OperationInput;
};

export type EventOperationOperationUpdateInput = {
  operation: OperationUpdateInputNoEventOperation;
};

export type EventRefuelingCreateInput = {
  completionDay?: InputMaybe<Scalars['DateTime']>;
  creationDate?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<CurrencyInput>;
  description?: InputMaybe<Scalars['String']>;
  device: DeviceInput;
  eventDate?: InputMaybe<Scalars['DateTime']>;
  exchangeRate?: InputMaybe<Scalars['Float']>;
  lastEditedDate?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
  priceEstimated?: InputMaybe<Scalars['Float']>;
  priceTotal?: InputMaybe<Scalars['Float']>;
  stateMh?: InputMaybe<Scalars['Int']>;
  stateOdometer: Scalars['Int'];
};

export type EventRefuelingUpdateInput = {
  completionDay?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<CurrencyInput>;
  description?: InputMaybe<Scalars['String']>;
  eventDate?: InputMaybe<Scalars['DateTime']>;
  lastEditedDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  priceEstimated?: InputMaybe<Scalars['Float']>;
  priceTotal?: InputMaybe<Scalars['Float']>;
  stateOdometer?: InputMaybe<Scalars['Int']>;
};

export type EventScroll = {
  __typename?: 'EventScroll';
  event: Array<Event>;
  isLast?: Maybe<Scalars['Boolean']>;
};

export type EventTag = {
  __typename?: 'EventTag';
  event: Event;
  tag: Tag;
};

export type EventTagCreateInput = {
  eventId: Scalars['BigInt'];
  tagId: Scalars['Int'];
};

export type EventTagScroll = {
  __typename?: 'EventTagScroll';
  eventTag: Array<EventTag>;
  isLast?: Maybe<Scalars['Boolean']>;
};

export type EventType = {
  __typename?: 'EventType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EventTypeInput = {
  id: Scalars['Int'];
};

export type EventUpdateInput = {
  completionDay?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<CurrencyInput>;
  description?: InputMaybe<Scalars['String']>;
  eventDate?: InputMaybe<Scalars['DateTime']>;
  eventOperation?: InputMaybe<Array<EventOperationOperationUpdateInput>>;
  eventType?: InputMaybe<EventTypeInput>;
  exchangeRate?: InputMaybe<Scalars['Float']>;
  id: Scalars['BigInt'];
  lastEditedDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
  priceEstimated?: InputMaybe<Scalars['Float']>;
  priceTotal?: InputMaybe<Scalars['Float']>;
  stateMh?: InputMaybe<Scalars['Int']>;
  stateOdometer?: InputMaybe<Scalars['Int']>;
};

export type EventUpdateInputNoEventOperation = {
  completionDay?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<CurrencyInput>;
  description?: InputMaybe<Scalars['String']>;
  eventDate?: InputMaybe<Scalars['DateTime']>;
  eventType?: InputMaybe<EventTypeInput>;
  exchangeRate?: InputMaybe<Scalars['Float']>;
  id: Scalars['BigInt'];
  lastEditedDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
  priceEstimated?: InputMaybe<Scalars['Float']>;
  priceTotal?: InputMaybe<Scalars['Float']>;
  stateMh?: InputMaybe<Scalars['Int']>;
  stateOdometer?: InputMaybe<Scalars['Int']>;
};

export type Expense = {
  __typename?: 'Expense';
  currency?: Maybe<Currency>;
  event: Event;
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Float']>;
  tag: Tag;
};

export type ExpenseCreateInput = {
  currency?: InputMaybe<CurrencyInput>;
  price?: InputMaybe<Scalars['Float']>;
  tag: TagInput;
};

export type ExpenseEvent = {
  __typename?: 'ExpenseEvent';
  completionDay?: Maybe<Scalars['DateTime']>;
  creationDate: Scalars['DateTime'];
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  deviceId: Scalars['Int'];
  eventDate?: Maybe<Scalars['DateTime']>;
  eventType: EventType;
  exchangeRate?: Maybe<Scalars['Decimal']>;
  expense: Array<Expense>;
  id: Scalars['BigInt'];
  lastEditedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  periodDay?: Maybe<Scalars['Int']>;
  periodDistance?: Maybe<Scalars['Int']>;
  priceEstimated?: Maybe<Scalars['Decimal']>;
  priceTotal?: Maybe<Scalars['Decimal']>;
  stateMh?: Maybe<Scalars['Int']>;
  stateOdometer?: Maybe<Scalars['Int']>;
};

export type ExpenseEventUpdateInput = {
  completionDay?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<CurrencyInput>;
  description?: InputMaybe<Scalars['String']>;
  eventDate?: InputMaybe<Scalars['DateTime']>;
  exchangeRate?: InputMaybe<Scalars['Float']>;
  expense?: InputMaybe<Array<ExpenseNoEventUpsertInput>>;
  id: Scalars['BigInt'];
  lastEditedDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
  priceEstimated?: InputMaybe<Scalars['Float']>;
  priceTotal?: InputMaybe<Scalars['Float']>;
  stateMh?: InputMaybe<Scalars['Int']>;
  stateOdometer?: InputMaybe<Scalars['Int']>;
};

export type ExpenseNoEventUpdateInput = {
  currency?: InputMaybe<CurrencyInput>;
  id: Scalars['BigInt'];
  price?: InputMaybe<Scalars['Float']>;
  tag?: InputMaybe<TagInput>;
};

export type ExpenseNoEventUpsertInput = {
  currency?: InputMaybe<CurrencyInput>;
  id?: InputMaybe<Scalars['BigInt']>;
  price?: InputMaybe<Scalars['Float']>;
  tag?: InputMaybe<TagInput>;
};

export type ExpenseScroll = {
  __typename?: 'ExpenseScroll';
  expense: Array<Expense>;
  isLast?: Maybe<Scalars['Boolean']>;
};

export type ExpenseUpdateInput = {
  currency?: InputMaybe<CurrencyInput>;
  event?: InputMaybe<EventInput>;
  id: Scalars['BigInt'];
  price?: InputMaybe<Scalars['Float']>;
  tag?: InputMaybe<TagInput>;
};

export type Filter = {
  date?: InputMaybe<DateFilter>;
  odometer?: InputMaybe<OdometerFilter>;
  price?: InputMaybe<PriceFilter>;
  recordType?: InputMaybe<Array<Scalars['Int']>>;
};

export type Fuel = {
  __typename?: 'Fuel';
  actualConsumption?: Maybe<Scalars['Float']>;
  consumptionUnit?: Maybe<ConsumptionUnit>;
  device: Device;
  fuelType?: Maybe<FuelTypeFuel>;
  id: Scalars['ID'];
  initialConsumption?: Maybe<Scalars['Float']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  tankVolume?: Maybe<Scalars['Int']>;
  volumeUnit?: Maybe<VolumeUnit>;
};

export type FuelCreateInput = {
  actualConsumption?: InputMaybe<Scalars['Float']>;
  consumptionUnit: ConsumptionUnitInput;
  device: DeviceInput;
  fuelType: FuelTypeInput;
  initialConsumption?: InputMaybe<Scalars['Float']>;
  tankVolume?: InputMaybe<Scalars['Int']>;
  volumeUnit: VolumeUnitInput;
};

export type FuelDevice = {
  __typename?: 'FuelDevice';
  actualConsumption?: Maybe<Scalars['Float']>;
  consumptionUnit?: Maybe<ConsumptionUnit>;
  fuelType?: Maybe<FuelTypeFuel>;
  id: Scalars['ID'];
  initialConsumption?: Maybe<Scalars['Float']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  tankVolume?: Maybe<Scalars['Int']>;
  volumeUnit?: Maybe<VolumeUnit>;
};

export type FuelType = {
  __typename?: 'FuelType';
  fuel: Array<Fuel>;
  fuelName: Scalars['String'];
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
};

export type FuelTypeFuel = {
  __typename?: 'FuelTypeFuel';
  fuelName: Scalars['String'];
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
};

export type FuelTypeInput = {
  id: Scalars['Int'];
};

export type FuelUpdateInput = {
  actualConsumption?: InputMaybe<Scalars['Float']>;
  consumptionUnit?: InputMaybe<ConsumptionUnitInput>;
  device?: InputMaybe<DeviceInput>;
  fuelType?: InputMaybe<FuelTypeInput>;
  id: Scalars['Int'];
  initialConsumption?: InputMaybe<Scalars['Float']>;
  tankVolume?: InputMaybe<Scalars['Int']>;
  volumeUnit?: InputMaybe<VolumeUnitInput>;
};

export type MaintenanceEvent = {
  __typename?: 'MaintenanceEvent';
  completionDay?: Maybe<Scalars['DateTime']>;
  creationDate: Scalars['DateTime'];
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']>;
  deviceId: Scalars['Int'];
  eventDate?: Maybe<Scalars['DateTime']>;
  eventOperation?: Maybe<Array<EventOperation>>;
  eventType: EventType;
  exchangeRate?: Maybe<Scalars['Decimal']>;
  expense: Array<Expense>;
  id: Scalars['BigInt'];
  lastEditedDate?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  periodDay?: Maybe<Scalars['Int']>;
  periodDistance?: Maybe<Scalars['Int']>;
  priceEstimated?: Maybe<Scalars['Decimal']>;
  priceTotal?: Maybe<Scalars['Decimal']>;
  stateMh?: Maybe<Scalars['Int']>;
  stateOdometer?: Maybe<Scalars['Int']>;
};

export type MaintenanceEventUpdateInput = {
  completionDay?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<CurrencyInput>;
  description?: InputMaybe<Scalars['String']>;
  eventDate?: InputMaybe<Scalars['DateTime']>;
  eventOperation?: InputMaybe<Array<EventOperationOperationCreateInput>>;
  eventType?: InputMaybe<EventTypeInput>;
  exchangeRate?: InputMaybe<Scalars['Float']>;
  expense?: InputMaybe<Array<ExpenseNoEventUpdateInput>>;
  id: Scalars['BigInt'];
  lastEditedDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
  priceEstimated?: InputMaybe<Scalars['Float']>;
  priceTotal?: InputMaybe<Scalars['Float']>;
  stateMh?: InputMaybe<Scalars['Int']>;
  stateOdometer?: InputMaybe<Scalars['Int']>;
};

export type MaintenanceInterval = {
  __typename?: 'MaintenanceInterval';
  device: Device;
  dueDistance?: Maybe<Scalars['Int']>;
  id: Scalars['BigInt'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  operation?: Maybe<Array<Operation>>;
  periodDay?: Maybe<Scalars['Int']>;
  periodDistance?: Maybe<Scalars['Int']>;
};

export type MaintenanceIntervalCreateInput = {
  device: DeviceInput;
  name: Scalars['String'];
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
};

export type MaintenanceIntervalEvent = {
  __typename?: 'MaintenanceIntervalEvent';
  device: Device;
  id: Scalars['BigInt'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  operation?: Maybe<Array<OperationEvent>>;
  periodDay?: Maybe<Scalars['Int']>;
  periodDistance?: Maybe<Scalars['Int']>;
};

export type MaintenanceIntervalInput = {
  id: Scalars['BigInt'];
};

export type MaintenanceIntervalUpdateInput = {
  device?: InputMaybe<DeviceInput>;
  id: Scalars['BigInt'];
  name?: InputMaybe<Scalars['String']>;
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
};

export type Membership = {
  __typename?: 'Membership';
  dataFree: Scalars['Int'];
  deviceCountMembership: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Int'];
};

export type MembershipCreateInput = {
  dataFree: Scalars['Int'];
  deviceCountMembership: Scalars['Int'];
  name: Scalars['String'];
  price: Scalars['Int'];
};

export type MembershipUpdateInput = {
  dataFree?: InputMaybe<Scalars['Int']>;
  deviceCountMembership?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeCashPaymentStatus?: Maybe<CashPayment>;
  confirmRegistration?: Maybe<Scalars['String']>;
  createAttachment?: Maybe<Attachment>;
  createCashPayment?: Maybe<PaymentRedirect>;
  createDevice?: Maybe<Device>;
  createDeviceAttachment?: Maybe<DeviceAttachment>;
  createEvent?: Maybe<Event>;
  createEventAttachment?: Maybe<EventAttachment>;
  createEventOperation?: Maybe<EventOperation>;
  createEventTags?: Maybe<Array<Maybe<EventTag>>>;
  createExpenseEvent?: Maybe<ExpenseEvent>;
  createFuel?: Maybe<FuelDevice>;
  createFuels?: Maybe<Array<Maybe<FuelDevice>>>;
  createMaintenanceEvent?: Maybe<Event>;
  createMaintenanceInterval?: Maybe<MaintenanceInterval>;
  createOperation?: Maybe<Operation>;
  createRefueling?: Maybe<Refueling>;
  createTags?: Maybe<Array<Maybe<Tag>>>;
  createUser?: Maybe<User>;
  deleteAttachment?: Maybe<Attachment>;
  deleteDevice?: Maybe<Device>;
  deleteDeviceAttachment?: Maybe<DeviceAttachment>;
  deleteDevicesByUser?: Maybe<OutputMany>;
  deleteEvent?: Maybe<Event>;
  deleteEventAttachment?: Maybe<EventAttachment>;
  deleteEventOperation?: Maybe<EventOperation>;
  deleteEventTag?: Maybe<EventTag>;
  deleteExpense?: Maybe<Expense>;
  deleteFuel?: Maybe<FuelDevice>;
  deleteFuels?: Maybe<Array<Maybe<FuelDevice>>>;
  deleteMaintenanceInterval?: Maybe<MaintenanceInterval>;
  deleteOperation?: Maybe<Operation>;
  deleteTag?: Maybe<Tag>;
  deleteUser?: Maybe<User>;
  loginUser?: Maybe<AuthUser>;
  logoutUser?: Maybe<User>;
  requestRefundCashPayment?: Maybe<CashPayment>;
  resetPassword?: Maybe<Scalars['String']>;
  setNewPassword?: Maybe<Scalars['String']>;
  setNewTypeOfMembership?: Maybe<Scalars['String']>;
  updateAttachment?: Maybe<Attachment>;
  updateDevice?: Maybe<Device>;
  updateEvent?: Maybe<Event>;
  updateExpense?: Maybe<Expense>;
  updateExpenseEvent?: Maybe<ExpenseEvent>;
  updateFuel?: Maybe<FuelDevice>;
  updateMaintenanceEvent?: Maybe<MaintenanceEvent>;
  updateMaintenanceInterval?: Maybe<MaintenanceInterval>;
  updateOperation?: Maybe<Operation>;
  updateRefueling?: Maybe<Refueling>;
  updateRefuelingEvent?: Maybe<Event>;
  updateTag?: Maybe<Tag>;
  updateUser?: Maybe<User>;
  upgradeDataQuote?: Maybe<Scalars['String']>;
};


export type MutationChangeCashPaymentStatusArgs = {
  cashPaymentId: Scalars['Int'];
  status: CashPaymentStatus;
};


export type MutationConfirmRegistrationArgs = {
  confirmationToken: Scalars['String'];
};


export type MutationCreateAttachmentArgs = {
  attachment: AttachmentCreateInput;
};


export type MutationCreateCashPaymentArgs = {
  cashPaymentData: CashPaymentCreateInput;
};


export type MutationCreateDeviceArgs = {
  device: DeviceCreateInput;
};


export type MutationCreateDeviceAttachmentArgs = {
  attachmentId: Scalars['BigInt'];
  deviceId: Scalars['Int'];
};


export type MutationCreateEventArgs = {
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
  event: EventCreateInput;
};


export type MutationCreateEventAttachmentArgs = {
  attachmentId: Scalars['BigInt'];
  eventId: Scalars['BigInt'];
};


export type MutationCreateEventOperationArgs = {
  eventId: Scalars['BigInt'];
  operationId: Scalars['Int'];
};


export type MutationCreateEventTagsArgs = {
  eventTags: Array<EventTagCreateInput>;
};


export type MutationCreateExpenseEventArgs = {
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
  event: EventCreateInput;
  expenses: Array<ExpenseCreateInput>;
};


export type MutationCreateFuelArgs = {
  fuel: FuelCreateInput;
};


export type MutationCreateFuelsArgs = {
  fuels: Array<FuelCreateInput>;
};


export type MutationCreateMaintenanceEventArgs = {
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
  event: EventCreateInput;
  expenses: Array<ExpenseCreateInput>;
};


export type MutationCreateMaintenanceIntervalArgs = {
  maintenanceInterval: MaintenanceIntervalCreateInput;
};


export type MutationCreateOperationArgs = {
  operation: OperationCreateInput;
};


export type MutationCreateRefuelingArgs = {
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
  event: EventRefuelingCreateInput;
  refueling: RefuelingCreateInput;
};


export type MutationCreateTagsArgs = {
  tags: Array<TagCreateInput>;
};


export type MutationCreateUserArgs = {
  user: UserCreateInput;
};


export type MutationDeleteAttachmentArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteDeviceArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteDeviceAttachmentArgs = {
  attachmentId: Scalars['BigInt'];
  deviceId: Scalars['Int'];
};


export type MutationDeleteDevicesByUserArgs = {
  userId: Scalars['Int'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteEventAttachmentArgs = {
  attachmentId: Scalars['BigInt'];
  eventId: Scalars['BigInt'];
};


export type MutationDeleteEventOperationArgs = {
  eventId: Scalars['BigInt'];
  operationId: Scalars['Int'];
};


export type MutationDeleteEventTagArgs = {
  eventId: Scalars['BigInt'];
  tagId: Scalars['Int'];
};


export type MutationDeleteExpenseArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteFuelArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteFuelsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteMaintenanceIntervalArgs = {
  id: Scalars['BigInt'];
};


export type MutationDeleteOperationArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int'];
};


export type MutationLoginUserArgs = {
  user: UserLoginInput;
};


export type MutationLogoutUserArgs = {
  id: Scalars['Int'];
};


export type MutationRequestRefundCashPaymentArgs = {
  cashPaymentId: Scalars['Int'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationSetNewPasswordArgs = {
  resetPassword: ResetPasswordInput;
};


export type MutationSetNewTypeOfMembershipArgs = {
  devicesNotToSuspend?: InputMaybe<Array<Scalars['Int']>>;
  newMembershipId: Scalars['Int'];
};


export type MutationUpdateAttachmentArgs = {
  attachment: AttachmentUpdateInput;
};


export type MutationUpdateDeviceArgs = {
  device: DeviceUpdateInput;
};


export type MutationUpdateEventArgs = {
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
  event: EventUpdateInput;
};


export type MutationUpdateExpenseArgs = {
  expense: ExpenseUpdateInput;
};


export type MutationUpdateExpenseEventArgs = {
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
  event: ExpenseEventUpdateInput;
};


export type MutationUpdateFuelArgs = {
  fuel: FuelUpdateInput;
};


export type MutationUpdateMaintenanceEventArgs = {
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
  event: MaintenanceEventUpdateInput;
};


export type MutationUpdateMaintenanceIntervalArgs = {
  maintenanceInterval: MaintenanceIntervalUpdateInput;
};


export type MutationUpdateOperationArgs = {
  operation: OperationUpdateInput;
};


export type MutationUpdateRefuelingArgs = {
  refueling: RefuelingUpdateInput;
};


export type MutationUpdateRefuelingEventArgs = {
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
  event: RefuelingEventUpdateInput;
};


export type MutationUpdateTagArgs = {
  tag: TagUpdateInput;
};


export type MutationUpdateUserArgs = {
  user: UserUpdateInput;
};


export type MutationUpgradeDataQuoteArgs = {
  countOfDataQuoteToUnlock: Scalars['Int'];
};

export type OdometerFilter = {
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type OdometerUnit = {
  __typename?: 'OdometerUnit';
  id: Scalars['ID'];
  unitName: Scalars['String'];
};

export type OdometerUnitInput = {
  id: Scalars['Int'];
};

export type Operation = {
  __typename?: 'Operation';
  creationDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  dueDistance?: Maybe<Scalars['Int']>;
  eventOperation?: Maybe<Array<EventOperationNoOperation>>;
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  maintenanceInterval?: Maybe<MaintenanceInterval>;
  name: Scalars['String'];
  operationType: OperationType;
  periodDay?: Maybe<Scalars['Int']>;
  periodDistance?: Maybe<Scalars['Int']>;
  periodEndDate?: Maybe<Scalars['DateTime']>;
};

export type OperationCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  eventOperation?: InputMaybe<Array<EventOperationEventCreateInput>>;
  maintenanceInterval: MaintenanceIntervalInput;
  name: Scalars['String'];
  operationType: OperationTypeInput;
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
};

export type OperationEvent = {
  __typename?: 'OperationEvent';
  description?: Maybe<Scalars['String']>;
  eventOperation?: Maybe<Array<EventOperationNoOperation>>;
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  operationType: OperationType;
  periodDay?: Maybe<Scalars['Int']>;
  periodDistance?: Maybe<Scalars['Int']>;
};

export type OperationInput = {
  id: Scalars['Int'];
};

export type OperationType = {
  __typename?: 'OperationType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type OperationTypeInput = {
  id: Scalars['Int'];
};

export type OperationUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  eventOperation?: InputMaybe<Array<EventOperationEventUpdateInput>>;
  id: Scalars['Int'];
  maintenanceInterval?: InputMaybe<MaintenanceIntervalInput>;
  name?: InputMaybe<Scalars['String']>;
  operationType?: InputMaybe<OperationTypeInput>;
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
};

export type OperationUpdateInputNoEventOperation = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  maintenanceInterval?: InputMaybe<MaintenanceIntervalInput>;
  name?: InputMaybe<Scalars['String']>;
  operationType?: InputMaybe<OperationTypeInput>;
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
};

export type OutputMany = {
  __typename?: 'OutputMany';
  count?: Maybe<Scalars['Int']>;
};

export type PaymentRedirect = {
  __typename?: 'PaymentRedirect';
  url: Scalars['String'];
};

export type PowerUnit = {
  __typename?: 'PowerUnit';
  id: Scalars['ID'];
  unitName: Scalars['String'];
};

export type PowerUnitInput = {
  id: Scalars['Int'];
};

export type PriceFilter = {
  from: Scalars['Int'];
  to: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  actualOdometerCalculated?: Maybe<Scalars['Int']>;
  analyze: Array<AnalyzeRow>;
  analyzeEventFilterCount: Scalars['Int'];
  attachment?: Maybe<Attachment>;
  attachmentByDeviceAndFiltersAndTags?: Maybe<AttachmentScroll>;
  attachmentByStorageName?: Maybe<Attachment>;
  attachments: Array<Attachment>;
  attachmentsByMimeTypes?: Maybe<AttachmentScroll>;
  cashPaymentById: CashPayment;
  cashPayments: Array<Maybe<CashPayment>>;
  consumptionUnit?: Maybe<ConsumptionUnit>;
  consumptionUnits: Array<ConsumptionUnit>;
  consumptionUnitsByFuelType: Array<ConsumptionUnit>;
  creditTransactionById?: Maybe<CreditTransaction>;
  creditTransactions?: Maybe<Array<Maybe<CreditTransaction>>>;
  creditTransactionsByUserId?: Maybe<Array<Maybe<CreditTransaction>>>;
  currencies: Array<Currency>;
  currency?: Maybe<Currency>;
  destroySignature?: Maybe<DestroySignature>;
  device?: Maybe<Device>;
  deviceAttachments: Array<DeviceAttachment>;
  deviceAttachmentsByDevice: Array<DeviceAttachment>;
  deviceAttachmentsByDeviceAndMimeTypes?: Maybe<DeviceAttachmentScroll>;
  deviceCategories: Array<DeviceCategoryDevice>;
  deviceCategory?: Maybe<DeviceCategoryDevice>;
  devices: Array<Device>;
  event?: Maybe<Event>;
  eventAttachments: Array<EventAttachment>;
  eventAttachmentsByEventAndMimeTypes?: Maybe<EventAttachmentScroll>;
  eventAttachmentsByEventId: Array<EventAttachment>;
  eventAttachmentsByEventType: Array<EventAttachment>;
  eventOperation?: Maybe<EventOperation>;
  eventOperations: Array<EventOperation>;
  eventTag?: Maybe<EventTag>;
  eventTags?: Maybe<EventTagScroll>;
  eventTagsByEvent?: Maybe<EventTagScroll>;
  eventType?: Maybe<EventType>;
  eventTypes: Array<EventType>;
  events: Array<Event>;
  eventsByDevice?: Maybe<EventScroll>;
  eventsByDeviceAndEventType?: Maybe<EventScroll>;
  eventsByDeviceAndFiltersAndTags?: Maybe<EventScroll>;
  eventsByEventType?: Maybe<EventScroll>;
  expense?: Maybe<Expense>;
  expenses?: Maybe<ExpenseScroll>;
  expensesByDevice?: Maybe<ExpenseScroll>;
  expensesByEvent?: Maybe<ExpenseScroll>;
  fuel?: Maybe<FuelDevice>;
  fuelType?: Maybe<FuelTypeFuel>;
  fuelTypes: Array<FuelTypeFuel>;
  fuels: Array<FuelDevice>;
  lastEditedDevice?: Maybe<Device>;
  maintenanceInterval?: Maybe<MaintenanceInterval>;
  maintenanceIntervals: Array<MaintenanceInterval>;
  maintenanceIntervalsByDevice: Array<MaintenanceInterval>;
  maintenanceIntervalsByEvent: Array<MaintenanceIntervalEvent>;
  memberships: Array<Maybe<Membership>>;
  odometerUnit?: Maybe<OdometerUnit>;
  odometerUnits: Array<OdometerUnit>;
  operation?: Maybe<Operation>;
  operationType?: Maybe<OperationType>;
  operationTypes: Array<OperationType>;
  operations: Array<Operation>;
  powerUnit?: Maybe<PowerUnit>;
  powerUnits: Array<PowerUnit>;
  refueling?: Maybe<RefuelingScroll>;
  refuelingByDevice?: Maybe<RefuelingScroll>;
  refuelingByEvent: Array<Refueling>;
  refuelingById?: Maybe<Refueling>;
  storageUsage: StorageUsageRecord;
  tag?: Maybe<Tag>;
  tagCategories: Array<TagCategory>;
  tagCategory?: Maybe<TagCategory>;
  tags?: Maybe<TagScroll>;
  tagsByCategories?: Maybe<TagScroll>;
  tagsOrderedByUsage: Array<Tag>;
  transmission?: Maybe<TransmissionDevice>;
  transmissions: Array<TransmissionDevice>;
  uploadSignature?: Maybe<UploadSignature>;
  user?: Maybe<User>;
  users: Array<User>;
  vehicleType?: Maybe<VehicleTypeDevice>;
  vehicleTypes: Array<VehicleTypeDevice>;
  vehicleTypesByDeviceCategory: Array<VehicleTypeDevice>;
  volumeUnit?: Maybe<VolumeUnit>;
  volumeUnits: Array<VolumeUnit>;
  volumeUnitsByFuelType: Array<VolumeUnit>;
};


export type QueryActualOdometerCalculatedArgs = {
  deviceId: Scalars['Int'];
};


export type QueryAnalyzeArgs = {
  analyzeCategory: AnalyzeCategory;
  command: AnalyzeCommand;
  deviceId: Scalars['Int'];
  filters: Filter;
  fuelId?: InputMaybe<Scalars['Int']>;
  tags: Array<Scalars['Int']>;
};


export type QueryAnalyzeEventFilterCountArgs = {
  deviceId: Scalars['Int'];
  filters?: InputMaybe<Filter>;
  tags: Array<Scalars['Int']>;
};


export type QueryAttachmentArgs = {
  id: Scalars['BigInt'];
};


export type QueryAttachmentByDeviceAndFiltersAndTagsArgs = {
  deviceId?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Filter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  tags: Array<Scalars['Int']>;
};


export type QueryAttachmentByStorageNameArgs = {
  storageName: Scalars['String'];
};


export type QueryAttachmentsByMimeTypesArgs = {
  limit: Scalars['Int'];
  mimeTypes: Array<Scalars['String']>;
  offset: Scalars['Int'];
};


export type QueryCashPaymentByIdArgs = {
  cashPaymentId: Scalars['Int'];
};


export type QueryConsumptionUnitArgs = {
  id: Scalars['Int'];
};


export type QueryConsumptionUnitsByFuelTypeArgs = {
  fuelTypeId: Scalars['Int'];
};


export type QueryCreditTransactionByIdArgs = {
  id: Scalars['Int'];
};


export type QueryCreditTransactionsByUserIdArgs = {
  userId: Scalars['Int'];
};


export type QueryCurrencyArgs = {
  id: Scalars['Int'];
};


export type QueryDestroySignatureArgs = {
  publicId: Scalars['String'];
};


export type QueryDeviceArgs = {
  id: Scalars['Int'];
};


export type QueryDeviceAttachmentsByDeviceArgs = {
  deviceId: Scalars['Int'];
};


export type QueryDeviceAttachmentsByDeviceAndMimeTypesArgs = {
  deviceId: Scalars['Int'];
  limit: Scalars['Int'];
  mimeTypes: Array<Scalars['String']>;
  offset: Scalars['Int'];
};


export type QueryDeviceCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryEventArgs = {
  id: Scalars['BigInt'];
};


export type QueryEventAttachmentsByEventAndMimeTypesArgs = {
  eventId: Scalars['BigInt'];
  limit: Scalars['Int'];
  mimeTypes: Array<Scalars['String']>;
  offset: Scalars['Int'];
};


export type QueryEventAttachmentsByEventIdArgs = {
  eventId: Scalars['BigInt'];
};


export type QueryEventAttachmentsByEventTypeArgs = {
  eventTypeIds: Array<Scalars['Int']>;
};


export type QueryEventOperationArgs = {
  id: Scalars['Int'];
};


export type QueryEventTagArgs = {
  eventId: Scalars['BigInt'];
  tagId: Scalars['Int'];
};


export type QueryEventTagsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryEventTagsByEventArgs = {
  eventId: Scalars['BigInt'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryEventTypeArgs = {
  id: Scalars['Int'];
};


export type QueryEventsByDeviceArgs = {
  deviceId: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryEventsByDeviceAndEventTypeArgs = {
  deviceId: Scalars['Int'];
  eventTypeIds: Array<Scalars['Int']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryEventsByDeviceAndFiltersAndTagsArgs = {
  deviceId: Scalars['Int'];
  filters?: InputMaybe<Filter>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  tags: Array<Scalars['Int']>;
};


export type QueryEventsByEventTypeArgs = {
  eventTypeIds: Array<Scalars['Int']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryExpenseArgs = {
  id: Scalars['BigInt'];
};


export type QueryExpensesArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryExpensesByDeviceArgs = {
  deviceId: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryExpensesByEventArgs = {
  eventId: Scalars['BigInt'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryFuelArgs = {
  id: Scalars['Int'];
};


export type QueryFuelTypeArgs = {
  id: Scalars['Int'];
};


export type QueryMaintenanceIntervalArgs = {
  id: Scalars['BigInt'];
};


export type QueryMaintenanceIntervalsByDeviceArgs = {
  deviceId: Scalars['Int'];
};


export type QueryMaintenanceIntervalsByEventArgs = {
  eventId: Scalars['BigInt'];
};


export type QueryOdometerUnitArgs = {
  id: Scalars['Int'];
};


export type QueryOperationArgs = {
  id: Scalars['Int'];
};


export type QueryOperationTypeArgs = {
  id: Scalars['Int'];
};


export type QueryPowerUnitArgs = {
  id: Scalars['Int'];
};


export type QueryRefuelingArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryRefuelingByDeviceArgs = {
  deviceId: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryRefuelingByEventArgs = {
  eventId: Scalars['BigInt'];
};


export type QueryRefuelingByIdArgs = {
  id: Scalars['BigInt'];
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTagCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryTagsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryTagsByCategoriesArgs = {
  categoryIds: Array<Scalars['Int']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};


export type QueryTagsOrderedByUsageArgs = {
  limit: Scalars['Int'];
};


export type QueryTransmissionArgs = {
  id: Scalars['Int'];
};


export type QueryUploadSignatureArgs = {
  uploadSignature?: InputMaybe<UploadSignatureInput>;
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryVehicleTypeArgs = {
  id: Scalars['Int'];
};


export type QueryVehicleTypesByDeviceCategoryArgs = {
  deviceCategoryId: Scalars['Int'];
};


export type QueryVolumeUnitArgs = {
  id: Scalars['Int'];
};


export type QueryVolumeUnitsByFuelTypeArgs = {
  fuelTypeId: Scalars['Int'];
};

export type Refueling = {
  __typename?: 'Refueling';
  actualConsumption?: Maybe<Scalars['Float']>;
  event: Event;
  fuelType?: Maybe<FuelTypeFuel>;
  fullTank: Scalars['Boolean'];
  id: Scalars['BigInt'];
  pricePerUnit?: Maybe<Scalars['Decimal']>;
  skipTank: Scalars['Boolean'];
  unitNumber: Scalars['Float'];
};

export type RefuelingCreateInput = {
  fuelType: FuelTypeInput;
  fullTank: Scalars['Boolean'];
  pricePerUnit?: InputMaybe<Scalars['Decimal']>;
  skipTank: Scalars['Boolean'];
  unitNumber: Scalars['Float'];
};

export type RefuelingEventUpdateInput = {
  completionDay?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<CurrencyInput>;
  description?: InputMaybe<Scalars['String']>;
  eventDate?: InputMaybe<Scalars['DateTime']>;
  exchangeRate?: InputMaybe<Scalars['Float']>;
  id: Scalars['BigInt'];
  lastEditedDate?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  periodDay?: InputMaybe<Scalars['Int']>;
  periodDistance?: InputMaybe<Scalars['Int']>;
  priceEstimated?: InputMaybe<Scalars['Float']>;
  priceTotal?: InputMaybe<Scalars['Float']>;
  refueling?: InputMaybe<Array<InputMaybe<RefuelingNoEventUpdateInput>>>;
  stateMh?: InputMaybe<Scalars['Int']>;
  stateOdometer?: InputMaybe<Scalars['Int']>;
};

export type RefuelingNoEventUpdateInput = {
  fuelType?: InputMaybe<FuelTypeInput>;
  fullTank?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['BigInt'];
  pricePerUnit?: InputMaybe<Scalars['Decimal']>;
  skipTank?: InputMaybe<Scalars['Boolean']>;
  unitNumber?: InputMaybe<Scalars['Float']>;
};

export type RefuelingScroll = {
  __typename?: 'RefuelingScroll';
  isLast?: Maybe<Scalars['Boolean']>;
  refueling: Array<Refueling>;
};

export type RefuelingUpdateInput = {
  event?: InputMaybe<EventRefuelingUpdateInput>;
  fuelType?: InputMaybe<FuelTypeInput>;
  fullTank?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['BigInt'];
  pricePerUnit?: InputMaybe<Scalars['Decimal']>;
  skipTank?: InputMaybe<Scalars['Boolean']>;
  unitNumber?: InputMaybe<Scalars['Float']>;
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  resetToken: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** Dataset indicating users usage of the Storage. */
export type StorageUsageRecord = {
  __typename?: 'StorageUsageRecord';
  /** User's maximum file size (MB) */
  dataQuoteMB: Scalars['Int'];
  /** Current size of all files uploaded to Storage (MB) */
  dataVolumeMB: Scalars['Int'];
};

export type Tag = {
  __typename?: 'Tag';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  tagCategory: TagCategory;
  tagId: Scalars['String'];
  tagName: Scalars['String'];
};

export type TagCategory = {
  __typename?: 'TagCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TagCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  tagCategoryId: Scalars['Int'];
  tagId: Scalars['String'];
  tagName: Scalars['String'];
};

export type TagInput = {
  id: Scalars['BigInt'];
};

export type TagScroll = {
  __typename?: 'TagScroll';
  isLast?: Maybe<Scalars['Boolean']>;
  tag: Array<Tag>;
};

export type TagUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  tagCategoryId?: InputMaybe<Scalars['Int']>;
  tagId?: InputMaybe<Scalars['String']>;
  tagName?: InputMaybe<Scalars['String']>;
};

export type ThumbnailImageInput = {
  id?: InputMaybe<Scalars['BigInt']>;
};

export type Token = {
  __typename?: 'Token';
  id: Scalars['ID'];
  refreshToken: Scalars['String'];
  user: User;
  valid: Scalars['Boolean'];
};

export type TokenCreateInput = {
  refreshToken: Scalars['String'];
  user: UserInput;
  valid: Scalars['Boolean'];
};

export type TokenInput = {
  id: Scalars['ID'];
  refreshToken: Scalars['String'];
  user: UserInput;
  valid: Scalars['Boolean'];
};

export type TokenUpdateInput = {
  refreshToken?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<UserInput>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type Transmission = {
  __typename?: 'Transmission';
  device: Array<Device>;
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type TransmissionDevice = {
  __typename?: 'TransmissionDevice';
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type TransmissionInput = {
  id: Scalars['Int'];
};

export type UploadSignature = {
  __typename?: 'UploadSignature';
  path: Scalars['String'];
  signature: Scalars['String'];
  timestamp: Scalars['String'];
};

export type UploadSignatureInput = {
  deviceId?: InputMaybe<Scalars['Int']>;
  eager?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['BigInt']>;
  eventTypeId?: InputMaybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean'];
  cashPayment?: Maybe<Array<CashPayment>>;
  consumptionUnit: ConsumptionUnit;
  countryCode?: Maybe<Scalars['String']>;
  creditBalance: Scalars['Int'];
  dataQuote: Scalars['Int'];
  dataVolume: Scalars['Int'];
  device: Array<Device>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeviceCountExceeded?: Maybe<Scalars['Boolean']>;
  isRemoved?: Maybe<Scalars['Boolean']>;
  login: Scalars['String'];
  membership: Membership;
  membershipRegistrationDate: Scalars['DateTime'];
  odometerUnit: OdometerUnit;
  phone?: Maybe<Scalars['String']>;
  powerUnit: PowerUnit;
  registrationDate: Scalars['DateTime'];
  role?: Maybe<Role>;
  surname?: Maybe<Scalars['String']>;
  thumbnailImage?: Maybe<Attachment>;
  volumeUnit: VolumeUnit;
};

export type UserCreateInput = {
  consumptionUnit?: InputMaybe<ConsumptionUnitInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  forceRegister?: InputMaybe<Scalars['Boolean']>;
  login: Scalars['String'];
  membership?: InputMaybe<MembershipCreateInput>;
  odometerUnit?: InputMaybe<OdometerUnitInput>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  powerUnit?: InputMaybe<PowerUnitInput>;
  surname?: InputMaybe<Scalars['String']>;
  thumbnailImage?: InputMaybe<ThumbnailImageInput>;
  volumeUnit?: InputMaybe<VolumeUnitInput>;
};

export type UserDevice = {
  __typename?: 'UserDevice';
  active: Scalars['Boolean'];
  consumptionUnit: ConsumptionUnit;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  login: Scalars['String'];
  membership: Membership;
  odometerUnit: OdometerUnit;
  phone?: Maybe<Scalars['String']>;
  powerUnit: PowerUnit;
  registrationDate: Scalars['DateTime'];
  role?: Maybe<Role>;
  surname?: Maybe<Scalars['String']>;
  volumeUnit: VolumeUnit;
};

export type UserInput = {
  id: Scalars['Int'];
};

export type UserLoginInput = {
  extendedExpiration?: InputMaybe<Scalars['Boolean']>;
  login: Scalars['String'];
  password: Scalars['String'];
};

export type UserUpdateInput = {
  consumptionUnit?: InputMaybe<ConsumptionUnitInput>;
  countryCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  login?: InputMaybe<Scalars['String']>;
  membership?: InputMaybe<MembershipUpdateInput>;
  odometerUnit?: InputMaybe<OdometerUnitInput>;
  phone?: InputMaybe<Scalars['String']>;
  powerUnit?: InputMaybe<PowerUnitInput>;
  surname?: InputMaybe<Scalars['String']>;
  thumbnailImage?: InputMaybe<ThumbnailImageInput>;
  volumeUnit?: InputMaybe<VolumeUnitInput>;
};

export type VehicleType = {
  __typename?: 'VehicleType';
  device: Array<Device>;
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type VehicleTypeDevice = {
  __typename?: 'VehicleTypeDevice';
  id: Scalars['ID'];
  isRemoved?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type VehicleTypeInput = {
  id: Scalars['Int'];
};

export type VolumeUnit = {
  __typename?: 'VolumeUnit';
  id: Scalars['ID'];
  unitName: Scalars['String'];
};

export type VolumeUnitInput = {
  id: Scalars['Int'];
};

export type ActualOdometerStateByDeviceIdQueryVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type ActualOdometerStateByDeviceIdQuery = { __typename?: 'Query', actualOdometerCalculated?: number | null };

export type AnalyzeQueryVariables = Exact<{
  deviceId: Scalars['Int'];
  analyzeCategory: AnalyzeCategory;
  command: AnalyzeCommand;
  tags: Array<Scalars['Int']> | Scalars['Int'];
  fuelId?: InputMaybe<Scalars['Int']>;
  filters: Filter;
}>;


export type AnalyzeQuery = { __typename?: 'Query', analyze: Array<{ __typename?: 'AnalyzeRow', rowDescriptionKey: string, rowDescriptionCategory?: string | null, value: string }> };

export type AnalyzeEventFilterCounterQueryVariables = Exact<{
  deviceId: Scalars['Int'];
  tags: Array<Scalars['Int']> | Scalars['Int'];
  filters?: InputMaybe<Filter>;
}>;


export type AnalyzeEventFilterCounterQuery = { __typename?: 'Query', analyzeEventFilterCount: number };

export type AttachmentByDeviceAndFiltersAndTagsQueryVariables = Exact<{
  tags: Array<Scalars['Int']> | Scalars['Int'];
  deviceId?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Filter>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type AttachmentByDeviceAndFiltersAndTagsQuery = { __typename?: 'Query', attachmentByDeviceAndFiltersAndTags?: { __typename?: 'AttachmentScroll', isLast?: boolean | null, attachment: Array<{ __typename?: 'Attachment', id: any, mimeType: string, originalName: string, size?: number | null, storageName: string }> } | null };

export type BasicInfoAboutEventQueryVariables = Exact<{
  eventId: Scalars['BigInt'];
}>;


export type BasicInfoAboutEventQuery = { __typename?: 'Query', event?: { __typename?: 'Event', eventDate?: any | null, stateOdometer?: number | null, description?: string | null } | null };

export type ConfirmRegistrationMutationVariables = Exact<{
  confirmationToken: Scalars['String'];
}>;


export type ConfirmRegistrationMutation = { __typename?: 'Mutation', confirmRegistration?: string | null };

export type ConsumptionUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConsumptionUnitsQuery = { __typename?: 'Query', consumptionUnits: Array<{ __typename?: 'ConsumptionUnit', id: string, unitName: string }> };

export type ConsumptionUnitsByFuelTypeQueryVariables = Exact<{
  fuelTypeId: Scalars['Int'];
}>;


export type ConsumptionUnitsByFuelTypeQuery = { __typename?: 'Query', consumptionUnitsByFuelType: Array<{ __typename?: 'ConsumptionUnit', id: string, unitName: string }> };

export type CreateAttachmentMutationVariables = Exact<{
  attachment: AttachmentCreateInput;
}>;


export type CreateAttachmentMutation = { __typename?: 'Mutation', createAttachment?: { __typename?: 'Attachment', id: any, originalName: string, storageName: string, mimeType: string, size?: number | null } | null };

export type CreateCashPaymentByAmountMutationVariables = Exact<{
  cashPaymentData: CashPaymentCreateInput;
}>;


export type CreateCashPaymentByAmountMutation = { __typename?: 'Mutation', createCashPayment?: { __typename?: 'PaymentRedirect', url: string } | null };

export type CreateDeviceMutationVariables = Exact<{
  device: DeviceCreateInput;
}>;


export type CreateDeviceMutation = { __typename?: 'Mutation', createDevice?: { __typename?: 'Device', id: string, name: string, manufacturer?: string | null, model?: string | null, spz?: string | null, color?: string | null, firstRegistrationDate?: any | null } | null };

export type CreateDeviceAttachmentMutationVariables = Exact<{
  deviceId: Scalars['Int'];
  attachmentId: Scalars['BigInt'];
}>;


export type CreateDeviceAttachmentMutation = { __typename?: 'Mutation', createDeviceAttachment?: { __typename?: 'DeviceAttachment', attachment: { __typename?: 'Attachment', id: any } } | null };

export type CreateEventMutationVariables = Exact<{
  event: EventCreateInput;
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent?: { __typename?: 'Event', id: any, name: string } | null };

export type CreateEventAttachmentMutationVariables = Exact<{
  eventId: Scalars['BigInt'];
  attachmentId: Scalars['BigInt'];
}>;


export type CreateEventAttachmentMutation = { __typename?: 'Mutation', createEventAttachment?: { __typename?: 'EventAttachment', attachment: { __typename?: 'Attachment', id: any } } | null };

export type CreateExpenseEventMutationVariables = Exact<{
  event: EventCreateInput;
  expenses: Array<ExpenseCreateInput> | ExpenseCreateInput;
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
}>;


export type CreateExpenseEventMutation = { __typename?: 'Mutation', createExpenseEvent?: { __typename?: 'ExpenseEvent', id: any, priceTotal?: any | null, stateOdometer?: number | null } | null };

export type CreateMaintenanceEventMutationVariables = Exact<{
  event: EventCreateInput;
  expenses: Array<ExpenseCreateInput> | ExpenseCreateInput;
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
}>;


export type CreateMaintenanceEventMutation = { __typename?: 'Mutation', createMaintenanceEvent?: { __typename?: 'Event', id: any, name: string } | null };

export type CreateMaintenanceIntervalMutationVariables = Exact<{
  maintenanceInterval: MaintenanceIntervalCreateInput;
}>;


export type CreateMaintenanceIntervalMutation = { __typename?: 'Mutation', createMaintenanceInterval?: { __typename?: 'MaintenanceInterval', name: string, periodDay?: number | null, periodDistance?: number | null, device: { __typename?: 'Device', id: string } } | null };

export type CreateOperationMutationVariables = Exact<{
  operation: OperationCreateInput;
}>;


export type CreateOperationMutation = { __typename?: 'Mutation', createOperation?: { __typename?: 'Operation', description?: string | null, name: string, periodDay?: number | null, periodDistance?: number | null, eventOperation?: Array<{ __typename?: 'EventOperationNoOperation', event: { __typename?: 'Event', id: any } }> | null, operationType: { __typename?: 'OperationType', id: string } } | null };

export type CreateRefuelingMutationVariables = Exact<{
  event: EventRefuelingCreateInput;
  refueling: RefuelingCreateInput;
  attachmentIds?: InputMaybe<Scalars['BigInt']>;
}>;


export type CreateRefuelingMutation = { __typename?: 'Mutation', createRefueling?: { __typename?: 'Refueling', id: any, pricePerUnit?: any | null, fullTank: boolean, skipTank: boolean, fuelType?: { __typename?: 'FuelTypeFuel', id: string, fuelName: string } | null } | null };

export type CreateUserMutationVariables = Exact<{
  user: UserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', id: string, login: string, phone?: string | null, firstName?: string | null, surname?: string | null, registrationDate: any, active: boolean } | null };

export type CreditPreviewQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type CreditPreviewQuery = { __typename?: 'Query', user?: { __typename?: 'User', creditBalance: number } | null, creditTransactionsByUserId?: Array<{ __typename?: 'CreditTransaction', date: any, value: number, description: string, type?: { __typename?: 'CreditTransactionType', id: number, name: string } | null } | null> | null };

export type DeleteAttachmentMutationVariables = Exact<{
  deleteAttachmentId: Scalars['BigInt'];
}>;


export type DeleteAttachmentMutation = { __typename?: 'Mutation', deleteAttachment?: { __typename?: 'Attachment', id: any, storageName: string } | null };

export type DeleteDeviceMutationVariables = Exact<{
  deleteDeviceId: Scalars['Int'];
}>;


export type DeleteDeviceMutation = { __typename?: 'Mutation', deleteDevice?: { __typename?: 'Device', name: string } | null };

export type DeleteDeviceAttachmentMutationVariables = Exact<{
  deviceId: Scalars['Int'];
  attachmentId: Scalars['BigInt'];
}>;


export type DeleteDeviceAttachmentMutation = { __typename?: 'Mutation', deleteDeviceAttachment?: { __typename?: 'DeviceAttachment', attachment: { __typename?: 'Attachment', id: any, storageName: string } } | null };

export type DeleteEventMutationVariables = Exact<{
  deleteEventId: Scalars['BigInt'];
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteEvent?: { __typename?: 'Event', eventType: { __typename?: 'EventType', name: string } } | null };

export type DeleteEventAttachmentMutationVariables = Exact<{
  eventId: Scalars['BigInt'];
  attachmentId: Scalars['BigInt'];
}>;


export type DeleteEventAttachmentMutation = { __typename?: 'Mutation', deleteEventAttachment?: { __typename?: 'EventAttachment', attachment: { __typename?: 'Attachment', id: any } } | null };

export type DeleteMaintenanceIntervalMutationVariables = Exact<{
  deleteMaintenanceIntervalId: Scalars['BigInt'];
}>;


export type DeleteMaintenanceIntervalMutation = { __typename?: 'Mutation', deleteMaintenanceInterval?: { __typename?: 'MaintenanceInterval', id: any } | null };

export type DeleteOperationMutationVariables = Exact<{
  deleteOperationId: Scalars['Int'];
}>;


export type DeleteOperationMutation = { __typename?: 'Mutation', deleteOperation?: { __typename?: 'Operation', id: string } | null };

export type DestroySignatureQueryVariables = Exact<{
  publicId: Scalars['String'];
}>;


export type DestroySignatureQuery = { __typename?: 'Query', destroySignature?: { __typename?: 'DestroySignature', signature: string, timestamp: string } | null };

export type DeviceAttachmentsByDeviceQueryVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type DeviceAttachmentsByDeviceQuery = { __typename?: 'Query', deviceAttachmentsByDevice: Array<{ __typename?: 'DeviceAttachment', attachment: { __typename?: 'Attachment', id: any, mimeType: string, originalName: string, size?: number | null, storageName: string }, device: { __typename?: 'DeviceNoAttachment', id: string, name: string } }> };

export type DeviceBasicInformationQueryVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type DeviceBasicInformationQuery = { __typename?: 'Query', device?: { __typename?: 'Device', name: string, manufacturer?: string | null, model?: string | null, color?: string | null, manufacturedYearMonth?: any | null, vin?: string | null, spz?: string | null, deviceCategory?: { __typename?: 'DeviceCategoryDevice', id: string, name: string } | null, vehicleType?: { __typename?: 'VehicleTypeDevice', id: string, name: string } | null } | null };

export type DeviceCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type DeviceCategoriesQuery = { __typename?: 'Query', deviceCategories: Array<{ __typename?: 'DeviceCategoryDevice', id: string, name: string }> };

export type DeviceHeaderDetailQueryVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type DeviceHeaderDetailQuery = { __typename?: 'Query', device?: { __typename?: 'Device', name: string, spz?: string | null, id: string, serialNumber?: string | null, thumbnailImage?: { __typename?: 'Attachment', storageName: string } | null, deviceCategory?: { __typename?: 'DeviceCategoryDevice', id: string, name: string } | null } | null };

export type DeviceOperationalDataQueryVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type DeviceOperationalDataQuery = { __typename?: 'Query', device?: { __typename?: 'Device', acquisitionDate?: any | null, actualOdometerState?: number | null, firstRegistrationDate?: any | null, guaranteeDate?: any | null, guaranteeDistance?: number | null, guaranteeMonths?: number | null, initialOdometerState?: number | null, odometerUnit?: { __typename?: 'OdometerUnit', unitName: string, id: string } | null } | null };

export type DeviceTechnicalParametersQueryVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type DeviceTechnicalParametersQuery = { __typename?: 'Query', device?: { __typename?: 'Device', engineSpecification?: string | null, serialNumber?: string | null, engineDisplacementCc?: number | null, power?: number | null, powerRpm?: number | null, torqueNm?: number | null, torqueRpm?: number | null, transmissionNumber?: number | null, powerUnit?: { __typename?: 'PowerUnit', id: string, unitName: string } | null, transmission?: { __typename?: 'TransmissionDevice', id: string, name: string } | null, fuel: Array<{ __typename?: 'FuelDevice', tankVolume?: number | null, actualConsumption?: number | null, initialConsumption?: number | null, fuelType?: { __typename?: 'FuelTypeFuel', id: string, fuelName: string } | null, consumptionUnit?: { __typename?: 'ConsumptionUnit', id: string, unitName: string } | null, volumeUnit?: { __typename?: 'VolumeUnit', id: string, unitName: string } | null }> } | null };

export type DeviceThumbnailImageQueryVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type DeviceThumbnailImageQuery = { __typename?: 'Query', device?: { __typename?: 'Device', thumbnailImage?: { __typename?: 'Attachment', id: any, mimeType: string, size?: number | null, originalName: string, storageName: string } | null } | null };

export type DeviceUnitsByDeviceIdQueryVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type DeviceUnitsByDeviceIdQuery = { __typename?: 'Query', device?: { __typename?: 'Device', odometerUnit?: { __typename?: 'OdometerUnit', id: string, unitName: string } | null, powerUnit?: { __typename?: 'PowerUnit', id: string, unitName: string } | null, fuel: Array<{ __typename?: 'FuelDevice', fuelType?: { __typename?: 'FuelTypeFuel', fuelName: string } | null, consumptionUnit?: { __typename?: 'ConsumptionUnit', unitName: string, id: string } | null }> } | null };

export type DeviceUsedFuelsQueryVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type DeviceUsedFuelsQuery = { __typename?: 'Query', device?: { __typename?: 'Device', fuel: Array<{ __typename?: 'FuelDevice', actualConsumption?: number | null, id: string, initialConsumption?: number | null, isRemoved?: boolean | null, tankVolume?: number | null, consumptionUnit?: { __typename?: 'ConsumptionUnit', id: string, unitName: string } | null, fuelType?: { __typename?: 'FuelTypeFuel', fuelName: string, id: string, isRemoved?: boolean | null } | null, volumeUnit?: { __typename?: 'VolumeUnit', id: string, unitName: string } | null }> } | null };

export type DevicesIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type DevicesIdsQuery = { __typename?: 'Query', devices: Array<{ __typename?: 'Device', id: string }> };

export type EventQueryVariables = Exact<{
  eventId: Scalars['BigInt'];
}>;


export type EventQuery = { __typename?: 'Query', event?: { __typename?: 'Event', name: string } | null };

export type EventAttachmentsByEventIdQueryVariables = Exact<{
  eventId: Scalars['BigInt'];
}>;


export type EventAttachmentsByEventIdQuery = { __typename?: 'Query', eventAttachmentsByEventId: Array<{ __typename?: 'EventAttachment', attachment: { __typename?: 'Attachment', id: any, mimeType: string, originalName: string, size?: number | null, storageName: string } }> };

export type EventByIdQueryVariables = Exact<{
  eventId: Scalars['BigInt'];
}>;


export type EventByIdQuery = { __typename?: 'Query', event?: { __typename?: 'Event', stateOdometer?: number | null, eventDate?: any | null } | null };

export type EventsQueryVariables = Exact<{ [key: string]: never; }>;


export type EventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', id: any, name: string, stateOdometer?: number | null, priceTotal?: any | null, eventType: { __typename?: 'EventType', id: string, name: string } }> };

export type EventsByDeviceQueryVariables = Exact<{
  deviceId: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type EventsByDeviceQuery = { __typename?: 'Query', eventsByDevice?: { __typename?: 'EventScroll', isLast?: boolean | null, event: Array<{ __typename?: 'Event', priceTotal?: any | null, lastEditedDate?: any | null, name: string, id: any, eventDate?: any | null, stateOdometer?: number | null, eventType: { __typename?: 'EventType', description?: string | null, id: string, name: string }, refueling?: Array<{ __typename?: 'Refueling', actualConsumption?: number | null }> | null }> } | null };

export type EventsByDeviceAndFiltersAndTagsQueryVariables = Exact<{
  deviceId: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  filters?: InputMaybe<Filter>;
  tags: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type EventsByDeviceAndFiltersAndTagsQuery = { __typename?: 'Query', eventsByDeviceAndFiltersAndTags?: { __typename?: 'EventScroll', isLast?: boolean | null, event: Array<{ __typename?: 'Event', id: any, name: string, priceTotal?: any | null, stateOdometer?: number | null, eventDate?: any | null, creationDate: any, deviceId: number, refueling?: Array<{ __typename?: 'Refueling', id: any, pricePerUnit?: any | null, unitNumber: number, fullTank: boolean, skipTank: boolean, actualConsumption?: number | null, fuelType?: { __typename?: 'FuelTypeFuel', id: string, fuelName: string } | null }> | null, eventType: { __typename?: 'EventType', id: string, name: string, description?: string | null } }> } | null };

export type EventsByEventTypeQueryVariables = Exact<{
  eventTypeIds: Array<Scalars['Int']> | Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type EventsByEventTypeQuery = { __typename?: 'Query', eventsByEventType?: { __typename?: 'EventScroll', isLast?: boolean | null, event: Array<{ __typename?: 'Event', id: any, name: string, priceTotal?: any | null, stateOdometer?: number | null, creationDate: any, deviceId: number, eventType: { __typename?: 'EventType', id: string, name: string, description?: string | null } }> } | null };

export type ExpensesByEventQueryVariables = Exact<{
  eventId: Scalars['BigInt'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type ExpensesByEventQuery = { __typename?: 'Query', expensesByEvent?: { __typename?: 'ExpenseScroll', isLast?: boolean | null, expense: Array<{ __typename?: 'Expense', id: string, price?: number | null, isRemoved?: boolean | null, tag: { __typename?: 'Tag', id: string, tagId: string, tagName: string, tagCategory: { __typename?: 'TagCategory', id: string, name: string } }, event: { __typename?: 'Event', priceTotal?: any | null } }> } | null };

export type FuelTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type FuelTypesQuery = { __typename?: 'Query', fuelTypes: Array<{ __typename?: 'FuelTypeFuel', id: string, fuelName: string }> };

export type GetUserActiveMembershipIdQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type GetUserActiveMembershipIdQuery = { __typename?: 'Query', user?: { __typename?: 'User', membership: { __typename?: 'Membership', id: string } } | null };

export type DevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type DevicesQuery = { __typename?: 'Query', devices: Array<{ __typename?: 'Device', name: string, creationDate?: any | null, spz?: string | null, id: string, actualOdometerState?: number | null, lastEditedDate?: any | null, serialNumber?: string | null, suspendedDate?: any | null, deviceCategory?: { __typename?: 'DeviceCategoryDevice', id: string, name: string } | null, thumbnailImage?: { __typename?: 'Attachment', storageName: string } | null, odometerUnit?: { __typename?: 'OdometerUnit', id: string, unitName: string } | null }> };

export type LoginUserMutationVariables = Exact<{
  user: UserLoginInput;
}>;


export type LoginUserMutation = { __typename?: 'Mutation', loginUser?: { __typename?: 'AuthUser', accessToken: string, user: { __typename?: 'User', id: string, firstName?: string | null, login: string, phone?: string | null, surname?: string | null, role?: { __typename?: 'Role', name: string, id: number } | null } } | null };

export type LogoutUserMutationVariables = Exact<{
  logoutUserId: Scalars['Int'];
}>;


export type LogoutUserMutation = { __typename?: 'Mutation', logoutUser?: { __typename?: 'User', id: string } | null };

export type MaintenanceByEventIdQueryVariables = Exact<{
  eventId: Scalars['BigInt'];
}>;


export type MaintenanceByEventIdQuery = { __typename?: 'Query', maintenanceIntervalsByEvent: Array<{ __typename?: 'MaintenanceIntervalEvent', name: string, periodDay?: number | null, periodDistance?: number | null, id: any, operation?: Array<{ __typename?: 'OperationEvent', name: string, description?: string | null, id: string, isRemoved?: boolean | null, isVisible?: boolean | null, periodDay?: number | null, periodDistance?: number | null, operationType: { __typename?: 'OperationType', id: string, name: string } }> | null }> };

export type MaintenanceIntervalsByDeviceQueryVariables = Exact<{
  deviceId: Scalars['Int'];
}>;


export type MaintenanceIntervalsByDeviceQuery = { __typename?: 'Query', maintenanceIntervalsByDevice: Array<{ __typename?: 'MaintenanceInterval', id: any, name: string, periodDay?: number | null, periodDistance?: number | null, dueDistance?: number | null, operation?: Array<{ __typename?: 'Operation', id: string, name: string, description?: string | null, periodDay?: number | null, isVisible?: boolean | null, periodDistance?: number | null, periodEndDate?: any | null, dueDistance?: number | null, operationType: { __typename?: 'OperationType', id: string, name: string } }> | null, device: { __typename?: 'Device', actualOdometerState?: number | null } }> };

export type MembershipsQueryVariables = Exact<{ [key: string]: never; }>;


export type MembershipsQuery = { __typename?: 'Query', memberships: Array<{ __typename?: 'Membership', dataFree: number, deviceCountMembership: number, id: string, name: string, price: number } | null> };

export type OdometerUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type OdometerUnitsQuery = { __typename?: 'Query', odometerUnits: Array<{ __typename?: 'OdometerUnit', id: string, unitName: string }> };

export type PaymentResultPreviewQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type PaymentResultPreviewQuery = { __typename?: 'Query', user?: { __typename?: 'User', creditBalance: number } | null };

export type PowerUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type PowerUnitsQuery = { __typename?: 'Query', powerUnits: Array<{ __typename?: 'PowerUnit', id: string, unitName: string }> };

export type RecordLayoutInfoByEventIdQueryVariables = Exact<{
  eventId: Scalars['BigInt'];
}>;


export type RecordLayoutInfoByEventIdQuery = { __typename?: 'Query', event?: { __typename?: 'Event', eventDate?: any | null, name: string, eventType: { __typename?: 'EventType', id: string, name: string } } | null };

export type RefuelingByEventIdQueryVariables = Exact<{
  eventId: Scalars['BigInt'];
}>;


export type RefuelingByEventIdQuery = { __typename?: 'Query', refuelingByEvent: Array<{ __typename?: 'Refueling', fullTank: boolean, id: any, pricePerUnit?: any | null, skipTank: boolean, unitNumber: number, actualConsumption?: number | null, fuelType?: { __typename?: 'FuelTypeFuel', fuelName: string, id: string } | null }> };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: string | null };

export type SetNewPasswordMutationVariables = Exact<{
  resetPassword: ResetPasswordInput;
}>;


export type SetNewPasswordMutation = { __typename?: 'Mutation', setNewPassword?: string | null };

export type SetNewTypeOfMembershipMutationVariables = Exact<{
  newMembershipId: Scalars['Int'];
  devicesNotToSuspend?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type SetNewTypeOfMembershipMutation = { __typename?: 'Mutation', setNewTypeOfMembership?: string | null };

export type TagCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type TagCategoriesQuery = { __typename?: 'Query', tagCategories: Array<{ __typename?: 'TagCategory', id: string, name: string }> };

export type TagsQueryVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type TagsQuery = { __typename?: 'Query', tags?: { __typename?: 'TagScroll', isLast?: boolean | null, tag: Array<{ __typename?: 'Tag', tagName: string, tagId: string, id: string, description?: string | null, tagCategory: { __typename?: 'TagCategory', id: string, name: string } }> } | null };

export type TagsOrderedByUsageQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type TagsOrderedByUsageQuery = { __typename?: 'Query', tagsOrderedByUsage: Array<{ __typename?: 'Tag', tagName: string, tagId: string, id: string, description?: string | null, tagCategory: { __typename?: 'TagCategory', name: string, id: string } }> };

export type TransmissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TransmissionsQuery = { __typename?: 'Query', transmissions: Array<{ __typename?: 'TransmissionDevice', id: string, name: string }> };

export type UpdateDeviceAvatarMutationVariables = Exact<{
  device: DeviceUpdateInput;
}>;


export type UpdateDeviceAvatarMutation = { __typename?: 'Mutation', updateDevice?: { __typename?: 'Device', thumbnailImage?: { __typename?: 'Attachment', id: any, originalName: string, storageName: string, mimeType: string, size?: number | null } | null } | null };

export type UpdateDeviceBasicInfoMutationVariables = Exact<{
  device: DeviceUpdateInput;
}>;


export type UpdateDeviceBasicInfoMutation = { __typename?: 'Mutation', updateDevice?: { __typename?: 'Device', id: string, name: string } | null };

export type UpdateDeviceOperationalDataMutationVariables = Exact<{
  device: DeviceUpdateInput;
}>;


export type UpdateDeviceOperationalDataMutation = { __typename?: 'Mutation', updateDevice?: { __typename?: 'Device', id: string, acquisitionDate?: any | null, firstRegistrationDate?: any | null, guaranteeDate?: any | null, guaranteeDistance?: number | null, guaranteeMonths?: number | null, initialOdometerState?: number | null } | null };

export type UpdateDeviceTechnicalParametersMutationVariables = Exact<{
  device: DeviceUpdateInput;
}>;


export type UpdateDeviceTechnicalParametersMutation = { __typename?: 'Mutation', updateDevice?: { __typename?: 'Device', id: string, engineSpecification?: string | null, serialNumber?: string | null, engineDisplacementCc?: number | null, power?: number | null, powerRpm?: number | null, torqueNm?: number | null, torqueRpm?: number | null, transmissionType?: string | null, transmissionNumber?: number | null, powerUnit?: { __typename?: 'PowerUnit', id: string } | null } | null };

export type UpdateDeviceUnitsMutationVariables = Exact<{
  device: DeviceUpdateInput;
}>;


export type UpdateDeviceUnitsMutation = { __typename?: 'Mutation', updateDevice?: { __typename?: 'Device', id: string, name: string } | null };

export type UpdateDeviceUsedFuelsMutationVariables = Exact<{
  device: DeviceUpdateInput;
  fuels: Array<FuelCreateInput> | FuelCreateInput;
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type UpdateDeviceUsedFuelsMutation = { __typename?: 'Mutation', updateDevice?: { __typename?: 'Device', fuel: Array<{ __typename?: 'FuelDevice', actualConsumption?: number | null, id: string, initialConsumption?: number | null, isRemoved?: boolean | null, tankVolume?: number | null, consumptionUnit?: { __typename?: 'ConsumptionUnit', id: string, unitName: string } | null, fuelType?: { __typename?: 'FuelTypeFuel', fuelName: string, id: string, isRemoved?: boolean | null } | null, volumeUnit?: { __typename?: 'VolumeUnit', id: string, unitName: string } | null }> } | null, createFuels?: Array<{ __typename?: 'FuelDevice', id: string, isRemoved?: boolean | null, tankVolume?: number | null, consumptionUnit?: { __typename?: 'ConsumptionUnit', id: string, unitName: string } | null, fuelType?: { __typename?: 'FuelTypeFuel', fuelName: string, id: string, isRemoved?: boolean | null } | null, volumeUnit?: { __typename?: 'VolumeUnit', id: string, unitName: string } | null } | null> | null, deleteFuels?: Array<{ __typename?: 'FuelDevice', id: string } | null> | null };

export type UpdateEventDescriptionMutationVariables = Exact<{
  event: EventUpdateInput;
}>;


export type UpdateEventDescriptionMutation = { __typename?: 'Mutation', updateEvent?: { __typename?: 'Event', id: any, description?: string | null } | null };

export type UpdateEventDateMutationVariables = Exact<{
  event: EventUpdateInput;
}>;


export type UpdateEventDateMutation = { __typename?: 'Mutation', updateEvent?: { __typename?: 'Event', id: any, eventDate?: any | null } | null };

export type UpdateEventOdometerStateMutationVariables = Exact<{
  event: EventUpdateInput;
}>;


export type UpdateEventOdometerStateMutation = { __typename?: 'Mutation', updateEvent?: { __typename?: 'Event', id: any, stateOdometer?: number | null } | null };

export type UpdateExpenseEventMutationVariables = Exact<{
  event: ExpenseEventUpdateInput;
}>;


export type UpdateExpenseEventMutation = { __typename?: 'Mutation', updateExpenseEvent?: { __typename?: 'ExpenseEvent', priceTotal?: any | null, expense: Array<{ __typename?: 'Expense', id: string, price?: number | null, tag: { __typename?: 'Tag', id: string } }> } | null };

export type UpdateMaintenanceEventMutationVariables = Exact<{
  event: MaintenanceEventUpdateInput;
}>;


export type UpdateMaintenanceEventMutation = { __typename?: 'Mutation', updateMaintenanceEvent?: { __typename?: 'MaintenanceEvent', id: any } | null };

export type UpdateMaintenanceIntervalMutationVariables = Exact<{
  maintenanceInterval: MaintenanceIntervalUpdateInput;
}>;


export type UpdateMaintenanceIntervalMutation = { __typename?: 'Mutation', updateMaintenanceInterval?: { __typename?: 'MaintenanceInterval', id: any, name: string, periodDay?: number | null, periodDistance?: number | null, device: { __typename?: 'Device', id: string } } | null };

export type UpdateOperationMutationVariables = Exact<{
  operation: OperationUpdateInput;
}>;


export type UpdateOperationMutation = { __typename?: 'Mutation', updateOperation?: { __typename?: 'Operation', description?: string | null, dueDistance?: number | null, id: string, name: string, periodDay?: number | null, periodDistance?: number | null, periodEndDate?: any | null, eventOperation?: Array<{ __typename?: 'EventOperationNoOperation', event: { __typename?: 'Event', id: any } }> | null, operationType: { __typename?: 'OperationType', id: string, name: string } } | null };

export type UpdateRefuelingMutationVariables = Exact<{
  refueling: RefuelingUpdateInput;
}>;


export type UpdateRefuelingMutation = { __typename?: 'Mutation', updateRefueling?: { __typename?: 'Refueling', id: any, pricePerUnit?: any | null, unitNumber: number, fullTank: boolean, skipTank: boolean, actualConsumption?: number | null } | null };

export type UpdateUserMutationVariables = Exact<{
  user: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string, firstName?: string | null, surname?: string | null, phone?: string | null } | null };

export type UpgradeDataQuoteMutationVariables = Exact<{
  countOfDataQuoteToUnlock: Scalars['Int'];
}>;


export type UpgradeDataQuoteMutation = { __typename?: 'Mutation', upgradeDataQuote?: string | null };

export type UserQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, login: string, phone?: string | null, countryCode?: string | null, firstName?: string | null, surname?: string | null, registrationDate: any, active: boolean, isDeviceCountExceeded?: boolean | null, membership: { __typename?: 'Membership', dataFree: number, deviceCountMembership: number, id: string, name: string, price: number }, role?: { __typename?: 'Role', name: string, id: number } | null } | null };

export type UserDataQuoteQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type UserDataQuoteQuery = { __typename?: 'Query', user?: { __typename?: 'User', dataQuote: number, dataVolume: number, membership: { __typename?: 'Membership', dataFree: number } } | null };

export type VehicleTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type VehicleTypesQuery = { __typename?: 'Query', vehicleTypes: Array<{ __typename?: 'VehicleTypeDevice', id: string, name: string }> };

export type VehicleTypesByDeviceCategoryQueryVariables = Exact<{
  deviceCategoryId: Scalars['Int'];
}>;


export type VehicleTypesByDeviceCategoryQuery = { __typename?: 'Query', vehicleTypesByDeviceCategory: Array<{ __typename?: 'VehicleTypeDevice', id: string, name: string }> };

export type VolumeUnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type VolumeUnitsQuery = { __typename?: 'Query', volumeUnits: Array<{ __typename?: 'VolumeUnit', id: string, unitName: string }> };

export type VolumeUnitsByFuelTypeQueryVariables = Exact<{
  fuelTypeId: Scalars['Int'];
}>;


export type VolumeUnitsByFuelTypeQuery = { __typename?: 'Query', volumeUnitsByFuelType: Array<{ __typename?: 'VolumeUnit', id: string, unitName: string }> };

export type OperationTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type OperationTypesQuery = { __typename?: 'Query', operationTypes: Array<{ __typename?: 'OperationType', id: string, name: string }> };


export const ActualOdometerStateByDeviceIdDocument = gql`
    query ActualOdometerStateByDeviceId($deviceId: Int!) {
  actualOdometerCalculated(deviceId: $deviceId)
}
    `;

/**
 * __useActualOdometerStateByDeviceIdQuery__
 *
 * To run a query within a React component, call `useActualOdometerStateByDeviceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useActualOdometerStateByDeviceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActualOdometerStateByDeviceIdQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useActualOdometerStateByDeviceIdQuery(baseOptions: Apollo.QueryHookOptions<ActualOdometerStateByDeviceIdQuery, ActualOdometerStateByDeviceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActualOdometerStateByDeviceIdQuery, ActualOdometerStateByDeviceIdQueryVariables>(ActualOdometerStateByDeviceIdDocument, options);
      }
export function useActualOdometerStateByDeviceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActualOdometerStateByDeviceIdQuery, ActualOdometerStateByDeviceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActualOdometerStateByDeviceIdQuery, ActualOdometerStateByDeviceIdQueryVariables>(ActualOdometerStateByDeviceIdDocument, options);
        }
export type ActualOdometerStateByDeviceIdQueryHookResult = ReturnType<typeof useActualOdometerStateByDeviceIdQuery>;
export type ActualOdometerStateByDeviceIdLazyQueryHookResult = ReturnType<typeof useActualOdometerStateByDeviceIdLazyQuery>;
export type ActualOdometerStateByDeviceIdQueryResult = Apollo.QueryResult<ActualOdometerStateByDeviceIdQuery, ActualOdometerStateByDeviceIdQueryVariables>;
export const AnalyzeDocument = gql`
    query Analyze($deviceId: Int!, $analyzeCategory: AnalyzeCategory!, $command: AnalyzeCommand!, $tags: [Int!]!, $fuelId: Int, $filters: Filter!) {
  analyze(
    deviceId: $deviceId
    analyzeCategory: $analyzeCategory
    command: $command
    tags: $tags
    fuelId: $fuelId
    filters: $filters
  ) {
    rowDescriptionKey
    rowDescriptionCategory
    value
  }
}
    `;

/**
 * __useAnalyzeQuery__
 *
 * To run a query within a React component, call `useAnalyzeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyzeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyzeQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      analyzeCategory: // value for 'analyzeCategory'
 *      command: // value for 'command'
 *      tags: // value for 'tags'
 *      fuelId: // value for 'fuelId'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAnalyzeQuery(baseOptions: Apollo.QueryHookOptions<AnalyzeQuery, AnalyzeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyzeQuery, AnalyzeQueryVariables>(AnalyzeDocument, options);
      }
export function useAnalyzeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyzeQuery, AnalyzeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyzeQuery, AnalyzeQueryVariables>(AnalyzeDocument, options);
        }
export type AnalyzeQueryHookResult = ReturnType<typeof useAnalyzeQuery>;
export type AnalyzeLazyQueryHookResult = ReturnType<typeof useAnalyzeLazyQuery>;
export type AnalyzeQueryResult = Apollo.QueryResult<AnalyzeQuery, AnalyzeQueryVariables>;
export const AnalyzeEventFilterCounterDocument = gql`
    query AnalyzeEventFilterCounter($deviceId: Int!, $tags: [Int!]!, $filters: Filter) {
  analyzeEventFilterCount(deviceId: $deviceId, tags: $tags, filters: $filters)
}
    `;

/**
 * __useAnalyzeEventFilterCounterQuery__
 *
 * To run a query within a React component, call `useAnalyzeEventFilterCounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnalyzeEventFilterCounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnalyzeEventFilterCounterQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      tags: // value for 'tags'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useAnalyzeEventFilterCounterQuery(baseOptions: Apollo.QueryHookOptions<AnalyzeEventFilterCounterQuery, AnalyzeEventFilterCounterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnalyzeEventFilterCounterQuery, AnalyzeEventFilterCounterQueryVariables>(AnalyzeEventFilterCounterDocument, options);
      }
export function useAnalyzeEventFilterCounterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnalyzeEventFilterCounterQuery, AnalyzeEventFilterCounterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnalyzeEventFilterCounterQuery, AnalyzeEventFilterCounterQueryVariables>(AnalyzeEventFilterCounterDocument, options);
        }
export type AnalyzeEventFilterCounterQueryHookResult = ReturnType<typeof useAnalyzeEventFilterCounterQuery>;
export type AnalyzeEventFilterCounterLazyQueryHookResult = ReturnType<typeof useAnalyzeEventFilterCounterLazyQuery>;
export type AnalyzeEventFilterCounterQueryResult = Apollo.QueryResult<AnalyzeEventFilterCounterQuery, AnalyzeEventFilterCounterQueryVariables>;
export const AttachmentByDeviceAndFiltersAndTagsDocument = gql`
    query AttachmentByDeviceAndFiltersAndTags($tags: [Int!]!, $deviceId: Int, $filters: Filter, $offset: Int!, $limit: Int!) {
  attachmentByDeviceAndFiltersAndTags(
    tags: $tags
    deviceId: $deviceId
    filters: $filters
    offset: $offset
    limit: $limit
  ) {
    attachment {
      id
      mimeType
      originalName
      size
      storageName
    }
    isLast
  }
}
    `;

/**
 * __useAttachmentByDeviceAndFiltersAndTagsQuery__
 *
 * To run a query within a React component, call `useAttachmentByDeviceAndFiltersAndTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttachmentByDeviceAndFiltersAndTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttachmentByDeviceAndFiltersAndTagsQuery({
 *   variables: {
 *      tags: // value for 'tags'
 *      deviceId: // value for 'deviceId'
 *      filters: // value for 'filters'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAttachmentByDeviceAndFiltersAndTagsQuery(baseOptions: Apollo.QueryHookOptions<AttachmentByDeviceAndFiltersAndTagsQuery, AttachmentByDeviceAndFiltersAndTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AttachmentByDeviceAndFiltersAndTagsQuery, AttachmentByDeviceAndFiltersAndTagsQueryVariables>(AttachmentByDeviceAndFiltersAndTagsDocument, options);
      }
export function useAttachmentByDeviceAndFiltersAndTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AttachmentByDeviceAndFiltersAndTagsQuery, AttachmentByDeviceAndFiltersAndTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AttachmentByDeviceAndFiltersAndTagsQuery, AttachmentByDeviceAndFiltersAndTagsQueryVariables>(AttachmentByDeviceAndFiltersAndTagsDocument, options);
        }
export type AttachmentByDeviceAndFiltersAndTagsQueryHookResult = ReturnType<typeof useAttachmentByDeviceAndFiltersAndTagsQuery>;
export type AttachmentByDeviceAndFiltersAndTagsLazyQueryHookResult = ReturnType<typeof useAttachmentByDeviceAndFiltersAndTagsLazyQuery>;
export type AttachmentByDeviceAndFiltersAndTagsQueryResult = Apollo.QueryResult<AttachmentByDeviceAndFiltersAndTagsQuery, AttachmentByDeviceAndFiltersAndTagsQueryVariables>;
export const BasicInfoAboutEventDocument = gql`
    query BasicInfoAboutEvent($eventId: BigInt!) {
  event(id: $eventId) {
    eventDate
    stateOdometer
    description
  }
}
    `;

/**
 * __useBasicInfoAboutEventQuery__
 *
 * To run a query within a React component, call `useBasicInfoAboutEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicInfoAboutEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicInfoAboutEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useBasicInfoAboutEventQuery(baseOptions: Apollo.QueryHookOptions<BasicInfoAboutEventQuery, BasicInfoAboutEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BasicInfoAboutEventQuery, BasicInfoAboutEventQueryVariables>(BasicInfoAboutEventDocument, options);
      }
export function useBasicInfoAboutEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BasicInfoAboutEventQuery, BasicInfoAboutEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BasicInfoAboutEventQuery, BasicInfoAboutEventQueryVariables>(BasicInfoAboutEventDocument, options);
        }
export type BasicInfoAboutEventQueryHookResult = ReturnType<typeof useBasicInfoAboutEventQuery>;
export type BasicInfoAboutEventLazyQueryHookResult = ReturnType<typeof useBasicInfoAboutEventLazyQuery>;
export type BasicInfoAboutEventQueryResult = Apollo.QueryResult<BasicInfoAboutEventQuery, BasicInfoAboutEventQueryVariables>;
export const ConfirmRegistrationDocument = gql`
    mutation ConfirmRegistration($confirmationToken: String!) {
  confirmRegistration(confirmationToken: $confirmationToken)
}
    `;
export type ConfirmRegistrationMutationFn = Apollo.MutationFunction<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>;

/**
 * __useConfirmRegistrationMutation__
 *
 * To run a mutation, you first call `useConfirmRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmRegistrationMutation, { data, loading, error }] = useConfirmRegistrationMutation({
 *   variables: {
 *      confirmationToken: // value for 'confirmationToken'
 *   },
 * });
 */
export function useConfirmRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>(ConfirmRegistrationDocument, options);
      }
export type ConfirmRegistrationMutationHookResult = ReturnType<typeof useConfirmRegistrationMutation>;
export type ConfirmRegistrationMutationResult = Apollo.MutationResult<ConfirmRegistrationMutation>;
export type ConfirmRegistrationMutationOptions = Apollo.BaseMutationOptions<ConfirmRegistrationMutation, ConfirmRegistrationMutationVariables>;
export const ConsumptionUnitsDocument = gql`
    query ConsumptionUnits {
  consumptionUnits {
    id
    unitName
  }
}
    `;

/**
 * __useConsumptionUnitsQuery__
 *
 * To run a query within a React component, call `useConsumptionUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumptionUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumptionUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsumptionUnitsQuery(baseOptions?: Apollo.QueryHookOptions<ConsumptionUnitsQuery, ConsumptionUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsumptionUnitsQuery, ConsumptionUnitsQueryVariables>(ConsumptionUnitsDocument, options);
      }
export function useConsumptionUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumptionUnitsQuery, ConsumptionUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsumptionUnitsQuery, ConsumptionUnitsQueryVariables>(ConsumptionUnitsDocument, options);
        }
export type ConsumptionUnitsQueryHookResult = ReturnType<typeof useConsumptionUnitsQuery>;
export type ConsumptionUnitsLazyQueryHookResult = ReturnType<typeof useConsumptionUnitsLazyQuery>;
export type ConsumptionUnitsQueryResult = Apollo.QueryResult<ConsumptionUnitsQuery, ConsumptionUnitsQueryVariables>;
export const ConsumptionUnitsByFuelTypeDocument = gql`
    query ConsumptionUnitsByFuelType($fuelTypeId: Int!) {
  consumptionUnitsByFuelType(fuelTypeId: $fuelTypeId) {
    id
    unitName
  }
}
    `;

/**
 * __useConsumptionUnitsByFuelTypeQuery__
 *
 * To run a query within a React component, call `useConsumptionUnitsByFuelTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsumptionUnitsByFuelTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsumptionUnitsByFuelTypeQuery({
 *   variables: {
 *      fuelTypeId: // value for 'fuelTypeId'
 *   },
 * });
 */
export function useConsumptionUnitsByFuelTypeQuery(baseOptions: Apollo.QueryHookOptions<ConsumptionUnitsByFuelTypeQuery, ConsumptionUnitsByFuelTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsumptionUnitsByFuelTypeQuery, ConsumptionUnitsByFuelTypeQueryVariables>(ConsumptionUnitsByFuelTypeDocument, options);
      }
export function useConsumptionUnitsByFuelTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsumptionUnitsByFuelTypeQuery, ConsumptionUnitsByFuelTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsumptionUnitsByFuelTypeQuery, ConsumptionUnitsByFuelTypeQueryVariables>(ConsumptionUnitsByFuelTypeDocument, options);
        }
export type ConsumptionUnitsByFuelTypeQueryHookResult = ReturnType<typeof useConsumptionUnitsByFuelTypeQuery>;
export type ConsumptionUnitsByFuelTypeLazyQueryHookResult = ReturnType<typeof useConsumptionUnitsByFuelTypeLazyQuery>;
export type ConsumptionUnitsByFuelTypeQueryResult = Apollo.QueryResult<ConsumptionUnitsByFuelTypeQuery, ConsumptionUnitsByFuelTypeQueryVariables>;
export const CreateAttachmentDocument = gql`
    mutation CreateAttachment($attachment: AttachmentCreateInput!) {
  createAttachment(attachment: $attachment) {
    id
    originalName
    storageName
    mimeType
    size
  }
}
    `;
export type CreateAttachmentMutationFn = Apollo.MutationFunction<CreateAttachmentMutation, CreateAttachmentMutationVariables>;

/**
 * __useCreateAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAttachmentMutation, { data, loading, error }] = useCreateAttachmentMutation({
 *   variables: {
 *      attachment: // value for 'attachment'
 *   },
 * });
 */
export function useCreateAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAttachmentMutation, CreateAttachmentMutationVariables>(CreateAttachmentDocument, options);
      }
export type CreateAttachmentMutationHookResult = ReturnType<typeof useCreateAttachmentMutation>;
export type CreateAttachmentMutationResult = Apollo.MutationResult<CreateAttachmentMutation>;
export type CreateAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateAttachmentMutation, CreateAttachmentMutationVariables>;
export const CreateCashPaymentByAmountDocument = gql`
    mutation CreateCashPaymentByAmount($cashPaymentData: CashPaymentCreateInput!) {
  createCashPayment(cashPaymentData: $cashPaymentData) {
    url
  }
}
    `;
export type CreateCashPaymentByAmountMutationFn = Apollo.MutationFunction<CreateCashPaymentByAmountMutation, CreateCashPaymentByAmountMutationVariables>;

/**
 * __useCreateCashPaymentByAmountMutation__
 *
 * To run a mutation, you first call `useCreateCashPaymentByAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCashPaymentByAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCashPaymentByAmountMutation, { data, loading, error }] = useCreateCashPaymentByAmountMutation({
 *   variables: {
 *      cashPaymentData: // value for 'cashPaymentData'
 *   },
 * });
 */
export function useCreateCashPaymentByAmountMutation(baseOptions?: Apollo.MutationHookOptions<CreateCashPaymentByAmountMutation, CreateCashPaymentByAmountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCashPaymentByAmountMutation, CreateCashPaymentByAmountMutationVariables>(CreateCashPaymentByAmountDocument, options);
      }
export type CreateCashPaymentByAmountMutationHookResult = ReturnType<typeof useCreateCashPaymentByAmountMutation>;
export type CreateCashPaymentByAmountMutationResult = Apollo.MutationResult<CreateCashPaymentByAmountMutation>;
export type CreateCashPaymentByAmountMutationOptions = Apollo.BaseMutationOptions<CreateCashPaymentByAmountMutation, CreateCashPaymentByAmountMutationVariables>;
export const CreateDeviceDocument = gql`
    mutation CreateDevice($device: DeviceCreateInput!) {
  createDevice(device: $device) {
    id
    name
    manufacturer
    model
    spz
    color
    firstRegistrationDate
  }
}
    `;
export type CreateDeviceMutationFn = Apollo.MutationFunction<CreateDeviceMutation, CreateDeviceMutationVariables>;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useCreateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeviceMutation, CreateDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument, options);
      }
export type CreateDeviceMutationHookResult = ReturnType<typeof useCreateDeviceMutation>;
export type CreateDeviceMutationResult = Apollo.MutationResult<CreateDeviceMutation>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<CreateDeviceMutation, CreateDeviceMutationVariables>;
export const CreateDeviceAttachmentDocument = gql`
    mutation CreateDeviceAttachment($deviceId: Int!, $attachmentId: BigInt!) {
  createDeviceAttachment(deviceId: $deviceId, attachmentId: $attachmentId) {
    attachment {
      id
    }
  }
}
    `;
export type CreateDeviceAttachmentMutationFn = Apollo.MutationFunction<CreateDeviceAttachmentMutation, CreateDeviceAttachmentMutationVariables>;

/**
 * __useCreateDeviceAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateDeviceAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceAttachmentMutation, { data, loading, error }] = useCreateDeviceAttachmentMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useCreateDeviceAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeviceAttachmentMutation, CreateDeviceAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeviceAttachmentMutation, CreateDeviceAttachmentMutationVariables>(CreateDeviceAttachmentDocument, options);
      }
export type CreateDeviceAttachmentMutationHookResult = ReturnType<typeof useCreateDeviceAttachmentMutation>;
export type CreateDeviceAttachmentMutationResult = Apollo.MutationResult<CreateDeviceAttachmentMutation>;
export type CreateDeviceAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateDeviceAttachmentMutation, CreateDeviceAttachmentMutationVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($event: EventCreateInput!, $attachmentIds: BigInt) {
  createEvent(event: $event, attachmentIds: $attachmentIds) {
    id
    name
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      attachmentIds: // value for 'attachmentIds'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CreateEventAttachmentDocument = gql`
    mutation CreateEventAttachment($eventId: BigInt!, $attachmentId: BigInt!) {
  createEventAttachment(eventId: $eventId, attachmentId: $attachmentId) {
    attachment {
      id
    }
  }
}
    `;
export type CreateEventAttachmentMutationFn = Apollo.MutationFunction<CreateEventAttachmentMutation, CreateEventAttachmentMutationVariables>;

/**
 * __useCreateEventAttachmentMutation__
 *
 * To run a mutation, you first call `useCreateEventAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventAttachmentMutation, { data, loading, error }] = useCreateEventAttachmentMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useCreateEventAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventAttachmentMutation, CreateEventAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventAttachmentMutation, CreateEventAttachmentMutationVariables>(CreateEventAttachmentDocument, options);
      }
export type CreateEventAttachmentMutationHookResult = ReturnType<typeof useCreateEventAttachmentMutation>;
export type CreateEventAttachmentMutationResult = Apollo.MutationResult<CreateEventAttachmentMutation>;
export type CreateEventAttachmentMutationOptions = Apollo.BaseMutationOptions<CreateEventAttachmentMutation, CreateEventAttachmentMutationVariables>;
export const CreateExpenseEventDocument = gql`
    mutation CreateExpenseEvent($event: EventCreateInput!, $expenses: [ExpenseCreateInput!]!, $attachmentIds: BigInt) {
  createExpenseEvent(
    event: $event
    expenses: $expenses
    attachmentIds: $attachmentIds
  ) {
    id
    priceTotal
    stateOdometer
  }
}
    `;
export type CreateExpenseEventMutationFn = Apollo.MutationFunction<CreateExpenseEventMutation, CreateExpenseEventMutationVariables>;

/**
 * __useCreateExpenseEventMutation__
 *
 * To run a mutation, you first call `useCreateExpenseEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseEventMutation, { data, loading, error }] = useCreateExpenseEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      expenses: // value for 'expenses'
 *      attachmentIds: // value for 'attachmentIds'
 *   },
 * });
 */
export function useCreateExpenseEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpenseEventMutation, CreateExpenseEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExpenseEventMutation, CreateExpenseEventMutationVariables>(CreateExpenseEventDocument, options);
      }
export type CreateExpenseEventMutationHookResult = ReturnType<typeof useCreateExpenseEventMutation>;
export type CreateExpenseEventMutationResult = Apollo.MutationResult<CreateExpenseEventMutation>;
export type CreateExpenseEventMutationOptions = Apollo.BaseMutationOptions<CreateExpenseEventMutation, CreateExpenseEventMutationVariables>;
export const CreateMaintenanceEventDocument = gql`
    mutation CreateMaintenanceEvent($event: EventCreateInput!, $expenses: [ExpenseCreateInput!]!, $attachmentIds: BigInt) {
  createMaintenanceEvent(
    event: $event
    expenses: $expenses
    attachmentIds: $attachmentIds
  ) {
    id
    name
  }
}
    `;
export type CreateMaintenanceEventMutationFn = Apollo.MutationFunction<CreateMaintenanceEventMutation, CreateMaintenanceEventMutationVariables>;

/**
 * __useCreateMaintenanceEventMutation__
 *
 * To run a mutation, you first call `useCreateMaintenanceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaintenanceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaintenanceEventMutation, { data, loading, error }] = useCreateMaintenanceEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *      expenses: // value for 'expenses'
 *      attachmentIds: // value for 'attachmentIds'
 *   },
 * });
 */
export function useCreateMaintenanceEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateMaintenanceEventMutation, CreateMaintenanceEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMaintenanceEventMutation, CreateMaintenanceEventMutationVariables>(CreateMaintenanceEventDocument, options);
      }
export type CreateMaintenanceEventMutationHookResult = ReturnType<typeof useCreateMaintenanceEventMutation>;
export type CreateMaintenanceEventMutationResult = Apollo.MutationResult<CreateMaintenanceEventMutation>;
export type CreateMaintenanceEventMutationOptions = Apollo.BaseMutationOptions<CreateMaintenanceEventMutation, CreateMaintenanceEventMutationVariables>;
export const CreateMaintenanceIntervalDocument = gql`
    mutation CreateMaintenanceInterval($maintenanceInterval: MaintenanceIntervalCreateInput!) {
  createMaintenanceInterval(maintenanceInterval: $maintenanceInterval) {
    device {
      id
    }
    name
    periodDay
    periodDistance
  }
}
    `;
export type CreateMaintenanceIntervalMutationFn = Apollo.MutationFunction<CreateMaintenanceIntervalMutation, CreateMaintenanceIntervalMutationVariables>;

/**
 * __useCreateMaintenanceIntervalMutation__
 *
 * To run a mutation, you first call `useCreateMaintenanceIntervalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaintenanceIntervalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaintenanceIntervalMutation, { data, loading, error }] = useCreateMaintenanceIntervalMutation({
 *   variables: {
 *      maintenanceInterval: // value for 'maintenanceInterval'
 *   },
 * });
 */
export function useCreateMaintenanceIntervalMutation(baseOptions?: Apollo.MutationHookOptions<CreateMaintenanceIntervalMutation, CreateMaintenanceIntervalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMaintenanceIntervalMutation, CreateMaintenanceIntervalMutationVariables>(CreateMaintenanceIntervalDocument, options);
      }
export type CreateMaintenanceIntervalMutationHookResult = ReturnType<typeof useCreateMaintenanceIntervalMutation>;
export type CreateMaintenanceIntervalMutationResult = Apollo.MutationResult<CreateMaintenanceIntervalMutation>;
export type CreateMaintenanceIntervalMutationOptions = Apollo.BaseMutationOptions<CreateMaintenanceIntervalMutation, CreateMaintenanceIntervalMutationVariables>;
export const CreateOperationDocument = gql`
    mutation CreateOperation($operation: OperationCreateInput!) {
  createOperation(operation: $operation) {
    description
    eventOperation {
      event {
        id
      }
    }
    name
    operationType {
      id
    }
    periodDay
    periodDistance
  }
}
    `;
export type CreateOperationMutationFn = Apollo.MutationFunction<CreateOperationMutation, CreateOperationMutationVariables>;

/**
 * __useCreateOperationMutation__
 *
 * To run a mutation, you first call `useCreateOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOperationMutation, { data, loading, error }] = useCreateOperationMutation({
 *   variables: {
 *      operation: // value for 'operation'
 *   },
 * });
 */
export function useCreateOperationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOperationMutation, CreateOperationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOperationMutation, CreateOperationMutationVariables>(CreateOperationDocument, options);
      }
export type CreateOperationMutationHookResult = ReturnType<typeof useCreateOperationMutation>;
export type CreateOperationMutationResult = Apollo.MutationResult<CreateOperationMutation>;
export type CreateOperationMutationOptions = Apollo.BaseMutationOptions<CreateOperationMutation, CreateOperationMutationVariables>;
export const CreateRefuelingDocument = gql`
    mutation CreateRefueling($event: EventRefuelingCreateInput!, $refueling: RefuelingCreateInput!, $attachmentIds: BigInt) {
  createRefueling(
    event: $event
    refueling: $refueling
    attachmentIds: $attachmentIds
  ) {
    id
    pricePerUnit
    fuelType {
      id
      fuelName
    }
    fullTank
    skipTank
  }
}
    `;
export type CreateRefuelingMutationFn = Apollo.MutationFunction<CreateRefuelingMutation, CreateRefuelingMutationVariables>;

/**
 * __useCreateRefuelingMutation__
 *
 * To run a mutation, you first call `useCreateRefuelingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRefuelingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRefuelingMutation, { data, loading, error }] = useCreateRefuelingMutation({
 *   variables: {
 *      event: // value for 'event'
 *      refueling: // value for 'refueling'
 *      attachmentIds: // value for 'attachmentIds'
 *   },
 * });
 */
export function useCreateRefuelingMutation(baseOptions?: Apollo.MutationHookOptions<CreateRefuelingMutation, CreateRefuelingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRefuelingMutation, CreateRefuelingMutationVariables>(CreateRefuelingDocument, options);
      }
export type CreateRefuelingMutationHookResult = ReturnType<typeof useCreateRefuelingMutation>;
export type CreateRefuelingMutationResult = Apollo.MutationResult<CreateRefuelingMutation>;
export type CreateRefuelingMutationOptions = Apollo.BaseMutationOptions<CreateRefuelingMutation, CreateRefuelingMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($user: UserCreateInput!) {
  createUser(user: $user) {
    id
    login
    phone
    firstName
    surname
    registrationDate
    active
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreditPreviewDocument = gql`
    query CreditPreview($userId: Int!) {
  user(id: $userId) {
    creditBalance
  }
  creditTransactionsByUserId(userId: $userId) {
    date
    value
    description
    type {
      id
      name
    }
  }
}
    `;

/**
 * __useCreditPreviewQuery__
 *
 * To run a query within a React component, call `useCreditPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditPreviewQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreditPreviewQuery(baseOptions: Apollo.QueryHookOptions<CreditPreviewQuery, CreditPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreditPreviewQuery, CreditPreviewQueryVariables>(CreditPreviewDocument, options);
      }
export function useCreditPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreditPreviewQuery, CreditPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreditPreviewQuery, CreditPreviewQueryVariables>(CreditPreviewDocument, options);
        }
export type CreditPreviewQueryHookResult = ReturnType<typeof useCreditPreviewQuery>;
export type CreditPreviewLazyQueryHookResult = ReturnType<typeof useCreditPreviewLazyQuery>;
export type CreditPreviewQueryResult = Apollo.QueryResult<CreditPreviewQuery, CreditPreviewQueryVariables>;
export const DeleteAttachmentDocument = gql`
    mutation DeleteAttachment($deleteAttachmentId: BigInt!) {
  deleteAttachment(id: $deleteAttachmentId) {
    id
    storageName
  }
}
    `;
export type DeleteAttachmentMutationFn = Apollo.MutationFunction<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;

/**
 * __useDeleteAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAttachmentMutation, { data, loading, error }] = useDeleteAttachmentMutation({
 *   variables: {
 *      deleteAttachmentId: // value for 'deleteAttachmentId'
 *   },
 * });
 */
export function useDeleteAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>(DeleteAttachmentDocument, options);
      }
export type DeleteAttachmentMutationHookResult = ReturnType<typeof useDeleteAttachmentMutation>;
export type DeleteAttachmentMutationResult = Apollo.MutationResult<DeleteAttachmentMutation>;
export type DeleteAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteAttachmentMutation, DeleteAttachmentMutationVariables>;
export const DeleteDeviceDocument = gql`
    mutation DeleteDevice($deleteDeviceId: Int!) {
  deleteDevice(id: $deleteDeviceId) {
    name
  }
}
    `;
export type DeleteDeviceMutationFn = Apollo.MutationFunction<DeleteDeviceMutation, DeleteDeviceMutationVariables>;

/**
 * __useDeleteDeviceMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceMutation, { data, loading, error }] = useDeleteDeviceMutation({
 *   variables: {
 *      deleteDeviceId: // value for 'deleteDeviceId'
 *   },
 * });
 */
export function useDeleteDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeviceMutation, DeleteDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeviceMutation, DeleteDeviceMutationVariables>(DeleteDeviceDocument, options);
      }
export type DeleteDeviceMutationHookResult = ReturnType<typeof useDeleteDeviceMutation>;
export type DeleteDeviceMutationResult = Apollo.MutationResult<DeleteDeviceMutation>;
export type DeleteDeviceMutationOptions = Apollo.BaseMutationOptions<DeleteDeviceMutation, DeleteDeviceMutationVariables>;
export const DeleteDeviceAttachmentDocument = gql`
    mutation DeleteDeviceAttachment($deviceId: Int!, $attachmentId: BigInt!) {
  deleteDeviceAttachment(deviceId: $deviceId, attachmentId: $attachmentId) {
    attachment {
      id
      storageName
    }
  }
}
    `;
export type DeleteDeviceAttachmentMutationFn = Apollo.MutationFunction<DeleteDeviceAttachmentMutation, DeleteDeviceAttachmentMutationVariables>;

/**
 * __useDeleteDeviceAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceAttachmentMutation, { data, loading, error }] = useDeleteDeviceAttachmentMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDeleteDeviceAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeviceAttachmentMutation, DeleteDeviceAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeviceAttachmentMutation, DeleteDeviceAttachmentMutationVariables>(DeleteDeviceAttachmentDocument, options);
      }
export type DeleteDeviceAttachmentMutationHookResult = ReturnType<typeof useDeleteDeviceAttachmentMutation>;
export type DeleteDeviceAttachmentMutationResult = Apollo.MutationResult<DeleteDeviceAttachmentMutation>;
export type DeleteDeviceAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteDeviceAttachmentMutation, DeleteDeviceAttachmentMutationVariables>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($deleteEventId: BigInt!) {
  deleteEvent(id: $deleteEventId) {
    eventType {
      name
    }
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      deleteEventId: // value for 'deleteEventId'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const DeleteEventAttachmentDocument = gql`
    mutation DeleteEventAttachment($eventId: BigInt!, $attachmentId: BigInt!) {
  deleteEventAttachment(eventId: $eventId, attachmentId: $attachmentId) {
    attachment {
      id
    }
  }
}
    `;
export type DeleteEventAttachmentMutationFn = Apollo.MutationFunction<DeleteEventAttachmentMutation, DeleteEventAttachmentMutationVariables>;

/**
 * __useDeleteEventAttachmentMutation__
 *
 * To run a mutation, you first call `useDeleteEventAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventAttachmentMutation, { data, loading, error }] = useDeleteEventAttachmentMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useDeleteEventAttachmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventAttachmentMutation, DeleteEventAttachmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventAttachmentMutation, DeleteEventAttachmentMutationVariables>(DeleteEventAttachmentDocument, options);
      }
export type DeleteEventAttachmentMutationHookResult = ReturnType<typeof useDeleteEventAttachmentMutation>;
export type DeleteEventAttachmentMutationResult = Apollo.MutationResult<DeleteEventAttachmentMutation>;
export type DeleteEventAttachmentMutationOptions = Apollo.BaseMutationOptions<DeleteEventAttachmentMutation, DeleteEventAttachmentMutationVariables>;
export const DeleteMaintenanceIntervalDocument = gql`
    mutation DeleteMaintenanceInterval($deleteMaintenanceIntervalId: BigInt!) {
  deleteMaintenanceInterval(id: $deleteMaintenanceIntervalId) {
    id
  }
}
    `;
export type DeleteMaintenanceIntervalMutationFn = Apollo.MutationFunction<DeleteMaintenanceIntervalMutation, DeleteMaintenanceIntervalMutationVariables>;

/**
 * __useDeleteMaintenanceIntervalMutation__
 *
 * To run a mutation, you first call `useDeleteMaintenanceIntervalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMaintenanceIntervalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMaintenanceIntervalMutation, { data, loading, error }] = useDeleteMaintenanceIntervalMutation({
 *   variables: {
 *      deleteMaintenanceIntervalId: // value for 'deleteMaintenanceIntervalId'
 *   },
 * });
 */
export function useDeleteMaintenanceIntervalMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMaintenanceIntervalMutation, DeleteMaintenanceIntervalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMaintenanceIntervalMutation, DeleteMaintenanceIntervalMutationVariables>(DeleteMaintenanceIntervalDocument, options);
      }
export type DeleteMaintenanceIntervalMutationHookResult = ReturnType<typeof useDeleteMaintenanceIntervalMutation>;
export type DeleteMaintenanceIntervalMutationResult = Apollo.MutationResult<DeleteMaintenanceIntervalMutation>;
export type DeleteMaintenanceIntervalMutationOptions = Apollo.BaseMutationOptions<DeleteMaintenanceIntervalMutation, DeleteMaintenanceIntervalMutationVariables>;
export const DeleteOperationDocument = gql`
    mutation DeleteOperation($deleteOperationId: Int!) {
  deleteOperation(id: $deleteOperationId) {
    id
  }
}
    `;
export type DeleteOperationMutationFn = Apollo.MutationFunction<DeleteOperationMutation, DeleteOperationMutationVariables>;

/**
 * __useDeleteOperationMutation__
 *
 * To run a mutation, you first call `useDeleteOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOperationMutation, { data, loading, error }] = useDeleteOperationMutation({
 *   variables: {
 *      deleteOperationId: // value for 'deleteOperationId'
 *   },
 * });
 */
export function useDeleteOperationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOperationMutation, DeleteOperationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOperationMutation, DeleteOperationMutationVariables>(DeleteOperationDocument, options);
      }
export type DeleteOperationMutationHookResult = ReturnType<typeof useDeleteOperationMutation>;
export type DeleteOperationMutationResult = Apollo.MutationResult<DeleteOperationMutation>;
export type DeleteOperationMutationOptions = Apollo.BaseMutationOptions<DeleteOperationMutation, DeleteOperationMutationVariables>;
export const DestroySignatureDocument = gql`
    query DestroySignature($publicId: String!) {
  destroySignature(publicId: $publicId) {
    signature
    timestamp
  }
}
    `;

/**
 * __useDestroySignatureQuery__
 *
 * To run a query within a React component, call `useDestroySignatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestroySignatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestroySignatureQuery({
 *   variables: {
 *      publicId: // value for 'publicId'
 *   },
 * });
 */
export function useDestroySignatureQuery(baseOptions: Apollo.QueryHookOptions<DestroySignatureQuery, DestroySignatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DestroySignatureQuery, DestroySignatureQueryVariables>(DestroySignatureDocument, options);
      }
export function useDestroySignatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DestroySignatureQuery, DestroySignatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DestroySignatureQuery, DestroySignatureQueryVariables>(DestroySignatureDocument, options);
        }
export type DestroySignatureQueryHookResult = ReturnType<typeof useDestroySignatureQuery>;
export type DestroySignatureLazyQueryHookResult = ReturnType<typeof useDestroySignatureLazyQuery>;
export type DestroySignatureQueryResult = Apollo.QueryResult<DestroySignatureQuery, DestroySignatureQueryVariables>;
export const DeviceAttachmentsByDeviceDocument = gql`
    query DeviceAttachmentsByDevice($deviceId: Int!) {
  deviceAttachmentsByDevice(deviceId: $deviceId) {
    attachment {
      id
      mimeType
      originalName
      size
      storageName
    }
    device {
      id
      name
    }
  }
}
    `;

/**
 * __useDeviceAttachmentsByDeviceQuery__
 *
 * To run a query within a React component, call `useDeviceAttachmentsByDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceAttachmentsByDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceAttachmentsByDeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceAttachmentsByDeviceQuery(baseOptions: Apollo.QueryHookOptions<DeviceAttachmentsByDeviceQuery, DeviceAttachmentsByDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceAttachmentsByDeviceQuery, DeviceAttachmentsByDeviceQueryVariables>(DeviceAttachmentsByDeviceDocument, options);
      }
export function useDeviceAttachmentsByDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceAttachmentsByDeviceQuery, DeviceAttachmentsByDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceAttachmentsByDeviceQuery, DeviceAttachmentsByDeviceQueryVariables>(DeviceAttachmentsByDeviceDocument, options);
        }
export type DeviceAttachmentsByDeviceQueryHookResult = ReturnType<typeof useDeviceAttachmentsByDeviceQuery>;
export type DeviceAttachmentsByDeviceLazyQueryHookResult = ReturnType<typeof useDeviceAttachmentsByDeviceLazyQuery>;
export type DeviceAttachmentsByDeviceQueryResult = Apollo.QueryResult<DeviceAttachmentsByDeviceQuery, DeviceAttachmentsByDeviceQueryVariables>;
export const DeviceBasicInformationDocument = gql`
    query DeviceBasicInformation($deviceId: Int!) {
  device(id: $deviceId) {
    deviceCategory {
      id
      name
    }
    name
    manufacturer
    model
    vehicleType {
      id
      name
    }
    color
    manufacturedYearMonth
    vin
    spz
  }
}
    `;

/**
 * __useDeviceBasicInformationQuery__
 *
 * To run a query within a React component, call `useDeviceBasicInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceBasicInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceBasicInformationQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceBasicInformationQuery(baseOptions: Apollo.QueryHookOptions<DeviceBasicInformationQuery, DeviceBasicInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceBasicInformationQuery, DeviceBasicInformationQueryVariables>(DeviceBasicInformationDocument, options);
      }
export function useDeviceBasicInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceBasicInformationQuery, DeviceBasicInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceBasicInformationQuery, DeviceBasicInformationQueryVariables>(DeviceBasicInformationDocument, options);
        }
export type DeviceBasicInformationQueryHookResult = ReturnType<typeof useDeviceBasicInformationQuery>;
export type DeviceBasicInformationLazyQueryHookResult = ReturnType<typeof useDeviceBasicInformationLazyQuery>;
export type DeviceBasicInformationQueryResult = Apollo.QueryResult<DeviceBasicInformationQuery, DeviceBasicInformationQueryVariables>;
export const DeviceCategoriesDocument = gql`
    query DeviceCategories {
  deviceCategories {
    id
    name
  }
}
    `;

/**
 * __useDeviceCategoriesQuery__
 *
 * To run a query within a React component, call `useDeviceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeviceCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<DeviceCategoriesQuery, DeviceCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceCategoriesQuery, DeviceCategoriesQueryVariables>(DeviceCategoriesDocument, options);
      }
export function useDeviceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceCategoriesQuery, DeviceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceCategoriesQuery, DeviceCategoriesQueryVariables>(DeviceCategoriesDocument, options);
        }
export type DeviceCategoriesQueryHookResult = ReturnType<typeof useDeviceCategoriesQuery>;
export type DeviceCategoriesLazyQueryHookResult = ReturnType<typeof useDeviceCategoriesLazyQuery>;
export type DeviceCategoriesQueryResult = Apollo.QueryResult<DeviceCategoriesQuery, DeviceCategoriesQueryVariables>;
export const DeviceHeaderDetailDocument = gql`
    query DeviceHeaderDetail($deviceId: Int!) {
  device(id: $deviceId) {
    name
    spz
    id
    serialNumber
    thumbnailImage {
      storageName
    }
    deviceCategory {
      id
      name
    }
  }
}
    `;

/**
 * __useDeviceHeaderDetailQuery__
 *
 * To run a query within a React component, call `useDeviceHeaderDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceHeaderDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceHeaderDetailQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceHeaderDetailQuery(baseOptions: Apollo.QueryHookOptions<DeviceHeaderDetailQuery, DeviceHeaderDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceHeaderDetailQuery, DeviceHeaderDetailQueryVariables>(DeviceHeaderDetailDocument, options);
      }
export function useDeviceHeaderDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceHeaderDetailQuery, DeviceHeaderDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceHeaderDetailQuery, DeviceHeaderDetailQueryVariables>(DeviceHeaderDetailDocument, options);
        }
export type DeviceHeaderDetailQueryHookResult = ReturnType<typeof useDeviceHeaderDetailQuery>;
export type DeviceHeaderDetailLazyQueryHookResult = ReturnType<typeof useDeviceHeaderDetailLazyQuery>;
export type DeviceHeaderDetailQueryResult = Apollo.QueryResult<DeviceHeaderDetailQuery, DeviceHeaderDetailQueryVariables>;
export const DeviceOperationalDataDocument = gql`
    query DeviceOperationalData($deviceId: Int!) {
  device(id: $deviceId) {
    acquisitionDate
    actualOdometerState
    firstRegistrationDate
    guaranteeDate
    guaranteeDistance
    guaranteeMonths
    initialOdometerState
    odometerUnit {
      unitName
      id
    }
  }
}
    `;

/**
 * __useDeviceOperationalDataQuery__
 *
 * To run a query within a React component, call `useDeviceOperationalDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceOperationalDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceOperationalDataQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceOperationalDataQuery(baseOptions: Apollo.QueryHookOptions<DeviceOperationalDataQuery, DeviceOperationalDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceOperationalDataQuery, DeviceOperationalDataQueryVariables>(DeviceOperationalDataDocument, options);
      }
export function useDeviceOperationalDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceOperationalDataQuery, DeviceOperationalDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceOperationalDataQuery, DeviceOperationalDataQueryVariables>(DeviceOperationalDataDocument, options);
        }
export type DeviceOperationalDataQueryHookResult = ReturnType<typeof useDeviceOperationalDataQuery>;
export type DeviceOperationalDataLazyQueryHookResult = ReturnType<typeof useDeviceOperationalDataLazyQuery>;
export type DeviceOperationalDataQueryResult = Apollo.QueryResult<DeviceOperationalDataQuery, DeviceOperationalDataQueryVariables>;
export const DeviceTechnicalParametersDocument = gql`
    query DeviceTechnicalParameters($deviceId: Int!) {
  device(id: $deviceId) {
    engineSpecification
    serialNumber
    engineDisplacementCc
    power
    powerRpm
    powerUnit {
      id
      unitName
    }
    torqueNm
    torqueRpm
    transmission {
      id
      name
    }
    transmissionNumber
    fuel {
      tankVolume
      fuelType {
        id
        fuelName
      }
      consumptionUnit {
        id
        unitName
      }
      actualConsumption
      initialConsumption
      volumeUnit {
        id
        unitName
      }
    }
  }
}
    `;

/**
 * __useDeviceTechnicalParametersQuery__
 *
 * To run a query within a React component, call `useDeviceTechnicalParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceTechnicalParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceTechnicalParametersQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceTechnicalParametersQuery(baseOptions: Apollo.QueryHookOptions<DeviceTechnicalParametersQuery, DeviceTechnicalParametersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceTechnicalParametersQuery, DeviceTechnicalParametersQueryVariables>(DeviceTechnicalParametersDocument, options);
      }
export function useDeviceTechnicalParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceTechnicalParametersQuery, DeviceTechnicalParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceTechnicalParametersQuery, DeviceTechnicalParametersQueryVariables>(DeviceTechnicalParametersDocument, options);
        }
export type DeviceTechnicalParametersQueryHookResult = ReturnType<typeof useDeviceTechnicalParametersQuery>;
export type DeviceTechnicalParametersLazyQueryHookResult = ReturnType<typeof useDeviceTechnicalParametersLazyQuery>;
export type DeviceTechnicalParametersQueryResult = Apollo.QueryResult<DeviceTechnicalParametersQuery, DeviceTechnicalParametersQueryVariables>;
export const DeviceThumbnailImageDocument = gql`
    query DeviceThumbnailImage($deviceId: Int!) {
  device(id: $deviceId) {
    thumbnailImage {
      id
      mimeType
      size
      originalName
      storageName
    }
  }
}
    `;

/**
 * __useDeviceThumbnailImageQuery__
 *
 * To run a query within a React component, call `useDeviceThumbnailImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceThumbnailImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceThumbnailImageQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceThumbnailImageQuery(baseOptions: Apollo.QueryHookOptions<DeviceThumbnailImageQuery, DeviceThumbnailImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceThumbnailImageQuery, DeviceThumbnailImageQueryVariables>(DeviceThumbnailImageDocument, options);
      }
export function useDeviceThumbnailImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceThumbnailImageQuery, DeviceThumbnailImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceThumbnailImageQuery, DeviceThumbnailImageQueryVariables>(DeviceThumbnailImageDocument, options);
        }
export type DeviceThumbnailImageQueryHookResult = ReturnType<typeof useDeviceThumbnailImageQuery>;
export type DeviceThumbnailImageLazyQueryHookResult = ReturnType<typeof useDeviceThumbnailImageLazyQuery>;
export type DeviceThumbnailImageQueryResult = Apollo.QueryResult<DeviceThumbnailImageQuery, DeviceThumbnailImageQueryVariables>;
export const DeviceUnitsByDeviceIdDocument = gql`
    query DeviceUnitsByDeviceId($deviceId: Int!) {
  device(id: $deviceId) {
    odometerUnit {
      id
      unitName
    }
    powerUnit {
      id
      unitName
    }
    fuel {
      fuelType {
        fuelName
      }
      consumptionUnit {
        unitName
        id
      }
    }
  }
}
    `;

/**
 * __useDeviceUnitsByDeviceIdQuery__
 *
 * To run a query within a React component, call `useDeviceUnitsByDeviceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceUnitsByDeviceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceUnitsByDeviceIdQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceUnitsByDeviceIdQuery(baseOptions: Apollo.QueryHookOptions<DeviceUnitsByDeviceIdQuery, DeviceUnitsByDeviceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceUnitsByDeviceIdQuery, DeviceUnitsByDeviceIdQueryVariables>(DeviceUnitsByDeviceIdDocument, options);
      }
export function useDeviceUnitsByDeviceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceUnitsByDeviceIdQuery, DeviceUnitsByDeviceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceUnitsByDeviceIdQuery, DeviceUnitsByDeviceIdQueryVariables>(DeviceUnitsByDeviceIdDocument, options);
        }
export type DeviceUnitsByDeviceIdQueryHookResult = ReturnType<typeof useDeviceUnitsByDeviceIdQuery>;
export type DeviceUnitsByDeviceIdLazyQueryHookResult = ReturnType<typeof useDeviceUnitsByDeviceIdLazyQuery>;
export type DeviceUnitsByDeviceIdQueryResult = Apollo.QueryResult<DeviceUnitsByDeviceIdQuery, DeviceUnitsByDeviceIdQueryVariables>;
export const DeviceUsedFuelsDocument = gql`
    query DeviceUsedFuels($deviceId: Int!) {
  device(id: $deviceId) {
    fuel {
      actualConsumption
      consumptionUnit {
        id
        unitName
      }
      fuelType {
        fuelName
        id
        isRemoved
      }
      id
      initialConsumption
      isRemoved
      tankVolume
      volumeUnit {
        id
        unitName
      }
    }
  }
}
    `;

/**
 * __useDeviceUsedFuelsQuery__
 *
 * To run a query within a React component, call `useDeviceUsedFuelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceUsedFuelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceUsedFuelsQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeviceUsedFuelsQuery(baseOptions: Apollo.QueryHookOptions<DeviceUsedFuelsQuery, DeviceUsedFuelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceUsedFuelsQuery, DeviceUsedFuelsQueryVariables>(DeviceUsedFuelsDocument, options);
      }
export function useDeviceUsedFuelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceUsedFuelsQuery, DeviceUsedFuelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceUsedFuelsQuery, DeviceUsedFuelsQueryVariables>(DeviceUsedFuelsDocument, options);
        }
export type DeviceUsedFuelsQueryHookResult = ReturnType<typeof useDeviceUsedFuelsQuery>;
export type DeviceUsedFuelsLazyQueryHookResult = ReturnType<typeof useDeviceUsedFuelsLazyQuery>;
export type DeviceUsedFuelsQueryResult = Apollo.QueryResult<DeviceUsedFuelsQuery, DeviceUsedFuelsQueryVariables>;
export const DevicesIdsDocument = gql`
    query DevicesIds {
  devices {
    id
  }
}
    `;

/**
 * __useDevicesIdsQuery__
 *
 * To run a query within a React component, call `useDevicesIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDevicesIdsQuery(baseOptions?: Apollo.QueryHookOptions<DevicesIdsQuery, DevicesIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesIdsQuery, DevicesIdsQueryVariables>(DevicesIdsDocument, options);
      }
export function useDevicesIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesIdsQuery, DevicesIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesIdsQuery, DevicesIdsQueryVariables>(DevicesIdsDocument, options);
        }
export type DevicesIdsQueryHookResult = ReturnType<typeof useDevicesIdsQuery>;
export type DevicesIdsLazyQueryHookResult = ReturnType<typeof useDevicesIdsLazyQuery>;
export type DevicesIdsQueryResult = Apollo.QueryResult<DevicesIdsQuery, DevicesIdsQueryVariables>;
export const EventDocument = gql`
    query Event($eventId: BigInt!) {
  event(id: $eventId) {
    name
  }
}
    `;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
      }
export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
        }
export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const EventAttachmentsByEventIdDocument = gql`
    query EventAttachmentsByEventId($eventId: BigInt!) {
  eventAttachmentsByEventId(eventId: $eventId) {
    attachment {
      id
      mimeType
      originalName
      size
      storageName
    }
  }
}
    `;

/**
 * __useEventAttachmentsByEventIdQuery__
 *
 * To run a query within a React component, call `useEventAttachmentsByEventIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventAttachmentsByEventIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventAttachmentsByEventIdQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventAttachmentsByEventIdQuery(baseOptions: Apollo.QueryHookOptions<EventAttachmentsByEventIdQuery, EventAttachmentsByEventIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventAttachmentsByEventIdQuery, EventAttachmentsByEventIdQueryVariables>(EventAttachmentsByEventIdDocument, options);
      }
export function useEventAttachmentsByEventIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventAttachmentsByEventIdQuery, EventAttachmentsByEventIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventAttachmentsByEventIdQuery, EventAttachmentsByEventIdQueryVariables>(EventAttachmentsByEventIdDocument, options);
        }
export type EventAttachmentsByEventIdQueryHookResult = ReturnType<typeof useEventAttachmentsByEventIdQuery>;
export type EventAttachmentsByEventIdLazyQueryHookResult = ReturnType<typeof useEventAttachmentsByEventIdLazyQuery>;
export type EventAttachmentsByEventIdQueryResult = Apollo.QueryResult<EventAttachmentsByEventIdQuery, EventAttachmentsByEventIdQueryVariables>;
export const EventByIdDocument = gql`
    query EventById($eventId: BigInt!) {
  event(id: $eventId) {
    stateOdometer
    eventDate
  }
}
    `;

/**
 * __useEventByIdQuery__
 *
 * To run a query within a React component, call `useEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventByIdQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useEventByIdQuery(baseOptions: Apollo.QueryHookOptions<EventByIdQuery, EventByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventByIdQuery, EventByIdQueryVariables>(EventByIdDocument, options);
      }
export function useEventByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventByIdQuery, EventByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventByIdQuery, EventByIdQueryVariables>(EventByIdDocument, options);
        }
export type EventByIdQueryHookResult = ReturnType<typeof useEventByIdQuery>;
export type EventByIdLazyQueryHookResult = ReturnType<typeof useEventByIdLazyQuery>;
export type EventByIdQueryResult = Apollo.QueryResult<EventByIdQuery, EventByIdQueryVariables>;
export const EventsDocument = gql`
    query Events {
  events {
    id
    name
    stateOdometer
    priceTotal
    eventType {
      id
      name
    }
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const EventsByDeviceDocument = gql`
    query EventsByDevice($deviceId: Int!, $offset: Int!, $limit: Int!) {
  eventsByDevice(deviceId: $deviceId, offset: $offset, limit: $limit) {
    event {
      eventType {
        description
        id
        name
      }
      refueling {
        actualConsumption
      }
      priceTotal
      lastEditedDate
      name
      id
      eventDate
      stateOdometer
    }
    isLast
  }
}
    `;

/**
 * __useEventsByDeviceQuery__
 *
 * To run a query within a React component, call `useEventsByDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsByDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsByDeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEventsByDeviceQuery(baseOptions: Apollo.QueryHookOptions<EventsByDeviceQuery, EventsByDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsByDeviceQuery, EventsByDeviceQueryVariables>(EventsByDeviceDocument, options);
      }
export function useEventsByDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsByDeviceQuery, EventsByDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsByDeviceQuery, EventsByDeviceQueryVariables>(EventsByDeviceDocument, options);
        }
export type EventsByDeviceQueryHookResult = ReturnType<typeof useEventsByDeviceQuery>;
export type EventsByDeviceLazyQueryHookResult = ReturnType<typeof useEventsByDeviceLazyQuery>;
export type EventsByDeviceQueryResult = Apollo.QueryResult<EventsByDeviceQuery, EventsByDeviceQueryVariables>;
export const EventsByDeviceAndFiltersAndTagsDocument = gql`
    query eventsByDeviceAndFiltersAndTags($deviceId: Int!, $offset: Int!, $limit: Int!, $filters: Filter, $tags: [Int!]!) {
  eventsByDeviceAndFiltersAndTags(
    deviceId: $deviceId
    offset: $offset
    limit: $limit
    filters: $filters
    tags: $tags
  ) {
    event {
      id
      name
      priceTotal
      stateOdometer
      eventDate
      creationDate
      deviceId
      refueling {
        id
        pricePerUnit
        unitNumber
        fullTank
        skipTank
        actualConsumption
        fuelType {
          id
          fuelName
        }
      }
      eventType {
        id
        name
        description
      }
    }
    isLast
  }
}
    `;

/**
 * __useEventsByDeviceAndFiltersAndTagsQuery__
 *
 * To run a query within a React component, call `useEventsByDeviceAndFiltersAndTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsByDeviceAndFiltersAndTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsByDeviceAndFiltersAndTagsQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useEventsByDeviceAndFiltersAndTagsQuery(baseOptions: Apollo.QueryHookOptions<EventsByDeviceAndFiltersAndTagsQuery, EventsByDeviceAndFiltersAndTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsByDeviceAndFiltersAndTagsQuery, EventsByDeviceAndFiltersAndTagsQueryVariables>(EventsByDeviceAndFiltersAndTagsDocument, options);
      }
export function useEventsByDeviceAndFiltersAndTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsByDeviceAndFiltersAndTagsQuery, EventsByDeviceAndFiltersAndTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsByDeviceAndFiltersAndTagsQuery, EventsByDeviceAndFiltersAndTagsQueryVariables>(EventsByDeviceAndFiltersAndTagsDocument, options);
        }
export type EventsByDeviceAndFiltersAndTagsQueryHookResult = ReturnType<typeof useEventsByDeviceAndFiltersAndTagsQuery>;
export type EventsByDeviceAndFiltersAndTagsLazyQueryHookResult = ReturnType<typeof useEventsByDeviceAndFiltersAndTagsLazyQuery>;
export type EventsByDeviceAndFiltersAndTagsQueryResult = Apollo.QueryResult<EventsByDeviceAndFiltersAndTagsQuery, EventsByDeviceAndFiltersAndTagsQueryVariables>;
export const EventsByEventTypeDocument = gql`
    query EventsByEventType($eventTypeIds: [Int!]!, $offset: Int!, $limit: Int!) {
  eventsByEventType(eventTypeIds: $eventTypeIds, offset: $offset, limit: $limit) {
    event {
      id
      name
      priceTotal
      stateOdometer
      creationDate
      deviceId
      eventType {
        id
        name
        description
      }
    }
    isLast
  }
}
    `;

/**
 * __useEventsByEventTypeQuery__
 *
 * To run a query within a React component, call `useEventsByEventTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsByEventTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsByEventTypeQuery({
 *   variables: {
 *      eventTypeIds: // value for 'eventTypeIds'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEventsByEventTypeQuery(baseOptions: Apollo.QueryHookOptions<EventsByEventTypeQuery, EventsByEventTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsByEventTypeQuery, EventsByEventTypeQueryVariables>(EventsByEventTypeDocument, options);
      }
export function useEventsByEventTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsByEventTypeQuery, EventsByEventTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsByEventTypeQuery, EventsByEventTypeQueryVariables>(EventsByEventTypeDocument, options);
        }
export type EventsByEventTypeQueryHookResult = ReturnType<typeof useEventsByEventTypeQuery>;
export type EventsByEventTypeLazyQueryHookResult = ReturnType<typeof useEventsByEventTypeLazyQuery>;
export type EventsByEventTypeQueryResult = Apollo.QueryResult<EventsByEventTypeQuery, EventsByEventTypeQueryVariables>;
export const ExpensesByEventDocument = gql`
    query ExpensesByEvent($eventId: BigInt!, $offset: Int!, $limit: Int!) {
  expensesByEvent(eventId: $eventId, offset: $offset, limit: $limit) {
    expense {
      id
      price
      tag {
        id
        tagCategory {
          id
          name
        }
        tagId
        tagName
      }
      isRemoved
      event {
        priceTotal
      }
    }
    isLast
  }
}
    `;

/**
 * __useExpensesByEventQuery__
 *
 * To run a query within a React component, call `useExpensesByEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesByEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesByEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useExpensesByEventQuery(baseOptions: Apollo.QueryHookOptions<ExpensesByEventQuery, ExpensesByEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesByEventQuery, ExpensesByEventQueryVariables>(ExpensesByEventDocument, options);
      }
export function useExpensesByEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesByEventQuery, ExpensesByEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesByEventQuery, ExpensesByEventQueryVariables>(ExpensesByEventDocument, options);
        }
export type ExpensesByEventQueryHookResult = ReturnType<typeof useExpensesByEventQuery>;
export type ExpensesByEventLazyQueryHookResult = ReturnType<typeof useExpensesByEventLazyQuery>;
export type ExpensesByEventQueryResult = Apollo.QueryResult<ExpensesByEventQuery, ExpensesByEventQueryVariables>;
export const FuelTypesDocument = gql`
    query FuelTypes {
  fuelTypes {
    id
    fuelName
  }
}
    `;

/**
 * __useFuelTypesQuery__
 *
 * To run a query within a React component, call `useFuelTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFuelTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFuelTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFuelTypesQuery(baseOptions?: Apollo.QueryHookOptions<FuelTypesQuery, FuelTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FuelTypesQuery, FuelTypesQueryVariables>(FuelTypesDocument, options);
      }
export function useFuelTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FuelTypesQuery, FuelTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FuelTypesQuery, FuelTypesQueryVariables>(FuelTypesDocument, options);
        }
export type FuelTypesQueryHookResult = ReturnType<typeof useFuelTypesQuery>;
export type FuelTypesLazyQueryHookResult = ReturnType<typeof useFuelTypesLazyQuery>;
export type FuelTypesQueryResult = Apollo.QueryResult<FuelTypesQuery, FuelTypesQueryVariables>;
export const GetUserActiveMembershipIdDocument = gql`
    query GetUserActiveMembershipId($userId: Int!) {
  user(id: $userId) {
    membership {
      id
    }
  }
}
    `;

/**
 * __useGetUserActiveMembershipIdQuery__
 *
 * To run a query within a React component, call `useGetUserActiveMembershipIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserActiveMembershipIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserActiveMembershipIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserActiveMembershipIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserActiveMembershipIdQuery, GetUserActiveMembershipIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserActiveMembershipIdQuery, GetUserActiveMembershipIdQueryVariables>(GetUserActiveMembershipIdDocument, options);
      }
export function useGetUserActiveMembershipIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserActiveMembershipIdQuery, GetUserActiveMembershipIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserActiveMembershipIdQuery, GetUserActiveMembershipIdQueryVariables>(GetUserActiveMembershipIdDocument, options);
        }
export type GetUserActiveMembershipIdQueryHookResult = ReturnType<typeof useGetUserActiveMembershipIdQuery>;
export type GetUserActiveMembershipIdLazyQueryHookResult = ReturnType<typeof useGetUserActiveMembershipIdLazyQuery>;
export type GetUserActiveMembershipIdQueryResult = Apollo.QueryResult<GetUserActiveMembershipIdQuery, GetUserActiveMembershipIdQueryVariables>;
export const DevicesDocument = gql`
    query Devices {
  devices {
    name
    creationDate
    spz
    id
    actualOdometerState
    lastEditedDate
    deviceCategory {
      id
      name
    }
    serialNumber
    suspendedDate
    thumbnailImage {
      storageName
    }
    odometerUnit {
      id
      unitName
    }
  }
}
    `;

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDevicesQuery(baseOptions?: Apollo.QueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
      }
export function useDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
        }
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesQueryResult = Apollo.QueryResult<DevicesQuery, DevicesQueryVariables>;
export const LoginUserDocument = gql`
    mutation LoginUser($user: UserLoginInput!) {
  loginUser(user: $user) {
    accessToken
    user {
      id
      firstName
      login
      phone
      surname
      role {
        name
        id
      }
    }
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const LogoutUserDocument = gql`
    mutation LogoutUser($logoutUserId: Int!) {
  logoutUser(id: $logoutUserId) {
    id
  }
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *      logoutUserId: // value for 'logoutUserId'
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const MaintenanceByEventIdDocument = gql`
    query MaintenanceByEventId($eventId: BigInt!) {
  maintenanceIntervalsByEvent(eventId: $eventId) {
    name
    operation {
      name
      description
      id
      isRemoved
      isVisible
      operationType {
        id
        name
      }
      periodDay
      periodDistance
    }
    periodDay
    periodDistance
    id
  }
}
    `;

/**
 * __useMaintenanceByEventIdQuery__
 *
 * To run a query within a React component, call `useMaintenanceByEventIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceByEventIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceByEventIdQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useMaintenanceByEventIdQuery(baseOptions: Apollo.QueryHookOptions<MaintenanceByEventIdQuery, MaintenanceByEventIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaintenanceByEventIdQuery, MaintenanceByEventIdQueryVariables>(MaintenanceByEventIdDocument, options);
      }
export function useMaintenanceByEventIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceByEventIdQuery, MaintenanceByEventIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaintenanceByEventIdQuery, MaintenanceByEventIdQueryVariables>(MaintenanceByEventIdDocument, options);
        }
export type MaintenanceByEventIdQueryHookResult = ReturnType<typeof useMaintenanceByEventIdQuery>;
export type MaintenanceByEventIdLazyQueryHookResult = ReturnType<typeof useMaintenanceByEventIdLazyQuery>;
export type MaintenanceByEventIdQueryResult = Apollo.QueryResult<MaintenanceByEventIdQuery, MaintenanceByEventIdQueryVariables>;
export const MaintenanceIntervalsByDeviceDocument = gql`
    query MaintenanceIntervalsByDevice($deviceId: Int!) {
  maintenanceIntervalsByDevice(deviceId: $deviceId) {
    id
    name
    operation {
      id
      name
      description
      periodDay
      isVisible
      periodDistance
      periodEndDate
      dueDistance
      operationType {
        id
        name
      }
    }
    periodDay
    periodDistance
    device {
      actualOdometerState
    }
    dueDistance
  }
}
    `;

/**
 * __useMaintenanceIntervalsByDeviceQuery__
 *
 * To run a query within a React component, call `useMaintenanceIntervalsByDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceIntervalsByDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceIntervalsByDeviceQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useMaintenanceIntervalsByDeviceQuery(baseOptions: Apollo.QueryHookOptions<MaintenanceIntervalsByDeviceQuery, MaintenanceIntervalsByDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaintenanceIntervalsByDeviceQuery, MaintenanceIntervalsByDeviceQueryVariables>(MaintenanceIntervalsByDeviceDocument, options);
      }
export function useMaintenanceIntervalsByDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceIntervalsByDeviceQuery, MaintenanceIntervalsByDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaintenanceIntervalsByDeviceQuery, MaintenanceIntervalsByDeviceQueryVariables>(MaintenanceIntervalsByDeviceDocument, options);
        }
export type MaintenanceIntervalsByDeviceQueryHookResult = ReturnType<typeof useMaintenanceIntervalsByDeviceQuery>;
export type MaintenanceIntervalsByDeviceLazyQueryHookResult = ReturnType<typeof useMaintenanceIntervalsByDeviceLazyQuery>;
export type MaintenanceIntervalsByDeviceQueryResult = Apollo.QueryResult<MaintenanceIntervalsByDeviceQuery, MaintenanceIntervalsByDeviceQueryVariables>;
export const MembershipsDocument = gql`
    query Memberships {
  memberships {
    dataFree
    deviceCountMembership
    id
    name
    price
  }
}
    `;

/**
 * __useMembershipsQuery__
 *
 * To run a query within a React component, call `useMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipsQuery(baseOptions?: Apollo.QueryHookOptions<MembershipsQuery, MembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipsQuery, MembershipsQueryVariables>(MembershipsDocument, options);
      }
export function useMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipsQuery, MembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipsQuery, MembershipsQueryVariables>(MembershipsDocument, options);
        }
export type MembershipsQueryHookResult = ReturnType<typeof useMembershipsQuery>;
export type MembershipsLazyQueryHookResult = ReturnType<typeof useMembershipsLazyQuery>;
export type MembershipsQueryResult = Apollo.QueryResult<MembershipsQuery, MembershipsQueryVariables>;
export const OdometerUnitsDocument = gql`
    query OdometerUnits {
  odometerUnits {
    id
    unitName
  }
}
    `;

/**
 * __useOdometerUnitsQuery__
 *
 * To run a query within a React component, call `useOdometerUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOdometerUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOdometerUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOdometerUnitsQuery(baseOptions?: Apollo.QueryHookOptions<OdometerUnitsQuery, OdometerUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OdometerUnitsQuery, OdometerUnitsQueryVariables>(OdometerUnitsDocument, options);
      }
export function useOdometerUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OdometerUnitsQuery, OdometerUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OdometerUnitsQuery, OdometerUnitsQueryVariables>(OdometerUnitsDocument, options);
        }
export type OdometerUnitsQueryHookResult = ReturnType<typeof useOdometerUnitsQuery>;
export type OdometerUnitsLazyQueryHookResult = ReturnType<typeof useOdometerUnitsLazyQuery>;
export type OdometerUnitsQueryResult = Apollo.QueryResult<OdometerUnitsQuery, OdometerUnitsQueryVariables>;
export const PaymentResultPreviewDocument = gql`
    query PaymentResultPreview($userId: Int!) {
  user(id: $userId) {
    creditBalance
  }
}
    `;

/**
 * __usePaymentResultPreviewQuery__
 *
 * To run a query within a React component, call `usePaymentResultPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentResultPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentResultPreviewQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePaymentResultPreviewQuery(baseOptions: Apollo.QueryHookOptions<PaymentResultPreviewQuery, PaymentResultPreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentResultPreviewQuery, PaymentResultPreviewQueryVariables>(PaymentResultPreviewDocument, options);
      }
export function usePaymentResultPreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentResultPreviewQuery, PaymentResultPreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentResultPreviewQuery, PaymentResultPreviewQueryVariables>(PaymentResultPreviewDocument, options);
        }
export type PaymentResultPreviewQueryHookResult = ReturnType<typeof usePaymentResultPreviewQuery>;
export type PaymentResultPreviewLazyQueryHookResult = ReturnType<typeof usePaymentResultPreviewLazyQuery>;
export type PaymentResultPreviewQueryResult = Apollo.QueryResult<PaymentResultPreviewQuery, PaymentResultPreviewQueryVariables>;
export const PowerUnitsDocument = gql`
    query PowerUnits {
  powerUnits {
    id
    unitName
  }
}
    `;

/**
 * __usePowerUnitsQuery__
 *
 * To run a query within a React component, call `usePowerUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePowerUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePowerUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePowerUnitsQuery(baseOptions?: Apollo.QueryHookOptions<PowerUnitsQuery, PowerUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PowerUnitsQuery, PowerUnitsQueryVariables>(PowerUnitsDocument, options);
      }
export function usePowerUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PowerUnitsQuery, PowerUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PowerUnitsQuery, PowerUnitsQueryVariables>(PowerUnitsDocument, options);
        }
export type PowerUnitsQueryHookResult = ReturnType<typeof usePowerUnitsQuery>;
export type PowerUnitsLazyQueryHookResult = ReturnType<typeof usePowerUnitsLazyQuery>;
export type PowerUnitsQueryResult = Apollo.QueryResult<PowerUnitsQuery, PowerUnitsQueryVariables>;
export const RecordLayoutInfoByEventIdDocument = gql`
    query RecordLayoutInfoByEventId($eventId: BigInt!) {
  event(id: $eventId) {
    eventType {
      id
      name
    }
    eventDate
    name
  }
}
    `;

/**
 * __useRecordLayoutInfoByEventIdQuery__
 *
 * To run a query within a React component, call `useRecordLayoutInfoByEventIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordLayoutInfoByEventIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordLayoutInfoByEventIdQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useRecordLayoutInfoByEventIdQuery(baseOptions: Apollo.QueryHookOptions<RecordLayoutInfoByEventIdQuery, RecordLayoutInfoByEventIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordLayoutInfoByEventIdQuery, RecordLayoutInfoByEventIdQueryVariables>(RecordLayoutInfoByEventIdDocument, options);
      }
export function useRecordLayoutInfoByEventIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordLayoutInfoByEventIdQuery, RecordLayoutInfoByEventIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordLayoutInfoByEventIdQuery, RecordLayoutInfoByEventIdQueryVariables>(RecordLayoutInfoByEventIdDocument, options);
        }
export type RecordLayoutInfoByEventIdQueryHookResult = ReturnType<typeof useRecordLayoutInfoByEventIdQuery>;
export type RecordLayoutInfoByEventIdLazyQueryHookResult = ReturnType<typeof useRecordLayoutInfoByEventIdLazyQuery>;
export type RecordLayoutInfoByEventIdQueryResult = Apollo.QueryResult<RecordLayoutInfoByEventIdQuery, RecordLayoutInfoByEventIdQueryVariables>;
export const RefuelingByEventIdDocument = gql`
    query RefuelingByEventId($eventId: BigInt!) {
  refuelingByEvent(eventId: $eventId) {
    fuelType {
      fuelName
      id
    }
    fullTank
    id
    pricePerUnit
    skipTank
    unitNumber
    actualConsumption
  }
}
    `;

/**
 * __useRefuelingByEventIdQuery__
 *
 * To run a query within a React component, call `useRefuelingByEventIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefuelingByEventIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefuelingByEventIdQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useRefuelingByEventIdQuery(baseOptions: Apollo.QueryHookOptions<RefuelingByEventIdQuery, RefuelingByEventIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefuelingByEventIdQuery, RefuelingByEventIdQueryVariables>(RefuelingByEventIdDocument, options);
      }
export function useRefuelingByEventIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefuelingByEventIdQuery, RefuelingByEventIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefuelingByEventIdQuery, RefuelingByEventIdQueryVariables>(RefuelingByEventIdDocument, options);
        }
export type RefuelingByEventIdQueryHookResult = ReturnType<typeof useRefuelingByEventIdQuery>;
export type RefuelingByEventIdLazyQueryHookResult = ReturnType<typeof useRefuelingByEventIdLazyQuery>;
export type RefuelingByEventIdQueryResult = Apollo.QueryResult<RefuelingByEventIdQuery, RefuelingByEventIdQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
  resetPassword(email: $email)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SetNewPasswordDocument = gql`
    mutation SetNewPassword($resetPassword: ResetPasswordInput!) {
  setNewPassword(resetPassword: $resetPassword)
}
    `;
export type SetNewPasswordMutationFn = Apollo.MutationFunction<SetNewPasswordMutation, SetNewPasswordMutationVariables>;

/**
 * __useSetNewPasswordMutation__
 *
 * To run a mutation, you first call `useSetNewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewPasswordMutation, { data, loading, error }] = useSetNewPasswordMutation({
 *   variables: {
 *      resetPassword: // value for 'resetPassword'
 *   },
 * });
 */
export function useSetNewPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SetNewPasswordMutation, SetNewPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNewPasswordMutation, SetNewPasswordMutationVariables>(SetNewPasswordDocument, options);
      }
export type SetNewPasswordMutationHookResult = ReturnType<typeof useSetNewPasswordMutation>;
export type SetNewPasswordMutationResult = Apollo.MutationResult<SetNewPasswordMutation>;
export type SetNewPasswordMutationOptions = Apollo.BaseMutationOptions<SetNewPasswordMutation, SetNewPasswordMutationVariables>;
export const SetNewTypeOfMembershipDocument = gql`
    mutation SetNewTypeOfMembership($newMembershipId: Int!, $devicesNotToSuspend: [Int!]) {
  setNewTypeOfMembership(
    newMembershipId: $newMembershipId
    devicesNotToSuspend: $devicesNotToSuspend
  )
}
    `;
export type SetNewTypeOfMembershipMutationFn = Apollo.MutationFunction<SetNewTypeOfMembershipMutation, SetNewTypeOfMembershipMutationVariables>;

/**
 * __useSetNewTypeOfMembershipMutation__
 *
 * To run a mutation, you first call `useSetNewTypeOfMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewTypeOfMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewTypeOfMembershipMutation, { data, loading, error }] = useSetNewTypeOfMembershipMutation({
 *   variables: {
 *      newMembershipId: // value for 'newMembershipId'
 *      devicesNotToSuspend: // value for 'devicesNotToSuspend'
 *   },
 * });
 */
export function useSetNewTypeOfMembershipMutation(baseOptions?: Apollo.MutationHookOptions<SetNewTypeOfMembershipMutation, SetNewTypeOfMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNewTypeOfMembershipMutation, SetNewTypeOfMembershipMutationVariables>(SetNewTypeOfMembershipDocument, options);
      }
export type SetNewTypeOfMembershipMutationHookResult = ReturnType<typeof useSetNewTypeOfMembershipMutation>;
export type SetNewTypeOfMembershipMutationResult = Apollo.MutationResult<SetNewTypeOfMembershipMutation>;
export type SetNewTypeOfMembershipMutationOptions = Apollo.BaseMutationOptions<SetNewTypeOfMembershipMutation, SetNewTypeOfMembershipMutationVariables>;
export const TagCategoriesDocument = gql`
    query TagCategories {
  tagCategories {
    id
    name
  }
}
    `;

/**
 * __useTagCategoriesQuery__
 *
 * To run a query within a React component, call `useTagCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<TagCategoriesQuery, TagCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagCategoriesQuery, TagCategoriesQueryVariables>(TagCategoriesDocument, options);
      }
export function useTagCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagCategoriesQuery, TagCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagCategoriesQuery, TagCategoriesQueryVariables>(TagCategoriesDocument, options);
        }
export type TagCategoriesQueryHookResult = ReturnType<typeof useTagCategoriesQuery>;
export type TagCategoriesLazyQueryHookResult = ReturnType<typeof useTagCategoriesLazyQuery>;
export type TagCategoriesQueryResult = Apollo.QueryResult<TagCategoriesQuery, TagCategoriesQueryVariables>;
export const TagsDocument = gql`
    query Tags($offset: Int!, $limit: Int!) {
  tags(offset: $offset, limit: $limit) {
    tag {
      tagName
      tagId
      tagCategory {
        id
        name
      }
      id
      description
    }
    isLast
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTagsQuery(baseOptions: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const TagsOrderedByUsageDocument = gql`
    query TagsOrderedByUsage($limit: Int!) {
  tagsOrderedByUsage(limit: $limit) {
    tagName
    tagId
    id
    tagCategory {
      name
      id
    }
    description
  }
}
    `;

/**
 * __useTagsOrderedByUsageQuery__
 *
 * To run a query within a React component, call `useTagsOrderedByUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsOrderedByUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsOrderedByUsageQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTagsOrderedByUsageQuery(baseOptions: Apollo.QueryHookOptions<TagsOrderedByUsageQuery, TagsOrderedByUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsOrderedByUsageQuery, TagsOrderedByUsageQueryVariables>(TagsOrderedByUsageDocument, options);
      }
export function useTagsOrderedByUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsOrderedByUsageQuery, TagsOrderedByUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsOrderedByUsageQuery, TagsOrderedByUsageQueryVariables>(TagsOrderedByUsageDocument, options);
        }
export type TagsOrderedByUsageQueryHookResult = ReturnType<typeof useTagsOrderedByUsageQuery>;
export type TagsOrderedByUsageLazyQueryHookResult = ReturnType<typeof useTagsOrderedByUsageLazyQuery>;
export type TagsOrderedByUsageQueryResult = Apollo.QueryResult<TagsOrderedByUsageQuery, TagsOrderedByUsageQueryVariables>;
export const TransmissionsDocument = gql`
    query Transmissions {
  transmissions {
    id
    name
  }
}
    `;

/**
 * __useTransmissionsQuery__
 *
 * To run a query within a React component, call `useTransmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransmissionsQuery(baseOptions?: Apollo.QueryHookOptions<TransmissionsQuery, TransmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransmissionsQuery, TransmissionsQueryVariables>(TransmissionsDocument, options);
      }
export function useTransmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransmissionsQuery, TransmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransmissionsQuery, TransmissionsQueryVariables>(TransmissionsDocument, options);
        }
export type TransmissionsQueryHookResult = ReturnType<typeof useTransmissionsQuery>;
export type TransmissionsLazyQueryHookResult = ReturnType<typeof useTransmissionsLazyQuery>;
export type TransmissionsQueryResult = Apollo.QueryResult<TransmissionsQuery, TransmissionsQueryVariables>;
export const UpdateDeviceAvatarDocument = gql`
    mutation UpdateDeviceAvatar($device: DeviceUpdateInput!) {
  updateDevice(device: $device) {
    thumbnailImage {
      id
      originalName
      storageName
      mimeType
      size
    }
  }
}
    `;
export type UpdateDeviceAvatarMutationFn = Apollo.MutationFunction<UpdateDeviceAvatarMutation, UpdateDeviceAvatarMutationVariables>;

/**
 * __useUpdateDeviceAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceAvatarMutation, { data, loading, error }] = useUpdateDeviceAvatarMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useUpdateDeviceAvatarMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceAvatarMutation, UpdateDeviceAvatarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceAvatarMutation, UpdateDeviceAvatarMutationVariables>(UpdateDeviceAvatarDocument, options);
      }
export type UpdateDeviceAvatarMutationHookResult = ReturnType<typeof useUpdateDeviceAvatarMutation>;
export type UpdateDeviceAvatarMutationResult = Apollo.MutationResult<UpdateDeviceAvatarMutation>;
export type UpdateDeviceAvatarMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceAvatarMutation, UpdateDeviceAvatarMutationVariables>;
export const UpdateDeviceBasicInfoDocument = gql`
    mutation UpdateDeviceBasicInfo($device: DeviceUpdateInput!) {
  updateDevice(device: $device) {
    id
    name
  }
}
    `;
export type UpdateDeviceBasicInfoMutationFn = Apollo.MutationFunction<UpdateDeviceBasicInfoMutation, UpdateDeviceBasicInfoMutationVariables>;

/**
 * __useUpdateDeviceBasicInfoMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceBasicInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceBasicInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceBasicInfoMutation, { data, loading, error }] = useUpdateDeviceBasicInfoMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useUpdateDeviceBasicInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceBasicInfoMutation, UpdateDeviceBasicInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceBasicInfoMutation, UpdateDeviceBasicInfoMutationVariables>(UpdateDeviceBasicInfoDocument, options);
      }
export type UpdateDeviceBasicInfoMutationHookResult = ReturnType<typeof useUpdateDeviceBasicInfoMutation>;
export type UpdateDeviceBasicInfoMutationResult = Apollo.MutationResult<UpdateDeviceBasicInfoMutation>;
export type UpdateDeviceBasicInfoMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceBasicInfoMutation, UpdateDeviceBasicInfoMutationVariables>;
export const UpdateDeviceOperationalDataDocument = gql`
    mutation UpdateDeviceOperationalData($device: DeviceUpdateInput!) {
  updateDevice(device: $device) {
    id
    acquisitionDate
    firstRegistrationDate
    guaranteeDate
    guaranteeDistance
    guaranteeMonths
    initialOdometerState
  }
}
    `;
export type UpdateDeviceOperationalDataMutationFn = Apollo.MutationFunction<UpdateDeviceOperationalDataMutation, UpdateDeviceOperationalDataMutationVariables>;

/**
 * __useUpdateDeviceOperationalDataMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceOperationalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceOperationalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceOperationalDataMutation, { data, loading, error }] = useUpdateDeviceOperationalDataMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useUpdateDeviceOperationalDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceOperationalDataMutation, UpdateDeviceOperationalDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceOperationalDataMutation, UpdateDeviceOperationalDataMutationVariables>(UpdateDeviceOperationalDataDocument, options);
      }
export type UpdateDeviceOperationalDataMutationHookResult = ReturnType<typeof useUpdateDeviceOperationalDataMutation>;
export type UpdateDeviceOperationalDataMutationResult = Apollo.MutationResult<UpdateDeviceOperationalDataMutation>;
export type UpdateDeviceOperationalDataMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceOperationalDataMutation, UpdateDeviceOperationalDataMutationVariables>;
export const UpdateDeviceTechnicalParametersDocument = gql`
    mutation UpdateDeviceTechnicalParameters($device: DeviceUpdateInput!) {
  updateDevice(device: $device) {
    id
    engineSpecification
    serialNumber
    engineDisplacementCc
    power
    powerUnit {
      id
    }
    powerRpm
    torqueNm
    torqueRpm
    transmissionType
    transmissionNumber
  }
}
    `;
export type UpdateDeviceTechnicalParametersMutationFn = Apollo.MutationFunction<UpdateDeviceTechnicalParametersMutation, UpdateDeviceTechnicalParametersMutationVariables>;

/**
 * __useUpdateDeviceTechnicalParametersMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceTechnicalParametersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceTechnicalParametersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceTechnicalParametersMutation, { data, loading, error }] = useUpdateDeviceTechnicalParametersMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useUpdateDeviceTechnicalParametersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceTechnicalParametersMutation, UpdateDeviceTechnicalParametersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceTechnicalParametersMutation, UpdateDeviceTechnicalParametersMutationVariables>(UpdateDeviceTechnicalParametersDocument, options);
      }
export type UpdateDeviceTechnicalParametersMutationHookResult = ReturnType<typeof useUpdateDeviceTechnicalParametersMutation>;
export type UpdateDeviceTechnicalParametersMutationResult = Apollo.MutationResult<UpdateDeviceTechnicalParametersMutation>;
export type UpdateDeviceTechnicalParametersMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceTechnicalParametersMutation, UpdateDeviceTechnicalParametersMutationVariables>;
export const UpdateDeviceUnitsDocument = gql`
    mutation UpdateDeviceUnits($device: DeviceUpdateInput!) {
  updateDevice(device: $device) {
    id
    name
  }
}
    `;
export type UpdateDeviceUnitsMutationFn = Apollo.MutationFunction<UpdateDeviceUnitsMutation, UpdateDeviceUnitsMutationVariables>;

/**
 * __useUpdateDeviceUnitsMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceUnitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceUnitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceUnitsMutation, { data, loading, error }] = useUpdateDeviceUnitsMutation({
 *   variables: {
 *      device: // value for 'device'
 *   },
 * });
 */
export function useUpdateDeviceUnitsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceUnitsMutation, UpdateDeviceUnitsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceUnitsMutation, UpdateDeviceUnitsMutationVariables>(UpdateDeviceUnitsDocument, options);
      }
export type UpdateDeviceUnitsMutationHookResult = ReturnType<typeof useUpdateDeviceUnitsMutation>;
export type UpdateDeviceUnitsMutationResult = Apollo.MutationResult<UpdateDeviceUnitsMutation>;
export type UpdateDeviceUnitsMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceUnitsMutation, UpdateDeviceUnitsMutationVariables>;
export const UpdateDeviceUsedFuelsDocument = gql`
    mutation UpdateDeviceUsedFuels($device: DeviceUpdateInput!, $fuels: [FuelCreateInput!]!, $ids: [Int!]!) {
  updateDevice(device: $device) {
    fuel {
      actualConsumption
      consumptionUnit {
        id
        unitName
      }
      fuelType {
        fuelName
        id
        isRemoved
      }
      id
      initialConsumption
      isRemoved
      volumeUnit {
        id
        unitName
      }
      tankVolume
    }
  }
  createFuels(fuels: $fuels) {
    consumptionUnit {
      id
      unitName
    }
    fuelType {
      fuelName
      id
      isRemoved
    }
    id
    isRemoved
    tankVolume
    volumeUnit {
      id
      unitName
    }
  }
  deleteFuels(ids: $ids) {
    id
  }
}
    `;
export type UpdateDeviceUsedFuelsMutationFn = Apollo.MutationFunction<UpdateDeviceUsedFuelsMutation, UpdateDeviceUsedFuelsMutationVariables>;

/**
 * __useUpdateDeviceUsedFuelsMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceUsedFuelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceUsedFuelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceUsedFuelsMutation, { data, loading, error }] = useUpdateDeviceUsedFuelsMutation({
 *   variables: {
 *      device: // value for 'device'
 *      fuels: // value for 'fuels'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUpdateDeviceUsedFuelsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceUsedFuelsMutation, UpdateDeviceUsedFuelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceUsedFuelsMutation, UpdateDeviceUsedFuelsMutationVariables>(UpdateDeviceUsedFuelsDocument, options);
      }
export type UpdateDeviceUsedFuelsMutationHookResult = ReturnType<typeof useUpdateDeviceUsedFuelsMutation>;
export type UpdateDeviceUsedFuelsMutationResult = Apollo.MutationResult<UpdateDeviceUsedFuelsMutation>;
export type UpdateDeviceUsedFuelsMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceUsedFuelsMutation, UpdateDeviceUsedFuelsMutationVariables>;
export const UpdateEventDescriptionDocument = gql`
    mutation UpdateEventDescription($event: EventUpdateInput!) {
  updateEvent(event: $event) {
    id
    description
  }
}
    `;
export type UpdateEventDescriptionMutationFn = Apollo.MutationFunction<UpdateEventDescriptionMutation, UpdateEventDescriptionMutationVariables>;

/**
 * __useUpdateEventDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateEventDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventDescriptionMutation, { data, loading, error }] = useUpdateEventDescriptionMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateEventDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventDescriptionMutation, UpdateEventDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventDescriptionMutation, UpdateEventDescriptionMutationVariables>(UpdateEventDescriptionDocument, options);
      }
export type UpdateEventDescriptionMutationHookResult = ReturnType<typeof useUpdateEventDescriptionMutation>;
export type UpdateEventDescriptionMutationResult = Apollo.MutationResult<UpdateEventDescriptionMutation>;
export type UpdateEventDescriptionMutationOptions = Apollo.BaseMutationOptions<UpdateEventDescriptionMutation, UpdateEventDescriptionMutationVariables>;
export const UpdateEventDateDocument = gql`
    mutation UpdateEventDate($event: EventUpdateInput!) {
  updateEvent(event: $event) {
    id
    eventDate
  }
}
    `;
export type UpdateEventDateMutationFn = Apollo.MutationFunction<UpdateEventDateMutation, UpdateEventDateMutationVariables>;

/**
 * __useUpdateEventDateMutation__
 *
 * To run a mutation, you first call `useUpdateEventDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventDateMutation, { data, loading, error }] = useUpdateEventDateMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateEventDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventDateMutation, UpdateEventDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventDateMutation, UpdateEventDateMutationVariables>(UpdateEventDateDocument, options);
      }
export type UpdateEventDateMutationHookResult = ReturnType<typeof useUpdateEventDateMutation>;
export type UpdateEventDateMutationResult = Apollo.MutationResult<UpdateEventDateMutation>;
export type UpdateEventDateMutationOptions = Apollo.BaseMutationOptions<UpdateEventDateMutation, UpdateEventDateMutationVariables>;
export const UpdateEventOdometerStateDocument = gql`
    mutation UpdateEventOdometerState($event: EventUpdateInput!) {
  updateEvent(event: $event) {
    id
    stateOdometer
  }
}
    `;
export type UpdateEventOdometerStateMutationFn = Apollo.MutationFunction<UpdateEventOdometerStateMutation, UpdateEventOdometerStateMutationVariables>;

/**
 * __useUpdateEventOdometerStateMutation__
 *
 * To run a mutation, you first call `useUpdateEventOdometerStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventOdometerStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventOdometerStateMutation, { data, loading, error }] = useUpdateEventOdometerStateMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateEventOdometerStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventOdometerStateMutation, UpdateEventOdometerStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventOdometerStateMutation, UpdateEventOdometerStateMutationVariables>(UpdateEventOdometerStateDocument, options);
      }
export type UpdateEventOdometerStateMutationHookResult = ReturnType<typeof useUpdateEventOdometerStateMutation>;
export type UpdateEventOdometerStateMutationResult = Apollo.MutationResult<UpdateEventOdometerStateMutation>;
export type UpdateEventOdometerStateMutationOptions = Apollo.BaseMutationOptions<UpdateEventOdometerStateMutation, UpdateEventOdometerStateMutationVariables>;
export const UpdateExpenseEventDocument = gql`
    mutation UpdateExpenseEvent($event: ExpenseEventUpdateInput!) {
  updateExpenseEvent(event: $event) {
    expense {
      id
      price
      tag {
        id
      }
    }
    priceTotal
  }
}
    `;
export type UpdateExpenseEventMutationFn = Apollo.MutationFunction<UpdateExpenseEventMutation, UpdateExpenseEventMutationVariables>;

/**
 * __useUpdateExpenseEventMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseEventMutation, { data, loading, error }] = useUpdateExpenseEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateExpenseEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseEventMutation, UpdateExpenseEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseEventMutation, UpdateExpenseEventMutationVariables>(UpdateExpenseEventDocument, options);
      }
export type UpdateExpenseEventMutationHookResult = ReturnType<typeof useUpdateExpenseEventMutation>;
export type UpdateExpenseEventMutationResult = Apollo.MutationResult<UpdateExpenseEventMutation>;
export type UpdateExpenseEventMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseEventMutation, UpdateExpenseEventMutationVariables>;
export const UpdateMaintenanceEventDocument = gql`
    mutation UpdateMaintenanceEvent($event: MaintenanceEventUpdateInput!) {
  updateMaintenanceEvent(event: $event) {
    id
  }
}
    `;
export type UpdateMaintenanceEventMutationFn = Apollo.MutationFunction<UpdateMaintenanceEventMutation, UpdateMaintenanceEventMutationVariables>;

/**
 * __useUpdateMaintenanceEventMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceEventMutation, { data, loading, error }] = useUpdateMaintenanceEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateMaintenanceEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMaintenanceEventMutation, UpdateMaintenanceEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMaintenanceEventMutation, UpdateMaintenanceEventMutationVariables>(UpdateMaintenanceEventDocument, options);
      }
export type UpdateMaintenanceEventMutationHookResult = ReturnType<typeof useUpdateMaintenanceEventMutation>;
export type UpdateMaintenanceEventMutationResult = Apollo.MutationResult<UpdateMaintenanceEventMutation>;
export type UpdateMaintenanceEventMutationOptions = Apollo.BaseMutationOptions<UpdateMaintenanceEventMutation, UpdateMaintenanceEventMutationVariables>;
export const UpdateMaintenanceIntervalDocument = gql`
    mutation UpdateMaintenanceInterval($maintenanceInterval: MaintenanceIntervalUpdateInput!) {
  updateMaintenanceInterval(maintenanceInterval: $maintenanceInterval) {
    id
    name
    periodDay
    periodDistance
    device {
      id
    }
  }
}
    `;
export type UpdateMaintenanceIntervalMutationFn = Apollo.MutationFunction<UpdateMaintenanceIntervalMutation, UpdateMaintenanceIntervalMutationVariables>;

/**
 * __useUpdateMaintenanceIntervalMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceIntervalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceIntervalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceIntervalMutation, { data, loading, error }] = useUpdateMaintenanceIntervalMutation({
 *   variables: {
 *      maintenanceInterval: // value for 'maintenanceInterval'
 *   },
 * });
 */
export function useUpdateMaintenanceIntervalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMaintenanceIntervalMutation, UpdateMaintenanceIntervalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMaintenanceIntervalMutation, UpdateMaintenanceIntervalMutationVariables>(UpdateMaintenanceIntervalDocument, options);
      }
export type UpdateMaintenanceIntervalMutationHookResult = ReturnType<typeof useUpdateMaintenanceIntervalMutation>;
export type UpdateMaintenanceIntervalMutationResult = Apollo.MutationResult<UpdateMaintenanceIntervalMutation>;
export type UpdateMaintenanceIntervalMutationOptions = Apollo.BaseMutationOptions<UpdateMaintenanceIntervalMutation, UpdateMaintenanceIntervalMutationVariables>;
export const UpdateOperationDocument = gql`
    mutation UpdateOperation($operation: OperationUpdateInput!) {
  updateOperation(operation: $operation) {
    description
    dueDistance
    eventOperation {
      event {
        id
      }
    }
    id
    name
    operationType {
      id
      name
    }
    periodDay
    periodDistance
    periodEndDate
  }
}
    `;
export type UpdateOperationMutationFn = Apollo.MutationFunction<UpdateOperationMutation, UpdateOperationMutationVariables>;

/**
 * __useUpdateOperationMutation__
 *
 * To run a mutation, you first call `useUpdateOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOperationMutation, { data, loading, error }] = useUpdateOperationMutation({
 *   variables: {
 *      operation: // value for 'operation'
 *   },
 * });
 */
export function useUpdateOperationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOperationMutation, UpdateOperationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOperationMutation, UpdateOperationMutationVariables>(UpdateOperationDocument, options);
      }
export type UpdateOperationMutationHookResult = ReturnType<typeof useUpdateOperationMutation>;
export type UpdateOperationMutationResult = Apollo.MutationResult<UpdateOperationMutation>;
export type UpdateOperationMutationOptions = Apollo.BaseMutationOptions<UpdateOperationMutation, UpdateOperationMutationVariables>;
export const UpdateRefuelingDocument = gql`
    mutation UpdateRefueling($refueling: RefuelingUpdateInput!) {
  updateRefueling(refueling: $refueling) {
    id
    pricePerUnit
    unitNumber
    fullTank
    skipTank
    actualConsumption
  }
}
    `;
export type UpdateRefuelingMutationFn = Apollo.MutationFunction<UpdateRefuelingMutation, UpdateRefuelingMutationVariables>;

/**
 * __useUpdateRefuelingMutation__
 *
 * To run a mutation, you first call `useUpdateRefuelingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRefuelingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRefuelingMutation, { data, loading, error }] = useUpdateRefuelingMutation({
 *   variables: {
 *      refueling: // value for 'refueling'
 *   },
 * });
 */
export function useUpdateRefuelingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRefuelingMutation, UpdateRefuelingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRefuelingMutation, UpdateRefuelingMutationVariables>(UpdateRefuelingDocument, options);
      }
export type UpdateRefuelingMutationHookResult = ReturnType<typeof useUpdateRefuelingMutation>;
export type UpdateRefuelingMutationResult = Apollo.MutationResult<UpdateRefuelingMutation>;
export type UpdateRefuelingMutationOptions = Apollo.BaseMutationOptions<UpdateRefuelingMutation, UpdateRefuelingMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($user: UserUpdateInput!) {
  updateUser(user: $user) {
    id
    firstName
    surname
    phone
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpgradeDataQuoteDocument = gql`
    mutation UpgradeDataQuote($countOfDataQuoteToUnlock: Int!) {
  upgradeDataQuote(countOfDataQuoteToUnlock: $countOfDataQuoteToUnlock)
}
    `;
export type UpgradeDataQuoteMutationFn = Apollo.MutationFunction<UpgradeDataQuoteMutation, UpgradeDataQuoteMutationVariables>;

/**
 * __useUpgradeDataQuoteMutation__
 *
 * To run a mutation, you first call `useUpgradeDataQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeDataQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeDataQuoteMutation, { data, loading, error }] = useUpgradeDataQuoteMutation({
 *   variables: {
 *      countOfDataQuoteToUnlock: // value for 'countOfDataQuoteToUnlock'
 *   },
 * });
 */
export function useUpgradeDataQuoteMutation(baseOptions?: Apollo.MutationHookOptions<UpgradeDataQuoteMutation, UpgradeDataQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpgradeDataQuoteMutation, UpgradeDataQuoteMutationVariables>(UpgradeDataQuoteDocument, options);
      }
export type UpgradeDataQuoteMutationHookResult = ReturnType<typeof useUpgradeDataQuoteMutation>;
export type UpgradeDataQuoteMutationResult = Apollo.MutationResult<UpgradeDataQuoteMutation>;
export type UpgradeDataQuoteMutationOptions = Apollo.BaseMutationOptions<UpgradeDataQuoteMutation, UpgradeDataQuoteMutationVariables>;
export const UserDocument = gql`
    query User($userId: Int!) {
  user(id: $userId) {
    id
    login
    phone
    countryCode
    firstName
    surname
    registrationDate
    active
    isDeviceCountExceeded
    membership {
      dataFree
      deviceCountMembership
      id
      name
      price
    }
    role {
      name
      id
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserDataQuoteDocument = gql`
    query UserDataQuote($userId: Int!) {
  user(id: $userId) {
    dataQuote
    dataVolume
    membership {
      dataFree
    }
  }
}
    `;

/**
 * __useUserDataQuoteQuery__
 *
 * To run a query within a React component, call `useUserDataQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDataQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDataQuoteQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserDataQuoteQuery(baseOptions: Apollo.QueryHookOptions<UserDataQuoteQuery, UserDataQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDataQuoteQuery, UserDataQuoteQueryVariables>(UserDataQuoteDocument, options);
      }
export function useUserDataQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDataQuoteQuery, UserDataQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDataQuoteQuery, UserDataQuoteQueryVariables>(UserDataQuoteDocument, options);
        }
export type UserDataQuoteQueryHookResult = ReturnType<typeof useUserDataQuoteQuery>;
export type UserDataQuoteLazyQueryHookResult = ReturnType<typeof useUserDataQuoteLazyQuery>;
export type UserDataQuoteQueryResult = Apollo.QueryResult<UserDataQuoteQuery, UserDataQuoteQueryVariables>;
export const VehicleTypesDocument = gql`
    query VehicleTypes {
  vehicleTypes {
    id
    name
  }
}
    `;

/**
 * __useVehicleTypesQuery__
 *
 * To run a query within a React component, call `useVehicleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleTypesQuery(baseOptions?: Apollo.QueryHookOptions<VehicleTypesQuery, VehicleTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleTypesQuery, VehicleTypesQueryVariables>(VehicleTypesDocument, options);
      }
export function useVehicleTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleTypesQuery, VehicleTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleTypesQuery, VehicleTypesQueryVariables>(VehicleTypesDocument, options);
        }
export type VehicleTypesQueryHookResult = ReturnType<typeof useVehicleTypesQuery>;
export type VehicleTypesLazyQueryHookResult = ReturnType<typeof useVehicleTypesLazyQuery>;
export type VehicleTypesQueryResult = Apollo.QueryResult<VehicleTypesQuery, VehicleTypesQueryVariables>;
export const VehicleTypesByDeviceCategoryDocument = gql`
    query VehicleTypesByDeviceCategory($deviceCategoryId: Int!) {
  vehicleTypesByDeviceCategory(deviceCategoryId: $deviceCategoryId) {
    id
    name
  }
}
    `;

/**
 * __useVehicleTypesByDeviceCategoryQuery__
 *
 * To run a query within a React component, call `useVehicleTypesByDeviceCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleTypesByDeviceCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleTypesByDeviceCategoryQuery({
 *   variables: {
 *      deviceCategoryId: // value for 'deviceCategoryId'
 *   },
 * });
 */
export function useVehicleTypesByDeviceCategoryQuery(baseOptions: Apollo.QueryHookOptions<VehicleTypesByDeviceCategoryQuery, VehicleTypesByDeviceCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleTypesByDeviceCategoryQuery, VehicleTypesByDeviceCategoryQueryVariables>(VehicleTypesByDeviceCategoryDocument, options);
      }
export function useVehicleTypesByDeviceCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleTypesByDeviceCategoryQuery, VehicleTypesByDeviceCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleTypesByDeviceCategoryQuery, VehicleTypesByDeviceCategoryQueryVariables>(VehicleTypesByDeviceCategoryDocument, options);
        }
export type VehicleTypesByDeviceCategoryQueryHookResult = ReturnType<typeof useVehicleTypesByDeviceCategoryQuery>;
export type VehicleTypesByDeviceCategoryLazyQueryHookResult = ReturnType<typeof useVehicleTypesByDeviceCategoryLazyQuery>;
export type VehicleTypesByDeviceCategoryQueryResult = Apollo.QueryResult<VehicleTypesByDeviceCategoryQuery, VehicleTypesByDeviceCategoryQueryVariables>;
export const VolumeUnitsDocument = gql`
    query VolumeUnits {
  volumeUnits {
    id
    unitName
  }
}
    `;

/**
 * __useVolumeUnitsQuery__
 *
 * To run a query within a React component, call `useVolumeUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVolumeUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVolumeUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVolumeUnitsQuery(baseOptions?: Apollo.QueryHookOptions<VolumeUnitsQuery, VolumeUnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VolumeUnitsQuery, VolumeUnitsQueryVariables>(VolumeUnitsDocument, options);
      }
export function useVolumeUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VolumeUnitsQuery, VolumeUnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VolumeUnitsQuery, VolumeUnitsQueryVariables>(VolumeUnitsDocument, options);
        }
export type VolumeUnitsQueryHookResult = ReturnType<typeof useVolumeUnitsQuery>;
export type VolumeUnitsLazyQueryHookResult = ReturnType<typeof useVolumeUnitsLazyQuery>;
export type VolumeUnitsQueryResult = Apollo.QueryResult<VolumeUnitsQuery, VolumeUnitsQueryVariables>;
export const VolumeUnitsByFuelTypeDocument = gql`
    query VolumeUnitsByFuelType($fuelTypeId: Int!) {
  volumeUnitsByFuelType(fuelTypeId: $fuelTypeId) {
    id
    unitName
  }
}
    `;

/**
 * __useVolumeUnitsByFuelTypeQuery__
 *
 * To run a query within a React component, call `useVolumeUnitsByFuelTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVolumeUnitsByFuelTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVolumeUnitsByFuelTypeQuery({
 *   variables: {
 *      fuelTypeId: // value for 'fuelTypeId'
 *   },
 * });
 */
export function useVolumeUnitsByFuelTypeQuery(baseOptions: Apollo.QueryHookOptions<VolumeUnitsByFuelTypeQuery, VolumeUnitsByFuelTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VolumeUnitsByFuelTypeQuery, VolumeUnitsByFuelTypeQueryVariables>(VolumeUnitsByFuelTypeDocument, options);
      }
export function useVolumeUnitsByFuelTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VolumeUnitsByFuelTypeQuery, VolumeUnitsByFuelTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VolumeUnitsByFuelTypeQuery, VolumeUnitsByFuelTypeQueryVariables>(VolumeUnitsByFuelTypeDocument, options);
        }
export type VolumeUnitsByFuelTypeQueryHookResult = ReturnType<typeof useVolumeUnitsByFuelTypeQuery>;
export type VolumeUnitsByFuelTypeLazyQueryHookResult = ReturnType<typeof useVolumeUnitsByFuelTypeLazyQuery>;
export type VolumeUnitsByFuelTypeQueryResult = Apollo.QueryResult<VolumeUnitsByFuelTypeQuery, VolumeUnitsByFuelTypeQueryVariables>;
export const OperationTypesDocument = gql`
    query OperationTypes {
  operationTypes {
    id
    name
  }
}
    `;

/**
 * __useOperationTypesQuery__
 *
 * To run a query within a React component, call `useOperationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOperationTypesQuery(baseOptions?: Apollo.QueryHookOptions<OperationTypesQuery, OperationTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OperationTypesQuery, OperationTypesQueryVariables>(OperationTypesDocument, options);
      }
export function useOperationTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationTypesQuery, OperationTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OperationTypesQuery, OperationTypesQueryVariables>(OperationTypesDocument, options);
        }
export type OperationTypesQueryHookResult = ReturnType<typeof useOperationTypesQuery>;
export type OperationTypesLazyQueryHookResult = ReturnType<typeof useOperationTypesLazyQuery>;
export type OperationTypesQueryResult = Apollo.QueryResult<OperationTypesQuery, OperationTypesQueryVariables>;