import React from 'react';
import { CommonIcons } from './types';
import { SvgIconProps } from '@mechis/elements/SvgIcon';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import InfoIcon from '@mui/icons-material/Info';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import TimelineIcon from '@mui/icons-material/Timeline';
import CarRentalIcon from '@mui/icons-material/CarRental';
import SpeedIcon from '@mui/icons-material/Speed';
import PaidIcon from '@mui/icons-material/Paid';
import BuildIcon from '@mui/icons-material/Build';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import EvStationIcon from '@mui/icons-material/EvStation';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  MechisCar,
  MechisTruck,
  MechisSportCar,
  MechisVintageCar,
  MechisTrailer,
  MechisCrane,
  MechisMower,
  MechisVin,
  MechisSerialNumber,
  MechisEngineSpecification,
  MechisEngineDisplacement,
  MechisTorque,
  MechisTransmission,
  MechisTransmissionNumber,
  MechisHydrogen,
  MechisAddition,
  MechisAdjustment,
  MechisCalibration,
  MechisCheck,
  MechisReplacement,
  MechisRevision,
} from '@mechis/icons';

// todo: bad way of import, why this above reexport pattern does not work?
import MechisModel from '@mechis/icons/MechisModel/MechisModel';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const useIcon = (icon: CommonIcons, props?: SvgIconProps) => {
  switch (icon) {
    case 'attachment':
      return <AttachFileIcon {...props} />;
    case 'dashboard':
      return <DashboardRoundedIcon {...props} />;
    case 'info':
      return <InfoIcon {...props} />;
    case 'record':
      return <EventNoteIcon {...props} />;
    case 'photo':
      return <PhotoCameraIcon {...props} />;
    case 'maintenance':
      return <DepartureBoardIcon {...props} />;
    case 'analysis':
      return <TimelineIcon {...props} />;
    case 'techSettings':
      return <PermDataSettingIcon {...props} />;
    case 'newTech':
      return <CarRentalIcon {...props} />;
    case 'newOdometer':
      return <SpeedIcon {...props} />;
    case 'newRefueling':
      return <LocalGasStationIcon {...props} />;
    case 'newExpenses':
      return <PaidIcon {...props} />;
    case 'newMaintenance':
      return <BuildIcon {...props} />;
    case 'newReminders':
      return <NotificationsActiveIcon {...props} />;
    case 'moto':
      return <TwoWheelerIcon {...props} />;
    case 'mechisCar':
      return <MechisCar {...props} />;
    case 'mechisTruck':
      return <MechisTruck {...props} />;
    case 'mechisSportCar':
      return <MechisSportCar {...props} />;
    case 'mechisVintageCar':
      return <MechisVintageCar {...props} />;
    case 'mechisTrailer':
      return <MechisTrailer {...props} />;
    case 'mechisCrane':
      return <MechisCrane {...props} />;
    case 'mechisMower':
      return <MechisMower {...props} />;
    case 'mechisAddition':
      return <MechisAddition {...props} />;
    case 'mechisAdjustment':
      return <MechisAdjustment {...props} />;
    case 'mechisCalibration':
      return <MechisCalibration {...props} />;
    case 'mechisCheck':
      return <MechisCheck {...props} />;
    case 'mechisReplacement':
      return <MechisReplacement {...props} />;
    case 'mechisRevision':
      return <MechisRevision {...props} />;
    case 'fuel-gasoline':
      return <LocalGasStationIcon {...props} />;
    case 'fuel-diesel':
      return <OilBarrelIcon {...props} />;
    case 'fuel-LPG':
      return <GasMeterIcon {...props} />;
    case 'fuel-CNG':
      return <GasMeterIcon {...props} />;
    case 'fuel-electric':
      return <EvStationIcon {...props} />;
    case 'fuel-LNG':
      return <LocalGasStationIcon {...props} />;
    case 'fuel-ethanol E85':
      return <LocalGasStationIcon {...props} />;
    case 'fuel-hydrogen':
      return <MechisHydrogen {...props} />;
    case 'fuel-other':
      return <LocalGasStationIcon {...props} />;
    case 'mechisModel':
      return <MechisModel {...props} />;
    case 'mechisVin':
      return <MechisVin {...props} />;
    case 'mechisSerialNumber':
      return <MechisSerialNumber {...props} />;
    case 'mechisEngineSpecification':
      return <MechisEngineSpecification {...props} />;
    case 'mechisEngineDisplacement':
      return <MechisEngineDisplacement {...props} />;
    case 'mechisTorque':
      return <MechisTorque {...props} />;
    case 'mechisTransmission':
      return <MechisTransmission {...props} />;
    case 'mechisTransmissionNumber':
      return <MechisTransmissionNumber {...props} />;
    case 'person':
      return <PersonOutlineIcon {...props} />;
    case 'settings':
      return <SettingsIcon {...props} />;
    default:
      return <></>;
  }
};

export default useIcon;
