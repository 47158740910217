import { styled } from '@mui/material/styles';
import { Box, TableCell } from '@mechis/elements';

export const Block = styled(Box, {
  name: 'SimpleTable',
  slot: 'Root',
})(() => ({

}));

export const CellLabel = styled(TableCell, {
  name: 'SimpleTable',
  slot: 'CellLabel',
})(() => ({

}));

export const CellValue = styled(TableCell, {
  name: 'SimpleTable',
  slot: 'CellValue',
})(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  textAlign: 'right',
}));

