import React, { FC } from 'react';
import { IProps } from './types';
import {
  TextField,
  InputAdornment,
} from '@mechis/elements';
import { useQuery } from '@apollo/client';
import addDeviceCacheControl from '@state/mutations/addDevice';
import { IAddDeviceQuery } from '@state/queries/addDevice/types';
import { GET_ADD_DEVICE_TMP_STORE } from '@state/queries/addDevice';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import useIcon from '@mechis/icons/useIcon';
import {useAddDeviceSetValidationError} from '@hooks/validationHooks/useAddDeviceSetValidatonError';

const inputIdentifier = 'vin';
const Vin: FC<IProps> = () => {
  const { t } = useTranslation();
  const { data } = useQuery<IAddDeviceQuery>(GET_ADD_DEVICE_TMP_STORE);
  const { setVin } = addDeviceCacheControl;

  const schema = yup.object({
    vin: yup.string().matches(/^[A-HJ-NPR-Z-a-hj-npr-z-0-9-/]{8,17}$/, {
      excludeEmptyString: true,
      message: t('addDevice.components.vin.invalidCharacters'),
    }),
  }).required();

  const { control, handleSubmit, formState: { errors } } = useForm<{ vin: string | null }>({
    defaultValues: {
      vin: data?.addDevice.vin?.toUpperCase(),
    },
    resolver: yupResolver(schema),
  });

  useAddDeviceSetValidationError(inputIdentifier, errors.vin?.message);

  const onSave = async ({ vin }: { vin: string | null }) => {
    setVin(vin?.toUpperCase());
  };

  return (
    <Controller
      render={({ field, formState }) => (
        <TextField
          {...field}
          onKeyUp={() => {
            handleSubmit(onSave)();
          }}
          label={t('addDevice.components.vin.name')}
          id="vin"
          error={!!formState.errors.vin?.message}
          helperText={formState.errors.vin?.message}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {useIcon('mechisVin')}
              </InputAdornment>
            ),
            inputProps: {
              style: {
                textTransform: 'uppercase',
              },
            },
          }}
        />
      )}
      name="vin"
      control={control}
    />
  );
};

export default Vin;
