import { styled } from '@mui/material/styles';

export const Editable = styled('div', {
  name: 'Avatar',
  slot: 'Editable',
})(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  position: 'relative',
  '& > button': {
    position: 'absolute',
    bottom: '-25px',
    right: '-20px',
  },
  '& > img': {
    borderRadius: '16px',
  },
}));
