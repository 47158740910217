import React, { FC, useEffect } from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '../Slide';

// todo: make typescript definitions here
const HideOnScroll: FC<any> = ({ children, window, onTrigger }) => {
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  useEffect(() => {
    onTrigger(trigger);
  }, [ trigger ]);

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
