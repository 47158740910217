import React, { useEffect, useState } from 'react';
import { IProps } from './types';
import { initializeApp } from 'firebase/app';
import { getStorage, ref, uploadBytes, getMetadata } from 'firebase/storage';
import { v4 } from 'uuid';
import {
  useCreateAttachmentMutation,
} from '@state/mechis-backend/generated/schema';
import snackbarCacheControl from '@state/mutations/snackbar';
import { useAttachments } from '@hooks/useAttachments';

const FirebaseUploadWidget: React.FC<IProps> = ({
  setFirebaseAttachmentInfo,
  linkingCallback,
  folder,
  firebaseConfig,
  children,
}) => {
  const [ attachmentUpload, setAttachmentUpload ] = useState<File | null>(null);
  const [ createAttachment ] = useCreateAttachmentMutation();
  const [ hasValidationError, setHasValidationError ] = useState<boolean>(false);
  const { setSnackbar } = snackbarCacheControl;
  const { isImageType } = useAttachments();

  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);

  useEffect(() => {
    if (attachmentUpload !== null) {
      uploadFile();
    }
  }, [ attachmentUpload ]);

  const setAttachmentUploadValidated = (file: File) => {
    if (isImageType(file.type)) {
      setHasValidationError(true);
    } else {
      setHasValidationError(false);
      setAttachmentUpload(file);
    }
  };

  const uploadFile = async () => {
    if (attachmentUpload === null) return;

    const imageRef = ref(storage, `${folder}/${attachmentUpload.name + v4()}`);

    try {
      const snapshot = await uploadBytes(imageRef, attachmentUpload);
      const metadata = await getMetadata(snapshot.ref);

      const resultAttachment = await createAttachment({
        variables: {
          attachment: {
            originalName: attachmentUpload.name,
            storageName: metadata.fullPath,
            version: 1, //nothing like that is provided by firebase
            mimeType: metadata.contentType ?? 'text/plain',
            size: metadata.size,
          },
        },
      });

      if (linkingCallback) {
        linkingCallback(Number(resultAttachment.data?.createAttachment?.id));
      }

      setFirebaseAttachmentInfo({
        id: Number(resultAttachment.data?.createAttachment?.id),
        originalName: attachmentUpload.name,
        storageName: metadata.fullPath,
        mimeType: metadata.contentType ?? 'text/plain',
        size: metadata.size,
      });
      setAttachmentUpload(null);
    } catch (error) {
      setSnackbar('error', 'FIREBASE_UPLOAD_ERROR');
    }
  };

  return (
    <>
      {children({ attachmentUpload, setAttachmentUploadValidated, uploadFile, hasValidationError })}
    </>
  );
};

export default FirebaseUploadWidget;
